@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/icomoon.eot?8gmddb');
  src: url('../fonts/icomoon/icomoon.eot?m0fifg#8gmddb') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?8gmddb') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?8gmddb') format('woff'),
    url('../fonts/icomoon/icomoon.svg?8gmddb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-add-icon:before {
  content: "\e977";
}
.icon-excel:before {
  content: "\e978";
}
.icon-bin:before {
  content: "\e973";
}
.icon-cross-menu:before {
  content: "\e974";
}
.icon-edit-2:before {
  content: "\e975";
}
.icon-more-vertical:before {
  content: "\e976";
}
.icon-interface:before {
  content: "\e971";
}
.icon-math:before {
  content: "\e972";
}
.icon-activeuser:before {
  content: "\e967";
}
.icon-algo-logo-left:before {
  content: "\e968";
}
.icon-dashboard1:before {
  content: "\e969";
}
.icon-disableuser:before {
  content: "\e96a";
  color: #bfbfbf;
}
.icon-dots:before {
  content: "\e96b";
  color: #00b186;
}
.icon-growth:before {
  content: "\e96c";
}
.icon-plus1:before {
  content: "\e96d";
  color: #f99c20;
}
.icon-rules:before {
  content: "\e96e";
}
.icon-search1:before {
  content: "\e96f";
}
.icon-user:before {
  content: "\e970";
}
.icon-image:before {
  content: "\e964";
}
.icon-folder:before {
  content: "\e963";
}
.icon-camera1:before {
  content: "\e962";
}
.icon-pdf:before {
  content: "\e965";
}
.icon-newspaper:before {
  content: "\e961";
}
.icon-downloadDoc:before {
  content: "\e95c";
}
.icon-jpgDoc:before {
  content: "\e95d";
}
.icon-pdfDoc:before {
  content: "\e95e";
}
.icon-pngDoc:before {
  content: "\e95f";
}
.icon-removeDoc:before {
  content: "\e960";
}
.icon-delete1:before {
  content: "\e95a";
}
.icon-download:before {
  content: "\e95b";
}
.icon-facebook:before {
  content: "\e957";
}
.icon-insta:before {
  content: "\e958";
}
.icon-twitter:before {
  content: "\e959";
}
.icon-check:before {
  content: "\e950";
}
.icon-dealership:before {
  content: "\e951";
}
.icon-history .path1:before {
  content: "\e952";
  color: rgb(0, 0, 0);
}
.icon-history .path2:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(19, 31, 107);
}
.icon-inspection-center:before {
  content: "\e954";
}
.icon-inventory:before {
  content: "\e955";
}
.icon-warehouse:before {
  content: "\e956";
  color: #7379a4;
}
.icon-INPRG:before {
  content: "\e942";
}
.icon-INSCOMPLET:before {
  content: "\e943";
}
.icon-IV:before {
  content: "\e944";
}
.icon-IVIP:before {
  content: "\e945";
}
.icon-IVS:before {
  content: "\e946";
}
.icon-PQR:before {
  content: "\e947";
}
.icon-PQS:before {
  content: "\e948";
}
.icon-PR:before {
  content: "\e949";
}
.icon-PUR:before {
  content: "\e94a";
}
.icon-PURREJ:before {
  content: "\e94b";
}
.icon-PUS:before {
  content: "\e94c";
}
.icon-RFINSP:before {
  content: "\e94d";
}
.icon-RFP:before {
  content: "\e94e";
}
.icon-RFV:before {
  content: "\e94f";
}
.icon-drop-arrow:before {
  content: "\e941";
  color: #52585b;
}
.icon-manage-warehouse:before {
  content: "\e966";
}
.icon-camera:before {
  content: "\e92b";
}
.icon-docs:before {
  content: "\e93c";
}
.icon-export:before {
  content: "\e93d";
}
.icon-multi-files:before {
  content: "\e93e";
}
.icon-print:before {
  content: "\e93f";
}
.icon-status-not-avialable:before {
  content: "\e940";
}
.icon-attachment:before {
  content: "\e929";
}
.icon-Camera-Upload-Default:before {
  content: "\e92a";
}
.icon-Comment:before {
  content: "\e92c";
}
.icon-Refresh:before {
  content: "\e92d";
}
.icon-Success .path1:before {
  content: "\e92e";
  color: rgb(243, 246, 251);
}
.icon-Success .path2:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(243, 246, 251);
}
.icon-Success .path3:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(135, 208, 174);
}
.icon-Success .path4:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(254, 153, 116);
}
.icon-Success .path5:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(178, 140, 208);
}
.icon-Success .path6:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Success .path7:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(240, 243, 246);
}
.icon-Success .path8:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(84, 89, 107);
}
.icon-Tick .path1:before {
  content: "\e936";
  color: rgb(13, 184, 126);
}
.icon-Tick .path2:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Trash .path1:before {
  content: "\e938";
  color: rgb(226, 76, 76);
}
.icon-Trash .path2:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(254, 254, 254);
}
.icon-Trash .path3:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(254, 254, 254);
}
.icon-Trash .path4:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(254, 254, 254);
}
.icon-clock:before {
  content: "\e927";
}
.icon-start-inspection:before {
  content: "\e928";
}
.icon-view-customers:before {
  content: "\e926";
  color: #ff6b00;
}
.icon-more-data:before {
  content: "\e925";
}
.icon-add:before {
  content: "\e900";
}
.icon-add-user:before {
  content: "\e901";
}
.icon-afternoon:before {
  content: "\e902";
}
.icon-auction:before {
  content: "\e903";
}
.icon-avatar:before {
  content: "\e904";
}
.icon-back-arrow:before {
  content: "\e905";
}
.icon-notification:before {
  content: "\e906";
}
.icon-block:before {
  content: "\e907";
}
.icon-car-gear:before {
  content: "\e908";
}
.icon-close:before {
  content: "\e909";
}
.icon-cross-slim:before {
  content: "\e90a";
}
.icon-delete:before {
  content: "\e90b";
}
.icon-down:before {
  content: "\e90c";
}
.icon-edit:before {
  content: "\e90d";
}
.icon-up:before {
  content: "\e90e";
}
.icon-purchase:before {
  content: "\e90f";
}
.icon-inspection:before {
  content: "\e910";
}
.icon-dashboard:before {
  content: "\e911";
}
.icon-calendar:before {
  content: "\e912";
}
.icon-ico-two:before {
  content: "\e913";
}
.icon-logo:before {
  content: "\e914";
}
.icon-mileage:before {
  content: "\e915";
}
.icon-minus:before {
  content: "\e916";
}
.icon-more:before {
  content: "\e917";
}
.icon-morning:before {
  content: "\e918";
}
.icon-next-next:before {
  content: "\e919";
}
.icon-next-previous:before {
  content: "\e91a";
}
.icon-insp:before {
  content: "\e91b";
}
.icon-filter:before {
  content: "\e91c";
}
.icon-tick-blank:before {
  content: "\e91d";
}
.icon-tick-filled:before {
  content: "\e91e";
}
.icon-plus:before {
  content: "\e91f";
}
.icon-report:before {
  content: "\e920";
}
.icon-search:before {
  content: "\e921";
}
.icon-sunrise:before {
  content: "\e922";
}
.icon-view:before {
  content: "\e923";
}
.icon-warning:before {
  content: "\e924";
}
