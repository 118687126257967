  //media queries
  $media-breakpoint-sm-up: 576px;
  $media-breakpoint-md-up: 768px;
  $media-breakpoint-lg-up: 992px;
  $media-breakpoint-xl-up: 1200px;
  $media-breakpoint-xxl-up: 1400px;
  $media-breakpoint-xxl-screen-up: 1900px;

  $media-breakpoint-sm-down: 575.98px;
  $media-breakpoint-md-down: 767.98px;
  $media-breakpoint-lg-down: 991.98px;
  $media-breakpoint-xl-down: 1199.98px;
  $media-breakpoint-xxl-down: 1399.98px;
  $media-breakpoint-xxl-screen-down: 1899.98px;

//flex mixin
@mixin flex-row($flex, $row, $align, $justify) {
    display: $flex;
    flex-direction: $row;
    align-items: $align;
    justify-content: $justify;
}

@mixin flex-center($flex, $align, $justify) {
    display: $flex;
    align-items: $align;
    justify-content: $justify;
}

@mixin flex-align($flex, $align) {
    display: $flex;
    align-items: $align;
}

@mixin flex-justify($flex, $justify) {
    display: $flex;
    justify-content: $justify;
}

@mixin flex-between($flex, $space-between) {
    display: $flex;
    justify-content: $space-between;
}

@mixin align-justify($align, $justify) {
    align-items: $align;
    justify-content: $justify;
}

@mixin flex-column($flex, $column, $align, $justify) {
    display: $flex;
    flex-direction: $column;
    align-items: $align;
    justify-content: $justify;
}

@mixin flex-column-between($flex, $column, $space-between) {
    display: $flex;
    flex-direction: $column;
    justify-content: $space-between;
}

@mixin flex-justify-between($flex, $align, $space-between) {
    display: $flex;
    align-items: $align;
    justify-content: $space-between;
}

@mixin flex-justify-end($flex, $align, $flex-end) {
    display: $flex;
    align-items: $align;
    justify-content: $flex-end;
}

//border radius mixin
@mixin border-radius($radius) {
    border-radius: $radius;
}