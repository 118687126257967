@import 'mixins';
.p-sidebar-left{
  transition-duration: 600ms;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  top: 0px;
  left: 50px;
  width: 240px;
  height: 100%;
  @media (max-width: 1800px){
    width: 200px;
  }
  @media screen and (min-width: 320px) and (max-width: 1024px){
    left: 0;
    top: 32px;
  }
  .p-sidebar-content{
    overflow: hidden;
  }
}
.p-sidenav-content{
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: transform, margin-left, margin-right;
  z-index: 1;
  position: relative;
}

.sidebar-wrapper{
  .page-head{
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    @media (min-width: 992px){
      left: 303px !important;
    }
  }
  &.p-sidenav-content{
    margin-left: 240px;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: transform, margin-left, margin-right;
    z-index: 1;
    position: relative;
    @media (max-width: 1800px){
      margin-left: 200px;
    }
    @media screen and (min-width: 320px) and (max-width: 1024px){
      margin-left: 0;
    }
  }
  .p-sidebar-left{
    box-shadow: none;
    border-right: 1px solid #666666 !important;
    visibility: visible;
    transform: none;
  }
  .p-toolbar{
    left: 290px;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: transform, left, right;
    @media (max-width: 1800px){
      left: 250px;
    }
    @media screen and (min-width: 320px) and (max-width: 1024px){
      left: 0;
    }
  }
}

.p-toolbar{
  z-index: var(--z-index-9999);
  position: fixed;
  min-height: 56px;
  margin-bottom: 0 !important;
  background-color: var(--primary-purple) !important;
  box-shadow: 2px 6px 6px -8px rgba(0, 0, 0, 0.2);
  min-height: 32px;
  padding: 0 16px;
  border-radius: 0;
  top: 0;
  left: 50px;
  right: 0;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: transform, left, right;
  @media screen and (min-width: 320px) and (max-width: 1024px){
    left: 0;
  }
  .toggleBtn {
    width: 20px !important;
    height: 20px !important;
    background: var(--secondary-carnation);
    box-shadow: 0px 4px 20px rgba(63, 63, 63, 0.1) !important;
    position: var(--position-relative);
    border: var(--display-none);
    @include flex-center(flex, center, center);
    @include border-radius(50%);
    min-width: 20px;
    padding: 0;

    &:focus {
      outline: none !important;
    }

    &:after {
      border-style: solid;
      border-width: 0 2px 2px 0;
      content: "";
      display: var(--display-inline-block);
      font-size: 15px !important;
      color: var(--white) !important;
      padding: 3px;
      vertical-align: middle;
      transform: rotate(-45deg);
      position: var(--position-absolute);
      left: 4px;
      transition: all .5s ease;
    }
  }
  .right-user{
    .microsoft{
      width: 32px !important;
    }
  }
}

.mat-drawer {
  &.left {
    position: var(--position-fixed) !important;
  }
}

.mat-drawer-container-has-open {
  .mat-toolbar {
    .mat-toolbar-row {
      button {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
}

::ng-deep.favorites {
  &:hover {
    .fav-icon {
      transition: all ease 0.5s;
      opacity: 1;
    }
  }

  .mdc-list-item__content {
    justify-content: var(--justify-between);
    padding-right: var(--value-10) !important;
  }

  p {
    margin: var(--value-0);
    font-size: var(--font-size-12);
  }

  .fav-icon {
    transition: all ease 0.5s;
    opacity: 0;

    .mat-icon {
      height: 10px;
      width: 10px;
      margin: 0 5px;
      font-size: var(--font-size-16);
      line-height: inherit;

      .material-icons {
        font-size: var(--font-size-10);
        transition: all ease-in 0.3s;

        &:hover {
          transition: all ease-out 0.3s;
          font-size: var(--font-size-14);
        }
      }
    }
  }
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]),
.mat-expansion-panel:not(.mat-expanded)
  .mat-expansion-panel-header:hover:not([aria-disabled="true"])
  .mat-expansion-panel-header-title {
  background-color: transparent;
  color: var(--white);

  // &:after {
  //   background: transparent none repeat scroll 0 0;
  //   border-bottom: 10px solid transparent;
  //   border-right: 8px solid #e9e9e9;
  //   border-top: 10px solid transparent;
  //   display: var(--display-block);
  //   float: right;
  //   height: 0;
  //   position: var(--position-absolute);
  //   right: var(--value-0);
  //   content: "";
  // }
}

marquee {
  background-color: var(--Alabaster);

  div {
    width: 1000%;
  }

  p {
    float: left;
    width: auto;
    margin: var(--value-0);
    padding-right: var(--value-30);
  }
}

@keyframes bounce_notify {
  0% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: top right;
  }

  50% {
    transform: scale(1.03, 1.03);
    transform-origin: top right;
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: top right;
  }
}

::ng-deep.mat-select-arrow {
  color: var(--white) !important;
}

hr {
  margin-top: 0rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid var(--white) !important;
}

.cv-powered-by {
  font-size: 9px;
  color: #dce1de;
  margin-top: 6px;
}
#current_version {
  font-size: var(--font-size-10);
}
