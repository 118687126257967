@import 'mixins';

//newscreen
.home-base-wrapper {
  padding-right: var(--value-30);
}

.welcome-dashboard {
  background-color: var(--gray-100);
  padding: 20px 10px 20px 25px;

  .material-icons {
    font-size: var(--font-size-22);
    margin-left: var(--value-10);
    color: var(--rolling-stone);
   
  }

  h1 {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-500);
    color: var(--black);
  }

  span {
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-500);
    color: var(--black);
  }
}

.favorites-list {
  ul {
    li {
      position: var(--position-relative);

      &:hover {
        .cancel-fav {
          display: var(--display-flex);
        }
      }
    }
  }

  .cancel-fav {
    display: var(--display-none);
    width: 18px;
    height: 18px;
    @include border-radius(50%);
    background-color: var(--rolling-stone);
    position: var(--position-absolute);
    right: 7px;
    @include align-justify(center, center);
    cursor: var(--cursor-pointer);
    top: 8px;

    .mat-icon {
      font-size: var(--font-size-14);
      color: var(--white);
      width: 14px;
      height: 14px;
    }
  }
}

.favorites-list,
.tasks-list {
  margin-top: var(--value-20);
}

.favorites-list,
.tasks-list,
.name-list {
  text-align: var(--text-center);
  border: 1px solid var(--gray-100);

  h3 {
    font-size: var(--font-size-14);
    font-style: normal;
    font-weight: var(--font-weight-700);
    color: var(--black);
    background-color: var(--gray-100);
    padding: var(--value-10);
    height: 40px;
    @include flex-center(flex, center, center);
    margin: var(--value-0);
  }

  .fav-list-wrapper {
    height: 356px;
    min-height: 356px;
    position: var(--position-relative);

    .fav-list-text {
      margin: var(--value-0);
      @include flex-center(flex, center, center);
      height: var(--height-100);
      color: var(--black);
      font-size: var(--font-size-14);
      font-weight: var(--font-weight-400);
      opacity: .6;
    }
  }

  ul {
    margin: var(--value-0);
    list-style-type: var(--display-none);
    padding: var(--value-10);

    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: var(--value-0);
      }

      &.active {
        span {
          background-color: var(--primary-purple);
          color: var(--white);
          border: var(--display-none);
        }
      }

      span {
        @include border-radius(5px);
        border: 1px solid rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.87);
        padding: 8px 24px;
        @include flex-center(flex, center, center);
        text-align: var(--text-center);
        font-size: var(--font-size-12);
        font-style: normal;
        font-weight: var(--font-weight-500);
        background: var(--Alabaster);
        height: 35px;
        cursor: var(--cursor-pointer);
      }
    }
  }
}

.kpi-list-wrapper {
  .mat-standard-chip {
    &:after {
      background: transparent;
    }

    .mat-chip-ripple {
      display: var(--display-none);
    }
  }
}

//layout css
.layout-icon {
  position: var(--position-relative);
  padding-left: var(--value-20);
  &.icon-disabled{
    .mat-icon{
      pointer-events: var(--display-none);
    }
    .layout-wrapper{
      display: var(--display-none);
    }
  }
  &:hover {
    transition: all ease-in 0.5s;

    .layout-wrapper {
      opacity: 1;
      visibility: visible;
    }
  }

  .mat-icon {
    font-size: var(--font-size-30);
    width: 30px;
    height: 27px;
    color: var(--rolling-stone);
   
  }

  .layout-wrapper {
    position: var(--position-absolute);
    background-color: var(--white);
    padding: var(--value-10);
    right: var(--value-0);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    @include border-radius(10px);
    opacity: 0;
    visibility: hidden;
    transition: all ease-out 0.5s;
    cursor: var(--cursor-pointer);
    z-index: var(--z-index-2);
    @include flex-justify-between(flex, center, space-between);
    flex-wrap: wrap;
    width: 250px;
    .layoutchange{
      &.active{
        transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        transform: scale(1.1);
    
        .one,
        .two,
        .three,
        .four {
          background-color: var(--Silver);
        }
      }
    }
  }
}


.layout-one {
  @include flex-align(flex, center);
  padding: 5px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover{
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    transform: scale(1.1);

    .one,
    .two,
    .three,
    .four {
      background-color: var(--Silver);
    }
  }

  .one {
    height: 20px;
    width: 50px;
    border: 1px solid var(--rolling-stone);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-bottom: 5px;
    background-color: var(--mercury);
    margin-right: 5px;
  }

  .two {
    height: 40px;
    width: 50px;
    border: 1px solid var(--rolling-stone);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: var(--mercury);
    margin-right: 5px;
    position: var(--position-relative);

    &:before {
      content: "";
      width: var(--width-100);
      height: 1px;
      background-color: var(--rolling-stone);
      left: var(--value-0);
      right: var(--value-0);
      position: var(--position-absolute);
      top: 50%;
    }
  }

  .three {
    height: 30px;
    width: 50px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 5px;
    background-color: var(--mercury);
    border: 1px solid var(--rolling-stone);
  }

  .four {
    height: 30px;
    width: 50px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--mercury);
    border: 1px solid var(--rolling-stone);
  }
}

.layout-two {
  @include flex-align(flex, center);
  padding: 5px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    transform: scale(1.1);

    .one,
    .two,
    .three,
    .four {
      background-color: var(--Silver);
    }
  }

  .one {
    height: 30px;
    width: 50px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-bottom: 5px;
    background-color: var(--mercury);
    border: 1px solid var(--rolling-stone);
  }

  .two {
    height: 30px;
    width: 50px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: var(--mercury);
    border: 1px solid var(--rolling-stone);
  }

  .three {
    height: 40px;
    width: 50px;
    border: 1px solid var(--rolling-stone);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--mercury);
    margin-left: 5px;
    margin-bottom: 5px;
    position: var(--position-relative);

    &:before {
      content: "";
      width: var(--width-100);
      height: 1px;
      background-color: var(--rolling-stone);
      left: var(--value-0);
      right: var(--value-0);
      position: var(--position-absolute);
      top: 50%;
    }
  }

  .four {
    height: 20px;
    width: 50px;
    border: 1px solid var(--rolling-stone);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--mercury);
    margin-left: 5px;
  }
}

.layout-three {
  @include flex-align(flex, center);
  padding: 5px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    transform: scale(1.1);

    .one,
    .two,
    .three,
    .four {
      background-color: var(--Silver);
    }
  }

  .one {
    height: 30px;
    width: 50px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-bottom: 5px;
    background-color: var(--mercury);
    border: 1px solid var(--rolling-stone);
  }

  .two {
    height: 30px;
    width: 50px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: var(--mercury);
    border: 1px solid var(--rolling-stone);
  }

  .three {
    height: 40px;
    width: 50px;
    border: 1px solid var(--rolling-stone);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--mercury);
    margin-left: 5px;
    margin-bottom: 5px;
    position: var(--position-relative);

    &:before {
      content: "";
      width: 1px;
      height: var(--height-100);
      background-color: var(--rolling-stone);
      left: var(--value-0);
      right: var(--value-0);
      position: var(--position-absolute);
      left: 50%;
    }
  }

  .four {
    height: 20px;
    width: 50px;
    border: 1px solid var(--rolling-stone);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--mercury);
    margin-left: 5px;
  }
}

.layout-four {
  @include flex-align(flex, center);
  padding: 5px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    transform: scale(1.1);

    .one,
    .two,
    .three,
    .four {
      background-color: var(--Silver);
    }
  }

  .one {
    height: 65px;
    width: 20px;
    border: 1px solid var(--rolling-stone);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: var(--mercury);
    margin-right: 5px;
  }

  .two {
    width: 35px;
    height: 65px;
    background-color: var(--mercury);
    border: 1px solid var(--rolling-stone);
  }

  .three {
    height: 30px;
    width: 40px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 5px;
    background-color: var(--mercury);
    border: 1px solid var(--rolling-stone);
    margin-left: 5px;
  }

  .four {
    height: 30px;
    width: 40px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--mercury);
    border: 1px solid var(--rolling-stone);
    margin-left: 5px;
  }
}

.layout-one-change {
  height: 860px;
  transition: all ease 2s;

  .column-change-animate-one {
    transition: all ease 2s;
    transform: translateX(0px);
  }

  .column-change-animate-two {
    transition: all ease 2s;
    transform: translateX(0px);
  }

  #column-change-two {
    .pin-icon {
      right: 30px !important;
    }
  }
}

.layout-two-change {
  height: 860px;
  transition: all ease 2s;

  #layout-change {
    #column-change-one {
      flex-direction: column-reverse;
      display: var(--display-flex);
      transition: all ease 2s;
      &.w-100{
        transform: translateX(0px) !important;
      }
    }
  }

  .home-base-list {
    .add-circle {
      right: auto !important;
      left: var(--value-0);
      top: 15px !important;
    }
  }


  .home-base-list {
    transition: all ease 2s;

    ul {
      margin-left: 45px;

      li {
        &:before {
          right: auto !important;
          left: var(--value-0);
          transform: translateX(-25px) !important;
        }
      }
    }
  }

  .column-change-animate-one {
    transition: all ease 2s;
    transform: translateX(413px);

    @media (min-width: $media-breakpoint-xxl-screen-up) {
      transform: translateX(523px);
    }
  }

  .column-change-animate-two {
    transition: all ease 2s;
    transform: translateX(-827px);

    @media (min-width: $media-breakpoint-xxl-screen-up) {
      transform: translateX(-1046px);
    }
  }

  #column-change-two {

    .home-base-graph {
      transition: all ease 2s;
    }

    .pin-icon {
      right: 30px !important;
    }
  }

}

.layout-three-change {
  height: 860px;
  transition: all ease 2s;

  #layout-change {

    #column-change-one {
      flex-direction: column-reverse;
      display: var(--display-flex);
      transition: all ease 2s;
      &.w-100{
        transform: translateX(0px) !important;
      }
    }
  }

  .column-change-animate-one {
    transition: all ease 2s;
    transform: translateX(413px);

    @media (min-width: $media-breakpoint-xxl-screen-up) {
      transform: translateX(523px);
    }
  }

  .column-change-animate-two {
    transition: all ease 2s;
    transform: translateX(-827px);

    @media (min-width: $media-breakpoint-xxl-screen-up) {
      transform: translateX(-1046px);
    }
  }

  .home-base-list {
    transition: all ease 2s;

    .add-circle {
      right: auto !important;
      left: var(--value-0);
      top: 15px !important;
    }
  }

  .column-change-two {
    height: var(--height-100);
    transition: all ease 2s;
  }

  #column-change-two {
    display: var(--display-flex);

    .home-base-graph {
      height: 735px;
      margin-right: var(--value-20);
      width: 50%;
      transition: all ease 2s;

      &:last-child {
        margin-right: var(--value-0);
      }
    }

    .pin-icon {
      right: 50px !important;
    }
  }

  .home-base-list {
    transition: all ease 2s;

    ul {
      margin-left: 45px;

      li {
        &:before {
          right: auto !important;
          left: var(--value-0);
          transform: translateX(-25px) !important;
        }
      }
    }
  }
}

.layout-four-change {
  height: 860px;

  .column-change-animate-one {
    transition: all ease 2s;
    transform: translateX(0px);
  }

  .column-change-animate-two {
    transition: all ease 2s;
    transform: translateX(0px);
  }

  #layout-change {

    #column-change-one {
      display: var(--display-flex);
      transition: all ease 2s;
      &.w-100{
        transform: translateX(0px) !important;
      }
    }
  }

  .home-base-list {
    margin-right: var(--value-20);
    height: 735px;
    transition: all ease 2s;

    >div {
      height: var(--height-100);
      transition: all ease 2s;

      .no-data-display {
        height: 100% !important;
        text-align: var(--text-center);
      }
    }

    .add-circle {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      top: 15px !important;
    }

    ul {
      li {
        .cancel-fav {
          right: 0 !important;
        }
      }
    }
  }

  .column-change-two {
    height: var(--height-100);
    width: var(--width-100);
  }

  #column-change-two {
    display: var(--display-flex);

    .home-base-graph {
      height: 735px;
      margin-right: var(--value-20);
      width: 50%;

      &:last-child {
        margin-right: var(--value-0);
      }
    }

    .pin-icon {
      right: 50px !important;
    }
  }

  .home-base-list {
    transition: all ease 2s;

    ul {
      flex-direction: column;
      margin-top: 45px !important;

      li {
        padding: 22px 0 !important;
        width: 100% !important;

        &:before {
          width: 100% !important;
          height: 1px !important;
          transform: none !important;
          top: auto !important;
        }
      }
    }

    .pin-icon {
      right: 0 !important;
    }
  }
}

//pinned css
.sidebar-pinned-components {
  position: var(--position-fixed);
  right: 50px;
  width: 30px;
  top: 40px;
  background-color: var(--gray-100);
  bottom: 60px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 3px 7px 0px rgba(0, 0, 0, 0.14), 1px 3px 8px 0px rgba(0, 0, 0, 0.12);
  z-index: var(--z-index-2);
  @include flex-column-between(flex, column, space-between);

  p {
    font-size: var(--font-size-11);
    text-align: var(--text-center);
    text-decoration: underline;
    cursor: var(--cursor-pointer);
  }

  ul {
    margin: var(--value-0);
    padding: var(--value-0);
    list-style-type: var(--display-none);
    height: 30px;
    transform: rotate(90deg);
    @include flex-align(flex, center);

    li {
      margin-top: var(--value-10);
      p {
        padding: 0 20px;
        white-space: nowrap;
        font-size: var(--font-size-12);
        position: var(--position-relative);
        cursor: var(--cursor-pointer);
        transition: all ease 0.5s;
        text-decoration: var(--display-none);

        &:hover {
          color: var(--Carnation);
          transition: all ease 0.5s;

          &:before {
            background-color: var(--Carnation);
            transition: all ease 0.5s;
          }
        }

        &:before {
          content: "";
          position: var(--position-absolute);
          height: 5px;
          background-color: var(--Alto);
          top: -10px;
          bottom: var(--value-0);
          right: 20px;
          left: 20px;
          transition: all ease 0.5s;
        }
      }
    }
  }
}

app-home-base {
  .mat-drawer-content {
    overflow: var(--overflow-hidden);
  }
}
