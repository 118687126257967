/* ESS project css start */

// project-form-error-wrapper
.ess-project {
  .item-wrapper {
    .form-wrapper-validation {
      .error {
        border: 1px solid #ff5757 !important;
        color: #ff5757;
      }

      input.mat-mdc-input-element {
        margin-top: var(--value-0);
      }
    }
  }

  .item-search-wrapper {
    .item-search-btn {
      .btn {
        &:active {
          background-color: var(--secondary-carnation) !important;
          border-color: var(--secondary-carnation) !important;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .form-fields {
      .mat-mdc-form-field-appearance-legacy {
        input {
          padding-left: var(--value-10) !important;
        }
      }
    }
  }

  .custom-mattooltip {

    p,
    h1,
    h2,
    h3,
    blockquote,
    pre,
    strong,
    em,
    ul,
    ol {
      font-size: var(--font-size-13);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: var(--overflow-hidden);
      margin: var(--value-0);
      line-height: 1.2;
    }

    ul,
    ol {
      margin: var(--value-0);
      padding: 0 0 0 15px;
    }
  }

  .date-picker-wrapper {
    @media (max-width: 425px){
      margin-bottom: var(--value-20);
    }
    mat-hint {
      color: black;
    }

    mat-datepicker-toggle {
      color: var(--secondary-carnation);
      position: var(--position-relative);
      top: 25px;
    }
  }
  .ag-theme-material .ag-center-cols-viewport .ag-row .ag-cell{
    border: none !important;
  }
}

.mat-calendar-body-selected {
  background-color: var(--French-Rose) !important;

  &:focus {
    background-color: var(--French-Rose) !important;
  }
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--French-Rose) !important;
  color: var(--white);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: transparent;
}

.mat-datepicker-content .mat-calendar-previous-button {
  margin-right: 15px;
}

.mat-datepicker-content .mat-calendar-next-button {
  margin-right: var(--value-10);
}

.mat-calendar-body-active {
  .mat-calendar-body-today {
    color: var(--white);
    background-color: var(--French-Rose) !important;
  }
}

.btn-primary {
  &:active {
    background-color: var(--secondary-carnation) !important;
    border-color: var(--secondary-carnation) !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.btn-secondary {
  &:active {
    background-color: transparent !important;
    border-color: var(--secondary-carnation) !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

input {
  font-size: var(--font-size-12);
}

.algo-light-theme {
  .ess-project {
    background: var(--white) !important;
  }
}
.company-user-name p {
  overflow: var(--overflow-hidden);
  text-overflow: ellipsis;
}
.task-detail-dialog-ess{
  @media (max-width: 425px){
    max-width: 95vw !important;
    height: 70% !important;
  }
}
  


/* ESS project css end */
