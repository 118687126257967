@import 'mixins';
.grid-filter-row {
  box-shadow: 0px 3px 3px rgba(63, 63, 63, 0.1);
  padding: 2px;
}

.filter-clear-btn {
  width: 10%;
  padding-right: var(--value-0);
  display: var(--display-flex);
  margin-top: auto;

  button {
    width: var(--width-100);
    @include border-radius(0);;
  }
}

.grid-action-btn-bar {
  width: var(--width-100);
  display: inline-flex;
  justify-content: flex-end;
  padding: 10px 0;

  .title-label {
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: large;
    font-weight: var(--font-weight-500);
  }

  .grid-actions {
    display: var(--display-flex);
    margin-right: auto;

    .filtered-info {
      padding-left: 15px;
      border-left: 1px solid grey;
    }
  }

  .toggle-btn {
    font-weight: var(--font-weight-600);
    font-size: medium;
    margin: auto 0;
  }

  .mh-auto {
    margin: auto 0;
  }
}

.add-icon-btn {
  .mat-icon-button {
    box-shadow: 0 3px 8px 0 rgba(141, 0, 157, 0.68);
    margin-left: var(--value-10);
  }

  .material-icons {
    font-size: 50px !important;
    color: var(--primary-purple);
    cursor: var(--cursor-pointer);
    margin-left: -4px;
  }

  padding-top: var(--value-10);
  padding-bottom: var(--value-10);
  @include flex-justify-end(flex, center, flex-end);
}

.dialog-close-btn {
  display: inline-flex;
  width: var(--width-100);
}

.dialog-close-btn button {
  margin-left: auto;
}

.info-icon-class {
  font-size: 12px !important;
  width: 12px !important;
  height: 12px !important;
  color: darkgrey;
}

.cursor-pointer {
  cursor: var(--cursor-pointer);
}

.hover-effect {
  transition: 0.2s all;
}

.hover-effect:hover {
  scale: 1.03;
}

.bg-gray {
  background: var(--gray-100);
}

.bg-yellow {
  background: yellow;
}

.top-border {
  border-top: 1px solid darkgray;
}

.dialog-grid {
  .ag-body-viewport {
    max-height: 400px !important;
  }
}

.item-wrapper textarea.text-area-class {
  width: 100% !important;
  border: none !important;
  padding: 0 !important;
  padding-left: var(--value-10) !important;
  text-align: var(--text-left) !important;
  background-color: var(--gray-100) !important;

  @media (min-width: $media-breakpoint-md-up) {
    padding: 0 10px !important;
  }

  &:focus {
    outline: var(--display-none);
  }
}

app-visible-outs,
app-data-result-grid,
app-data-applied-grid {
  .mat-card-header {

    .mat-card-header-text {
      width: 100% !important;
    }
  }

  .mat-card {
    @include border-radius(10px !important);
    border: 1px solid var(--black) !important;

  }
}

app-analysis-grid,
app-data-detail-grid,
app-data-result-grid,
app-data-applied-grid {
  .ag-theme-material .ag-header {
    height: 90px !important;
    min-height: 91px !important;
  }

}

.ag-theme-material {
  &.applied-grid {
    .ag-header-group-text {
      color: white !important;
      font-size: larger;
    }
  }
}

.pog-actions {
  .mat-tab-body-content {
    overflow: hidden !important;
  }

}
.promo-master {
  .mat-tab-body-content {
    overflow: hidden !important;
  }

}

app-pog-import {
  .ag-header-cell {
    color: var(--gray-100);
  }

  .ag-header-container,
  .ag-header-row,
  .ag-header-cell {
    left: 15% !important;
    width: 100% !important;
    text-align: var(--text-center) !important;
    color: #CBCBCB !important;
  }
}