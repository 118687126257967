@import 'mixins';
//prime ng tree view
.p-treeselect {
  @include border-radius(0);
  color: var(--color-30);
  font-weight: normal;
  height: 48px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
  border: var(--display-none);
  margin: var(--value-0);
  padding: 0 20px 0 35px;
  background-color: var(--white);
  width: var(--width-100);

  &.p-focus {
    border: none !important;
  }

  .p-treeselect-label-container {
    @include flex-align(flex, center);

    .p-treeselect-label {
      padding: var(--value-0);
      color: black;
      width: 400px;
      font-size: var(--font-size-14);
    }
  }

  .p-treeselect-label.p-placeholder {
    padding: var(--value-0);
    color: black;
  }

  .p-treeselect-trigger {
    width: auto;

    .p-treeselect-trigger-icon {
      font-size: var(--font-size-12);
      font-weight: var(--font-weight-500);
      color: black;
      width: 12px;
      height: 12px;
    }
  }
}

#fb-forecast-new-planner {
  .btn-wrapper {
    .adjust-btn {
      width: var(--width-100);
      text-align: var(--text-center);
      height: 48px;
      @include border-radius(0);
      padding: 0 50px;
      font-weight: var(--font-weight-500);
      justify-content: var(--justify-center);
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
  }
}

.p-multiselect-panel,
.p-dropdown-panel {
  .save-clear-btn {
    background-color: white;
    position: var(--position-absolute);
    z-index: var(--z-index-1);
    top: 41px;
    left: var(--value-0);
    right: var(--value-10);

    .col-md-6 {
      border-bottom: 1px solid;
    }

    .btn {
      height: 40px;
      @include flex-center(flex, center, center);
      @include border-radius(0);
      background-color: transparent !important;
      border: var(--display-none);
      color: var(--black) !important;
      font-size: var(--font-size-14);
      outline: var(--display-none);
      min-width: auto;

      &:focus {
        box-shadow: var(--display-none);
      }

      &:active {
        background-color: transparent;
      }

      &.btn-primary {
        border-right: 1px solid var(--black);
      }

      .mdc-button-wrapper {
        justify-content: var(--justify-center);
      }
    }
  }
}

.classification-dropdown {

  .p-dropdown-items-wrapper,
  .p-multiselect-items-wrapper {
    padding-top: 41px;
  }
}

//tree grid dropdown
.p-treeselect-panel {
  .save-clear-btn {
    background-color: white;
    position: var(--position-absolute);
    z-index: var(--z-index-1);
    top: 41px;
    left: var(--value-0);
    right: var(--value-10);

    .col-md-6 {
      border-bottom: 1px solid;
    }

    .btn {
      height: 40px;
      @include flex-center(flex, center, center);
      @include border-radius(0);
      background-color: transparent !important;
      border: var(--display-none);
      color: var(--black) !important;
      font-size: var(--font-size-14);
      outline: var(--display-none);

      &:focus {
        box-shadow: var(--display-none);
      }

      &:active {
        background-color: transparent;
      }

      &.btn-primary {
        border-right: 1px solid var(--black);
      }

      .mdc-button-wrapper {
        justify-content: var(--justify-center);
      }
    }
  }

  .save-clear-dropdown {
    position: var(--position-absolute);
    right: 28px;
    top: 8px;
    z-index: var(--z-index-1);

    .material-icons {
      font-size: var(--font-size-22);
      color: rgba(0, 0, 0, 0.6);
      cursor: var(--cursor-pointer);
    }
  }

  .expand-collapse-icon {
    position: var(--position-absolute);
    top: 7px;
    left: 5px;
    z-index: var(--z-index-1);
  }

  .p-treeselect-header {
    padding: 0 10px;

    .p-treeselect-close {
      width: 20px;
    }

    .p-treeselect-filter-container {
      position: var(--position-relative);
      margin: var(--value-0);
      flex: inherit;
      width: calc(100% - 45px);
      height: 30px;
    }

    .p-treeselect-close:enabled:hover {
      background: var(--display-none);
    }

    .p-treeselect-filter-container {
      .p-inputtext {
        // margin-left: var(--value-20);
        border: var(--display-none);
        height: 30px;
        padding: 0 10px;
        text-align: var(--text-left);
        background-color: var(--gray-100);
        @include border-radius(5px);

        @media (max-width: 1800px) {
          font-size: var(--font-size-13);
        }

        &:focus {
          box-shadow: var(--display-none);
        }
      }

      .p-treeselect-filter-icon {
        right: var(--value-10);
        background-color: var(--gray-100);
      }
    }

    .pi-times {
      left: 5px;
    }
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content {
    padding: 12px 30px;

    @media (max-width: 1800px) {
      padding: 8px 20px;
    }
  }
}

.p-treeselect-filter-icon {
  position: var(--position-absolute);
  top: 50%;
  margin-top: -0.5rem;
  color: #0009;
}

.p-tree {
  padding: var(--value-0);

  .p-tree-container {
    .p-treenode {
      padding: var(--value-0);
      min-height: 40px !important;
      height: auto !important;
      &.ag-tree-node {
        .p-treenode-content {
          &.p-highlight {
            background-color: var(--French-Rose) !important;
            color: var(--white);

            .p-tree-toggler {
              color: var(--white);
            }
            &:after{
              content: "";
              position: var(--position-absolute);
              top: var(--value-0);
              right: var(--value-0);
              bottom: var(--value-0);
              left: -30px;
              background-color: var(--French-Rose) !important;
              color: var(--white);
              z-index: var(--z-index-negative1);
            }
          }
        }
      }

      .p-treenode-content {
        @include border-radius(0);
        font-size: var(--font-size-12);
        padding: 16px 45px;
        position: var(--position-relative);

        @media (max-width: 1800px) {
          font-size: var(--font-size-10);
        }

        &:before {
          content: "";
          border-bottom: 1px solid;
          position: var(--position-absolute);
          left: -100px;
          right: var(--value-0);
          bottom: var(--value-0);
        }

        &.p-treenode-selectable:not(.p-highlight):hover {
          background-color: transparent;
          color: var(--black);
        }

        &.p-highlight {
          background-color: transparent !important;
          color: var(--black);

          .p-tree-toggler {
            color: rgba(0, 0, 0, 0.6);
            .p-tree-toggler-icon{
              width: 12px;
              height: 12px;
            }
          }
        }

        .p-tree-toggler {
          position: var(--position-absolute);
          right: var(--value-0);
          .p-tree-toggler-icon{
            width: 12px;
            height: 12px;
          }
          .pi-chevron-down {
            transform: rotate(90deg);
          }
        }
      }

      .p-checkbox {
        .p-checkbox-box {
          border-color: #c1c1c1;

          @media (max-width: 1800px) {
            width: 15px;
            height: 15px;
          }

          &.p-highlight {
            border-color: var(--Wild-Strawberry);
            background: var(--Wild-Strawberry);

            &:hover {
              border-color: var(--Wild-Strawberry);
              background: var(--Wild-Strawberry);
            }

            .p-checkbox-icon.pi-check:before {
              top: 7px;
              left: 2px;
            }
          }
        }
      }

      .p-checkbox-disabled {
        .p-checkbox-box {
          &.p-highlight {
            border-color: #c1c1c1;
            background: #c1c1c1;
          }
        }
      }
    }
  }
}

//algo planner accordion
.algo-planner-accordion {
  padding: 40px 50px 0;
  display: var(--display-block);

  @media (max-width: $media-breakpoint-xxl-screen-down) {
    padding: 30px 25px 0;
  }

  .graph-img {
    @include flex-justify-end(flex, center, flex-end);
    margin-bottom: var(--value-30);
    padding-right: var(--value-20);
    margin-top: var(--value-10);
  }

  .text-align-center {
    text-align: var(--text-center);
  }

  .graph-legends {
    padding: var(--value-0);
    margin: var(--value-0);
    list-style-type: var(--display-none);
    @include flex-center(flex, center, center);
    flex-wrap: wrap;
    width: var(--width-100);

    .legend-label {
      margin: var(--value-10);
      cursor: var(--cursor-pointer);

      &.legend-label-opacity {
        .legend-label-color {
          background-color: rgb(204, 204, 204) !important;
        }

        .legend-label-text {
          color: rgb(204, 204, 204);
        }

        .legend-label-text:hover {
          color: rgb(0, 0, 0) !important;
        }
      }
    }

    .legend-label-color {
      width: 16px;
      height: 16px;
      display: var(--display-block);
      @include border-radius(50%);
      margin-right: 5px;
    }

    .legend-label-text {
      font-size: var(--font-size-15);
      color: rgb(124, 124, 124);
    }
  }

  &.measure-accordion {
    margin-left: 5px;

    .mat-expansion-panel-header {
      padding: 0 10px;
    }

    .mat-expansion-panel-content {
      margin-top: var(--value-0);

      .mat-expansion-panel-body {
        padding: 0 0 0 10px;
      }
    }
  }

  .mat-expansion-panel-header {
    border-top: 2px solid rgb(216, 26, 209);
    padding: 0px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    padding-top: var(--value-10) !important;
    padding-right: var(--value-10) !important;
  }
  .p-accordion-tab {
    &.p-accordion-tab-active {
      .p-accordion-header-link {
        .p-accordion-toggle-icon {
          transform: rotate(-180deg);
        }
      }
    }
    .p-accordion-header-link{
      border-top: 2px solid rgb(216, 26, 209);
      padding: 0px !important;
      background-color: transparent !important;
      box-shadow: none !important;
      padding-top: var(--value-10) !important;
      margin-bottom: 15px;
      padding-right: var(--value-10) !important;
      .p-accordion-header-text{
        display: none;
      }
      .p-accordion-toggle-icon {
        transform: rotate(-270deg);
        position: absolute;
        right: 10px;
      }
    }
  }


  .mat-expansion-panel-content {
    margin-top: var(--value-20);

    .mat-expansion-panel-body {
      margin: var(--value-0);
    }
  }

  .table th,
  .table td {
    border-top: var(--display-none);
  }

  &.order-panel {
    .mat-expansion-panel {
      &.mat-expanded {
        .mar {
          display: var(--display-block);
        }
      }
    }
  }

  .graph-img {
    @include flex-justify-end(flex, center, flex-end);
    margin-bottom: var(--value-30);
    padding-right: var(--value-20);
    margin-top: var(--value-10);

    @media (max-width: $media-breakpoint-xxl-screen-down) {
      margin-bottom: var(--value-20);
    }

    img {
      @media (max-width: $media-breakpoint-xxl-screen-down) {
        max-width: 20px;
      }
    }
  }
}

.item-wrapper {
  .padding-right {
    @media (min-width: 1600px) {
      padding-right: var(--value-40);
    }
  }

  .img-fluid {
    width: var(--width-100);
  }

  .info {
    margin-top: var(--value-40);
    border: 1px solid #707070;
    padding: var(--value-10);

    .labels {
      @include flex-justify-between(flex, center, space-between);
      margin-bottom: var(--value-10);

      &:nth-child(4) {
        margin-bottom: var(--value-0);
      }

      h4 {
        margin: var(--value-0);
        color: #adacad;
        font-size: var(--font-size-12);
      }
    }
  }

  .search-wrapper {
    @include flex-justify-between(flex, center, space-between);
    width: var(--width-100);
    margin-bottom: var(--value-20);
    margin-top: var(--value-40);

    .form-group {
      margin: var(--value-0);

      input {
        width: 250px;

        &::placeholder {
          font-weight: normal;
        }
      }

      i {
        color: var(--black);
        font-weight: var(--font-weight-500);
        font-size: var(--font-size-16);
        transform: rotate(95deg);
        cursor: var(--cursor-pointer);
      }
    }

    .icon-wrapper {
      i {
        font-size: var(--font-size-24);
        margin-left: 5px;
        cursor: var(--cursor-pointer);
      }
    }
  }

  app-my-journey,
  app-journey-shared-with-me,
  app-algo-pushed-journey-grid-view,
  app-journey-activity-log,
  app-upcoming-tasks,
  app-task-list {
    .mat-mdc-form-field {
      .mat-mdc-form-field-subscript-wrapper {
        display: var(--display-none);
      }

      .mat-mdc-form-field-flex {
        height: 30px;
      }
    }
  }

  app-upcoming-tasks {
    .mat-mdc-slide-toggle {
      margin: 0 !important;

      .mdc-form-field {
        .mdc-label {
          width: auto;
        }
      }
    }
  }

  .form-group {
    @include flex-align(flex, center);
    justify-content: flex-start;
    margin-bottom: var(--value-10);

    .mat-mdc-form-field-flex{
      height: 30px;
    }
    .mat-mdc-form-field-subscript-wrapper{
      display: var(--display-none);
    }

    &.textarea {
      textarea {
        resize: var(--display-none);
        width: 100% !important;
        border: none !important;
        padding: 10px !important;
        text-align: var(--text-left) !important;
        background-color: var(--gray-100) !important;
        font-size: var(--font-size-12);
      }
    }

    label {
      color: var(--color-30);
      font-size: var(--font-size-12);
      line-height: 19px;
      min-width: 130px;
      width: 180px;
      display: var(--display-block);
      overflow: var(--overflow-hidden);
      text-overflow: ellipsis;
    }

    .mat-slide-toggle-label {
      display: flex !important;
    }

    input {
      width: 100% !important;
      border: none !important;
      height: 30px !important;
      padding: 0 !important;
      padding-left: var(--value-10) !important;
      text-align: var(--text-left) !important;
      background-color: var(--gray-100) !important;
      @include border-radius(5px);
      &.save-input{
        height: 26px !important;
      }

      &.w-120 {
        width: 60px !important;
      }

      &.invalid-input {
        border: 1px solid red !important;
      }

      @media (min-width: $media-breakpoint-md-up) {
        padding: 0 10px !important;
      }

      @media (max-width: 1800px) {
        font-size: var(--font-size-13);
      }

      &:focus {
        outline: var(--display-none);
      }
    }

    i {
      position: var(--position-absolute);
      right: 5px;
      color: #393939;
      font-size: var(--font-size-10);
      cursor: var(--cursor-pointer);
    }

    .mat-select {
      border: var(--display-none);
      background-color: var(--gray-100);
      height: 30px;
      padding: 0 10px 0 10px;
      font-size: var(--font-size-12);
      @include border-radius(0);
      width: var(--width-100);
      text-align: var(--text-left);

      @media (max-width: $media-breakpoint-xxl-screen-down) {
        height: 25px;
      }

      .mat-select-trigger {
        padding-top: var(--value-0);
        height: 30px;

        .mat-select-value {
          padding-top: var(--value-0);
        }
      }

      .mat-select-arrow {
        border-style: solid;
        border-width: 0 2px 2px 0;
        content: "";
        display: var(--display-inline-block);
        padding: 3px;
        color: var(--black);
        transform: rotate(45deg);
        width: 0;
        height: 0;
        margin: var(--value-0);
        border-left: unset;
        border-right: 2px solid;
      }

      .mat-select-placeholder {
        color: var(--color-30);

        @media (min-width: 1600px) {
          font-size: var(--font-size-12);
        }
      }
    }

    .btn {
      height: 25px;
      @include border-radius(0);
      font-size: var(--font-size-12);
      padding: var(--value-0);
      min-width: 65px;
      justify-content: var(--justify-center);
      border: var(--display-none);
    }



    .mat-mdc-text-field-wrapper {
      padding: var(--value-0);

      .mat-mdc-form-field-icon-suffix {
        padding: var(--value-0);
      }

      .mdc-line-ripple {
        display: var(--display-none);
      }

      .mat-mdc-form-field-focus-overlay,
      .mdc-line-rippled {
        display: var(--display-none);
      }
    }

    .mat-mdc-form-field-wrapper {
      padding-bottom: var(--value-0);
      padding: var(--value-0);
    }

    .mat-mdc-form-field-prefix,
    .mat-mdc-form-field-suffix {
      margin: auto;
    }

    .mat-datepicker-toggle {
      color: var(--black);
    }

    .mat-mdc-form-field-underline,
    .mat-mdc-form-field-ripple {
      height: 0 !important;
    }

    .mat-mdc-form-field-flex {
      background-color: var(--gray-100);
    }

    .mat-mdc-form-field-infix {
      padding: var(--value-0);
      border-top: var(--value-0);
    }

    .mat-mdc-form-field-label span {
      font-size: var(--font-size-12);
      padding-left: var(--value-10);
    }

    .user-data {
      background-color: var(--gray-100);
      width: var(--width-100);
      height: 30px;
      @include flex-align(flex, center);
      padding: 0 10px;
    }

    &.time-dropdown {
      app-treeview-dropdown {
        width: var(--width-100);

        .mat-select-trigger {
          padding-top: 2px;

          .mat-select-value {
            padding-top: 4px;
          }
        }

        .dropdown {
          .dropdown-toggle:before {
            right: 7px !important;
            top: 9px !important;
            padding: 3px !important;
          }

          .btn {
            background-color: var(--gray-100) !important;
            height: 30px !important;
            min-height: 30px !important;
            max-height: 30px !important;
            padding: 0 5px 0 10px !important;
            font-size: 12px !important;
            @include border-radius(0 !important);
            width: 100% !important;
            text-align: var(--text-left) !important;
            box-shadow: none !important;
          }
        }
      }
    }

    // Asad implement csss
    &.time-dropdown {
      microsoft-treeview-dropdown {
        width: var(--width-100);

        .dropdown {
          .dropdown-toggle:before {
            right: 7px;
            top: 9px;
            padding: 3px;
          }

          .btn {
            background-color: var(--gray-100);
            height: 30px !important;
            padding: 0 5px 0 10px;
            font-size: var(--font-size-12);
            @include border-radius(0);
            width: var(--width-100);
            text-align: var(--text-left);
            box-shadow: var(--display-none);
          }
        }
      }
    }

    &.scrolling-dropdown {
      margin: 0 !important;
    }
  }

  .btn-wrapper {
    @include flex-justify-end(flex, center, flex-end);
    margin-top: var(--value-20);
    margin-bottom: var(--value-10) !important;

    img {
      @media (max-width: $media-breakpoint-xxl-screen-down) {
        max-width: 25px;
      }
    }

    .mat-slide-toggle {
      .mat-slide-toggle-label {
        display: flex !important;
      }
    }

    .btn {
      margin-left: var(--value-10);

      @media (max-width: 1800px) {
        font-size: var(--font-size-12);
        height: 36px;
      }

      @media (max-width:425px) {
        margin-left: 13px !important;
        margin-right: auto !important;
      }
    }
  }

  .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: var(--white);
  }

  .mat-tab-group .mat-ink-bar {
    bottom: -2px;
  }

  .select-arrow {
    .mat-select {
      .mat-select-arrow {
        border: var(--display-none);
      }
    }
  }

  .users-wrapper {
    border-top: 1px solid #707070;
    margin-top: var(--value-10);
    padding-top: var(--value-10);
  }

  &.insight-wrapper {
    .item-search-wrapper {
      margin-bottom: var(--value-0);

      .form-fields {
        padding-right: var(--value-10);
        width: 225px;
      }
    }

    .insight-form {
      display: var(--display-flex);
      padding-left: var(--value-10);
      flex-wrap: wrap;
      margin-right: auto;

      .form-group {
        margin-bottom: var(--value-0);
        margin-right: var(--value-10);
        margin-top: 5px;

        label {
          min-width: 60px;
        }

        .mat-select {
          min-width: 100px;
        }

        .mat-mdc-form-field {
          width: 100px !important;
        }
      }
    }

    .fb-forecast-accordion {
      padding-top: var(--value-0);

      .mat-expansion-panel-header {
        border-top: var(--value-0);
      }

      .mat-expansion-panel-header-description {
        justify-content: flex-end;

        a {
          color: var(--black);
          font-size: var(--font-size-14);

          &:first-child {
            padding-right: 5px;
            border-right: 1px solid var(--black);
            margin-right: 5px;
          }
        }
      }

      .mat-expansion-panel-body {
        #item-manager {
          padding: var(--value-0);
        }

        .items-wrapper-grid {
          padding: var(--value-0);
        }
      }
    }

    .insight-gallery-banner {
      background-color: var(--primary-purple);
      border-radius: 15px 15px 0 0;
      padding: 10px 20px;

      p {
        margin: var(--value-0);
        color: var(--white);
      }
    }

    .insight-gallery {
      border-bottom: 1px solid gray;
      padding: var(--value-30);
      position: var(--position-relative);

      &:before {
        content: "";
        position: var(--position-absolute);
        right: var(--value-0);
        top: 25px;
        bottom: 25px;
        width: 1px;
        background-color: gray;
      }

      .insight-gallery-inner {
        text-align: var(--text-left);
        padding-top: var(--value-30);

        p {
          margin-bottom: var(--value-0);
          color: var(--black);
        }

        a {
          color: var(--primary-purple);
          text-decoration: underline;
        }

        .gallery-date-value {
          @include flex-justify-between(flex, center, space-between);
          color: var(--black);

          p {
            margin-bottom: var(--value-0);

            span {
              font-size: var(--font-size-18);
              font-weight: var(--font-weight-500);
            }
          }
        }
      }
    }
  }

  .insight-form-wrapper {
    .col-md-3 {
      ul {
        margin-bottom: var(--value-20);
      }
    }

    .col-md-9 {
      .row {
        border-top: 1px solid gray;
        padding-top: var(--value-20);
        margin-top: var(--value-10);

        &:nth-child(2) {
          .form-group {
            align-items: flex-start;
          }
        }

        &.row-inner {
          padding: var(--value-0);
          margin: var(--value-0);
          border: var(--display-none);
          width: var(--width-100);
        }
      }

      .form-group {
        .mat-mdc-form-field {
          .mat-mdc-form-field-infix {
            display: var(--display-flex);

            label {
              background-color: var(--white);
            }
          }
        }
      }
    }
  }

  &.fb-forecast-planner {
    margin-top: var(--value-10);

    .col-md-8 {
      @media (min-width: 1600px) {
        padding-right: var(--value-30);
      }
    }

    .fb-line {
      border-right: 1px solid #707070;
    }

    .method-planner-top {
      border-bottom: 1px solid #707070;
      margin-bottom: 38px;

      .fb-line {
        padding-bottom: var(--value-40);

        @media (min-width: 1600px) {
          padding-right: var(--value-30);
        }
      }

      .fb-line2 {
        @media (min-width: 1600px) {
          padding-left: var(--value-30);
        }
      }

      .form-group {
        label {
          min-width: 130px;

          @media (min-width: 1600px) {
            min-width: 225px;
          }
        }
      }
    }

    .method-planner-bottom {
      .planner-col{
        p-fieldset{
          height: var(--height-100);
          fieldset{
            height: var(--height-100);
            .p-toggleable-content{
              height: var(--height-100);
              .p-fieldset-content{
                height: var(--height-100);
                @include flex-column-between(flex, column, space-between);
              }
            }
          }
          .btn-wrapper{
            @include flex-justify-end(flex, center, flex-end);
            .btn{
              font-size: 13px !important;
              min-width: 60px !important;
              height: 35px !important;
              width: 77px !important;
              @media (max-width: 1600px){
                font-size: 12px !important;
                min-width: 55px !important;
                height: 30px !important;
                width: 73px !important;
              }
            }
          }
        }
      }
      .form-group {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: var(--value-30);

        label {
          margin-bottom: var(--value-10);

          @media (max-width: $media-breakpoint-xxl-screen-down) {
            margin-bottom: 5px;
          }
        }
      }

      .align {
        display: flex;
        flex-direction: column;
      }
    }

    h3 {
      color: var(--color-30);
      font-size: var(--font-size-12);
      font-weight: var(--font-weight-500);
      line-height: 24px;
      margin-bottom: 15px;
      font-family: var(--font-family);

      @media (min-width: $media-breakpoint-xxl-screen-up) {
        margin-bottom: 25px;
      }
    }

    .form-group {
      label {
        font-size: var(--font-size-12);
        font-family: var(--font-family);
        font-weight: var(--font-weight-500);
        min-width: 90px;

        @media (max-width: 1800px) {
          font-size: var(--font-size-11);
        }

        @media (min-width: 1600px) {
          min-width: 130px;
        }
      }

      input {
        width: 100% !important;
        border: none !important;
        height: 30px !important;
        padding: 0 10px !important;
        text-align: var(--text-left) !important;
        background-color: var(--gray-100) !important;
        @include border-radius(5px);
      }

      input::placeholder {
        font-weight: normal !important;

        @media (min-width: 1600px) {
          color: var(--color-30);
          font-size: var(--font-size-16);
        }
      }

      .mat-select {
        .mat-select-value-text {
          font-weight: normal !important;

          @media (min-width: 1600px) {
            font-size: var(--font-size-12);
          }
        }
      }
    }

    app-treeview-dropdown {
      width: var(--width-100);

      .dropdown {
        .btn {
          background-color: var(--gray-100) !important;
          box-shadow: none !important;
          height: 30px !important;
          padding: 0 10px !important;

          @media (max-width: $media-breakpoint-xxl-screen-down) {
            height: 25px !important;
          }

          &:hover {
            color: var(--black) !important;
          }
        }

        .dropdown-toggle {
          &[aria-expanded="true"] {
            color: var(--black) !important;

            &:before {
              top: 15px !important;
            }
          }

          &:before {
            right: 10px !important;
            top: 10px !important;
          }
        }
      }
    }

    // asad implement css
    microsoft-treeview-dropdown {
      width: var(--width-100);

      .dropdown {
        .btn {
          background-color: var(--gray-100) !important;
          box-shadow: none !important;
          height: 30px !important;
          padding: 0 10px !important;

          &:hover {
            color: var(--black) !important;
          }
        }

        .dropdown-toggle {
          &[aria-expanded="true"] {
            color: var(--black) !important;

            &:before {
              top: 15px !important;
            }
          }

          &:before {
            right: 10px !important;
            top: 7px !important;
            padding: 4px !important;
          }
        }
      }
    }

    .btn-wrapper {
      margin: var(--value-0);
      justify-content: flex-start;
    }
  }

  &.promotion-wrapper {
    .col-md-4 {
      padding-left: var(--value-10);
    }

    .form-group {
      label {
        font-size: var(--font-size-12);

        &.promotion-label {
          @media (min-width: 1600px) {
            min-width: 180px;
          }
        }

        &.promotion-label2 {
          @media (min-width: 1600px) {
            min-width: 220px;
          }
        }
      }
    }

    .btn-wrapper {
      margin-top: 14px;
      margin-bottom: var(--value-40);
      width: var(--width-100);
      justify-content: flex-end;
    }
  }

  .p-treeselect {
    border: var(--display-none);
    background-color: var(--gray-100);
    height: 30px;
    padding: 0 10px;
    font-size: var(--font-size-12);
    @include border-radius(5px);
    width: 100% !important;
    text-align: var(--text-left);
    box-shadow: none !important;
  }
}

.cell-treeview {
  height: 39px;
  width: 50%;
  border-right: 1px solid white;
  box-shadow: 0 3px 1px -2px #0003, 0 0 2px #00000024, 0 1px 5px #0000001f;



  &.simple {
    background-color: var(--primary-purple);
    @include flex-center(flex, center, center);
    padding: 0 10px;

    span {
      font-size: var(--font-size-14);
      color: white;
      font-weight: var(--font-weight-400);
      width: 180px;
      overflow: var(--overflow-hidden);
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: var(--text-center);

      @media (max-width: 1800px) {
        font-size: var(--font-size-12);
      }
    }
  }

  .p-treeselect {
    height: 100% !important;
    background-color: var(--primary-purple);
    @include border-radius(0);

    .p-treeselect-label {
      text-align: var(--text-center);
      color: var(--white);
    }

    .p-treeselect-label.p-placeholder {
      text-align: var(--text-center);
      color: white;
    }

    .p-treeselect-header {
      padding: 5px;
    }
  }
}

.ag-theme-material {
  &.custom-ag-grid {
    &.custom-split-grid {
      .ag-layout-auto-height {
        .ag-center-cols-viewport {
          min-height: 40px;
        }
      }

      .ag-header {
        height: 0 !important;
        min-height: 0 !important;
      }
    }

    .ag-header-icon {
      display: var(--display-none);
    }


    .white-background {
      border-bottom: 4px solid var(--white) !important;
    }

    // .ag-center-cols-viewport {
    //   width: var(--width-100);
    //   overflow-x: auto !important;
    // }

    .ag-row {
      border: var(--display-none);

      &.ag-row-space {
        background-color: var(--white) !important;

        .ag-cell {
          &:after {
            display: var(--display-none);
          }
        }

        &.ag-row-hover:not(.ag-full-width-row)::before,
        &.ag-row-hover.ag-full-width-row.ag-row-group::before {
          background-color: var(--white) !important;
        }

        .ag-cell {
          background-color: var(--white) !important;
          border: none !important;
          display: none !important;
        }
      }
    }

    .ag-header {
      background-color: var(--white) !important;
    }

    .ag-pinned-left-cols-container,
    .ag-pinned-left-floating-bottom {
      .ag-row {
        &.ag-row-space {
          .ag-cell {
            background-color: var(--white) !important;
            display: none !important;

            &.disabled-input {
              background-color: var(--white) !important;
              display: none !important;
            }

            &:nth-child(2) {
              font-size: var(--font-size-14);
              font-weight: var(--font-weight-500);
              position: var(--position-relative);
              left: 0 !important;
              padding-left: var(--value-0);

              &:before {
                content: "";
                position: var(--position-absolute);
                background-color: var(--white);
                top: var(--value-0);
                right: var(--value-0);
                bottom: var(--value-0);
                left: var(--value-0);
              }

              app-measure-name-renderer {
                z-index: var(--z-index-1);
              }
            }
          }
        }

        .ag-cell {
          border-left: none !important;

          &:nth-child(1) {
            padding: var(--value-0);
          }

          &:nth-child(2) {
            border-right: 1px solid var(--white) !important;
            border-color: var(--white);
            border-bottom: 1px solid var(--white) !important;
            padding: 6px 15px;
            text-align: var(--text-right) !important;
            @include flex-align(flex !important, center);
          }
        }
      }
    }

    .ag-center-cols-viewport,
    .ag-floating-bottom-viewport,
    .ag-pinned-right-cols-container {
      .ag-row {
        border-bottom: 1px solid var(--white);

        &.ag-row-space {
          .ag-cell {
            background-color: var(--white) !important;
            display: none !important;

            &.disabled-input {
              background-color: var(--white) !important;
              display: none !important;
            }
          }
        }
      }

      .ag-cell {
        border-right: 1px solid var(--white) !important;
        padding: 8px 12px;
        font-weight: var(--font-weight-800);
        text-align: var(--text-right);
        line-height: 26px;
        flex-direction: row-reverse;
        text-align: var(--text-right) !important;
        @include flex-align(flex !important, center);

        &:after {
          content: "";
          position: var(--position-absolute);
          border: 5px solid var(--silver);
          bottom: var(--value-0);
          left: var(--value-0);
          right: var(--value-0);
          top: var(--value-0);
        }

        &.disabled-input {
          background-color: var(--silver) !important;
          opacity: 0.85;

          &:after {
            display: var(--display-none);
          }

          &.greater-then {
            background-color: red !important;
          }

          &.smaller-then {
            background-color: orange !important;
          }

          &.smaller-then {
            background-color: orange !important;
          }

          &.is-promo {
            color: rgb(36, 128, 211);
            cursor: var(--cursor-pointer);
            text-decoration: underline;

            &.ag-cell-range-selected {
              background: #c9c9c9 !important;
            }

            &.ag-cell-inline-editing {
              height: 36px;

              app-custom-cell-editor {
                input {
                  color: rgb(36, 128, 211) !important;
                  text-decoration: underline;
                }
              }

            }

          }
        }

        &.ag-cell-range-selected {
          background-color: rgb(135, 206, 250) !important;
        }

        &.ag-cell-inline-editing {
          height: 31px;
          box-shadow: var(--display-none);
          background: rgb(135, 206, 250) !important;
          &:after{
            left: -12px;
            right: 12px;
          }
          app-custom-cell-editor {
            input {
              font-weight: var(--font-weight-500);
              text-align: var(--text-right);
              border: var(--display-none);
              background-color: transparent;
              padding: 8px 12px;
              height: 31px;
              width: 135px;

              &:focus-visible {
                outline: var(--display-none);
              }
            }
          }

          .ag-input-field-input {
            padding-bottom: var(--value-0);
            height: 30px;
            border: var(--display-none);
            text-align: var(--text-right);
            font-weight: var(--font-weight-800);
          }
        }
      }
    }

    .ag-row {
      background-color: rgb(238, 238, 238) !important;
    }

    .ag-cell {
      border: none !important;

    }

    .ag-header {
      height: 50px;
      min-height: 50px;

      .ag-header-row {
        height: 40px !important;
        border-left: 1px solid var(--white);

      }

      .ag-header-viewport {
        border-bottom: var(--display-none);
      }
    }

    .ag-header-viewport {
      .ag-header-cell {
        background-color: var(--white) !important;
        padding: var(--value-0);
      }
    }

    .ag-pinned-left-header {
      .ag-header-row {
        .ag-header-cell {
          &:nth-child(1) {
            background-color: var(--primary-purple) !important;
          }
        }

        .ag-header-cell {
          &:nth-child(2) {
            // width: 100% !important;
            // left: 0px !important;
            padding: 0 !important;
            border-right: 1px solid !important;
            background-color: transparent !important;

            .ag-header-cell-resize {
              right: var(--value-0);
            }

            app-grid-custom-header {
              display: var(--display-flex);
            }
          }
        }
      }
    }

    .ag-header-cell {
      padding: 0 10px 0 10px;

      app-grid-custom-header {
        width: var(--width-100);

        .p-dropdown {
          height: 50px;
          background-color: var(--primary-purple);
          box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024,
            0 1px 5px #0000001f;
          padding: 0 10px 0 10px;
          border-right: 1px solid white;
          width: 135px !important;
          @include border-radius(0);


          .p-inputtext {
            font-size: var(--font-size-14);
            color: var(--black);

            @media (max-width: 1800px) {
              font-size: var(--font-size-12);
            }
          }

          .p-dropdown-trigger {
            color: white;
          }
        }

        .mat-select {
          background: var(--white);
          box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024,
            0 1px 5px #0000001f;
          padding: 0 10px 0 10px;
          height: 50px;
          @include flex-align(flex, center);
          border-right: 1px solid var(--black);

          .mat-select-trigger {
            width: var(--width-100);
          }

          .mat-select-arrow {
            color: black !important;
            border-style: solid;
            border-width: 0px 2px 2px 0px;
            transform: rotate(45deg);
            border-right: 2px solid #0d0d0d;
            padding: 4px;
            margin: -5px 0 0 0;

            @media (max-width: $media-breakpoint-xxl-screen-down) {
              padding: 3px;
            }
          }

          .mat-select-placeholder {
            color: var(--black) !important;
            font-size: var(--font-size-14);
            font-weight: var(--font-weight-400);

            @media (max-width: $media-breakpoint-xxl-screen-down) {
              font-size: var(--font-size-12);
            }
          }
        }
      }
    }

    .ag-cell-range-selected-1:not(.ag-cell-focus),
    .ag-root:not(.ag-context-menu-open) .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing).disabled-input {
      background-color: var(--silver) !important;
    }

    .ag-cell-range-selected-1:not(.ag-cell-focus),
    .ag-root:not(.ag-context-menu-open) .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
      background-color: rgb(238, 238, 238);
    }

    .ag-cell-range-selected-1:not(.ag-cell-focus),
    .ag-root:not(.ag-context-menu-open) .ag-floating-bottom:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing).disabled-input {
      background-color: var(--silver) !important;
    }

    .ag-cell-range-selected-1:not(.ag-cell-focus),
    .ag-root:not(.ag-context-menu-open) .ag-floating-bottom:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
      background-color: rgb(238, 238, 238) !important;
    }
  }
}

.data-wrapper {
  &.btn-wrapper {
    margin-top: var(--value-0);
    margin-bottom: var(--value-20);
  }
  &.dip-import-export-btn{
      .p-dropdown{
        min-width: auto;
        max-width: max-content;
        width: auto !important;
        .p-inputtext{
          margin-right: 5px;
        }
      }
  }
  .p-dropdown{
    background-color: var(--secondary-carnation);
    color: var(--white);
    border: var(--display-none);
    height: 35px !important;
    @include border-radius(5px);
    margin-left: var(--value-10);
    padding: 0 10px;
    font-weight: var(--font-weight-500);
    min-width: 110px;
    width: 110px !important;
    font-family: Roboto, sans-serif !important;
    font-size: var(--font-size-14);
    @media (max-width: 1800px) {
      height: 36px !important;
    }


    .p-inputtext {
      color: var(--white) !important;
      font-size: var(--font-size-13);
      font-weight: var(--font-weight-500);
      justify-content: var(--justify-center);
      padding: var(--value-0);

      @media (max-width: $media-breakpoint-xxl-screen-down) {
        font-size: var(--font-size-12);
      }
    }

    .p-dropdown-trigger .p-dropdown-trigger-icon {
      width: 0;
      height: 0;
      border-left: 4px solid rgba(0, 0, 0, 0);
      border-right: 4px solid rgba(0, 0, 0, 0);
      border-top: 4px solid;
      margin: 0 4px;
      color: var(--white);

      &:before {
        display: var(--display-none);
      }
    }
  }

  .mat-select {
    background-color: var(--secondary-carnation);
    color: var(--white);
    border: var(--display-none);
    height: 45px;
    @include border-radius(5px);
    margin-left: var(--value-20);
    padding: 0 10px;
    font-weight: var(--font-weight-500);
    min-width: 130px;
    width: 130px;
    font-family: var(--font-family);
    font-size: var(--font-size-14);

    @media (max-width:768px) {
      padding: 0 2px;
      min-width: 112px;
      width: 110px;
    }

    @media (max-width: 1800px) {
      font-size: var(--font-size-12);
    }

    .mat-select-value {
      text-align: var(--text-center);
    }

    &:focus {
      box-shadow: var(--display-none);
    }

    .mat-select-trigger {
      height: var(--height-100);
    }

    .mat-select-arrow {
      @media (max-width: 1800px) {
        border-left: 4px solid rgba(0, 0, 0, 0);
        border-right: 4px solid rgba(0, 0, 0, 0);
        border-top: 4px solid;
      }
    }

    .ag-cell-range-selected-1:not(.ag-cell-focus),
    .ag-root:not(.ag-context-menu-open) .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing).disabled-input {
      background-color: var(--silver) !important;
    }

    .ag-cell-range-selected-1:not(.ag-cell-focus),
    .ag-root:not(.ag-context-menu-open) .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
      background-color: rgb(238, 238, 238) !important;
    }
  }

  .data-wrapper {
    &.btn-wrapper {
      margin-top: var(--value-0);
      margin-bottom: var(--value-20);
    }

    .mat-select {
      background-color: var(--secondary-carnation);
      color: var(--white);
      vertical-align: middle;
      text-align: var(--text-center);

      .mat-select-placeholder {
        color: var(--white);
      }
    }

    .mat-select-arrow {
      color: var(--white) !important;
      padding: 5px;
      border-style: solid;
      border-width: 0px 2px 2px 0px;
      transform: rotate(45deg);
      border-right: 2px solid var(--white);
      margin: -6px 6px 0;
    }
  }

  .btn {
    white-space: nowrap;
  }
}

// .split-accessories-grid{
//   .ag-theme-material {
//     .ag-floating-bottom{
//       border-bottom: var(--display-none);
//     background-color: transparent;
//     }
//   }
// }

.ag-theme-material.custom-ag-grid .ag-header-cell {
  margin-top: -2px;
}

.label-update {
  .label {
    color: white !important;
    font-weight: var(--font-weight-500);
  }

  .material-icons-round {
    color: white !important;
  }
}

.custom-ag-grid {
  .p-treeselect {
    .p-treeselect-trigger {
      .p-treeselect-trigger-icon {
        color: white !important;
      }
    }
  }
}

.miv-planner-wrapper {
  .ag-theme-material .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
    border-bottom: none !important;
  }
}

.ag-theme-material.custom-ag-grid .ag-pinned-left-cols-container .ag-row.is-expanded .ag-cell:nth-child(2) {
  margin-left: var(--value-40);
}

