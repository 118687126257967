@import './scss/layout';
@import './scss/algo-menu';
@import './scss/icomoon';
@import './scss/animate';
@import './scss/theme';
@import './scss/base';
@import './scss/ess';
@import './scss/planners';
@import './scss/target';
@import './scss/home-base-layout';
@import './scss/mixins';

// Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }
// @include media-breakpoint-up(sm) { ... }

// Medium devices (tablets, 768px and up)
// @media (min-width: $media-breakpoint-md-up) { ... }
// @media (min-width: $media-breakpoint-md-up) { ... }

// Large devices (desktops, 992px and up)
// @media (min-width: $media-breakpoint-lg-up) { ... }
// @media (min-width: $media-breakpoint-lg-up) { ... }

// Extra large devices (large desktops, 1200px and up)
// @media (min-width: $media-breakpoint-xl-up) { ... }
// @media (min-width: $media-breakpoint-xl-up) { ... }

// Nunito:300,400,600,700,800,900
// Disbale Swap two finger on mouse Pad

//media query variables

//global varaibles
:root {
  --mat-option-label-text-tracking: normal;
  --mat-optgroup-label-text-tracking: normal;
  --mat-card-title-text-tracking: normal;
  --mat-card-subtitle-text-tracking: normal;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-select-trigger-text-tracking: normal;
  --mdc-dialog-supporting-text-tracking: normal;
  --mdc-chip-label-text-tracking: normal;
  --mat-slide-toggle-label-text-tracking: normal;
  --mdc-slider-label-label-text-tracking: normal;
  --mat-menu-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mat-paginator-container-text-tracking: normal;
  --mat-tab-header-label-text-tracking: normal;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-filled-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-extended-fab-label-text-tracking: normal;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-tracking: normal;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-expansion-container-text-tracking: normal;
  --mat-toolbar-title-text-tracking: normal;

  //font family
  --font-family: "Roboto", serif;

  //colors
  --primary-purple: #8d009d;
  --secondary-carnation: #F8486E;
  --black: #000000;
  --white: #ffffff;
  --red: #ff0000;

  --mine-shaft: #323232;
  --Mine-Shaft: #272222;
  --Mine-Shaft: #252222;
  --Mine-Shaft: #e8e6e6;
  --Mine-Shaft: #211f1f;
  --Mine-Shaft: #2b2929;
  --Mine-Shaft: #2d2b2b;
  --Mine-Shaft: #201f1f;

  --alto: #dfdfdf;
  --Alto: #cecece;
  --Alto: #d7d5d5;
  --Alto: #d9d9d9;
  --Alto: #e0e0e0;

  --Violet-Red: #f54070;
  --Violet-Red: #f93e73;
  --Violet-Red: #f0377a;

  --Silver-Chalice: #a6a6a6;
  --Silver-Chalice: #a2a2a2;
  --Silver-Chalice: #a5a2a2;
  --Silver-Chalice: #a0a0a0;
  --Silver-Chalice: #9d9d9d;

  --silver: #c9c9c9;
  --Silver: #cbcbcb;
  --Silver: #c4c4c4;

  --Alabaster: #f8f8f8;
  --Alabaster: #fafafa;
  --Alabaster: #f9f9f9;

  --mercury: #e8e8e8;
  --Mercury: #e6e6e6;

  --Concrete: #f2f2f2;
  --Concrete: #f3f3f3;

  --wild-sand: #f6f6f6;
  --Wild-Sand: #f5f5f5;

  --French-Rose: #f04371;
  --French-Rose: #ec4173;
  --French-Rose: #ee4073;
  --French-Rose: #ee4a6f;

  --Cod-Gray: #1a1717;
  --Cod-Gray: #080707;
  --Cod-Gray: #1b1a1a;
  --Cod-Gray: #1c1b1b;
  --Cod-Gray: #060606;

  --Catskill-White: #f5f8fa;
  --Catskill-White: #e2e8f0;

  --Athens-Gray: #f1f1f2;
  --Athens-Gray: #efeff5;
  --Athens-Gray: #f8f9fb;

  --Cerise: #d02985;
  --Cerise: #c3259c;

  --Purple: #960098;
  --Purple: #980a99;

  --Cocoa-Brown: #2a1f1f;
  --Cocoa-Brown: #241c1c;

  --Pink: #ffc4d2;
  --rolling-stone: #74777b;
  --Gallery: #eee;
  --Woodsmoke: #181d1f;
  --Tundora: #423e3e;
  --Abbey: #545758;
  --Thunder: #2e2525;
  --Japanese-Laurel: #1e9600;
  --Amour: #f3d6ee;
  --Solitude: #e2f1ff;
  --Half-Dutch-White: #fef7e0;
  --Link-Water: #f2f6fc;
  --Lightning-Yellow: #fbb91c;
  --Amber: #ffc107;
  --Punch: #dc3545;
  --Hippie-Blue: #559faf;
  --Vida-Loca: #4d841d;
  --Sunglow: #fedd2d;
  --Carnation: #f8486e;
  --Black-Squeeze: #eaf7f4;
  --Wild-Strawberry: #ff4081;
  --Cinderella: #fce4d6;
  --Cream-Brulee: #ffe699;
  --Royal-Blue: #1a73e8;
  --Geyser: #dee2e6;
  --Fiord: #475569;
  --Dodger-Blue: #2196f3;
  --Bon-Jour: #e5e2e2;
  --Tuatara: #282827;
  --Mardi-Gras: #2a022f;
  --Shuttle-Gray: #555e65;
  --Ebony: #070a1b;
  --Crimson: #df1b45;
  --Emperor: #564c4c;
  --Loulou: #611345;
  --Disco: #830f5f;
  --Yellow-Orange: #feac48;
  --Salem: #0f8738;
  --Green-Haze: #009872;
  --Red-Violet: #d81bd1;
  --Cerise-Red: #e13d72;
  --Rose-White: #fffcfc;
  --Outrageous-Orange: #ff5733;
  --Web-Orange: #ffa500;
  --Shark: #202124;
  --Yellow: #fdff0b;
  --Selective-Yellow: #fdb800;
  --Razzmatazz: #f00b5f;
  --caribbean-green: #01ca81;

  --color-d9: #d98e063d;
  --color-brown: #d98e0678;
  --color-indigo: #ef4b6e33;
  --color-2d: #2d043973;
  --color-1a: #1a1919d4;
  --color-2e: #2e2b2bde;
  --color-39: #39373799;
  --color-0e: #0e0d0dad;
  --color-4d: #4d4949cc;
  --color-2a: #2a2727;
  --color-9e: #9e9e9e14;
  --color-30: #302e31;



  //gray colors
  --gray-100: #f4f4f4;

  //tab colors
  --tab-default: #ececec;

  //action btn colors
  --action-btn-gray: #d5d1d1;

  //btn disabled color
  --btn-disabled: #ADADAD;
  --btn-disabled-color: #F4F4F4;
  --btn-primary-hover: #D81AD2;

  //font weight
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --font-weight-900: 900;
  --font-weight-bold: bold;

  //positions
  --position-relative: relative;
  --position-absolute: absolute;
  --position-fixed: fixed;

  //font sizes
  --font-size-10: 10px;
  --font-size-11: 11px;
  --font-size-12: 12px;
  --font-size-13: 13px;
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-19: 19px;
  --font-size-20: 20px;
  --font-size-21: 21px;
  --font-size-22: 22px;
  --font-size-23: 23px;
  --font-size-24: 24px;
  --font-size-25: 25px;
  --font-size-26: 26px;
  --font-size-30: 30px;

  //display
  --display-flex: flex;
  --display-block: block;
  --display-none: none;
  --display-inline: inline;
  --display-inline-block: inline-block;

  //flex properties
  --justify-between: space-between;
  --align-center: center;
  --justify-center: center;

  //width height
  --width-100: 100%;
  --height-100: 100%;

  --cursor-pointer: pointer;

  --overflow-hidden: hidden;

  --z-index-1: 1;
  --z-index-negative1: -1;
  --z-index-2: 2;
  --z-index-3: 3;
  --z-index-4: 4;
  --z-index-9: 9;
  --z-index-9999: 9999;

  --text-left: left;
  --text-right: right;
  --text-center: center;

  --value-0: 0;
  --value-10: 10px;
  --value-20: 20px;
  --value-30: 30px;
  --value-40: 40px;
  --value-50: 50px;
}

.no-data-display {
  @include flex-center(flex, center, center);
}

html,
body {
  overscroll-behavior-x: var(--display-none);
}

.float-right {
  float: right;
}

html,
body {
  height: var(--height-100);
}

html {
  font-family: var(--font-family) !important;
}

body {
  margin: var(--value-0);
  font-family: var(--font-family) !important;
  font-size: var(--font-size-14);
  color: var(--black);
  text-align: var(--text-left);
  background-color: var(--white);
}

#dashboard {
  padding-bottom: var(--value-50);

  @media (min-width: $media-breakpoint-lg-up) {
    padding-bottom: var(--value-0);
  }
}

body::-webkit-scrollbar-thumb {
  @include border-radius(10px);
}

// ---------------------------------------------------------
// @Main Content
// ---------------------------------------------------------

.main-content {
  min-height: 100vh;
  width: var(--width-100);
}

.no-data-found {
  @include flex-center(flex, center, center);
}

.remain-height {
  height: calc(100vh - 126px);
}

// ---------------------------------------------------------
// @Collapsed State
// ---------------------------------------------------------

.is-collapsed {
  .page-container {
    padding-left: 70px;

    @media (max-width: 1199.98px) {
      padding-left: var(--value-0);
    }

    @media (min-width: 576px) {
      padding-left: 266px;
    }
  }

  .full-container {
    left: 70px;

    @media (max-width: 1199.98px) {
      left: var(--value-0);
    }

    @media (min-width: $media-breakpoint-lg-up) {
      left: 266px;
      padding-left: var(--value-0);
    }
  }
}

.mat-mdc-form-field {
  font-family: var(--font-family) !important;
}

a,
.btn {
  cursor: pointer !important;
}

a {
  text-decoration: none;
}

.btn {
  &:focus {
    outline: var(--display-none);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: var(--font-weight-400);
}

.form-control {
  background-color: var(--wild-sand);
}

.mb-10 {
  margin-bottom: var(--value-10);
}

.mt-20 {
  margin-top: var(--value-20);
}

.mdc-label,
.p-checkbox-label {
  margin: var(--value-0) !important;
  font-size: 12px !important;
  padding-left: 5px;
  width: auto !important;
}

//btn global style
.btn {
  font-size: var(--font-size-14);
  color: var(--white);
  overflow: var(--overflow-hidden);
  transition: all 0.5s;
  position: var(--position-relative);
  height: 45px;
  min-width: 120px;
  justify-content: var(--justify-center);
  text-align: var(--text-center);
  font-weight: 500 !important;

  &.btn-yellow {
    background-color: var(--Yellow-Orange) !important;
  }

  &.btn-primary {
    color: var(--white);
    background-color: var(--secondary-carnation);
    @include border-radius(5px);
    @include flex-align(flex, center);
    padding: 0 15px;
    border-color: transparent;

    &:active {
      background-color: var(--primary-purple);
    }

    &:disabled,
    &.disabled {
      color: var(--btn-disabled-color);
      background-color: var(--btn-disabled);
    }

    .mdc-button-wrapper {
      text-align: var(--text-center);
      width: var(--width-100);

      @media (min-width: $media-breakpoint-md-up) {
        @include flex-justify-between(flex, center, space-between);
      }
    }

    i {
      font-size: var(--font-size-18);
      color: var(--white);
      padding-left: var(--value-30);
    }
  }

  &.btn-secondary {
    background-color: transparent;
    color: var(--secondary-carnation);
    @include border-radius(5px);
    border: 3px solid var(--secondary-carnation);

    &:disabled,
    &.disabled {
      color: var(--btn-disabled);
      border-color: var(--btn-disabled);
      box-shadow: none;
    }
  }

  &.btn-light {
    background-color: var(--Athens-Gray);
    color: #525261;
    border: var(--display-none);
  }

  &.btn-outline-danger {
    border: 2px solid #ff5a59;
    color: #ff5a59;
    box-shadow: 0 3px 8px 0 rgba(#ff5a59, 0.68);

    &:hover {
      color: var(--white);
    }
  }

  &.btn-outline-default {
    border: 2px solid #979797;
    color: #181b37;
    box-shadow: var(--display-none);

    &:hover {
      color: #181b37;
    }
  }

  &.btn-fab {
    padding: var(--value-0);
    @include border-radius(50%);
    line-height: 0;
    width: 58px;
    height: 56px;
    box-shadow: 0 3px 8px 0 rgba(15, 135, 56, 0.68);
    background-image: linear-gradient(to left,
        transparent,
        transparent 50%,
        #0f8738 50%,
        #0f8738);
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: all 0.25s ease-in;

    &:hover {
      background-position: 0 0;
    }
  }

  &.orange {
    background-color: var(--Salem);
    box-shadow: 0 3px 8px 0 rgba(15, 135, 56, 0.68);

    &:hover {
      color: var(--white);
    }

    i {
      color: var(--white);
      font-size: var(--font-size-10);
    }
  }

  &.btn-adjust {
    margin-bottom: var(--value-20);

    @media (min-width: $media-breakpoint-md-up) {
      margin-right: var(--value-20);
      margin-bottom: var(--value-0);
    }
  }
}

.mdc-switch:enabled .mdc-switch__track::after {
  background: #e8e6e6 !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: #f04371 !important;
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background: #980a99 !important;
}

.mdc-switch__icons,
.mat-mdc-form-field-focus-overlay,
.mdc-line-rippled {
  display: var(--display-none);
}

.ag-row-hover.ag-row-selected:before {
  background-color: var(--Gallery) !important;
  background-image: var(--display-none);
}

.ag-row-selected:before {
  background-color: var(--Gallery) !important;
}

.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}

.mat-mdc-input-element {
  // font: inherit;
  background: rgba(0, 0, 0, 0);
  color: currentColor;
  border: var(--display-none);
  outline: var(--display-none);
  padding: var(--value-0);
  margin: var(--value-0);
  width: var(--width-100);
  max-width: var(--width-100);
  vertical-align: bottom;
  text-align: inherit;
  box-sizing: content-box;
}

.save-journey-checkbox {
  .mdc-form-field {
    label {
      width: auto !important;
    }
  }
}

//dialog global header style
.p-dialog {
  background-color: var(--white);
  @include border-radius(10px);

  .p-dialog-header {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-500);
    line-height: 27px;
    font-family: var(--font-family);
    padding: 10px 15px;
    @include flex-justify-between(flex, center, space-between);
    background-color: var(--secondary-carnation);
    color: var(--white);
    border: var(--display-none);
    margin: 0 0 15px 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    .p-dialog-title {
      font-size: var(--font-size-16);
    }

    .p-dialog-header-icon {
      color: var(--white);
      width: 16px;
      height: 16px;
      margin-left: var(--value-10);
    }

  }

  .p-dialog-content {
    padding: 0 15px 1.5rem 15px;
  }

  .p-mdc-dialog-content {
    padding: 0 15px 15px;
  }

  .p-mdc-dialog-content {
    padding: 0 5px 0 0 !important;
    color: var(--black) !important;
    overflow: var(--overflow-hidden);
    overflow-y: auto;
    margin-bottom: var(--value-10);
    max-height: 75vh;
  }
}

.mdc-dialog__content> :last-child {
  margin-bottom: 1rem !important;
}

.p-mdc-dialog-content {
  @include border-radius(10px !important);

  .mdc-dialog__surface {
    @include border-radius(10px !important);
    padding: 24px 20px;
    overflow: var(--overflow-hidden);

    .action-panel {
      padding: 0 !important;
    }
  }

  .p-mdc-dialog-content {
    padding: 0 5px 0 0 !important;
    color: var(--black) !important;
    overflow: var(--overflow-hidden);
    overflow-y: auto;
    margin-bottom: var(--value-10);
  }

  app-graph-measures {
    .p-mdc-dialog-content {
      padding-bottom: 0 !important;
    }
  }
}

.journey-share-confirmation {
  padding: 24px;
}

.mat-mdc-dialog-title {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  font-family: var(--font-family) !important;
  padding: 10px 20px !important;
  @include flex-justify-between(flex !important, center, space-between);
  background-color: var(--secondary-carnation);
  color: var(--white) !important;
  border: var(--display-none);
  margin: -24px -24px 20px -24px !important;

  &:before {
    display: none !important;
  }
}


.action-panel {
  padding: var(--value-10);
  @include flex-justify-end(flex, center, flex-end);

  .btn {
    min-width: 100px;

    @media (max-width: 1800px) {
      height: 34px;
      font-size: var(--font-size-12);
    }
  }

  .btn.btn-secondary {
    margin-right: var(--value-10);
  }

  .book-appointment {
    bottom: 72px;

    @media only screen and (max-width: 991px) {
      bottom: var(--value-0);
    }

    @media only screen and (max-width: 767px) {
      bottom: var(--value-0);
    }
  }
}

.icomoon-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: var(--display-none);
  font-style: normal;
  font-variant: normal;
  text-transform: var(--display-none);
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: var(--display-block);
}

ul.p-dropdown-items {
  margin: var(--value-0);
  padding: var(--value-0);
}

.text-center {
  text-align: var(--text-center);
}

.required {
  .form-control {
    border-color: rgba($color: #d0021b, $alpha: 0.62);
  }

  .error-msg {
    font-size: var(--font-size-12);
    margin: var(--value-0);
    color: rgba($color: #d0021b, $alpha: 0.62);
  }

  &.floating-message {
    position: var(--position-relative);

    .error-msg {
      position: var(--position-absolute);
      bottom: -20px;
      left: var(--value-0);
      z-index: var(--z-index-1);
    }
  }
}

.arcadian-panel,
.accordian {

  .complete-panel,
  .accordion-inner {
    margin-bottom: var(--value-10);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.17), 0 2px 1px 0 rgba(0, 0, 0, 0.03);

    .btn {
      font-size: var(--font-size-12);
      line-height: 16px;
      color: #69747f;
      opacity: 0.7;
      padding: 6px 20px;
      height: 50px;
      background-color: var(--Catskill-White);
      border-radius: inherit;
    }

    .card {
      border: transparent;
    }

    .status-accordian {
      float: right;
      margin-top: 3px;

      &::before {
        @extend .icomoon-font;
        content: '\e916';
        font-size: var(--font-size-10);
        display: var(--display-none);
      }

      &:after {
        @extend .icomoon-font;
        content: '\e91f';
        font-size: var(--font-size-10);
      }

      &.minus {
        &::after {
          display: var(--display-none);
        }

        &::before {
          @extend .icomoon-font;
          content: '\e916';
          font-size: var(--font-size-10);
        }
      }
    }

    .panel-heading {
      border-radius: 2px 2px 0 0;
      background-color: var(--Catskill-White);
      height: 49px;
      padding: 16px 20px;

      .btn-link {
        color: rgba(#69747f, 0.7);

        font-size: var(--font-size-12);
        line-height: 16px;
        text-decoration: var(--display-none);
        padding: var(--value-0);
        margin: var(--value-0);
        height: inherit;
      }
    }

    &.panel-open {
      .panel-heading {
        color: var(--red);

        .status-accordian {
          &:after {
            @extend .icomoon-font;
            content: '\e916';
            font-size: var(--font-size-10);
          }
        }
      }
    }

    .card-body {
      padding: 26px 19px;

      .card-inner {
        padding-bottom: var(--value-10);
      }
    }
  }
}

.form-group {
  position: var(--position-relative);

  >label {
    display: var(--display-block);
    width: var(--width-100);
    color: #4a4a4a;
    font-size: var(--font-size-12);
    line-height: 19px;
  }
}

label {
  margin-bottom: 5px;
  display: var(--display-block);

  &.big {
    color: var(--black);
    font-size: var(--font-size-16);
    letter-spacing: -0.13px;
  }
}

.fb-btn {
  margin: var(--value-0);

  .fb-btn-wrapper {
    @include flex-align(flex, center);
    padding-left: var(--value-20);
    padding-right: var(--value-20);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .btn {
    @include border-radius(0);
    box-shadow: var(--display-none);
  }
}

.sidebar-inner {
  .fancy-scroll {

    .ps__rail-y:hover>.ps__thumb-y,
    .ps__rail-y:focus>.ps__thumb-y,
    .ps__thumb-y {
      width: 6px;
      background: darken(#f04371, 10%);
    }

    >.ps.ps--scrolling-y>.ps__rail-y,
    .ps .ps__rail-y:hover {
      background: transparent;
    }
  }

  .nav {
    min-height: 550px;

    @media (min-width: $media-breakpoint-md-up) {
      min-height: 0;
    }

    .report-menu-img {
      max-width: 24px;
      margin-right: 32px;
    }
  }
}

// .form-control,
// .btn {
//   height: 40px;
// }

.list-item time small {
  display: var(--display-block);
  text-transform: uppercase;
}

input:focus::-webkit-input-placeholder {
  color: transparent !important;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.loadingDiv {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  height: auto;
  @include border-radius(10px);
  position: var(--position-relative);
  overflow: var(--overflow-hidden);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: var(--display-none);
  margin: var(--value-0);
}

.k-grid a.hyperLink {
  color: var(--secondary-carnation);
}

.btn-light {

  .icon-export,
  .icon-print {
    margin-right: 5px;
  }
}

.text-center {

  .btn,
  .btn-default {
    width: var(--width-100);
  }
}

.btn-show {
  .mat-icon-button {
    box-shadow: 0 3px 8px 0 rgba(#980a99, 0.68);
    margin-left: var(--value-10);
  }

  .material-icons {
    font-size: 50px;
    color: var(--Purple);
    cursor: var(--cursor-pointer);
    margin-left: -4px;
  }

  padding-top: var(--value-10);
  padding-bottom: var(--value-10);
  @include flex-justify-end(flex, center, flex-end);
}

.form-heading {
  display: var(--display-inline);
  padding-left: var(--value-10);
}

.mat-mdc-form-field {
  width: 100% !important;
}

::ng-deep.mat-slide-toggle:checked {
  background-color: var(--French-Rose) !important;
}

.mat-slide-toggle.mat-checked {
  .mat-slide-toggle-bar {
    background-color: var(--Mine-Shaft) !important;

    @media (max-width: 1800px) {
      width: 32px;
      height: 12px;
    }
  }
}

.mat-slide-toggle-thumb {
  background-color: var(--Rose-White) !important;
}

.custom-width {
  @media (min-width: $media-breakpoint-lg-up) {
    padding: 32px 50px 70px 0;
  }
}

.full-width {
  width: var(--width-100);
  padding: 32px 50px 56px 0;
}

.mat-card {
  .mat-card-header-text {
    margin: var(--value-0);
  }
}

.mat-card-header {
  .mat-card-title {
    @include flex-align(flex, center);
    margin-bottom: var(--value-10);

    h3 {
      margin: var(--value-0);
    }
  }
}

.action-btns {
  @include flex-justify-end(flex, center, flex-end);
  margin-bottom: var(--value-20);

  .btn {
    margin-left: var(--value-10);
  }
}

.mat-icon-button {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

input::placeholder,
input:-webkit-autofill {
  font-size: var(--font-size-12);
  line-height: 19px;
  color: var(--black);
  font-weight: var(--font-weight-500);
}

.page-login {
  height: 100vh;
  background: var(--white);
  overflow: var(--overflow-hidden);

  &.forget-bg {
    background-image: url('./images/login-bg.png');
    width: var(--width-100);
    position: var(--position-relative);
    overflow: var(--overflow-hidden);
    background-repeat: no-repeat;
    background-size: cover;

    .box-content .mat-mdc-form-field-flex i {
      color: var(--white);
    }

    input::placeholder,
    input:-webkit-autofill {
      font-size: 20px !important;
      color: var(--white) !important;
      font-weight: 300 !important;
      background-color: transparent !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: var(--white) !important;
    }

    .box-content {
      @media (min-width: $media-breakpoint-lg-up) {
        padding: var(--value-0);
      }

      form {
        width: var(--width-100);
      }

      .btn-wrapper {
        margin-top: var(--value-0);
      }
    }

    .row {
      height: auto;
    }
  }

  .login-left {
    background-image: url('./images/bg-ess-login.png');
    height: var(--height-100);
    min-height: 100vh;
    width: var(--width-100);
    position: var(--position-relative);
    overflow: var(--overflow-hidden);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: var(--value-50);

    .logo-wrapper {
      padding-bottom: 70px;
      position: var(--position-relative);
      left: var(--value-0);

      @media (max-width: 1024px) {
        left: var(--value-0);
      }

      @media (max-width: 768px) {
        left: var(--value-0);
      }

      .one-algo {
        max-width: 500px;

        @media (max-width: 1024px) {
          max-width: 400px;
        }

        @media (max-width: 768px) {
          max-width: 300px;
        }

        @media (max-width: 425px) {
          max-width: 190px;
        }
      }

      .meta-wrapper {
        padding-left: 16px;
        margin-left: 16px;
        padding-top: var(--value-30);
        padding-bottom: var(--value-30);
        height: 110px;
        border-left: 3px solid var(--white);
        @include flex-align(flex, center);

        @media (min-width: $media-breakpoint-md-up) {
          height: 200px;
          padding-left: var(--value-50);
          margin-left: var(--value-50);
          padding-top: 60px;
          padding-bottom: 60px;
        }

        @media (min-width: $media-breakpoint-lg-up) {
          height: 250px;
        }

        .meta-logo {
          max-width: 500px;

          @media (max-width: 768px) {
            max-width: 250px;
          }

          @media (max-width: 425px) {
            max-width: 150px;
          }
        }
      }
    }

    .google-btn-wrapper {
      @include flex-center(flex, center, center);
    }

    .branding {
      height: calc(100vh - 88px);
    }
  }

  .algoinfo {
    color: var(--white);
    @include flex-column(flex, column, center, center);

    p {
      font-size: var(--font-size-14);
      margin-bottom: 2px;
    }

    ul {
      margin: var(--value-0);
      padding: var(--value-0);
      list-style-type: var(--display-none);
      display: var(--display-flex);
      font-size: var(--font-size-10);

      li {
        padding: 0 5px;
        border-right: 1px solid var(--white);

        &:last-child {
          border-right: var(--display-none);
        }

        a {
          color: var(--white);
        }
      }
    }
  }

  .container-fluid {
    padding: var(--value-0);
  }

  .container-fluid,
  .row {
    height: var(--height-100);
  }

  .login-logo {
    top: 20px;
    left: 20px;
    z-index: var(--z-index-1);
    max-width: 60px;

    @media (min-width: $media-breakpoint-md-up) {
      max-width: 80px;
    }

    @media (min-width: $media-breakpoint-lg-up) {
      max-width: 200px;
    }
  }

  .microsoft {
    max-width: 200px;
  }

  .login-microsoft {
    max-height: 30px;

    @media (min-width: $media-breakpoint-lg-up) {
      max-height: 100px;
    }
  }

  .top-bar {
    margin-top: 60px;
    margin-bottom: 100px;
    width: var(--width-100);
    z-index: var(--z-index-1);
    padding: 0 15px;
  }

  .box-content {
    z-index: var(--z-index-9);
    padding: 0 25px;
    @include flex-align(flex, center);
    height: var(--height-100);
    position: var(--position-relative);

    @media (min-width: $media-breakpoint-lg-up) {
      padding: 0 85px;
    }

    &.page-new-login {
      padding: var(--value-0);
      height: auto;

      .mat-mdc-text-field-wrapper {
        background-color: transparent;

        .mat-mdc-form-field-focus-overlay {
          display: var(--display-none);
        }

        .mat-mdc-form-field-icon-prefix {
          padding: var(--value-0);
        }

        .mat-mdc-form-field-infix {
          min-height: auto;

          input:-internal-autofill-selected {}
        }

        .mdc-line-ripple {
          &:before {
            border-bottom-color: var(--Red-Violet);
            left: 35px;
            width: 93%;
          }

          &:after {
            display: var(--display-none);
          }
        }
      }

      .mat-mdc-form-field-flex {
        i {
          color: white;
        }
      }

      input.mat-mdc-input-element {
        color: white;
      }

      .form-group {
        label {
          color: white;
        }
      }

      .btn-wrapper {
        justify-content: var(--justify-between);
        border: var(--display-none);
      }
    }

    img {
      margin-bottom: var(--value-50);
    }

    .form-group {
      label {
        font-size: var(--font-size-14);
      }
    }

    h1 {
      color: var(--white);
      font-size: 48px;
      margin-bottom: 70px;
      font-weight: 300;
    }

    .mat-mdc-form-field {
      margin-bottom: var(--value-20);

      &.mat-mdc-form-field-invalid {
        i {
          color: #fe0000;
        }

        .mat-mdc-form-field-ripple {
          background-color: var(--Red);
          left: 35px;
          width: 93%;
        }
      }
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-background,
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
      background-color: var(--Green-Haze);
      border: 1px solid;
    }

    .mat-mdc-form-field-flex {
      background: var(--display-none);
      padding: 0 0 4px;
      align-items: var(--align-center);

      .mat-mdc-form-field-infix {
        border-top: var(--value-0);
        padding: 0px 0 0 20px;
        font-size: var(--font-size-20);
      }

      input.mat-mdc-input-element {
        line-height: 22px;
        font-size: var(--font-size-14);
      }

      i {
        font-size: var(--font-size-30);
        color: var(--black);
      }
    }

    .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-underline::before {
      background-color: var(--Red-Violet);
      left: 35px;
      width: 93%;
    }

    .check-wrapper {
      @include flex-center(flex, center, center);
      margin-bottom: 15px;

      .mat-checkbox-layout {
        margin: var(--value-0);
      }

      .mat-checkbox-label {
        font-size: var(--font-size-16);
        color: var(--white);
        font-weight: normal;
        font-weight: 300;
      }

      a {
        color: var(--Violet-Red);
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-500);
        text-decoration: underline;
        text-transform: uppercase;
      }

      &.bottom {
        position: var(--position-absolute);
        bottom: var(--value-0);

        @media (min-width: $media-breakpoint-xl-up) {
          bottom: 70px;
        }

        a {
          margin: var(--value-0);
          padding: 0 15px;
          position: var(--position-relative);

          &:nth-child(2) {
            padding-right: var(--value-0);

            &:before {
              display: var(--display-none);
            }
          }

          &:before {
            content: '';
            position: var(--position-absolute);
            right: var(--value-0);
            width: 1px;
            height: 18px;
            background-color: var(--Violet-Red);
          }
        }
      }
    }

    .btn-wrapper {
      margin-top: var(--value-30);
      margin-bottom: 15px;
      border-bottom: 1px solid var(--Concrete);
      padding-bottom: 15px;

      .btn {
        margin: var(--value-0);
        background-color: var(--French-Rose);
        height: 50px;
        max-width: 160px;
        padding: var(--value-0);
        width: var(--width-100);
        font-size: var(--font-size-16);
        @include border-radius(20px);
        text-transform: uppercase;
        font-weight: var(--font-weight-500);
        color: var(--white) !important;
      }

      .check-wrapper {
        margin: var(--value-0);
        padding-left: var(--value-50);
      }
    }

    p {
      margin: var(--value-0);
      color: #004f3b;
      font-size: var(--font-size-18);
    }
  }

  input::placeholder,
  input:-webkit-autofill {
    font-size: 14px !important;
    color: white !important;
    font-weight: 300 !important;
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0px 1000px black inset;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: var(--white) !important;
  }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: var(--secondary-carnation) !important;
}

//dropdown styling
.mat-mdc-form-field-appearance-legacy {
  &.mat-focused {
    .mat-mdc-form-field-label {
      color: var(--black);
    }
  }

  .mat-mdc-form-field-infix {
    padding: 8px 0;
    width: var(--width-100);
  }

  .mat-mdc-form-field-label {
    color: #797979;

    span {
      font-size: var(--font-size-18);

      @media (max-width: $media-breakpoint-xxl-screen-down) {
        font-size: var(--font-size-14);
      }
    }
  }
}

.mat-mdc-form-field-appearance-legacy {
  .mat-mdc-form-field-underline {
    background-color: var(--French-Rose);
    height: 2px;
  }
}

.mat-mdc-option {
  font-size: 14px !important;

  .mdc-list-item__primary-text {
    width: var(--width-100);
  }

  &.mat-mdc-option-active,
  &:hover {
    background-color: var(--French-Rose) !important;
    color: var(--white) !important;
  }
}

//expansion panel styling
.mat-list-item {
  font-family: var(--font-family);
}

.mat-select-panel::-webkit-scrollbar,
.p-mdc-dialog-content::-webkit-scrollbar {
  width: 8px;
  background-color: var(--Wild-Sand);
  // @include border-radius(10px);
}

.mat-select-panel::-webkit-scrollbar-thumb,
.p-mdc-dialog-content::-webkit-scrollbar-thumb {
  background-color: var(--mine-shaft);
  // @include border-radius(10px);
}

.mat-select-panel::-webkit-scrollbar-track,
.p-mdc-dialog-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--Wild-Sand);
  // @include border-radius(10px);
}

.mat-drawer-content {

  &[style='margin-left: 240px;'],
  &[style='margin-left: 185px;'] {
    .toggleBtn:after {
      transform: rotate(135deg);
      left: 8px;
      transition: all 0.5s ease;
    }
  }
}

.bot-accordian {
  .action-panel.top {
    @include flex-justify-between(flex, center, space-between);
    padding: 10px 24px 10px 33px;

    @media (max-width: 767px) {
      padding: 10px 0 10px 0;
    }

    .form-heading {
      padding: var(--value-0);
      margin: var(--value-0);
      font-size: var(--font-size-14);
      color: var(--black);
      font-weight: var(--font-weight-600);
    }
  }

  .e-list-parent {
    padding: 20px 0 !important;
    overflow: var(--overflow-hidden);

    .e-text-content {
      padding: var(--value-0);

      .e-list-text {
        .action-panel {
          p {
            font-size: var(--font-size-14);
            color: var(--black);
          }
        }
      }
    }
  }

  .mat-expansion-panel-body {
    padding: var(--value-0);
    // margin-left: 85px;
    // margin-right: 100px;
    // @media (max-width: 767px){
    //   margin-left: var(--value-0);
    //   margin-right: var(--value-0);
    // }
  }
}

.embedded-report {
  iframe {
    height: 100vh !important;
  }
}

.embedded-report-filters {
  iframe {
    height: 100% !important;
    // zoom: 2 !important;
  }
}

:not(:root):fullscreen {
  .embedded-report {
    iframe {
      height: calc(100vh - 90px) !important;
    }
  }

  app-journey-right-panel {
    app-journey-comment {
      .message-chat-details {
        height: calc(100vh - 210px) !important;
      }
    }
  }

  .task-view-wrapper .task-view-tree {
    height: calc(100vh - 150px) !important;
  }

  .journey-height {
    height: calc(100vh - 150px) !important;
  }
}

:not(:root):fullscreen::backdrop {
  background: white;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: var(--black);
}

.loader-inner {
  position: var(--position-relative);
  padding-left: var(--value-40);

  img {
    max-width: 30px;
    position: var(--position-absolute);
    left: 5px;
    top: 5px;
  }

  p.icon {
    min-width: 28px;
    min-height: 28px;
    @include border-radius(50%);
    background-color: black;
    position: var(--position-relative);
    margin: var(--value-0);
    margin-right: 2px;
  }

  p.icon:before {
    content: '';
    position: var(--position-absolute);
    width: 20px;
    height: 20px;
    background-color: var(--white);
    @include border-radius(50%);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  p.icon:after {
    content: '';
    position: var(--position-absolute);
    width: 5px;
    height: 28px;
    background-color: black;
    @include border-radius(10px);
    right: -1px;
    top: var(--value-0);
    bottom: var(--value-0);
  }
}

.loader-inner-dark {
  align-items: var(--align-center);
  display: var(--display-none);

  img {
    max-width: 30px;
    position: var(--position-absolute);
    left: 5px;
    top: 5px;
  }

  p.icon {
    min-width: 28px;
    min-height: 28px;
    @include border-radius(50%);
    background-color: black;
    position: var(--position-relative);
    margin: var(--value-0);
    margin-right: 7px;
  }

  p.icon:before {
    content: '';
    position: var(--position-absolute);
    width: 20px;
    height: 20px;
    background-color: var(--white);
    @include border-radius(50%);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  p.icon:after {
    content: '';
    position: var(--position-absolute);
    width: 5px;
    height: 28px;
    background-color: black;
    @include border-radius(10px);
    right: -1px;
    top: var(--value-0);
    bottom: var(--value-0);
  }
}

@-webkit-keyframes line-scale-pulse-out {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }

  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

@keyframes line-scale-pulse-out {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }

  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

.line-scale-pulse-out>div {
  width: 6px;
  height: 38px;
  @include border-radius(10px);
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: var(--display-inline-block);
  -webkit-animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
  animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
}

.line-scale-pulse-out>div:nth-child(1) {
  background-color: var(--Violet-Red);
}

.line-scale-pulse-out>div:nth-child(2) {
  background-color: var(--Violet-Red);
}

.line-scale-pulse-out>div:nth-child(3) {
  background-color: var(--Cerise-Red);
}

.line-scale-pulse-out>div:nth-child(4) {
  background-color: var(--Cerise);
}

.line-scale-pulse-out>div:nth-child(5) {
  background-color: var(--Cerise);
}

.line-scale-pulse-out>div:nth-child(2),
.line-scale-pulse-out>div:nth-child(4) {
  -webkit-animation-delay: -0.4s !important;
  animation-delay: -0.4s !important;
}

.line-scale-pulse-out>div:nth-child(1),
.line-scale-pulse-out>div:nth-child(5) {
  -webkit-animation-delay: -0.2s !important;
  animation-delay: -0.2s !important;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: var(--position-absolute);
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  @include border-radius(50%);
  border: 2px solid var(--white);
  border-top-color: var(--black);
  animation: spinner 0.8s linear infinite;
}

marquee {
  background-color: var(--Alabaster);

  div {
    width: 1000%;
  }

  p {
    float: left;
    width: auto;
    margin: var(--value-0);
    padding-right: var(--value-30);
  }
}

@keyframes bounce_notify {
  0% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: top right;
  }

  50% {
    transform: scale(1.03, 1.03);
    transform-origin: top right;
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: top right;
  }
}

.multiselect-dropdown {
  .dropdown-btn {
    border: none !important;
    border-bottom: 1px solid #ee4a6f !important;
    border-radius: inherit !important;
    padding: 6px 0 !important;

    span {
      color: #969696;
      font-weight: var(--font-weight-500);
    }

    .dropdown-down {
      display: none !important;
    }
  }
}

.multiselect-item-checkbox input[type='checkbox']+div:before {
  color: #ee4a6f !important;
  border: 2px solid #ee4a6f !important;
}

.multiselect-item-checkbox input[type='checkbox']:checked+div:before {
  background-color: var(--French-Rose) !important;
}

.dropdown-multiselect__caret {
  display: none !important;
}

.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
  background: #ff4081 !important;
}

.mat-pseudo-checkbox {
  color: white;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--French-Rose) !important;

  @media (max-width: 1800px) {
    width: 17px;
    height: 17px;
  }
}

.fb-forecast-accordion {
  &.item-wrapper-panel {
    .mat-expansion-panel-header {
      .mat-expansion-indicator {
        &:after {
          padding: 4px;
          margin-top: -12px;
        }
      }
    }
  }

  &.insight-modal-wrapper {
    padding: 25px 50px 0;
  }

  &.promotion-accordion {
    padding-top: var(--value-0);

    .mat-expansion-panel-header {
      .item-search-wrapper {
        border: 0;
        padding: var(--value-0);
        margin: var(--value-0);
        width: var(--width-100);
        padding-right: var(--value-40);

        .form-fields {
          border-right: var(--display-none);
        }
      }
    }

    .mat-expansion-panel-content {
      margin-top: var(--value-40);

      .items-wrapper-grid {
        padding: var(--value-0);
      }
    }
  }

  .promotion-header {
    .mat-expansion-panel-header-title {
      @include flex-justify-between(flex, center, space-between);

      .mat-slide-toggle-label {
        flex-direction: row-reverse;

        .mat-slide-toggle-content {
          font-size: var(--font-size-16);
          font-weight: var(--font-weight-500);
          padding-right: var(--value-30);
          font-family: var(--font-family);
        }
      }
    }
  }
}

//item form css
.item-search-wrapper {
  @include flex-justify-between(flex, center, space-between);
  border-bottom: 2px solid #d71ad1;
  margin-bottom: var(--value-10);
  padding-left: var(--value-0);
  padding-right: var(--value-0);
  padding-bottom: var(--value-10);

  .form-fields {
    padding-right: var(--value-20);
    width: 150px;
    height: 30px;

    @media (min-width: $media-breakpoint-md-up) {
      width: 250px;
    }

    .mdc-line-ripple {
      display: var(--display-none);
    }

    .mat-mdc-form-field-focus-overlay {
      display: var(--display-none);
    }

    .mat-mdc-form-field-flex {
      height: 30px;
    }

    .form-group {
      margin-bottom: var(--value-0);
    }

    .mat-mdc-form-field-wrapper {
      padding-bottom: var(--value-0);
    }

    input {
      height: 30px !important;
      font-size: var(--font-size-12);
    }

    .mat-mdc-form-field-underline,
    .mat-mdc-form-field-ripple {
      height: 0;
    }

    .mat-mdc-form-field-flex {
      background-color: var(--gray-100);
    }

    .mat-mdc-form-field-infix {
      padding: var(--value-0);
      border-top: var(--value-0);
    }

    .mat-mdc-form-field-label span {
      font-size: var(--font-size-12);
      padding-left: var(--value-10);
    }

    .mat-icon {
      transform: rotate(90deg);
      cursor: var(--cursor-pointer);
      padding: 0 5px;
      width: 22px;
      height: 22px;
      font-size: var(--font-size-22);
    }
  }

  .refresh {
    color: var(--black);
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-18);
    font-family: var(--font-family);
    cursor: var(--cursor-pointer);

    @media (max-width: 1800px) {
      font-size: var(--font-size-14);
    }
  }

  .item-search-btn {
    @include flex-align(flex, center);
    height: 60px;

    @media (max-width: 767px) {
      justify-content: flex-end;
    }

    img,
    i {
      font-size: 35px;
      max-width: 35px;
      cursor: var(--cursor-pointer);
      margin-right: var(--value-20);
    }

    .btn {
      height: 60px;
    }
  }
}

.items-wrapper-grid {
  padding: 0 50px;

  &.new-item {
    padding: var(--value-0);
  }

  .journey-grid {
    .ag-row-group {
      @include flex-align(flex, center !important);
    }

    .ag-details-row {
      padding: 10px 0px 0px 0px;

      .ag-header-cell {
        background-color: var(--French-Rose) !important;
        padding: var(--value-0);

        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }
}

#fb-forecast-new-planner {
  min-height: 70vh;
  padding: var(--value-0);

  .side-label {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 5px;
  }

  .btn-wrapper {
    .btn {
      font-size: var(--font-size-13);
      min-width: 60px;
      height: 35px;

      @media (max-width: $media-breakpoint-xxl-screen-down) {
        font-size: var(--font-size-12);
      }
    }

    .mat-select {
      &.disti-dropdown {
        @include border-radius(0);
        color: var(--color-30);
        font-weight: normal;
        height: 48px !important;
        box-shadow: 0 3px 1px -2px rgba(black, 0.2),
          0 2px 2px 0 rgba(black, 0.14), 0 1px 5px 0 rgba(black, 0.12);
        border: var(--display-none);
        margin: var(--value-0);
        padding: 0 20px 0 35px;
        background-color: var(--white);
        transition: all 0.5s;

        @media (max-width: $media-breakpoint-xxl-screen-down) {
          height: 40px !important;
        }

        &:hover {
          background-color: var(--secondary-carnation);

          .mat-select-trigger {

            .mat-select-placeholder,
            .mat-select-value-text {
              color: var(--white);
            }

            .mat-select-arrow {
              color: var(--white) !important;
            }
          }
        }

        .mat-select-trigger {
          height: var(--height-100);

          .mat-select-placeholder,
          .mat-select-value-text {
            @include flex-align(flex, center);
            height: var(--height-100);
            font-size: var(--font-size-16);
            color: var(--black);
            font-weight: normal !important;

            @media (max-width: $media-breakpoint-xxl-screen-down) {
              font-size: var(--font-size-12);
            }
          }

          .mat-select-arrow {
            border-style: solid;
            border-width: 0 2px 2px 0;
            content: '';
            display: var(--display-inline-block);
            padding: 3px;
            transform: rotate(45deg);
            color: var(--black) !important;
            border-right: 2px solid;
            margin: var(--value-0);
            border-top: var(--value-0);

            @media (max-width: $media-breakpoint-xxl-screen-down) {
              padding: 3px;
            }
          }
        }
      }
    }

    display: var(--display-flex);

    // .form-group {
    //   width: 35%;
    // }
  }

  .dropdown {
    .btn {
      @include border-radius(0);
      color: var(--color-30);
      font-weight: normal;
      height: 48px;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      border: var(--display-none);
      margin: var(--value-0);
      padding: 0 20px 0 35px;
      background-color: var(--white);

      @media (max-width: $media-breakpoint-xxl-screen-down) {
        font-size: var(--font-size-12);
        height: 40px;
      }

      &:hover {
        background-color: var(--secondary-carnation);
        color: var(--white);
      }
    }

    .dropdown-toggle {
      &[aria-expanded='true'] {
        color: var(--white);

        &:before {
          top: 20px;
          transform: rotate(-135deg);

          @media (max-width: $media-breakpoint-xxl-screen-down) {
            top: 17px;
          }
        }
      }

      &:after {
        display: var(--display-none);
      }

      &:before {
        position: var(--position-absolute);
        right: 20px;
        top: 15px;
        border-style: solid;
        border-width: 0 2px 2px 0;
        content: '';
        display: var(--display-inline-block);
        padding: 3px;
        transform: rotate(45deg);

        @media (max-width: $media-breakpoint-xxl-screen-down) {
          padding: 3px;
          top: 13px;
        }
      }
    }

    .dropdown-menu {
      width: var(--width-100);
      margin: var(--value-0);
      @include border-radius(0);
      padding: var(--value-0);

      .dropdown-container {
        padding: var(--value-0);
        zoom: 80%;

        @media (max-width: $media-breakpoint-xxl-screen-down) {
          zoom: 65%;
        }

        .treeview-header {
          .dropdown-divider {
            border-top: 1px solid #a3a3a3;
          }

          .form-control {
            @include border-radius(0);
          }
        }
      }

      .row-all {
        .col-12 {
          @include flex-justify-between(flex, center, space-between);
          padding: 0 20px 0 25px;

          i {
            position: static;
            font-size: var(--font-size-14);
            right: var(--value-0);
          }
        }
      }
    }

    .treeview-container {
      padding: var(--value-0);

      .row-item {
        margin-bottom: var(--value-0);
        padding: 16px 45px;
        position: var(--position-relative);
        width: var(--width-100);

        &:before {
          content: '';
          position: var(--position-absolute);
          bottom: var(--value-0);
          border-bottom: 1px solid #a3a3a3;
          left: -100px;
          right: var(--value-0);
        }

        i {
          position: var(--position-absolute);
          right: 20px;
          top: 50%;
          transform: translateY(-50%);

          &:before {
            border-style: solid;
            border-width: 0 2px 2px 0;
            content: '';
            display: var(--display-inline-block);
            padding: 5px;
            transform: rotate(45deg);
          }

          .bi {
            display: var(--display-none);
          }
        }
      }

      .treeview-item {
        .treeview-item {
          margin-left: var(--value-0);
          padding: 0 0 0 25px;
        }
      }
    }
  }

  .form-check-input {
    position: var(--position-absolute); // take it out of document flow
    opacity: 0; // hide it

    &+label {
      position: var(--position-relative);
      cursor: var(--cursor-pointer);
      padding: var(--value-0);
      display: var(--display-block);
      margin: var(--value-0);
      width: var(--width-100);
    }

    // Box.
    &+label:before {
      content: '';
      margin-right: var(--value-10);
      display: var(--display-inline-block);
      vertical-align: text-top;
      width: 22px;
      height: 22px;
      background: white;
      border: 1px solid var(--black);
    }

    // Box checked
    &:disabled+label:before {
      background: #c2c1c1 !important;
      border: none !important;
    }

    &:checked+label:before {
      background: var(--secondary-carnation);
      border: var(--display-none);
    }

    // Checkmark. Could be replaced with an image
    &:checked+label:after {
      content: '';
      position: var(--position-absolute);
      left: 5px;
      top: 11px;
      background: white;
      width: 3px;
      height: 3px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
        4px -5px 0 white, 4px -9px 0 white, 4px -8px 0 white;
      transform: rotate(45deg);

      @media (max-width: $media-breakpoint-xxl-screen-down) {
        top: 16px;
      }
    }
  }

  div.settings label {
    text-align: var(--text-right);
  }

  .shipmentGroup {
    background-color: lightgray !important;
  }

  // tr {
  //   border-bottom: 2px solid lightgray;
  // }

  .ag-theme-material.budget-grid .ag-header .ag-header-cell-label .ag-header-cell-text {
    overflow: visible;
    text-overflow: inherit;
    white-space: normal;
  }

  .multiselect-dropdown {
    margin-top: var(--value-10) !important;
  }

  .rag-green-outer {
    background: #bfc9ca !important;
    /* border: 2px solid !important; */
    @include border-radius(2px);
  }

  .rag-grey-outer {
    background: lightgray;
    border-left: 2px solid !important;
  }

  .rag-white-outer {
    background: var(--white);
    border-left: 2px solid !important;
  }

  .rag-border-outer {
    border-bottom: 2px solid !important;
  }

  .rag-border-top-outer {
    border-top: 2px solid !important;
  }

  .multiselect-item-checkbox {
    white-space: nowrap !important;
  }

  .selected-item {
    white-space: nowrap !important;
    max-width: 100% !important;
  }

  .multiselect-dropdown .dropdown-btn {
    display: var(--display-inline-block);
    border: 1px solid var(--btn-disabled);
    width: var(--width-100);
    padding: 6px 12px;
    margin-bottom: var(--value-0);
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-400);
    line-height: 1.1;
    text-align: var(--text-left);
    vertical-align: middle;
    cursor: var(--cursor-pointer);
    background-image: var(--display-none);
    @include border-radius(4px);
  }

  #container-1 {
    height: auto !important;
  }

  .mat-card:not([class*='mat-elevation-z']) {
    box-shadow: 0 4px 4px -1px #c5abc6, 0 4px 4px 0 #c5abc6, 0 4px 4px 0 #c5abc6;
  }

  .td-padding {
    td {
      padding: 0px !important;
    }
  }

  .td-padding-none {
    td {
      padding: 1px !important;
    }
  }

  .hide-visibility {
    visibility: collapse;
  }

  .material-icons {
    cursor: var(--cursor-pointer);
  }

  .not-empty-select.mat-select-value-text {
    color: #212529 !important;
    font-weight: bold !important;
  }

  ::ng-deep.mat-select-value-text {
    font-weight: var(--font-weight-500);
  }

  ::ng-deep.mat-select-arrow {
    color: black;
  }

  .example-tree-invisible {
    display: var(--display-none);
  }

  .example-tree ul,
  .example-tree li {
    margin-top: var(--value-0);
    margin-bottom: var(--value-0);
    list-style-type: var(--display-none);
  }

  .journey-form {
    width: var(--width-100);
  }

  .example-full-width {
    width: var(--width-100);
  }

  .new-dropdown {
    border: none !important;
    border-bottom: 1px solid #ee4a6f !important;

    .dropdown-down {
      display: none !important;
    }
  }

  ::ng-deep.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
    padding-bottom: 2.2em;
  }

  ::ng-deep.multiselect-dropdown .dropdown-btn {
    border: var(--display-none);
    border-bottom: 1px solid var(--French-Rose);
  }

  .mat-mdc-form-field-label-wrapper {
    top: -16px;
  }

  .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label span {
    font-size: var(--font-size-14);
    color: #969696;
    font-weight: var(--font-weight-600);
  }
}

//new dailog cs
.account {
  .p-mdc-dialog-content {
    padding: var(--value-40);
    @include border-radius(30px);

    .p-mdc-dialog-content {
      p {
        margin: var(--value-0);
        font-weight: var(--font-weight-500);
      }
    }

    .action-panel {
      padding: var(--value-0);
      justify-content: var(--justify-between);
      margin: var(--value-0);

      &.btn-wrapper {
        .btn {
          height: 45px;
          @include border-radius(10px);
          margin-left: var(--value-0);

          &.btn-secondary {
            padding: 0 45px;
          }

          &.btn-primary {
            padding: 0 50px;
          }
        }
      }
    }
  }
}

.fb-dialog-wrapper {
  .p-mdc-dialog-content {
    margin: var(--value-0);
    padding: var(--value-0);
    overflow: var(--overflow-hidden);

    h2 {
      font-size: var(--font-size-12);
      padding-bottom: 13px;
      margin: var(--value-0);
      border-bottom: 1px solid #707070;
    }

    p {
      font-size: var(--font-size-18);
      font-weight: var(--font-weight-500);
      line-height: 27px;
      margin-bottom: 35px;
      border-bottom: 1px solid #707070;
      padding-bottom: 35px;
      font-family: var(--font-family);
    }

    .radio-wrapper {
      display: var(--display-flex);
      flex-direction: column;
      height: 378px;
      overflow-y: auto;
      padding-right: 7px;

      &.measure-profile-col {
        height: calc(100vh - 420px);
        padding-right: 5px;
      }

      .mat-checkbox {
        padding: 8px 10px;
        // height: 40px;
        width: var(--width-100);

        &:nth-child(odd) {
          background-color: var(--Alabaster);
        }

        .mat-checkbox-label {
          font-size: var(--font-size-10);
          font-weight: var(--font-weight-500);
          line-height: 19px;
          margin-left: 0px;
          font-family: var(--font-family);
          text-overflow: ellipsis;
          max-width: 64px;
          overflow: var(--overflow-hidden);

          @media (min-width: $media-breakpoint-md-up) {
            max-width: 88px;
          }

          @media (min-width: 1024px) {
            font-size: var(--font-size-16);
            margin-left: var(--value-10);
            max-width: 150px;
          }
        }
      }
    }

    .inner-data {
      height: 378px;
      overflow-y: auto;
      padding-right: 7px;

      &.split-measure {
        height: calc(100vh - 420px);
        border-left: var(--value-0);
        padding: var(--value-0);
      }

      &.select-measure {
        height: calc(100vh - 420px);
        border-left: var(--value-0);
        padding: var(--value-0);
      }

      .main-label {
        padding: 8px 10px;
        margin: var(--value-0);
        // height: 40px;

        &:nth-child(odd) {
          background-color: var(--Alabaster);
        }

        .mat-checkbox-label {
          font-size: var(--font-size-12);
          font-weight: var(--font-weight-500);
          line-height: 19px;
          margin-left: var(--value-10);
          white-space: normal;
          font-family: var(--font-family);
        }

        .mdc-checkbox {
          width: 16px;
          height: 16px;
          flex: 0 0 16px;
          padding: var(--value-0);

          .mat-mdc-checkbox-touch-target {
            display: var(--display-none);
          }

          .mdc-checkbox__native-control {
            width: 16px;
            height: 16px;
            top: var(--value-0);
            right: var(--value-0);
            left: var(--value-0);
          }

          .mdc-checkbox__ripple {
            display: var(--display-none);
          }

          .mdc-checkbox__background {
            width: 16px;
            height: 16px;
            top: var(--value-0);
            left: var(--value-0);
          }
        }

        .mdc-label,
        .p-checkbox-label {
          font-size: var(--font-size-12);
          font-weight: var(--font-weight-500);
          line-height: 19px;
          margin-left: var(--value-10);
          white-space: normal;
          font-family: Roboto, sans-serif;
        }
      }
    }

    .inner-data::-webkit-scrollbar-track {
      // @include border-radius(8px);
      background-color: #E5E0EB;
    }

    .inner-data::-webkit-scrollbar {
      width: 8px;
      background-color: var(--Mercury);
    }

    .inner-data::-webkit-scrollbar-thumb {
      // @include border-radius(8px);
      border-radius: 5px;
      background-color: #a6a6a6;
    }
  }

  .action-panel {
    padding: 20px 20px 20px !important;
    justify-content: var(--justify-between);
    margin: var(--value-0);
  }

  .p-multiselect-label {
    width: auto !important;
  }
}

.item-image {
  max-width: var(--width-100);
  max-height: 100%;
}

//loading div
.loading-div {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

.error {
  border: 1px solid red !important;
}

.material-icons-round {
  cursor: var(--cursor-pointer);
}

.treeview-container .row-item {
  .form-check {
    input {
      width: 18px;
      height: 18px;
    }
  }
}

//new item planer
.new-item-planner {
  border-top: 2px solid #d81ad1;
  padding-top: 40px !important;
  margin-top: var(--value-30);

  h2 {
    color: var(--color-30);
    font-size: var(--font-size-14);
    line-height: 19px;
    font-weight: var(--font-weight-500);
    margin-bottom: var(--value-20);
  }

  .padding-right,
  .padding-left {
    @include flex-column-between(flex, column, space-between);

    .bottom-div {
      @include flex-justify-end(flex, center, flex-end);
      padding-bottom: var(--value-30);

      .form-group {
        justify-content: flex-end !important;
        margin: 0 !important;

        label {
          min-width: 50px !important;
          width: 60px;
        }
      }
    }
  }

  .padding-right {
    border-right: 1px solid #cac8c8;
  }

  .padding-left {
    @media (min-width: 1600px) {
      padding-left: var(--value-40);
    }
  }

  .item-wrapper {
    .btn-wrapper {
      margin: var(--value-0);
      padding: var(--value-0);
      width: var(--width-100);

      .btn {
        margin: 0 !important;
        @include border-radius(12px !important);
        font-weight: var(--font-weight-500);
        font-family: var(--font-family);
        height: 45px !important;
        padding: 0 40px !important;
        line-height: 19px;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #E5E0EB;
  // @include border-radius(5px);
}

::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  @include border-radius(5px);
}

// .ag-center-cols-viewport {
//   width: var(--width-100);
//   height: 100% !important;
// }

.dropdown-text {
  .treeview-item {
    white-space: normal !important;
  }
}

.sticky {
  position: var(--position-fixed);
  top: 56px;
  background-color: var(--white);

  &.right-dropdown {
    width: 204px;
  }
}

.sticky2,
.sticky3 {
  margin-top: 47px;
  display: var(--display-block);
}

.table-header-sticky {
  display: var(--display-flex);
  background-color: var(--white);
  z-index: var(--z-index-1);
  position: sticky;
  top: var(--value-0);
  margin-right: var(--value-10);

  @media (max-width: $media-breakpoint-xxl-screen-down) {
    margin-right: 12px;
  }

  .wrapper-header {
    overflow-x: scroll;
    @include flex-align(flex, center);
  }

  .header-right-sticky {
    .mat-select {
      height: 45px;
      border-right: 1px solid;
      padding: 0 13px 0 18px;
      box-shadow: 0 3px 1px -2px rgba(black, 0.2), 0 2px 2px 0 rgba(black, 0.14),
        0 1px 5px 0 rgba(black, 0.12);
      margin-bottom: var(--value-0);
      width: 194px;

      @media (max-width: $media-breakpoint-xxl-screen-down) {
        width: 135px;
        height: 35px;
        padding: 0 10px 0 10px;
      }

      .mat-select-value .mat-select-placeholder {
        color: var(--black);

        @media (max-width: $media-breakpoint-xxl-screen-down) {
          font-size: var(--font-size-12);
        }
      }

      .mat-select-arrow {
        color: black !important;
        border-style: solid;
        border-width: 0px 2px 2px 0px;
        transform: rotate(45deg);
        border-right: 2px solid #0d0d0d;
        padding: 4px;
        margin: -5px 0 0 0;

        @media (max-width: $media-breakpoint-xxl-screen-down) {
          border-width: 0px 2px 2px 0px;
          border-right: 2px solid #0d0d0d;
          padding: 3px;
        }
      }

      .mat-select-trigger {
        height: var(--height-100);

        .mat-select-value {
          vertical-align: middle;
          text-align: var(--text-left);
        }
      }
    }
  }

  &.left-dropdown-without-ag {
    .scrolling-wrapper {
      @include flex-justify-between(flex, center, space-between);
      padding-left: 5px;
      width: 211px;
      height: 45px;
      background-color: var(--white);
      border-right: 1px solid var(--black);
      box-shadow: 0 3px 1px -2px rgba(black, 0.2), 0 2px 2px 0 rgba(black, 0.14),
        0 1px 5px 0 rgba(black, 0.12);

      @media (max-width: $media-breakpoint-xxl-screen-down) {
        width: 126px;
        height: 35px;
      }

      .form-group {
        .dropdown {
          .btn {
            box-shadow: none !important;
            padding: 0 !important;
            color: var(--white) !important;
            height: 45px !important;
            min-width: 35px !important;
            width: 36px !important;

            &:before {
              position: var(--position-absolute);
              right: 20px;
              top: 15px;
              border-style: solid;
              border-width: 0 2px 2px 0;
              content: '';
              display: var(--display-inline-block);
              padding: 5px;
              transform: rotate(45deg);
            }
          }
        }

        &.time-dropdown {
          app-treeview-dropdown {
            width: var(--width-100);

            .mat-select-trigger {
              padding-top: 2px;

              .mat-select-value {
                padding-top: 4px;
              }
            }

            .dropdown {
              .dropdown-toggle:before {
                right: 7px;
                top: 9px;
                padding: 3px;
              }

              .btn {
                background-color: var(--gray-100);
                height: 30px !important;
                padding: 0 5px 0 10px;
                font-size: var(--font-size-12);
                @include border-radius(0);
                width: var(--width-100);
                text-align: var(--text-left);
                box-shadow: var(--display-none);
              }
            }
          }
        }
      }
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.wrapper-header::-webkit-scrollbar {
  display: var(--display-none);
}

.Global-data-row::-webkit-scrollbar {
  display: var(--display-none);
}

.IIP-data-row::-webkit-scrollbar {
  display: var(--display-none);
}

// #fb-forecast-grid::-webkit-scrollbar {
//   display: var(--display-none);
// }
.regional-data-row::-webkit-scrollbar {
  display: var(--display-none);
}

.cdk-overlay-container {
  .cdk-overlay-pane {
    .orderClass {
      margin-top: 60px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: var(--display-none);
      zoom: 80%;
      margin-left: 7px;
      @include border-radius(0);

      .mat-mdc-option {
        font-size: 1rem !important;
      }
    }
  }
}

.form-element-position {
  .dropdown {
    .dropdown-menu {
      position: static;
    }
  }
}

//khizar

//username

.dashboardImageDark {
  display: var(--display-none);
}

.product-wrapper {
  h3 {
    &.title {
      font-size: var(--font-size-16);
      font-weight: var(--font-weight-500);
      margin: 20px 0;
    }
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
    background-color: var(--white);
  }

  .mat-expansion-panel {
    .mat-expansion-panel-header {
      padding: 0 20px 0 60px;
      position: var(--position-relative);

      .mat-expansion-indicator {
        position: var(--position-absolute);
        left: 22px;
        top: var(--value-10);

        &[style='transform: rotate(180deg);'] {
          top: 15px;
        }

        &:after {
          padding: 4px;
        }
      }

      .mat-expansion-panel-header-title {
        span {
          color: var(--primary-purple);
        }
      }
    }

    .mat-expansion-panel-header-description {
      flex-grow: unset;
      margin: var(--value-0);

      a {
        padding-left: 5px;
        cursor: var(--cursor-pointer);
        @include flex-center(flex, center, center);
        height: var(--height-100);
        color: rgba(0, 0, 0, 0.54);

        &:hover {
          text-decoration: var(--display-none);
        }
      }

      .delete-icon {
        padding-left: 5px;
      }
    }

    .mat-expansion-panel {
      .mat-expansion-panel-header {
        padding: 0 20px 0 100px;

        .mat-expansion-indicator {
          left: 60px;
        }
      }

      .mat-expansion-panel {
        .mat-expansion-panel-header {
          padding: 0 20px 0 140px;

          .mat-expansion-indicator {
            left: 100px;
          }
        }

        .mat-expansion-panel {
          .mat-expansion-panel-header {
            padding: 0 20px 0 180px;

            .mat-expansion-indicator {
              left: 140px;
            }
          }
        }
      }
    }
  }

  .single-link {
    padding: 0 20px 0 170px;
    background-color: var(--Concrete);

    &:hover {
      background-color: var(--white);
    }

    a {
      @include flex-align(flex, center);
    }

    .delete-icon {
      padding-top: var(--value-10);
      padding-left: var(--value-10);
    }

    &.child-links {
      padding: 0 20px 0 45px;
    }

    .mat-mdc-list-item {
      color: var(--black);
      font-weight: var(--font-weight-600);
      font-size: var(--font-size-18);
      font-family: var(--font-family);

      &:hover {
        background-color: transparent;
      }

      span {
        color: var(--primary-purple);
        padding-left: 5px;
      }
    }
  }
}

//modal style
.fb-dialog-wrapper2 {
  max-width: 700px !important;
  width: 700px !important;
  margin: 15px;

  @media (min-width: $media-breakpoint-xxl-screen-up) {
    max-width: 848px !important;
    width: 848px !important;
  }

  &.fb-dialog-wrapper3 {
    max-width: 780px !important;
    width: 780px !important;
    margin: 15px;

    @media (min-width: $media-breakpoint-lg-up) {
      margin: var(--value-0);
    }

    @media (min-width: $media-breakpoint-xxl-screen-up) {
      max-width: 880px !important;
      width: 880px !important;
    }
  }

  &.fb-dialog-wrapper4 {
    max-width: 686px !important;
    width: 686px !important;
    margin: 15px;

    @media (min-width: $media-breakpoint-xxl-screen-up) {
      max-width: 686px !important;
      width: 686px !important;
    }

    .p-mdc-dialog-content {
      .action-panel {
        justify-content: flex-start;
      }
    }
  }

  &.fb-dialog-wrapper5 {
    max-width: 596px !important;
    width: 596px !important;
    margin: 15px;

    @media (min-width: $media-breakpoint-xxl-screen-up) {
      max-width: 596px !important;
      width: 596px !important;
    }
  }

  .mat-mdc-dialog-title {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 27px !important;
    font-family: var(--font-family) !important;

    @media (min-width: $media-breakpoint-xxl-screen-up) {
      font-size: var(--font-size-18);
    }
  }

  .p-mdc-dialog-content {
    margin: var(--value-0);
    padding: var(--value-0);
    border-radius: 0 !important;

    h2 {
      font-size: var(--font-size-12);
      padding-bottom: 13px;
      margin: var(--value-0);
      border-bottom: 1px solid #707070;
    }

    p {
      font-size: var(--font-size-14);
      margin-bottom: 15px;
      padding-bottom: 15px;
      font-weight: var(--font-weight-500);
      line-height: 27px;
      border-bottom: 1px solid #707070;
      font-family: var(--font-family);

      @media (min-width: $media-breakpoint-xxl-screen-up) {
        font-size: var(--font-size-18);
        margin-bottom: var(--value-20);
        padding-bottom: var(--value-20);
      }
    }
  }

  .mat-mdc-form-field {
    .mat-mdc-form-field-wrapper {
      margin: 8px 0 0;
      padding-bottom: 35px;

      .mat-mdc-form-field-flex {
        padding: 0 20px;

        .mat-mdc-form-field-outline {
          background-color: var(--gray-100);

          .mat-mdc-form-field-outline-start,
          .mat-mdc-form-field-outline-gap,
          .mat-mdc-form-field-outline-end {
            display: var(--display-none);
          }
        }

        .mat-mdc-form-field-infix {
          border: 0;
          padding: 20px 0;

          input::placeholder {
            font-size: var(--font-size-22);
            font-family: var(--font-family);
            font-weight: normal;
            color: var(--color-30);
          }
        }
      }
    }
  }

  .action-panel {
    padding: var(--value-0);
    justify-content: var(--justify-center);
    margin: var(--value-0);
    flex-wrap: wrap;

    &.btn-wrapper {
      .btn {
        margin-left: var(--value-10);
        margin-bottom: var(--value-10);

        &:first-child {
          @media (min-width: $media-breakpoint-md-up) {
            margin-left: var(--value-0);
          }
        }
      }
    }
  }
}

//global inventry css
#fb-forecast-grid {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 700px;

  .table-first-cell,
  .table-second-cell {
    padding: 0px !important;
  }

  .table-first-cell {
    .td-padding-none {
      td {
        @media (max-width: $media-breakpoint-xxl-screen-down) {
          min-width: 37px;
          max-width: 37px;
        }
      }
    }

    .table-left-cell {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      text-align: var(--text-center);
      @include flex-align(flex, center);
      font-size: large;

      label {
        padding-right: 12px;
        color: var(--white);
        min-width: 50px;
        max-width: 50px;
        text-align: var(--text-center);
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-500);
        transform: rotate(180deg);

        @media (max-width: $media-breakpoint-xxl-screen-down) {
          min-width: 35px;
          max-width: 35px;
          font-size: var(--font-size-12);
        }
      }
    }

    .table-empty-cell {
      height: 40px !important;
      margin-bottom: 2px;

      label {
        height: 40px !important;
        margin-bottom: 2px;
      }
    }
  }

  .table-second-cell {
    .table-left-cell-desc {
      height: 40px;
      @include flex-align(flex, center);
      margin-bottom: 2px;
      padding-left: 15px;
      margin-right: 2px;
      min-width: 370px;

      @media (max-width: $media-breakpoint-xxl-screen-down) {
        min-width: 215px;
        max-width: 215px;
      }

      label {
        @media (max-width: $media-breakpoint-xxl-screen-down) {
          font-size: var(--font-size-12);
        }
      }

      mat-select {
        height: 40px;
        min-width: 300px;
        max-width: 300px;
      }
    }
  }

  .table-third-cell {
    overflow-x: scroll;
    width: var(--width-100);
    padding: 0px !important;
  }

  .row {
    .col-12 {
      input {
        text-align: var(--text-left);
        width: var(--width-100);
      }

      .float-right {
        width: var(--width-100);

        i {
          position: var(--position-relative);
          right: -20px;
          font-size: var(--font-size-14);
          top: 3px;
        }
      }
    }
  }

  .mat-select {
    height: 45px;
    border-right: 1px solid;
    padding: 0 13px 0 18px;
    box-shadow: 0 3px 1px -2px rgba(black, 0.2), 0 2px 2px 0 rgba(black, 0.14),
      0 1px 5px 0 rgba(black, 0.12);
    margin-bottom: var(--value-0);

    .mat-select-trigger {
      height: var(--height-100);
    }

    .mat-select-value {
      vertical-align: middle;
      text-align: var(--text-left);

      .mat-select-placeholder {
        color: var(--black);
      }
    }

    .mat-select-arrow {
      color: black !important;
      border-style: solid;
      border-width: 0px 2px 2px 0px;
      transform: rotate(45deg);
      border-right: 2px solid #0d0d0d;
      padding: 5px;
      margin: -5px 0 0 0;
    }
  }

  .side-label {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 5px;
  }

  div.settings label {
    text-align: var(--text-right);
  }

  .shipmentGroup {
    background-color: lightgray !important;
  }

  // tr {
  //   border-bottom: 2px solid lightgray;
  // }

  .ag-theme-material.budget-grid .ag-header .ag-header-cell-label .ag-header-cell-text {
    overflow: visible;
    text-overflow: inherit;
    white-space: normal;
  }

  .multiselect-dropdown {
    margin-top: var(--value-10) !important;
  }

  .rag-green-outer {
    background: #bfc9ca !important;
    /* border: 2px solid !important; */
    @include border-radius(2px);
  }

  .rag-grey-outer {
    background: lightgray;
    border-left: 2px solid !important;
  }

  .rag-white-outer {
    background: var(--white);
    border-left: 2px solid !important;
  }

  .rag-border-outer {
    border-bottom: 2px solid !important;
  }

  .rag-border-top-outer {
    border-top: 2px solid !important;
  }

  .multiselect-item-checkbox {
    white-space: nowrap !important;
  }

  .selected-item {
    white-space: nowrap !important;
    max-width: 100% !important;
  }

  .multiselect-dropdown .dropdown-btn {
    display: var(--display-inline-block);
    border: 1px solid var(--btn-disabled);
    width: var(--width-100);
    padding: 6px 12px;
    margin-bottom: var(--value-0);
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-400);
    line-height: 1.1;
    text-align: var(--text-left);
    vertical-align: middle;
    cursor: var(--cursor-pointer);
    background-image: var(--display-none);
    @include border-radius(4px);
  }

  #container-1 {
    height: auto !important;
  }

  label {
    margin-bottom: var(--value-0);
    margin-top: var(--value-0);
  }

  .mat-card:not([class*='mat-elevation-z']) {
    box-shadow: 0 4px 4px -1px #c5abc6, 0 4px 4px 0 #c5abc6, 0 4px 4px 0 #c5abc6;
  }

  input {
    border: none !important;
    background: var(--display-none);
  }

  .td-padding {
    td {
      padding: 0px !important;
    }
  }

  .td-padding-none {
    td {
      padding: 1px !important;
    }
  }

  .hide-visibility {
    visibility: collapse;
  }

  .material-icons {
    cursor: var(--cursor-pointer);
  }

  .input-fields {
    tr {
      height: 40px;
      min-height: 40px;
      margin-bottom: 2px;
      display: var(--display-block);
      position: var(--position-relative);
      min-width: 194px;

      @media (max-width: $media-breakpoint-xxl-screen-down) {
        min-width: 135px;
      }

      &:before {
        content: '';
        position: var(--position-absolute);
        border-right: 1px solid var(--white);
        right: 1px;
        top: var(--value-0);
        bottom: var(--value-0);
      }

      input,
      div {
        padding: 7px;
        font-weight: var(--font-weight-600);
        font-family: var(--font-family);
        text-align: var(--text-right);
        height: var(--height-100);
        border-top: 5px solid #c9c9c9 !important;
        border-left: 2px solid #c9c9c9 !important;
        border-bottom: 5px solid #c9c9c9 !important;
        border-right: 5px solid #c9c9c9 !important;
        background-color: rgba($color: #fff, $alpha: 36%);
        min-width: 194px;
        width: 194px;

        @media (max-width: $media-breakpoint-xxl-screen-down) {
          min-width: 135px;
          width: 135px;
          font-size: var(--font-size-12);
        }

        &.disabled-input {
          background-color: var(--silver);
          pointer-events: var(--display-none);
          cursor: default;
        }

        &:focus {
          outline: var(--display-none);
        }
      }
    }
  }

  .not-empty-select.mat-select-value-text {
    color: #212529 !important;
    font-weight: bold !important;
  }

  ::ng-deep.mat-select-value-text {
    font-weight: bold !important;
  }

  ::ng-deep.mat-select-arrow {
    color: black;
  }

  .example-tree-invisible {
    display: var(--display-none);
  }

  .example-tree ul,
  .example-tree li {
    margin-top: var(--value-0);
    margin-bottom: var(--value-0);
    list-style-type: var(--display-none);
  }

  .journey-form {
    min-width: 150px;
    max-width: 500px;
    width: var(--width-100);
  }

  .example-full-width {
    width: var(--width-100);
  }

  .cdk-overlay-pane,
  .mat-autocomplete-panel-above {
    zoom: 70%;
  }

  .new-dropdown {
    border: none !important;
    border-bottom: 1px solid #ee4a6f !important;

    .dropdown-down {
      display: none !important;
    }
  }

  ::ng-deep.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
    padding-bottom: 2.2em;
  }

  ::ng-deep.multiselect-dropdown .dropdown-btn {
    border: var(--display-none);
    border-bottom: 1px solid var(--French-Rose);
  }

  .mat-mdc-form-field-label-wrapper {
    top: -16px;
  }

  .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label span {
    font-size: var(--font-size-14);
    color: #969696;
    font-weight: var(--font-weight-600);
  }
}

.fb-forecast-grid-bottom {
  .grid-bottom-cell {
    @media (max-width: $media-breakpoint-xxl-screen-down) {
      min-width: 35px;
      max-width: 35px;
    }
  }

  .grid-bottom-cell-center {
    min-width: 370px;

    @media (max-width: $media-breakpoint-xxl-screen-down) {
      min-width: 215px;
      max-width: 215px;
    }
  }
}

//ag-grid
//ag-grid
.left-dropdown {
  position: var(--position-absolute);
  top: 116px;
  display: var(--display-flex);
  z-index: var(--z-index-9);

  @media (min-width: $media-breakpoint-xxl-screen-up) {
    top: 160px;
  }

  .scrolling-wrapper {
    height: 49px;
    width: 192px;
    box-shadow: 0 3px 1px -2px #0003, 0 0 2px #00000024, 0 1px 5px #0000001f;
  }
}

.select-wrap {
  .mat-select {
    .mat-select-trigger {
      height: 30px;
      padding-top: 0 !important;

      .mat-select-value {
        padding-top: 0 !important;

        .mat-select-value-text {
          .mat-select-min-line {
            overflow: var(--overflow-hidden);
            text-overflow: ellipsis;
            width: 320px;
            white-space: nowrap;
            display: var(--display-block);
          }
        }
      }

      .mat-select-arrow-wrapper {
        .mat-select-arrow {
          width: 0;
          height: 0;
          padding: 3px;
        }
      }
    }
  }
}

.admin-search {
  position: var(--position-relative);

  input::-webkit-input-placeholder {
    font-size: var(--font-size-12);
    color: var(--black);
    font-weight: var(--font-weight-400);
  }

  .input-search-icon {
    position: var(--position-absolute);
    right: var(--value-0);
    padding: 5px 0;
    margin-right: var(--value-10);
  }
}

.scrolling-wrapper {
  .dropdown-container {
    ngx-treeview-item {
      .treeview-item {
        padding: 0 0 0 12px !important;
        white-space: normal !important;

        .row-item {
          display: flex !important;
          align-items: center !important;
          justify-content: space-between !important;

          i {
            position: static !important;
            transform: inherit !important;
          }
        }
      }

      label {
        overflow: visible;
        width: auto;
        min-width: inherit;
        text-overflow: inherit;
        white-space: break-spaces;
      }
    }
  }
}

#fb-forecast-new-planner .position-middle .error-state .material-icons {
  font-size: 30px !important;
  width: 30px;
  height: 30px;
}

.position-middle {
  position: var(--position-absolute);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index-1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .error-state {
    display: var(--display-flex);
    align-items: flex-start;

    .mat-icon {}

    .content {
      padding-left: var(--value-10);

      h4 {
        font-weight: var(--font-weight-bold);
        margin-bottom: 15px;
      }
    }
  }
}

app-checkbox-renderer {
  app-checkbox {
    display: flex;
    align-items: center;
  }
}

.account-expansion-setting {
  .mat-expansion-panel-header {
    height: 50px !important;
  }
}

.form-group {
  .mat-select {
    &:focus {
      border-color: #ced4da;
      box-shadow: var(--display-none);
    }

    .mat-select-value .mat-select-placeholder {
      color: var(--black);
    }

    .mat-select-arrow-wrapper {
      height: 0;
    }

    .mat-select-arrow {
      border-style: solid;
      border-width: 0 2px 2px 0;
      content: '';
      display: var(--display-inline-block);
      padding: 3px;
      color: var(--black);
      transform: rotate(45deg);
      width: inherit;
      height: inherit;
      margin: var(--value-0);
      border-left: unset;
      border-right: 2px solid;
    }
  }
}

#fb-forecast-items-attribute {
  .form-group {
    @include flex-align(flex, center);
    justify-content: flex-start;
    margin-bottom: var(--value-10);

    &.tree-view {
      app-dropdown-treeview-select {
        width: var(--width-100);

        .mat-select-trigger {
          padding-top: 2px;

          .mat-select-value {
            padding-top: 4px;
          }
        }

        .dropdown {
          .dropdown-toggle:before {
            right: 7px !important;
            top: 9px !important;
            padding: 3px !important;
          }

          .btn {
            background-color: var(--gray-100) !important;
            height: 30px !important;
            min-height: 30px !important;
            max-height: 30px !important;
            padding: 0 5px 0 10px !important;
            font-size: 12px !important;
            @include border-radius(0 !important);
            width: 100% !important;
            text-align: var(--text-left) !important;
            box-shadow: none !important;
          }
        }
      }

      .dropdown {
        .btn {
          @include border-radius(0);
          color: var(--color-30);
          font-weight: normal;
          height: 48px;
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
            0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
          border: var(--display-none);
          margin: var(--value-0);
          padding: 0 20px 0 35px;
          background-color: var(--white);

          @media (max-width: $media-breakpoint-xxl-screen-down) {
            font-size: var(--font-size-12);
            height: 40px;
          }

          &:hover {
            background-color: var(--secondary-carnation);
            color: var(--black);
          }
        }

        .dropdown-toggle {
          &[aria-expanded='true'] {
            color: var(--black);

            &:before {
              top: 20px;
              transform: rotate(-135deg);

              @media (max-width: $media-breakpoint-xxl-screen-down) {
                top: 17px;
              }
            }
          }

          &:after {
            display: var(--display-none);
          }

          &:before {
            position: var(--position-absolute);
            right: 20px;
            top: 15px;
            border-style: solid;
            border-width: 0 2px 2px 0;
            content: '';
            display: var(--display-inline-block);
            padding: 3px;
            transform: rotate(45deg);

            @media (max-width: $media-breakpoint-xxl-screen-down) {
              padding: 3px;
              top: 13px;
            }
          }
        }

        .dropdown-menu {
          width: var(--width-100);
          margin: var(--value-0);
          @include border-radius(0);
          padding: var(--value-0);

          .dropdown-container {
            padding: var(--value-0);
            zoom: 80%;

            @media (max-width: $media-breakpoint-xxl-screen-down) {
              zoom: 65%;
            }

            .treeview-header {
              .dropdown-divider {
                border-top: 1px solid #a3a3a3;
              }

              .form-control {
                @include border-radius(0);
              }
            }
          }

          .row-all {
            .col-12 {
              @include flex-justify-between(flex, center, space-between);
              padding: 0 20px 0 25px;

              i {
                position: static;
                font-size: var(--font-size-14);
                right: var(--value-0);
              }
            }
          }
        }

        .treeview-container {
          padding: var(--value-0);

          .row-item {
            margin-bottom: var(--value-0);
            padding: 16px 45px;
            position: var(--position-relative);
            width: var(--width-100);

            &:before {
              content: '';
              position: var(--position-absolute);
              bottom: var(--value-0);
              border-bottom: 1px solid #a3a3a3;
              left: -100px;
              right: var(--value-0);
            }

            i {
              position: var(--position-absolute);
              right: 20px;
              top: 50%;
              transform: translateY(-50%);

              &:before {
                border-style: solid;
                border-width: 0 2px 2px 0;
                content: '';
                display: var(--display-inline-block);
                padding: 5px;
                transform: rotate(45deg);
              }

              .bi {
                display: var(--display-none);
              }
            }
          }

          .treeview-item {
            .treeview-item {
              margin-left: var(--value-0);
              padding: 0 0 0 25px;
            }
          }
        }
      }
    }
  }
}

.mat-progress-bar-fill::after {
  background-color: darkgray;
}

.mat-progress-bar-buffer {
  background: lightgray;
}

.mat-progress-bar {
  @include border-radius(10px);
  height: 15px !important;
}

.loader-container {
  position: var(--position-fixed);
  top: var(--value-0);
  left: var(--value-0);
  right: var(--value-0);
  z-index: 99999;

  .mat-progress-bar {
    @include border-radius(0);
    height: 6px !important;
    z-index: 99999;
  }
}

.success-text {
  display: var(--display-flex);
  padding-left: 14px;
  font-size: var(--font-size-16);
  padding: 14px;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-400);
}

.ag-theme-material {
  app-add-button-renderer {
    width: var(--width-100);
  }

  app-button-renderer {
    .mat-slide-toggle-label {
      flex-direction: row-reverse;

      .mat-slide-toggle-bar {
        margin-left: 12px;
      }

      .mat-slide-toggle-content {
        font-weight: var(--font-weight-500);
      }
    }
  }
}

// .bulk-wrapper {
//   .items-wrapper-grid {
//     padding: var(--value-0);

//     .ag-theme-material .ag-center-cols-viewport .ag-row .ag-cell {
//       padding: 0 14px;

//       app-status-renderer {
//         .mat-progress-bar {
//           @include border-radius(20px !important);
//           height: 25px !important;
//           width: 125px;
//           text-transform: capitalize;
//           color: var(--white);

//           &.completed {
//             .mat-progress-bar-fill::after {
//               background-color: var(--Salem);
//             }
//           }

//           &.failed {
//             .mat-progress-bar-fill::after {
//               background-color: var(--red);
//             }
//           }

//           &.in-progress {
//             .mat-progress-bar-buffer {
//               background-color: var(--Amber);
//             }

//             .mat-progress-bar-fill::after {
//               background-color: var(--mine-shaft);
//             }
//           }

//           &.in.progress {
//             .mat-progress-bar-buffer {
//               background-color: var(--Amber);
//             }

//             .mat-progress-bar-fill::after {
//               background-color: var(--mine-shaft);
//             }
//           }

//           &.started {
//             .mat-progress-bar-fill::after {
//               background-color: var(--Outrageous-Orange);
//             }
//           }
//         }
//       }
//     }
//   }

//   #fb-forecast-new-planner {
//     .dropdown {
//       .treeview-container {
//         max-height: 250px !important;
//       }
//     }
//   }
// }

.progress-wrapper {
  position: var(--position-relative);

  span {
    position: var(--position-absolute);
    z-index: var(--z-index-9);
    color: var(--white);
    top: var(--value-0);
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    line-height: 25px;
  }
}

.job-histroy-dailog {
  height: 650px !important;

  .job-dailog-wrapper {
    height: calc(100% - 0px) !important;

    .p-mdc-dialog-content {
      height: 600px;

      .item-wrapper {
        height: calc(100% - 20px);

        form {
          height: var(--height-100);

          .p-fieldset {
            height: var(--height-100);
          }
        }
      }
    }

    .action-panel {
      padding: 0 !important;
    }
  }
}

.job-history-grid {
  .ag-body-viewport {
    overflow-y: auto;
    max-height: 300px;
  }
}

.refesh-icon {
  width: 34px;
  padding-left: var(--value-10);

  @media (max-width: 1800px) {
    width: 30px;
  }
}

.loading-cross {
  @include flex-align(flex, center);
  flex-direction: row-reverse;

  span {
    background-color: var(--secondary-carnation);
    @include flex-center(flex, center, center);
    color: var(--white);
    height: 40px;
    padding: 0 42px;
    margin-left: 5px;
    cursor: var(--cursor-pointer);
    font-size: var(--font-size-16);
    @include border-radius(12px);
  }
}

.detail-label {
  color: #9700a5;
  font-weight: var(--font-weight-500);
  display: var(--display-block);
  text-transform: capitalize;
  text-decoration: underline;
  font-size: var(--font-size-12);
  cursor: var(--cursor-pointer);
}

.job-history {

  .ag-cell-focus,
  .ag-cell-no-focus {
    border: none !important;
  }

  /* This CSS is to not apply the border for the column having 'no-border' class */
  .no-border.ag-cell:focus {
    border: none !important;
    outline: var(--display-none);
  }
}

.upload-icon {
  font-size: var(--font-size-30);
  padding: 5px;

  &:hover {
    color: black !important;
  }
}

.config-container {
  min-height: 0vh !important;
}

.custom-toggle {
  @include flex-align(flex !important, center !important);

  label {
    width: auto !important;
    min-width: auto !important;
    padding-right: var(--value-10);

    @media (max-width: 400px) {
      padding-right: var(--value-0);
    }
  }
}

.ag-watermark-text {
  display: none !important;
}

.ag-watermark {
  display: none !important;
}

app-dropdown-renderer {
  width: var(--width-100);

  .mat-select-placeholder,
  .mat-select-value-text {
    color: var(--black);
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-12);
  }
}

//snakbar states
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: transparent !important;
}

.info {
  background: #e9e9ff;
  border: solid #696cff;
  border-width: 0 0 0 6px;
  color: #696cff;

  .snackbar-message {
    color: #696cff;
  }
}

.p-toast .p-toast-message.p-toast-message-info {
  background: #e9e9ff;
  border: solid #696cff;
  border-width: 0 0 0 6px;
  color: #696cff;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #696cff;
}

.success {
  background: #e4f8f0;
  border: solid #1ea97c;
  border-width: 0 0 0 6px;
  color: #1ea97c;

  .snackbar-message {
    color: #1ea97c;
  }
}

.p-toast .p-toast-message.p-toast-message-success {
  background: #e4f8f0;
  border: solid #1ea97c;
  border-width: 0 0 0 6px;
  color: #1ea97c;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #1ea97c;
}

.warn {
  background: #fff2e2;
  border: solid #cc8925;
  border-width: 0 0 0 6px;
  color: #cc8925;

  .snackbar-message {
    color: #cc8925;
  }
}

.p-toast .p-toast-message.p-toast-message-warn {
  background: #fff2e2;
  border: solid #cc8925;
  border-width: 0 0 0 6px;
  color: #cc8925;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #cc8925;
}

.error {
  background: #ffe7e6;
  border: solid #ff5757 !important;
  border-width: 0 0 0 6px !important;
  color: #ff5757;

  .snackbar-message {
    color: #ff5757;
  }
}

.p-toast .p-toast-message.p-toast-message-error {
  background: #ffe7e6;
  border: solid #ff5757 !important;
  border-width: 0 0 0 6px !important;
  color: #ff5757;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #ff5757;
}

.p-toast {
  width: auto;

  .p-toast-message-content {
    padding: 14px 8px 14px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .p-avatar {
      border-radius: 0;
      width: auto;
      height: auto;
      display: block;

      img {
        max-width: 70px;
        padding-right: var(--value-20);
        width: auto;
        height: auto;
      }
    }

    p {
      margin: 0;
      font-size: 14px;
      padding-right: var(--value-30);
    }
  }

  .p-toast-icon-close {
    height: auto;
  }
}

.setting-icon {
  font-size: 35px !important;
  cursor: var(--cursor-pointer);
}

.mat-select-value-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block !important;
  overflow: var(--overflow-hidden);
  max-width: 350px !important;
}

// .ag-theme-alpine-dark {
//   &.custom-ag-grid {
//     .white-background {
//       border-bottom: 4px solid var(--white) !important;
//     }

//     .ag-horizontal-left-spacer,
//     .ag-horizontal-right-spacer {
//       border-right: var(--display-none);
//       overflow: hidden !important;
//       border-left: var(--display-none);
//     }

//     .ag-center-cols-viewport {
//       width: var(--width-100);
//       overflow-x: auto !important;
//     }

//     .ag-row {
//       border: var(--display-none);
//     }

//     .ag-pinned-left-cols-container {
//       .ag-row {
//         .ag-cell {
//           border-left: none !important;

//           &:nth-child(1) {
//             padding: var(--value-0);
//           }

//           &:nth-child(2) {
//             border-right: 1px solid var(--white) !important;
//             border-color: var(--white);
//             border-bottom: 1px solid var(--white) !important;
//             padding: 6px 15px;
//             display: flex !important;
//             text-align: var(--text-right) !important;
//             align-items: center;
//           }
//         }
//       }
//     }

//     .ag-center-cols-viewport {
//       .ag-row {
//         border-bottom: 1px solid var(--white);
//       }

//       .ag-cell {
//         border-right: 1px solid var(--white) !important;
//         padding: 12px;
//         font-weight: var(--font-weight-800);
//         text-align: var(--text-right);
//         line-height: 26px;
//         display: flex !important;
//         flex-direction: row-reverse;
//         text-align: var(--text-right) !important;
//         align-items: center;

//         &:after {
//           content: "";
//           position: var(--position-absolute);
//           // border: 5px solid var(--black);
//           bottom: var(--value-0);
//           left: var(--value-0);
//           right: var(--value-0);
//           top: var(--value-0);
//         }

//         &.disabled-input {
//           // background-color: var(--silver) !important;
//           opacity: 0.85;

//           &:after {
//             display: var(--display-none);
//           }
//         }

//         &.ag-cell-inline-editing {
//           height: 40px;
//           box-shadow: var(--display-none);
//           background: var(--black) !important;

//           app-custom-cell-editor {
//             input {
//               font-weight: var(--font-weight-600);
//               text-align: var(--text-right);
//               border: var(--display-none);
//               background-color: transparent;
//               padding: var(--value-0);

//               &:focus-visible {
//                 outline: var(--display-none);
//               }
//             }
//           }

//           .ag-input-field-input {
//             padding-bottom: var(--value-0);
//             height: 30px;
//             border: var(--display-none);
//             text-align: var(--text-right);
//             font-weight: var(--font-weight-800);
//           }
//         }
//       }
//     }

//     .ag-cell {
//       border: none !important;
//     }

//     .ag-header {
//       height: 50px !important;
//       min-height: 50px !important;
//       background-color: var(--black);

//       .ag-header-row {
//         height: 50px !important;
//       }

//       .ag-header-viewport {
//         border-bottom: var(--display-none);
//       }
//     }

//     .ag-header-viewport {
//       .ag-header-cell {
//         padding: var(--value-0);
//       }
//     }

//     .ag-pinned-left-header {
//       .ag-header-row {
//         .ag-header-cell {
//           &:nth-child(2) {
//             // width: 382px !important;
//             // left: 2px !important;
//             padding: 0 !important;

//             app-grid-custom-header {
//               display: flex;
//             }
//           }
//         }
//       }
//     }

//     .ag-header-cell {
//       padding: 0 10px 0 10px;

//       app-grid-custom-header {
//         width: var(--width-100);

//         .mat-select {
//           box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024,
//             0 1px 5px #0000001f;
//           padding: 0 10px 0 10px;
//           height: 50px;
//           display: flex;
//           align-items: center;
//           border-right: 1px solid var(--black);

//           .mat-select-trigger {
//             width: var(--width-100);
//           }

//           .mat-select-arrow {
//             color: black !important;
//             border-style: solid;
//             border-width: 0px 2px 2px 0px;
//             transform: rotate(45deg);
//             border-right: 2px solid #0d0d0d;
//             padding: 4px;
//             margin: -5px 0 0 0;

//             @media (max-width: $media-breakpoint-xxl-screen-down) {
//               padding: 3px;
//             }
//           }

//           .mat-select-placeholder {
//             color: var(--black);
//             font-size: var(--font-size-14);
//             font-weight: var(--font-weight-400);

//             @media (max-width: $media-breakpoint-xxl-screen-down) {
//               font-size: var(--font-size-12);
//             }
//           }
//         }
//       }
//     }
//   }
// }

//prime ng multiselect
.p-multiselect {
  border: var(--display-none);
  background-color: var(--gray-100);
  background: var(--gray-100);
  height: 30px;
  padding: 0 10px;
  font-size: var(--font-size-12);
  @include border-radius(5px);
  width: 100% !important;
  text-align: var(--text-left);

  .p-multiselect-trigger {
    width: auto;

    .p-multiselect-trigger-icon {
      font-size: var(--font-size-12);
      font-weight: var(--font-weight-500);
      width: 12px;
      height: 12px;
    }
  }

  .p-multiselect-panel {
    .p-multiselect-header {
      padding: 0 10px;

      .p-multiselect-filter-container {
        .p-inputtext {
          width: var(--width-100);
          border: var(--display-none);
          height: 30px;
          padding: 0 10px;
          text-align: var(--text-left);
          background-color: var(--gray-100);

          &:focus {
            box-shadow: var(--display-none);
          }
        }
      }
    }

    .p-multiselect-items {
      .p-multiselect-item {
        font-size: var(--font-size-14);
        padding: 12px 10px;
        @media (max-width: 1800px){
          font-size: 12px;
          padding: 8px 10px;
        }
        &.p-highlight {
          background-color: var(--white);
          color: var(--black);
        }
      }

      &:focus {
        .p-highlight {
          background-color: var(--white);
          color: var(--black);
        }
      }
    }
  }

  // .p-checkbox {
  //   .p-checkbox-box {
  //     border-color: #c1c1c1;

  //     &.p-highlight {
  //       border-color: var(--Wild-Strawberry);
  //       background: var(--Wild-Strawberry);

  //       &:hover {
  //         border-color: var(--Wild-Strawberry);
  //         background: var(--Wild-Strawberry);
  //       }

  //       .p-checkbox-icon.pi-check:before {
  //         top: 7px;
  //         left: 2px;
  //       }
  //     }
  //   }
  // }

  .p-multiselect-items-wrapper {
    ul {
      display: var(--display-block);
      margin: var(--value-0);
    }
  }

  .p-multiselect-label-container {
    @include flex-align(flex, center);
    width: 100px;

    .p-multiselect-label {
      color: var(--black);
      padding: var(--value-0);
      font-size: var(--font-size-12);
      width: var(--width-100);

      @media (max-width: 1800px) {
        font-size: var(--font-size-10);
      }
    }
  }
}

//prime ng dropdown
.p-dropdown {
  border: var(--display-none);
  background-color: var(--gray-100);
  background: var(--gray-100);
  height: 30px;
  padding: 0 10px;
  font-size: var(--font-size-12);
  @include border-radius(5px);
  width: 100% !important;
  text-align: var(--text-left);

  // &.p-focus {
  //   border: var(--display-none);
  //   box-shadow: none !important;
  // }

  .p-inputtext {
    font-size: var(--font-size-12);
    @include flex-align(flex, center);
    padding: var(--value-0);
    color: var(--black) !important;

    @media (max-width: 1800px) {
      font-size: var(--font-size-10);
    }
  }

  .p-dropdown-trigger {
    width: auto;

    .p-dropdown-trigger-icon {
      font-size: var(--font-size-12);
      font-weight: var(--font-weight-500);
      width: 12px;
      height: 12px;
    }
  }

  .p-dropdown-header {
    .p-dropdown-filter {
      &:focus {
        box-shadow: var(--display-none);
      }
    }
  }

  .p-dropdown-items {
    display: var(--display-block);
    margin: var(--value-0);

    .p-dropdown-item {
      font-size: var(--font-size-14);

      &.p-highlight {
        background-color: var(--secondary-carnation);
        color: var(--white);
      }
    }
  }
}

// .p-disabled {
//   opacity: 0.75 !important;
// }

.p-treeselect-panel {
  .p-treeselect-header {
    .p-treeselect-filter {
      height: 30px;

      &:focus {
        box-shadow: var(--display-none);
        border-color: var(--black);
      }
    }
  }

  .p-tree-empty-message {
    height: 40px;
    padding: var(--value-10);
  }
}

//prime ng multiselect

.yellow-highlighted {
  .save-clear-btn {
    .btn.btn-primary {
      background-color: var(--Web-Orange) !important;
    }
  }

  .p-tree {
    .p-tree-container {
      .p-treenode {
        // .p-checkbox {
        //   .p-checkbox-box.p-highlight {
        //     border-color: var(--Web-Orange);
        //     background: var(--Web-Orange);
        //   }
        // }
      }
    }
  }

  // .p-multiselect-panel .p-checkbox .p-checkbox-box.p-highlight {
  //   border-color: var(--Web-Orange);
  //   background: var(--Web-Orange);
  // }
}

.tree-filter-class {
  .p-treeselect-items-wrapper {
    padding-top: 41px;
  }
}

//tree dropdown{
.p-dropdown-panel {
  .p-dropdown-header {
    padding: 5px 10px;

    .p-dropdown-filter-container {
      .p-inputtext {
        width: var(--width-100);
        border: var(--display-none);
        height: 30px;
        padding: 0 10px;
        text-align: var(--text-left);
        background-color: var(--gray-100);

        &:focus {
          box-shadow: var(--display-none);
        }
      }
    }
  }

  .p-dropdown-items {
    .p-dropdown-item {
      font-size: var(--font-size-13);
      padding: 11px 16px;

      @media (max-width: 1800px) {
        font-size: var(--font-size-12);
      }

      &.p-highlight {
        color: var(--secondary-carnation);
        background: rgba(#f04371, 0.12);
      }
    }
  }
}

///tree multiselect panel
.p-multiselect-panel {
  z-index: var(--z-index-9);

  .p-multiselect-header {
    padding: 0 10px;

    .p-multiselect-filter-container {
      .p-inputtext {
        width: var(--width-100);
        border: var(--display-none);
        height: 30px;
        padding: 0 10px;
        text-align: var(--text-left);
        background-color: var(--gray-100);

        &:focus {
          box-shadow: var(--display-none);
        }
      }
    }
  }

  .p-multiselect-items {
    .p-multiselect-item {
      font-size: var(--font-size-14);
      padding: 12px 10px;
      @media (max-width: 1800px){
        font-size: 12px;
        padding: 8px 10px;
      }
      @media (max-width: 425px) {
        width: 318px;
      }

      &.p-highlight {
        background-color: var(--white) !important;
        color: var(--black);
      }
    }

    &:focus {
      .p-highlight {
        background-color: var(--white);
        color: var(--black);
      }
    }
  }

  // .p-checkbox {
  //   .p-checkbox-box {
  //     border-color: #c1c1c1;

  //     &.p-highlight {
  //       border-color: var(--Wild-Strawberry);
  //       background: var(--Wild-Strawberry);

  //       &:hover {
  //         border-color: var(--Wild-Strawberry);
  //         background: var(--Wild-Strawberry);
  //       }

  //       .p-checkbox-icon.pi-check:before {
  //         top: 7px;
  //         left: 2px;
  //       }
  //     }
  //   }
  // }

  .p-multiselect-items-wrapper {
    ul {
      display: var(--display-block);
      margin: var(--value-0);
      padding: var(--value-0);
    }
  }

  .p-multiselect-label-container {
    @include flex-align(flex, center);

    .p-multiselect-label {
      color: var(--black);
      padding: var(--value-0);
    }
  }
}

.p-tree-container {
  overflow: hidden !important;
  margin: var(--value-0);
  padding: var(--value-0);
}

//retail dropdown
.disti-dropdown {
  .p-dropdown {
    @include border-radius(0);
    color: var(--color-30);
    font-weight: normal;
    height: 48px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
    border: var(--display-none);
    margin: var(--value-0);
    padding: 0 20px 0 35px;
    background-color: var(--white);
    width: var(--width-100);

    @media (max-width: 767px) {
      padding: 0 15px 0 15px;
    }

    &:focus {
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
    }

    .p-inputtext {
      font-size: var(--font-size-14);
    }
  }

  .p-multiselect {
    @include border-radius(0);
    color: var(--color-30);
    font-weight: normal;
    height: 48px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
    border: var(--display-none);
    margin: var(--value-0);
    padding: 0 20px 0 35px;
    background-color: var(--white);
    width: var(--width-100);

    &:focus {
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
    }

    .p-inputtext {
      font-size: var(--font-size-16);
    }
  }
}

/* .measure-search {
  padding: 8px 4px;
  margin: -4px;
  height: 10px;
  background-color: var(--Alabaster);
  border: 1px var(--black);
} */
.default-wrapper {
  display: flex;
  flex-direction: column;
  height: 378px;
  overflow-y: auto;
  padding-right: 7px;

  .mat-checkbox {
    padding: 8px 10px;
    height: 40px;
  }
}

.default-icon {
  padding: 8px 10px;
  height: 40px;
  cursor: var(--cursor-pointer);
}

.action-icons {
  @include flex-align(flex, center);
  cursor: var(--cursor-pointer);
  width: var(--width-100);

  .action-icon {
    padding: 8px 4px;
  }
}

.profiles {
  margin-bottom: 5px;
  background: var(--Gallery);
  @include border-radius(5px);
  padding: 0px 10px;
  vertical-align: middle;
  justify-content: flex-start;
  margin-bottom: 1px;

  @media (min-width: $media-breakpoint-lg-up) {
    justify-content: var(--justify-center);
  }
}

.title {
  span {
    font-size: var(--font-size-12);
  }

  .default-title {
    @include flex-align(flex, center);
    font-size: var(--font-size-12);
    margin-left: var(--value-10);

    .def-icon {
      font-size: var(--font-size-16);
      cursor: var(--cursor-pointer);
    }
  }
}

// app-graph-measures {
//   .p-mdc-dialog-content {
//     max-height: revert !important;
//     height: calc(100vh - 408px) !important;
//   }

//   .p-fieldset {
//     height: calc(100vh - 432px) !important;
//   }
// }

lib-dynamic-tile {
  height: 100%;
  display: block;

  .p-fieldset {
    height: calc(100vh - 300px) !important;

    @media (min-width: 1600px) {
      height: calc(100vh - 480px) !important;
    }
  }

  .p-mdc-dialog-content {
    max-height: revert !important;
    height: 100% !important;
  }
}

app-base-planner-measure-profile,
app-graph-measures,
lib-dynamic-tile {
  .p-mdc-dialog-content {
    max-height: revert !important;
    height: calc(100vh - 300px);
  }

  .input-field {
    input {
      border: none !important;
      width: var(--width-100);
      background-color: var(--gray-100);
      padding: 5px 10px;
      font-size: var(--font-size-13);
      margin-bottom: var(--value-20);
      outline: var(--display-none);

      &::placeholder {
        font-weight: var(--font-weight-400);
        font-size: var(--font-size-13);
      }
    }
  }

  .p-fieldset {
    height: calc(100vh - 320px);

    .p-fieldset-content {
      padding-top: var(--value-0);
    }

    .mat-expansion-panel {
      @include border-radius(0 !important);

      .mat-expansion-panel-body {
        padding: 0 !important;
        margin-top: var(--value-10);
      }

      .mat-expansion-panel-header {
        height: 35px !important;

        .mat-expansion-panel-header-title {
          font-size: var(--font-size-13);
          height: 35px !important;
        }
      }
    }
  }
}

app-google-one-tap {
  iframe {
    width: 100% !important;

    .haAclf {
      display: block !important;
    }
  }
}

app-item-attributes {
  .mat-mdc-form-field-infix {
    width: auto;
  }
}

//responsive
@media (max-width: 1024px) {
  .full-width {
    padding: var(--value-0);
  }

  .right-sidenav {
    bottom: unset !important;
    z-index: 9999 !important;
    right: 10px !important;
    top: 8px !important;
    padding: 0;
    background-color: transparent;
    border: none;

    #rightSidenav {
      .mat-drawer-content {
        width: 100% !important;
        padding: 0px !important;
        flex-direction: row !important;
        height: 50px;
        border-left: 0px !important;
        background-color: transparent !important;

        .mat-icon-button {
          margin-left: 15px;
          margin-bottom: 0 !important;

          .material-icons {
            color: whitesmoke !important;
            margin-right: 5px;

            @media (min-width: 770px) {
              color: #74777b !important;
            }
          }

          &:before {
            display: var(--display-none);
          }
        }

        .journey-notification {
          display: flex !important;
        }
      }

      &.show-sidenav {
        .button-notes {
          &:after {
            display: var(--display-none);
          }
        }
      }
    }

    .mat-drawer {
      position: var(--position-fixed) !important;
      top: 50px;
    }
  }

  .notification-alert {
    // width: 40px;
    // height: 40px;
    @include align-justify(center, center);
    margin-bottom: 0 !important;

    .alert-num {
      @include flex-center(flex, center, center);
    }
  }

  .items-wrapper-grid {
    padding: 0 20px;
  }

  .body-wrapper {
    .example-container {
      z-index: inherit;
    }
  }

  .mat-drawer-content {
    margin-left: 0 !important;
  }

  .algo-sidenav.main-sidenav {
    top: 40px;
  }

  .full-width,
  .custom-width {
    .page-head {
      left: var(--value-0);

      @media (max-width: 768px) {
        height: auto;
      }
    }
  }

  .page-container {
    padding: var(--value-0);
  }

  .page-head,
  .page-head-cred {
    left: var(--value-0);
  }

  .fb-dialog-wrapper2 {
    .p-mdc-dialog-content {
      padding: var(--value-20);

      .action-panel {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        &.btn-wrapper {
          .btn.btn-primary {
            margin-left: 12px;
          }
        }
      }
    }
  }

  .algo-planner-accordion {
    padding: 15px 15px 0;
  }

  .fb-dialog-wrapper {
    .p-mdc-dialog-content {
      padding: var(--value-20);

      .action-panel {
        padding-top: 15px !important;

        &.btn-wrapper {
          .btn {
            height: 40px;
            width: 130px;
            min-width: 130px;
            @include flex-align(flex, center);
          }
        }
      }
    }
  }
}

.attribute-tree-select {
  height: 45px;

  .p-treeselect {
    .p-treeselect-label-container {
      .p-treeselect-label {
        width: var(--width-100);
      }
    }
  }
}

@media (max-width: 767px) {
  app-admin-settings {
    .item-wrapper {
      .mat-tab-header {
        .mat-tab-label {
          border-right: var(--display-none);

          &.mat-tab-label-active {
            border-bottom: 1px solid #302e31;
          }
        }
      }
    }
  }

  .p-treeselect {
    padding: 0 15px 0 15px;
  }

  l {
    .btn {
      width: 45% !important;
      margin: 0 5px 10px !important;

      @media (max-width: 425px) {
        width: 40% !important;
      }
    }

    .mat-select {
      margin: 0 5px 10px !important;
      width: 45% !important;

      @media (max-width: 425px) {
        margin: 0 22px 9px 0px !important;
        width: 40% !important;
      }
    }
  }

  .fb-dialog-wrapper {
    .p-mdc-dialog-content {
      .p-mdc-dialog-content {
        .item-wrapper {
          padding: var(--value-0);
        }

        .inner-data {
          border-left: var(--display-none);

          .main-label {
            .mat-checkbox-label {
              font-size: var(--font-size-12);
            }
          }
        }

        .radio-wrapper {
          height: auto;
        }
      }
    }
  }

  .item-wrapper.fb-forecast-planner {
    .method-planner-bottom {
      .col-md-4 {
        padding: var(--value-0);
      }
    }

    .fb-line {
      border-right: var(--display-none);
      border-bottom: 1px solid #707070;
      margin-bottom: var(--value-20);
    }
  }

  #fb-forecast-graph {
    padding: var(--value-0);
  }
}

//chart legend
// ngx-charts-line-chart{
//   .ngx-charts{
//     height: 490px !important;
//   }
// }
// ngx-charts-chart {
//   .ngx-charts-outer {
//     height: auto !important;
//   }
// }
.highcharts-drag-box-default {
  height: 20px !important;
}

.chart-legend {
  .legend-wrap {
    width: auto !important;
  }

  .legend-labels {
    @include flex-justify(flex, center);
    background: transparent !important;
    @include border-radius(0 !important);
    flex-wrap: wrap;

    .legend-label {
      ngx-charts-legend-entry {
        >span {
          @include flex-align(flex, center);
        }
      }
    }
  }

  .legend-label-color {
    @include border-radius(50% !important);
    width: 16px !important;
    height: 16px !important;
  }

  .legend-label-text {
    font-size: 15px !important;
  }
}

// app-google-login{
//   background-color: var(--Shark);
//   display: var(--display-block);
//   padding: 0 10px;
//   @include border-radius(20px);
//   height: 50px;
//   display: flex;
//   align-items: center;
//   justify-content: var(--justify-center);
// }

//fixes after version updated
.mat-drawer-container {
  background-color: var(--white);
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) .mat-expansion-panel-header-title {
  color: var(--black);
}

.bot-wrapper {

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']),
  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) .mat-expansion-panel-header-title {
    background-color: var(--gray-100);
  }

  .mat-expansion-panel-header.mat-expanded:focus,
  .mat-expansion-panel-header.mat-expanded:hover {
    background: var(--gray-100);
  }
}

//measure
.measure-dropdown {
  .p-dropdown {
    max-width: 210px;
    margin-left: var(--value-0);
    @include flex-align(flex, center);

    @media (min-width: $media-breakpoint-md-up) {
      min-width: 475px;
      margin-left: var(--value-40);
    }

    .p-inputtext {
      width: 400px;
      overflow: var(--overflow-hidden);
      text-overflow: ellipsis;
      white-space: nowrap;
      display: var(--display-block);
    }
  }
}

.save-selection-btn {
  @include border-radius(0);
  color: var(--color-30);
  font-weight: normal;
  height: 48px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
  border: var(--display-none);
  margin: var(--value-0);
  padding: 0 20px 0 20px;
  background-color: var(--French-Rose) !important;
  width: 100% !important;
  @include flex-align(flex !important, center !important);

  &.mat-select-disabled {
    background-color: var(--white) !important;

    .mat-select-placeholder {
      color: var(--black);
    }

    .mat-select-arrow {
      color: var(--black);
    }
  }

  .mat-select-placeholder {
    color: white;
  }

  .mat-select-arrow {
    color: white;
  }
}

.mat-icon {
  cursor: var(--cursor-pointer);
}

app-measure-dependency {
  .p-overlay {
    width: auto;
    max-width: initial;
    padding: var(--value-0);
  }
}

//journey css
.journey-height {
  height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;
}

.journey-modal-header {
  @include flex-justify-between(flex, center, space-between);
  margin-bottom: var(--value-20);

  h2 {
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-500);
    margin: var(--value-0);
  }

  .mat-icon {
    font-size: var(--font-size-20);
    width: 20px;
    height: 20px;
  }

  &.form-field {
    button {
      min-width: 20px;
      font-size: var(--font-size-16);
    }

    .mat-mdc-form-field {
      .mat-mdc-form-field-wrapper {
        padding: var(--value-0);
        margin: var(--value-0);

        .mat-mdc-form-field-infix {
          border: var(--display-none);
        }
      }
    }
  }

  &.header {
    @include flex-align(flex, center);
    background-color: var(--gray-100);
    padding: var(--value-10);
  }
}

.share-journey-table {
  height: 250px;
  overflow: auto;
  margin-bottom: var(--value-10);

  .mat-mdc-table {
    border: 1px solid var(--Concrete);

    tr.mat-mdc-header-row {
      height: 40px;
      background: linear-gradient(180deg, #490e54 0%, #820391 100%);
    }

    th.mat-mdc-header-cell {
      color: white;

      @media (max-width: 425px) {
        font-size: 9px;
      }
    }
  }

  .disabled {
    pointer-events: var(--display-none);
    color: #ddd;
  }
}

.journey-comment {
  position: static !important;
  padding: 10px !important;
  box-shadow: none !important;
  border-top: 1px solid #c8c8c8;

  input {
    border: var(--display-none);
    width: var(--width-100);
    padding: 0 10px;
    height: 42px;
    position: var(--position-relative);
    top: -2px;

    &::placeholder {
      font-size: var(--font-size-12);
      color: #666666;
      font-weight: var(--font-weight-400);
      white-space: normal;
    }

    &:focus-visible {
      outline: var(--display-none);
    }
  }

  textarea {
    width: var(--width-100);
    border: var(--display-none);
    padding: 5px 10px;
    white-space: normal;
    height: 50px;
    resize: var(--display-none);
    margin: 0 5px;
    font-size: var(--font-size-12);
    outline: var(--display-none);

    &:focus-visible {
      outline: var(--display-none);
    }
  }

  .btn.btn-primary {
    @include border-radius(50%);
    width: 35px;
    min-width: 35px;
    height: 35px;
    padding: var(--value-0);
    @include flex-center(flex, center, center);

    img {
      max-width: 18px;
    }
  }
}

.journey-comment-dialog {
  .p-mdc-dialog-content {
    padding: var(--value-0);
    overflow: var(--overflow-hidden);
  }
}

.journey-comment-parent {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px;
}

.journey-comment-body {
  .bot-wrapper {
    margin-bottom: 5px;
  }

  .right-chat {
    display: flex;
    flex-direction: row-reverse;
  }

  .right-icon {
    @include flex-column(flex, column, center, center);
    margin-left: var(--value-10);

    mat-icon,
    p {
      color: var(--black);
      width: 70px;
      text-align: var(--text-center);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: var(--overflow-hidden);
    }
  }

  .darker {
    display: var(--display-inline-block);
    width: auto;
    position: var(--position-relative);
    padding: var(--value-20);
    max-width: 600px;
    @include border-radius(20px);
    margin: 5px 0;
    box-shadow: 4px 1px 7px rgba(63, 63, 63, 0.1);
    text-align: var(--text-left);

    p {
      margin: var(--value-0);
      word-break: break-word;
    }
  }

  .response-back {
    display: var(--display-inline-block);
    width: auto;
    position: var(--position-relative);
    padding: var(--value-20);
    max-width: 600px;
    @include border-radius(20px);
    margin: 5px 0;
    box-shadow: 4px 1px 7px rgba(63, 63, 63, 0.1);

    p {
      margin: var(--value-0);
      word-break: break-word;
    }
  }
}

.journey-comment-wrapper {
  position: var(--position-relative);

  .keyboard_tab {
    position: var(--position-absolute);
    right: var(--value-10);
    z-index: var(--z-index-2);
    top: 5px;
  }

  .mat-tab-header {
    width: 100% !important;
    background-color: var(--gray-100);
    padding: 0 10px 0 0;

    .mat-tab-label {
      .mat-icon {
        margin-right: var(--value-10);
      }
    }
  }

  .journey-comment-parent .journey-comment-body {
    height: 280px !important;

    @media (min-width: $media-breakpoint-xxl-screen-up) {
      height: 400px !important;
    }
  }

  app-genai-chat {
    .message-chat-details {
      @media (min-width: $media-breakpoint-xxl-screen-up) {
        height: 408px !important;
      }
    }
  }

  .message-chat-details {
    height: 280px !important;

    @media (min-width: $media-breakpoint-xxl-screen-up) {
      height: 400px !important;
    }

    &.message-new-details {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .inner-message {
        overflow-y: auto;
        padding: 5px;
        overflow-x: hidden;
      }
    }
  }
}

app-view-byoc {
  .items-wrapper-grid {
    margin-right: 5px;

    .ag-theme-material .ag-header {
      @include border-radius(0);
    }
  }
}

.dynamic-report-tabs {
  .mat-tab-label {
    .mat-tab-label-content {
      width: var(--width-100);
      padding: 0 5px;

      .journey-tab-content {
        justify-content: var(--justify-center);
        width: var(--width-100);
      }

      .example-tab-icon {
        position: var(--position-absolute);
        right: var(--value-0);
      }

      .opp-icon {
        color: #f8bf00;
        z-index: var(--z-index-1);
      }

      label {
        margin: var(--value-0);
        overflow: var(--overflow-hidden);
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100px;
        padding: 0 10px;

        @media (min-width: $media-breakpoint-xxl-screen-up) {
          width: 140px;
        }
      }

      .journey-tab-icon {
        height: 20px;

        .material-icons {
          font-size: var(--font-size-18);

          &:first-child {
            padding-right: 5px;
          }
        }
      }
    }
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }

  to {
    opacity: 1;
  }
}

.dynamic-report-wrapper {
  padding: 15px 0 15px 15px;

  .mat-mdc-tab-header {
    .mat-mdc-tab {
      .mdc-tab__content {
        width: var(--width-100);
        padding: 0 10px;

        .mdc-tab__text-label {
          width: var(--width-100);
          justify-content: var(--justify-center);
          white-space: normal;

          .journey-tab-content {
            width: var(--width-100);

            label {
              width: 106px;
              cursor: var(--cursor-pointer);
              overflow: var(--overflow-hidden);
              text-overflow: ellipsis;
              white-space: nowrap;
              position: var(--position-absolute);
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              padding-left: 5px;

              &.label-children {
                padding-right: 16px;
              }
            }

            .journey-tab-icon {
              position: var(--position-absolute);
              right: 5px;

              .material-icons {
                font-size: var(--font-size-20);
              }
            }
          }
        }
      }
    }
  }

  .p-tabview-nav {
    .p-tabview-nav-link {
      width: var(--width-100) !important;
      padding: 0 10px !important;

      .journey-tab-content {
        width: var(--width-100);

        .journey-tab-icon {
          position: var(--position-absolute);
          right: 5px;
          top: 8px;

          .material-icons {
            font-size: var(--font-size-20);
          }
        }

        label {
          width: 130px;
          cursor: var(--cursor-pointer);
          overflow: var(--overflow-hidden);
          text-overflow: ellipsis;
          white-space: nowrap;
          position: var(--position-absolute);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding-left: 5px;
          text-align: center;
        }
      }
    }
  }

  .algo-planner-accordion {
    padding: 15px 10px 0;
  }

  .splitter-over {
    overflow: auto;
    position: var(--position-absolute);
    right: 0px;
    background-color: var(--white);
    top: var(--value-0);
    bottom: var(--value-10);
    min-width: 70% !important;
    width: 70% !important;
    animation: fadeInRight 500ms ease-in-out;
    box-shadow: -10px 0px 7px rgba(63, 63, 63, 0.1);

    .journey-form {
      min-width: var(--width-100);
      max-width: var(--width-100);
    }

    .creat-alert-dialog {
      .btn.btn-secondary {
        margin-right: 15px;
      }
    }

    .mat-mdc-tab-header .mat-mdc-tab {
      background: var(--tab-default);
    }
  }

  .p-splitter {
    // height: 100vh;
    border: var(--display-none);
    display: contents;
    position: var(--position-relative);

    @media (min-width: $media-breakpoint-md-up) {
      display: var(--display-flex);
    }

    .p-splitter-gutter,
    .p-splitter-gutter-handle {
      height: 0 !important;
      width: 0 !important;

      @media (min-width: $media-breakpoint-md-up) {
        height: 24px;
        width: var(--width-100);
      }
    }

    .p-splitter-panel {
      &:nth-child(1) {
        flex-basis: calc(100%) !important;
        max-width: var(--width-100);
      }

      &:nth-child(3) {
        flex-basis: calc(0%) !important;
      }
    }


  }

  .embedded-report {
    iframe {
      height: 99vh !important;
    }
  }

  app-journey-comment {
    .journey-comment-parent {
      height: auto;
      overflow-y: hidden;
      padding: 0 5px;

      .journey-comment-body {
        height: calc(100vh - 130px);
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  app-task-form {
    .journey-task-form {
      .journey-form {

        .col-md-8,
        .col-md-4 {
          flex: 0 0 auto;
          width: 50%;
        }
      }
    }
  }
}

//new work
.selection-dropdowns {
  justify-content: inherit !important;
  flex-direction: column;

  @media (min-width: $media-breakpoint-md-up) {
    flex-direction: row;
  }

  .selection-wrapper {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    @include border-radius(0);
    width: var(--width-100);

    .selection-wrapper-btn {
      width: 60px !important;
      min-width: 60px !important;
      padding: 0 !important;
      box-shadow: none !important;
    }

    .planner-more-selection {
      padding: 0 !important;
      width: 30px !important;
      min-width: 30px !important;
      box-shadow: none !important;
    }

    .p-splitbutton {
      .p-button {
        height: 40px;
        font-size: var(--font-size-14);

        @media (max-width: 1800px) {
          height: 35px;
          font-size: var(--font-size-12);
        }

        &:disabled {
          background-color: #6c757d !important;
          color: #fff !important;
        }
      }

      .p-splitbutton-defaultbutton {
        height: 40px;
        font-size: var(--font-size-14);
        width: 97px !important;
        min-width: 97px !important;
        padding: 0 !important;
        box-shadow: none !important;

        @media (max-width: 1800px) {
          height: 35px;
          font-size: var(--font-size-12);
          width: 70px !important;
          min-width: 70px !important;
        }
      }

      .p-splitbutton-menubutton {
        padding: 0 !important;
        width: 30px !important;
        min-width: 30px !important;
        box-shadow: none !important;
        position: relative;

        &:before {
          content: "\e950";
          font-family: primeicons;
          font-weight: 600;
        }

        .p-icon-wrapper {
          display: none;
        }
      }
    }
  }
}

.p-tieredmenu-root-list .p-menuitem-link {
  font-size: var(--font-size-14);
  min-height: 40px !important;

  @media (max-width: 1800px) {
    min-height: 35px !important;
    font-size: var(--font-size-12);
  }
}

.p-button:disabled {
  background-color: #6c757d !important;
  color: #fff !important;
}

.p-inputtext:enabled:focus {
  box-shadow: none;
}

.p-splitbutton-defaultbutton,
.p-splitbutton-menubutton {
  background: var(--secondary-carnation);
}

.p-datepicker-trigger {
  background: transparent;
}

.custom-split-button {
  transform-origin: right top !important;
}

.p-tieredmenu-root-list {
  margin: 0;
  padding: 0;

  .p-menuitem-link {
    padding: 0 16px;
    min-height: 48px;
    text-decoration: none;
    color: var(--black);
  }
}

.item-account-toggle {
  .mat-slide-toggle-thumb {
    background-color: var(--Purple) !important;

    @media (max-width: 1800px) {
      width: 17px;
      height: 17px;
    }
  }

  .mat-slide-toggle-bar {
    background-color: var(--Mine-Shaft) !important;
  }
}

.planner-error-icon {
  color: #e74c3c;
  font-size: var(--font-size-30);
  cursor: var(--cursor-pointer);
}

.p-splitter-panel-nested {
  display: block !important;
}

.action-renderer-icons {
  justify-content: var(--justify-center);
  height: var(--height-100);

  .material-icons {
    color: var(--black);
    box-shadow: 0px 4px 20px rgba(63, 63, 63, 0.1) !important;
    width: 25px !important;
    height: 25px !important;
    min-width: 25px;
    @include border-radius(50%);
    margin: 0 2px;
    font-size: var(--font-size-16);
    @include flex-center(flex, center, center);
    padding: 0 !important;
    background: var(--action-btn-gray);

    &.notif-off-icon {
      background: var(--action-btn-gray);
    }

    &.timeline-icon {
      background: var(--action-btn-gray);
    }

    &.notif-active-icon {
      background: var(--action-btn-gray);
    }

    &.play-icon {
      background: var(--action-btn-gray);
    }

    &.subject-icon {
      background: var(--action-btn-gray);
    }

    &.chart-icon {
      background: var(--action-btn-gray);
    }

    &.share-icon {
      background: var(--action-btn-gray);
    }

    &.view-icon {
      background: var(--action-btn-gray);
    }

    &.delete-icon {
      background: var(--action-btn-gray);
    }

    &.edit-icon {
      background: var(--action-btn-gray);
    }

    &.check-icon {
      background: var(--action-btn-gray);
    }

    @media (max-width: 1800px) {
      font-size: var(--font-size-16);
    }

    &.disabled {
      background: var(--action-btn-gray);
      color: var(--black);
      opacity: 0.5;
    }

    &.opp-icon {
      color: #f8bf00;
      background: transparent;
      box-shadow: none !important;
      font-size: var(--font-size-18);
    }
  }

  .comment-wrapper {
    padding-left: var(--value-10);

    .count {
      padding: 0 5px;
      font-size: var(--font-size-12);
    }
  }

  .disabled {
    pointer-events: var(--display-none);
    color: #ddd;
  }
}

.time-text {
  font-size: var(--font-size-11);
  color: #2d3644;
}

.multiline-tooltip {
  white-space: pre-line;
}

.bg-calender {
  width: 100% !important;

  .p-calendar-w-btn {
    width: 100% !important;
    height: 30px;
    border: var(--display-none);
    background: var(--gray-100);
  }

  .p-calendar-w-btn:not(.p-calendar-disabled).p-focus {
    border: var(--display-none);
    box-shadow: var(--display-none);
  }
}

.journey-form-group {
  display: flex;
  justify-content: flex-end;

  .form-group {
    label {
      font-size: var(--font-size-13);
      font-weight: var(--font-weight-500);
    }
  }

  .mat-slide-toggle {
    margin: 0 !important;

    .mat-slide-toggle-label {
      width: auto;
      min-width: auto;
    }
  }
}

.journey-form {
  .form-group {
    .mat-error {
      font-size: var(--font-size-12);
      padding-top: 2px;
    }

    input {
      font-size: var(--font-size-12);
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}

.journeyView {
  .item-search-wrapper {
    padding: var(--value-0);
    border: var(--display-none);
    margin-bottom: var(--value-10);

    .form-fields {
      padding: var(--value-0);
      border: var(--display-none);

      .mat-mdc-form-field-suffix {
        padding-right: 5px;
      }
    }
  }
}

//task tree view
.task-view-wrapper {
  .task-header {
    @include flex-align(flex, center);
    background-color: var(--gray-100);
    padding: var(--value-10);
    margin-bottom: 15px;

    h3 {
      font-size: var(--font-size-16);
      padding-right: var(--value-10);
      font-weight: var(--font-weight-500);
    }

    .material-icons {
      color: var(--secondary-carnation);
      cursor: var(--cursor-pointer);
    }
  }

  .task-view-tree {
    height: calc(100vh - 60px);
    display: var(--display-block);
    overflow-y: auto;
    padding: 0 10px;

    .p-tree {
      .p-tree-container {
        .p-treenode {
          .p-treenode-content {
            padding: 10px 10px 10px 35px;

            &::before {
              border-bottom: 1px solid var(--gray-100);
            }

            &[aria-expanded='true'] {
              background-color: var(--gray-100);
              border-left: 3px solid var(--secondary-carnation);
            }

            .p-tree-toggler {
              right: auto;
              left: var(--value-0);

              .pi-chevron-down {
                font-size: var(--font-size-14);
              }
            }

            .p-treenode-label {
              display: var(--display-block);
              width: var(--width-100);

              >span {
                width: var(--width-100);
                @include flex-justify-between(flex, center, space-between);

                .box {
                  width: 12px;
                  height: 12px;
                  display: var(--display-block);
                  margin-right: 5px;

                  &.red {
                    background-color: var(--Red);
                  }

                  &.yellow {
                    background-color: var(--Yellow);
                  }

                  &.orange {
                    background-color: var(--Selective-Yellow);
                  }
                }
              }

              .complete {
                color: #3cb043;
              }

              .material-icons {
                font-size: var(--font-size-23);
                padding-left: 5px;
                cursor: var(--cursor-pointer);

                &.done {
                  font-weight: var(--font-weight-600);
                }
              }
            }

            .p-treenode-icon {
              font-size: var(--font-size-14);
            }
          }
        }
      }
    }
  }
}

.p-component,
.mat-slide-toggle-content,
.p-inputtext {
  font-family: var(--font-family) !important;
}

.assigned-task {
  position: var(--position-absolute);
  right: 15px;
  top: 5px;
  z-index: var(--z-index-2);
}

.mdc-dialog__container {
  .journey-modal-header.header {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-500);
    line-height: 27px;
    font-family: Roboto, sans-serif;
    padding: 10px 20px !important;
    background-color: var(--secondary-carnation) !important;
    color: var(--white);
    margin: -24px -24px 20px -24px !important;
  }

  .journey-task-form {
    padding: 0 24px;
  }
}

.node-form-wrapper {
  .journey-modal-header.header {
    margin: 0 0 20px 0 !important;
  }

  .form-height {
    padding: 24px;
  }
}

.task-board-header {
  position: var(--position-absolute);
  right: 12px;
  top: 60px;
  z-index: var(--z-index-2);

  @media (min-width: 1600px) {
    top: 11px;
  }

  lib-search-dropdown {
    margin-left: var(--value-20);

    .p-dropdown .p-inputtext {
      min-width: 120px;
      overflow: var(--overflow-hidden);
      text-overflow: ellipsis;
      display: var(--display-block);
      padding-top: 5px;
    }
  }

  .assigned-checkbox {
    &.icon {
      height: 32px;

      .material-icons {
        color: #8b209d;
        font-size: 32px;

        @media (max-width: 1800px) {
          font-size: var(--font-size-25);
        }
      }
    }

    .mat-checkbox-layout {
      margin: var(--value-0);
      display: flex;
      flex-direction: row-reverse;

      .mat-checkbox-inner-container {
        margin-left: var(--value-10);
      }

      .mat-checkbox-label {
        font-weight: var(--font-weight-500);
      }
    }
  }
}

app-task-form {
  app-journey-comment {
    .journey-comment-body {
      border: 1px solid var(--Concrete);
      border-bottom: var(--display-none);

      .message-chat-details {
        height: 260px;
        padding: 5px;
      }
    }

    .journey-comment {
      border: 1px solid var(--Concrete);
    }
  }
}

.material-icons {
  cursor: var(--cursor-pointer);
}

.form-height {
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}

app-journey-right-panel {
  app-journey-comment {
    .message-chat-details {
      height: calc(100vh - 130px) !important;
    }
  }

  app-save-journey {
    .journey-height {
      height: auto;
    }
  }

  app-comparison-create {
    .journey-height {
      height: 270px;
    }
  }
}

app-save-journey {
  .mat-mdc-slide-toggle {
    margin: 0 !important;

    .mdc-label {
      width: auto;
      min-width: auto;
    }
  }

  .mat-mdc-checkbox {
    .mdc-label {
      width: auto;
      min-width: auto;
    }
  }
}

.tabs-menu {
  height: 30px !important;
  line-height: 30px !important;
  @include flex-align(flex !important, center !important);
  margin-bottom: var(--value-0) !important;
  font-size: 12px !important;
  min-height: 30px !important;
  width: 100%;
  text-align: left;
  outline: none;
  border: none;
  color: var(--mat-menu-item-label-text-color, var(--mat-app-on-surface));
  padding-left: 10px;
  padding-right: 10px;
  background: transparent;

  &:hover {
    background: #f4f4f4
  }

  .mat-mdc-menu-item-text {
    @include flex-align(flex, center);
    font-size: 12px !important;
  }

  .material-icons {
    font-size: var(--font-size-20);
    padding-right: 8px;
  }
}

.split-icon {
  position: var(--position-absolute);
  right: var(--value-10);
}

.keyboard_tab {
  .material-icons {
    color: var(--black) !important;
  }
}

.task-comment {
  height: 260px !important;
}

.task-form {
  .form-group {
    label {
      margin-bottom: 5px;
    }
  }
}

.sub-task-height {
  max-height: 135px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 0 0;
  margin-bottom: var(--value-20);
}

.comment-box {
  border: 1px solid #acadac;
  @include border-radius(5px);
  padding: var(--value-10);
  height: 158px;

  .comment-height {
    height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .comment-box-wrapper {
    border-bottom: 1px solid var(--Alto);
    padding-bottom: var(--value-10);
    margin-bottom: var(--value-10);
  }

  .comment-user {
    padding-bottom: 5px;

    .username {
      font-size: var(--font-size-14);
      line-height: 14px;
      color: var(--black);
      padding-right: var(--value-30);
    }

    .comment-time {
      color: #acadac;
      font-size: var(--font-size-12);
    }
  }

  .comment-desc {
    p {
      font-size: var(--font-size-14);
      color: var(--black);

      .tag-user {
        font-weight: var(--font-weight-500);
        padding-right: var(--value-10);
      }
    }
  }

  .add-comment {
    position: var(--position-relative);

    textarea {
      background: var(--gray-100);
      @include border-radius(5px);
      border: var(--display-none);
      height: 36px;
      width: var(--width-100);
      padding: 5px 32px 5px 10px;
      font-size: var(--font-size-12);
      resize: var(--display-none);

      &::placeholder {
        font-size: var(--font-size-14);
        color: #acadac;
      }
    }

    .btn.btn-primary {
      @include border-radius(50%);
      width: 35px;
      min-width: 35px;
      height: 35px;
      padding: var(--value-0);
      @include flex-center(flex, center, center);
      position: var(--position-absolute);
      right: 5px;
      top: var(--value-0);
      cursor: var(--cursor-pointer);
      background-color: transparent;
      color: #434343;

      img {
        max-width: 18px;
      }
    }
  }
}

//journey modal css
.p-mdc-dialog-content {
  .item-wrapper {
    padding: var(--value-0);
  }

  .journey-modal-header.header {
    background-color: transparent;
    padding: var(--value-0);
    margin: 0 0 30px;
  }

  .journey-height {
    height: var(--height-100);
    padding: var(--value-0);
  }

  .task-view-wrapper {
    .task-view-tree {
      height: 300px;
      padding: var(--value-0);
    }
  }

  .journey-comment-parent {
    padding: var(--value-0);

    .message-chat-details {
      max-height: 400px;
      height: 400px;
    }
  }

  app-genai-col-mapping {
    .measure-btn {
      .col-lg-4 {
        width: var(--width-100);
        @include flex-align(flex, center);
        flex-wrap: wrap;
        padding: var(--value-0);

        .form-group {
          padding-right: calc(var(--bs-gutter-x) * 0.5);
          padding-left: calc(var(--bs-gutter-x) * 0.5);
          margin-top: var(--bs-gutter-y);
          width: 50%;
        }
      }
    }

    .algo-planner-accordion {
      padding: 20px 0 0 0;

      .mat-expansion-panel {
        box-shadow: var(--display-none);

        .mat-expansion-panel-body {
          padding: var(--value-0);
        }
      }
    }
  }
}

.zoom-in-out {
  max-width: 24px;
  cursor: var(--cursor-pointer);
  margin-left: var(--value-10);
}

.comparison-btn {
  margin-left: var(--value-10);
  padding: 0 5px !important;
  @include border-radius(10px !important);
  height: 27px !important;
  font-size: 12px !important;
}

app-indirect-inventory-planner-commit,
app-direct-inventory-planner-commit {
  .p-multiselect {
    .p-multiselect-label-container {
      .p-multiselect-label {
        width: 400px;
      }
    }
  }
}

.journey-tab-content {
  @include flex-align(flex, center);
}

//journey task board
.journey-taskboard-card {
  padding: 10px 14px;
  background: var(--white);
  @include border-radius(5px);
  margin-bottom: 15px;
  height: 150px;
  @include flex-column-between(flex, column, space-between);
  cursor: var(--cursor-pointer);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(230, 230, 230, 1);
  -moz-box-shadow: 0px 0px 8px 0px rgba(230, 230, 230, 1);
  box-shadow: 0px 0px 8px 0px rgba(230, 230, 230, 1);

  .task-title {
    h1 {
      font-weight: var(--font-weight-700);
      font-size: var(--font-size-14);
      line-height: 20px;
      color: var(--black);
    }
  }

  .task-title-name {
    h1 {
      font-weight: var(--font-weight-600);
      font-size: var(--font-size-12);
      line-height: 14px;
      color: var(--black);
    }
  }

  .task-detail {
    font-weight: var(--font-weight-400);
    font-size: var(--font-size-12);
    line-height: 16px;
    color: var(--black);
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: var(--overflow-hidden);
    text-overflow: ellipsis;
    margin-bottom: var(--value-10);
  }

  .task-timeline {
    .task-date {
      font-weight: var(--font-weight-400);
      font-size: var(--font-size-12);
      line-height: 14px;
      @include flex-align(flex, center);
      color: var(--black);
    }

    .count {
      font-weight: var(--font-weight-400);
      font-size: var(--font-size-12);
      line-height: 12px;
      color: #acadac;
    }

    span {
      padding-right: 4px;
    }

    .custom-icon {
      color: #acadac;
      font-size: var(--font-size-12);
    }
  }
}

.rag-green-outer {
  background-color: green;
}

.rag-amber-outer {
  background-color: yellow;
}

.rag-red-outer {
  background-color: var(--red);
}

.opp-icon {
  color: #f8bf00;
  z-index: var(--z-index-1);
}

.pointer {
  cursor: var(--cursor-pointer);
}

.created-by-label {
  font-style: italic;
}

.tabs-menu-icon-wrapper {
  position: var(--position-relative);

  .mat-tab-header {
    width: calc(100% - 30px);
  }

  .tabs-menu-icon {
    position: var(--position-absolute);
    right: 10px;
    top: 30px;
    z-index: var(--z-index-1);

    button {
      border: none;
      background-color: transparent;
      padding: 0;
    }

    .mat-icon-button {
      margin: var(--value-0);
      background-color: transparent;
      box-shadow: var(--display-none);
    }
  }
}

.digital-wallet {
  height: 50px;
}

.save-journey-checkbox {
  .mat-checkbox {
    .mat-checkbox-layout {
      @include flex-align(flex, center);
    }
  }
}

.journey-share-close {
  position: var(--position-absolute);
  right: 8px;
  top: 4px;
  z-index: var(--z-index-4);
}

.p-mdc-dialog-content {
  .journey-share-close {
    position: var(--position-absolute);
    right: 0px;
    top: 0px;
    z-index: var(--z-index-4);
  }
}

.base-ag-action-btn {
  .toggle {
    .mat-mdc-slide-toggle {
      .mdc-label {
        font-size: var(--font-size-13);
      }
    }
  }

  .toggle,
  .btn-toggle {
    @media (min-width: $media-breakpoint-md-up) {
      width: var(--width-100);
    }

    @media (min-width: 1440px) {
      width: auto;
    }
  }

  .mat-select-placeholder,
  .mat-select-arrow {
    color: white;
  }

  .toggle {
    .mat-slide-toggle-content {
      @media (max-width: 1800px) {
        font-size: var(--font-size-12);
      }
    }
  }
}

.sub-journey-label {
  color: var(--color-30);
  font-size: var(--font-size-12);
  line-height: 19px;
  margin: var(--value-0);
  display: var(--display-block);
  overflow: var(--overflow-hidden);
  text-overflow: ellipsis;
}

//journey task board
.journey-task-board {
  padding-top: var(--value-50);
  padding-bottom: var(--value-50);

  .column-title {
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-16);
    line-height: 19px;
    @include flex-align(flex, center);
    color: var(--black);
    margin-bottom: 16px;

    @media (max-width: 1800px) {
      font-size: var(--font-size-14);
    }
  }

  .tasks-container {
    overflow-y: auto;
    background: var(--gray-100);
    @include border-radius(5px);
    padding: 15px 10px;
    max-height: 650px;

    @media (max-width: 1800px) {
      padding: 8px 5px;
      font-size: var(--font-size-12);
    }

    &::-webkit-scrollbar-track {
      @include border-radius(10px);
      background-color: var(--gray-100);
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: var(--gray-100);
    }

    &::-webkit-scrollbar-thumb {
      @include border-radius(10px);
      background: #bdbdbd;
    }
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    @include border-radius(4px);
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .cdk-drag-placeholder {
    opacity: 0;
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .tasks-container.cdk-drop-list-dragging .task:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

//ai css
.mat-stepper-horizontal {
  padding: 25px;

  .mat-horizontal-content-container {
    padding: 0 24px 0;
  }

  .mat-horizontal-stepper-header-container {
    padding-bottom: var(--value-30);

    .mat-step-header {
      height: auto;
      padding: var(--value-0);

      .mat-step-icon-selected,
      .mat-step-icon-state-done,
      .mat-step-icon-state-edit {
        background-color: var(--secondary-carnation);
      }
    }
  }
}

.mat-stepper-vertical {

  .mat-step-header.cdk-keyboard-focused,
  .mat-step-header.cdk-program-focused,
  .mat-step-header:hover:not([aria-disabled]),
  .mat-step-header:hover[aria-disabled='false'] {
    background-color: transparent;
  }

  .mat-step-header {
    -webkit-tap-highlight-color: transparent;
    padding: 15px 24px;

    .mat-step-label-selected {
      font-size: var(--font-size-12);
    }

    .mat-radio-label-content {
      font-size: var(--font-size-12);
    }

    .mat-step-label {
      font-size: var(--font-size-12);
    }

    .mat-step-icon {
      height: 20px;
      width: 20px;
      font-size: var(--font-size-12);
      margin-left: 2px;
    }

    .mat-step-icon-selected,
    .mat-step-icon-state-done,
    .mat-step-icon-state-edit {
      background-color: var(--secondary-carnation);
      width: 20px;
      height: 20px;
      font-size: var(--font-size-12);
      margin-left: 4px;
    }
  }
}

app-genai-option,
app-genai-fileuploader {
  display: var(--display-block);
}

app-genai-option {
  .mat-radio-group {
    display: flex;
    flex-direction: column;

    .mat-radio-button {
      margin-bottom: var(--value-20);
      font-size: var(--font-size-12);
    }
  }
}

app-genai-user-metadata {
  .example-container {
    max-height: 540px;
    overflow-y: auto;

    mat-cell:last-of-type {
      padding-right: var(--value-0);
    }
  }

  th.mat-mdc-header-cell,
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    padding: 0 24px;
    color: var(--black);
  }

  .mat-mdc-table {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  .mat-mdc-cell {
    height: 48px;
    padding: var(--value-0);

    input {
      border: var(--display-none);
      border-bottom: 1px solid var(--secondary-carnation);
      width: var(--width-100);
      height: 48px;

      &:focus-visible {
        outline: var(--display-none);
      }

      &::placeholder {
        font-weight: var(--font-weight-500);
      }
    }
  }
}

.data-wrapper.btn-wrapper.direct-wrapper {
  .mat-select-arrow {
    color: white;
  }
}

app-public-journeys {
  .toggle-btn {
    margin-top: 0px !important;

    .btn {
      min-width: 50px !important;

      .mat-icon {
        padding-right: 30px !important;
      }
    }
  }
}

app-algo-pushed-journeys,
app-public-journeys,
app-manage-subscription {

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']),
  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) .mat-expansion-panel-header-title {
    background-color: transparent !important;
  }

  .mat-expansion-panel-header-title {
    margin-top: var(--value-10);
    height: 40px;
  }

  .toggle-btn {
    padding: var(--value-0);
    border: solid 1px var(--Alto);
    height: 40px;
    margin-top: var(--value-10);
    @include border-radius(20px);

    .btn {
      border: 0;
      background: var(--display-none);
      color: inherit;
      padding: var(--value-0);
      margin: 0 !important;
      font: inherit;
      outline: var(--display-none);
      width: var(--width-100);
      cursor: var(--cursor-pointer);
      @include border-radius(0);
      font-size: var(--font-size-14);
      min-width: 100px;
      height: 40px;
      @include flex-align(flex, center);

      &.btn-secondary {
        border-right: solid 1px var(--Alto);
      }

      .mat-icon {
        font-size: 22px !important;
        padding-right: var(--value-10);
      }
    }
  }

  .toggle-btn-feed {
    .btn {
      height: 40px;
      min-width: 100px;

      @media (max-width: 1800px) {
        height: 35px;
        min-width: 90px;
      }
    }
  }

  .feed-wrapper {
    border: 1px solid var(--gray-100);
    margin-bottom: var(--value-10);

    .feed-header {
      background-color: var(--gray-100);
      padding: var(--value-10);
      height: 50px;
      margin-bottom: var(--value-10);

      @media (max-width: 1800px) {
        height: 30px;
        font-size: var(--font-size-12);
      }

      b {
        position: var(--position-relative);

        .p-tooltip {
          left: 0 !important;
          top: 25px !important;
          width: 300px !important;
        }
      }
    }

    .feed-content {
      min-height: 200px;
    }
  }
}

.chart-container {
  margin-bottom: -40px;

  .tick text {
    transform: rotate(-60deg) !important;
    margin-right: 1px !important;
  }

  .refline-path {
    stroke: green !important;
    /* Set the stroke color */
    stroke-dasharray: 5 !important;
    /* Add a dashed line effect */
    stroke-width: 2 !important;
    /* Set the stroke width */
  }

  .refline-label {
    font-size: 11px !important;
  }

  .ngx-charts-outer,
  .ngx-charts {
    height: calc(100% - 100px) !important;
  }
}

.feed-manage-body {
  display: flex;
  flex-direction: column;
  padding: 15px 10px 10px 10px;
  max-height: 550px;
  overflow-y: auto;

  .coreAreas {
    margin-bottom: 15px;
    border-bottom: 1px solid var(--gray-100);

    h6 {
      font-size: var(--font-size-12);
      font-weight: var(--font-weight-500);
    }

    .p-button {
      margin-top: 7px !important;
      margin-bottom: 7px !important;
    }
  }

  .p-panel {
    .p-panel-title {
      font-size: var(--font-size-14);
      font-weight: var(--font-weight-500);
    }

    .sub-heading {
      font-size: var(--font-size-12);
      font-weight: var(--font-weight-500);
    }

    margin-bottom: var(--value-10);
  }

  .p-button {
    width: var(--width-100);
    height: 40px;
    background: #fafafa !important;
    margin-bottom: 15px !important;
    font-size: var(--font-size-12);

    @media (max-width: 1800px) {
      height: 30px;
      margin-bottom: var(--value-10) !important;
      font-size: var(--font-size-10);
    }

    .p-button-label {
      transition-duration: 0s;
    }
  }

  // .p-togglebutton.p-button:focus.p-highlight{
  //   background: var(--secondary-carnation) !important;
  //   border-color: var(--secondary-carnation);
  //   color: var(--white);
  // }
  // .p-togglebutton.p-button.p-highlight:hover{
  //   background: var(--secondary-carnation) !important;
  //   border-color: var(--secondary-carnation);
  //   color: var(--white);
  // }
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    border-color: var(--secondary-carnation);
  }

  // .p-togglebutton.p-button:focus{
  //   background: var(--secondary-carnation) !important;
  //   border-color: var(--secondary-carnation);
  //   color: var(--white);
  // }
  .p-togglebutton.p-button.p-highlight {
    background: var(--secondary-carnation) !important;
    border-color: var(--secondary-carnation);
    color: var(--white);
  }

  .p-multiselect {
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    @include border-radius(4px);
    background: var(--Alabaster);

    .p-multiselect-label-container .p-multiselect-label {
      font-weight: var(--font-weight-500);
    }
  }

  lib-search-dropdown {
    display: var(--display-block);
    margin-bottom: 15px;
  }
}

//planner classification
.classification-btn {

  .p-multiselect-label-container {
    justify-content: var(--justify-center);
  }

  p-dropdown {
    .p-dropdown {
      background-color: var(--secondary-carnation);
      color: var(--white);
      border: var(--display-none);
      height: 45px;
      @include border-radius(5px);
      padding: 0 10px;
      font-weight: var(--font-weight-500);
      min-width: 165px;
      font-size: var(--font-size-13);

      @media (max-width: 1800px) {
        font-size: var(--font-size-12);
        height: 36px;
      }

      .p-dropdown-label {
        font-size: var(--font-size-13);
        color: var(--white) !important;
        width: 130px;
        overflow: var(--overflow-hidden);
        text-overflow: ellipsis;
        justify-content: var(--justify-center);
        padding: var(--value-0);

        @media (max-width: 1800px) {
          font-size: var(--font-size-12);
        }
      }

      .p-dropdown-trigger-icon {
        width: 0;
        height: 0;
        border-left: 5px solid rgba(0, 0, 0, 0);
        border-right: 5px solid rgba(0, 0, 0, 0);
        border-top: 5px solid;
        margin: 0 4px;
        color: var(--white);

        &:before {
          display: var(--display-none);
        }
      }
    }
  }

  .p-dropdown-clearable {
    .p-dropdown-clear-icon {
      // display: var(--display-none);
      color: var(--white) !important;
      top: 18px;
      width: 14px;
    }
  }

  p-multiselect {
    display: var(--display-block);
    margin-left: 10px;
    width: 165px;
    .p-multiselect {
      background-color: var(--secondary-carnation);
      color: var(--white);
      border: var(--display-none);
      height: 35px;
      @include border-radius(5px);
      padding: 0 10px;
      font-weight: var(--font-weight-500);
      min-width: 160px;
      font-size: var(--font-size-13);

      @media (max-width: 1800px) {
        font-size: var(--font-size-12);
        height: 36px;
      }

      .p-multiselect-label.p-placeholder {
        font-size: var(--font-size-13);
        color: var(--white);
        width: 130px;
        overflow: var(--overflow-hidden);
        text-overflow: ellipsis;
        text-align: var(--text-center);

        @media (max-width: 1800px) {
          font-size: var(--font-size-12);
        }
      }

      .p-multiselect-trigger-icon {
        width: 0;
        height: 0;
        border-left: 4px solid rgba(0, 0, 0, 0);
        border-right: 4px solid rgba(0, 0, 0, 0);
        border-top: 4px solid;
        margin: 0 4px;
        color: var(--white);

        &:before {
          display: var(--display-none);
        }
      }
    }
  }

  .p-multiselect .p-multiselect-label-container .p-multiselect-label {
    color: var(--white);
  }
}

.feed-public-journey {
  .p-dropdown {
    background-color: var(--secondary-carnation);
    color: var(--white);
    border: var(--display-none);
    height: 45px;
    @include border-radius(5px);
    padding: 0 10px;
    font-weight: var(--font-weight-500);
    font-family: Roboto, sans-serif !important;
    font-size: var(--font-size-14);
    margin-bottom: var(--value-20);
    width: 130px !important;
    text-align: var(--text-center);
    outline: var(--display-none);
    box-shadow: var(--display-none);

    @media (max-width: 1800px) {
      height: 35px;
    }

    .p-inputtext {
      color: var(--white) !important;
      font-size: var(--font-size-14);
      font-weight: var(--font-weight-500);
    }

    .p-dropdown-trigger .p-dropdown-trigger-icon {
      width: 0;
      height: 0;
      border-left: 5px solid rgba(0, 0, 0, 0);
      border-right: 5px solid rgba(0, 0, 0, 0);
      border-top: 5px solid;
      margin: 0 4px;
      color: var(--white);

      &:before {
        display: var(--display-none);
      }
    }
  }

  .mat-select {
    background-color: var(--secondary-carnation);
    color: var(--white);
    border: var(--display-none);
    height: 45px;
    @include border-radius(5px);
    padding: 0 10px;
    font-weight: var(--font-weight-500);
    font-family: var(--font-family);
    font-size: var(--font-size-14);
    margin-bottom: var(--value-20);
    width: 130px;
    text-align: var(--text-center);
    outline: var(--display-none);
    box-shadow: var(--display-none);

    .mat-select-value-text {
      color: var(--white);
    }

    @media (max-width: 1800px) {
      height: 35px;
      width: 105px;
      font-size: var(--font-size-12);
    }

    .mat-select-trigger {
      height: var(--height-100);
    }

    .mat-select-arrow {
      color: var(--white);
    }
  }

  .feed-wrapper {
    border: var(--display-none);
    margin-bottom: var(--value-30);

    .public-journey-img {
      border: 1px solid rgb(216, 26, 209);

      img {
        object-fit: fill;
        height: 350px;

        @media (min-width: $media-breakpoint-md-up) {
          height: 350px;
        }

        @media (min-width: $media-breakpoint-lg-up) {
          height: 350px;
        }

        @media (min-width: $media-breakpoint-xxl-up) {
          height: 350px;
        }
      }
    }

    .toggle-btn-feed {
      padding-right: var(--value-0);

      .btn.btn-secondary {
        margin-right: var(--value-0);
        margin-left: var(--value-10);

        &:disabled,
        &.disabled {
          color: var(--btn-disabled-color);
          background-color: transparent;
          border-color: var(--btn-disabled);
        }
      }
    }

    .green {
      color: #8bed19;
    }

    .red {
      color: var(--Amber);
    }
  }

  .p-paginator {
    @media (max-width: 425px) {
      padding: var(--value-0);

      .p-paginator-pages {
        .p-paginator-page {
          min-width: 2rem;
          height: 2rem;
        }
      }

      .p-paginator-first {
        min-width: 1rem;
      }

      .p-paginator-last {
        min-width: 1rem;
        margin: var(--value-0);
      }
    }
  }
}

.p-image-mask {
  top: 40px;
  bottom: 50px;
}

.export-form-overlay {
  .p-overlay {
    top: 26px !important;
    left: 0 !important;
  }
}

.measure-form-overlay {
  position: var(--position-absolute);
  top: 26px;
  left: var(--value-0);
  width: var(--width-100);
  height: var(--height-100);
  z-index: 1001;

  .p-overlay {
    position: static !important;
  }
}

.modal-overlay {
  position: var(--position-absolute);
  top: 26px;
  left: 45px;
  right: 0px;
  z-index: 999;

  @media (min-width: $media-breakpoint-md-up) {
    left: 145px;
    right: 20px;
  }
}

.highlight {
  text-decoration: underline;
  color: var(--secondary-carnation);
  cursor: var(--cursor-pointer);
}

.toggle-btn-notification {
  padding-right: var(--value-0);

  .btn.btn-secondary {
    margin-right: var(--value-0);
    margin-left: var(--value-10);
    height: 32px;
    padding: 3px;
  }

  .btn.btn.btn-primary {
    background-color: var(--secondary-carnation) !important;
  }

  .notify-btn {
    height: auto !important;
  }
}

app-algo-pushed-journeys {
  .toggle-btn {
    .btn {
      min-width: 50px !important;

      .mat-icon {
        padding-right: 30px !important;
      }
    }
  }
}

// .custom-scroll-hide {
//   .split-accessories-grid {
//     &:nth-child(2),
//     &:nth-child(3),
//     &:nth-child(4) {
//       .ag-body-horizontal-scroll-viewport {
//         display: none !important;
//       }
//     }
//   }
// }

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

//menu configiration
.menu-configuration-wrapper {
  padding: 25px;

  .p-tree {
    border: var(--display-none);

    .p-treenode-children {
      transition: all 0.5s ease-in-out;

      .p-treenode {
        &[aria-expanded='true'] {
          .p-treenode-content {
            .p-tree-toggler {
              .p-tree-toggler-icon {
                transform: rotate(180deg) !important;
              }
            }
          }

          .p-treenode-children {
            .p-treenode {
              &[aria-expanded='true'] {
                .p-tree-toggler-icon {
                  transform: rotate(180deg) !important;
                }
              }

              .p-tree-toggler-icon {
                transform: rotate(90deg) !important;
              }
            }

            .p-treenode-children {
              .p-treenode {
                &[aria-expanded='true'] {
                  .p-tree-toggler-icon {
                    transform: rotate(180deg) !important;
                  }
                }

                .p-tree-toggler-icon {
                  transform: rotate(90deg) !important;
                }
              }

              .p-treenode-children {
                .p-treenode {
                  &[aria-expanded='true'] {
                    .p-tree-toggler-icon {
                      transform: rotate(180deg) !important;
                    }
                  }

                  .p-tree-toggler-icon {
                    transform: rotate(90deg) !important;
                  }
                }
              }
            }
          }
        }

        .p-treenode-content {
          .p-tree-toggler {
            .p-tree-toggler-icon {
              transform: rotate(90deg) !important;
            }
          }
        }
      }
    }

    .p-tree-container {
      .p-treenode-droppoint {
        height: 15px;
      }

      .p-treenode {
        &[aria-expanded='true'] {
          .p-treenode-content {
            .p-tree-toggler {
              .p-tree-toggler-icon {
                transform: rotate(180deg);
                animation: rotating 0.2s linear;
              }
            }
          }
        }

        .p-treenode-content {
          @include border-radius(8px);
          background-color: var(--Alabaster);
          padding: 12px 45px;
          margin-bottom: var(--value-0);
          cursor: var(--cursor-pointer);

          @media (max-width: 1800px) {
            margin-bottom: 8px;
          }

          &:before {
            display: var(--display-none);
          }

          .p-tree-toggler {
            right: var(--value-0);
            left: auto;
            margin: var(--value-0);
            @include flex-center(flex, center, center);
            z-index: var(--z-index-1);

            .p-tree-toggler-icon {
              width: 12px;
              height: 12px;
              color: var(--black);
              font-weight: var(--font-weight-bold);
              transform: rotate(90deg);
            }

            .pi {
              font-size: var(--font-size-12);
            }
          }

          .menu-configuration-drag {
            position: var(--position-absolute);
            top: 50%;
            transform: translateY(-50%);
            left: var(--value-10);
            height: 24px;
          }
        }
      }
    }
  }

  .menu-confirguration-action {
    @include flex-align(flex, center);
    flex-direction: row-reverse;
    position: var(--position-absolute);
    right: 40px;
    top: 50%;
    transform: translateY(-50%);

    .menu-toggle {
      margin-left: 15px;
      cursor: var(--cursor-pointer);
      z-index: var(--z-index-1);
      height: 24px;

      .icon-more-vertical {
        font-size: var(--font-size-16);
        color: var(--black);
      }

      &.menu-show {
        .icon-more-vertical {
          &:before {
            @extend .icomoon-font;
            content: '\e974';
            font-size: var(--font-size-12);
          }
        }
      }
    }

    .sub-menu-icon {
      background: transparent;
      box-shadow: var(--display-none);
      @include border-radius(0);
      margin-left: var(--value-10);
      cursor: var(--cursor-pointer);
      width: auto;
      height: auto;

      .add-icon,
      .eye-icon {
        font-weight: var(--font-weight-bold);
      }

      .material-icons {
        font-size: var(--font-size-19);
      }

      img {
        max-width: 15px;
        min-width: 15px;
        cursor: var(--cursor-pointer);
      }
    }

    .sub-menu {
      display: var(--display-flex);
      flex-direction: row-reverse;
      opacity: 0;
      visibility: hidden;
      transform: translateX(135px);
      transition: all ease-out 0.5s;

      &.active {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
        transition: all ease-in 0.5s;
      }
    }
  }
}

.sub-menu-icon {
  background: var(--white);
  box-shadow: 0 2px 4px #3f3f3f4d;
  @include border-radius(50%);
  width: 40px;
  height: 40px;
  @include flex-center(flex, center, center);
  margin-left: 15px;

  img {
    max-width: 15px;
    min-width: 15px;
    cursor: var(--cursor-pointer);
  }
}

.menu-heading {
  margin-right: auto !important;
}

.task-form-assignee {
  .p-overlay-content {
    min-width: 280px;
  }

  .p-component {
    input {
      padding-right: 30px !important;
    }

    .p-multiselect-filter-icon {
      right: var(--value-10);
    }
  }
}

.e-toolbar .e-toolbar-items {
  padding-right: 32px !important;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar .e-toolbar-extended.e-popup-open {
  width: 100% !important;
  left: 0 !important;
}

.e-richtexteditor .e-rte-content .e-content,
.e-richtexteditor .e-source-content .e-content {
  padding-bottom: 46px;
}

.e-richtexteditor .e-rte-content .e-content p,
.e-richtexteditor .e-source-content .e-content p {
  word-break: break-all;
}

.control-section {
  position: var(--position-relative);
}

.btn-send-wrapper {
  border: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  padding: 5px;

  .btn-send {
    color: var(--black) !important;
    @include border-radius(0 !important);
    background-color: transparent !important;
    cursor: var(--cursor-pointer);

    .send-icon {
      font-size: 24px !important;
      transform: none !important;
    }
  }
}

.mention-email {
  line-height: 36px;

  span {
    font-size: var(--font-size-12);
  }
}

.rightSidenavContent {
  .mat-sidenav-content {
    margin-right: 300px;

    .custom-width {
      @media (min-width: $media-breakpoint-lg-up) {
        padding: 0 0 70px 0;
      }
    }
  }
}

.genai-header {
  .num-hash {
    min-width: 35px;
    height: 25px;
    @include flex-center(flex, center, center);
    margin-right: var(--value-20);
    margin-bottom: var(--value-10);
    font-weight: var(--font-weight-bold);
  }

  h3 {
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--value-10);
  }
}

.genai-content {
  margin-bottom: var(--value-10);

  .num-box {
    min-width: 35px;
    height: 25px;
    @include flex-center(flex, center, center);
    background-color: var(--gray-100);
    margin-right: var(--value-20);
  }

  .input-group {
    input {
      border: var(--display-none);
      background-color: var(--gray-100);
      height: 25px;
      width: var(--width-100);
      padding: 0 30px 0 10px;
    }

    .mat-icon {
      position: var(--position-absolute);
      font-size: var(--font-size-16);
      right: 5px;
      top: 5px;
    }
  }

  .toggle-wrapper {
    @include flex-align(flex, center);

    .mat-slide-toggle {
      color: var(--color-30);
      font-size: var(--font-size-12);
      line-height: 19px;
      margin-left: 5px;
    }
  }

  .inputdesc-group {
    position: var(--position-relative);
    margin-right: var(--value-10);
    width: var(--width-100);

    input {
      background-color: var(--gray-100);
      height: 25px;
      width: var(--width-100);
      padding: 0 30px 0 10px;
      border: 1px solid;
    }

    .mat-icon {
      position: var(--position-absolute);
      font-size: var(--font-size-16);
      right: 5px;
      top: 5px;
    }
  }
}

.p-dropdown-clearable {
  .p-dropdown-clear-icon {
    right: 30px;
    font-size: var(--font-size-12);
    top: 15px;
  }
}

//dropzone css
ngx-dropzone {
  background: var(--gray-100) !important;
  padding: 25px;
  align-items: flex-start !important;
  border: none !important;
  position: var(--position-relative);
  height: 210px !important;

  &::before {
    content: '';
    position: var(--position-absolute);
    border: 2px dashed var(--Silver);
    top: var(--value-10);
    right: var(--value-10);
    bottom: var(--value-10);
    left: var(--value-10);
  }

  ngx-dropzone-preview {
    ngx-dropzone-label {
      text-align: var(--text-center) !important;
    }
  }

  ngx-dropzone-label {
    margin: 0 !important;
    text-align: var(--text-left) !important;

    span {
      display: var(--display-block);
      padding-top: var(--value-10);
      color: #ad84a2;
      font-size: var(--font-size-12);
      font-style: italic;
    }
  }
}

.byoc-grid {
  .ag-body-viewport {
    min-height: 615px !important;
  }
}

.e-toolbar-wrapper {
  height: 40px !important;
}

.tox .tox-statusbar {
  display: none !important;
}

.tox .tox-edit-area__iframe {
  padding-bottom: 45px;
}

.tox-tinymce {
  height: 200px !important;
}

.mention {
  font-weight: var(--font-weight-500);
  color: #0000ffa6;
  text-decoration: underline;
  padding: 2px;
  @include border-radius(3px);
  cursor: pointer !important;
  background-color: rgba(blue, 0.1);

  &:hover {
    color: blue;
    background-color: rgba(blue, 0.2);
  }
}

.merge-tags {
  font-weight: var(--font-weight-500);
  color: #a99777;
  cursor: pointer !important;
  background-color: var(--color-2d9);
  padding: 2px;
  @include border-radius(3px);
  text-decoration: var(--display-none);

  &:hover {
    color: #807259;
    background-color: var(--color-brown);
  }
}

.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  font-size: var(--font-size-13);
}

.threshhold-group {
  app-checkbox {
    height: 30px;
    position: relative;
    top: -5px;
  }

  .mat-mdc-checkbox {
    position: var(--position-relative);
    top: -5px;
  }

  .p-dropdown {
    min-width: 120px !important;
    width: 160px !important;
  }
}

.user-img {
  @include border-radius(50%);
}

app-notifications {
  .notification-alert {
    .notifications-alerts {
      position: static !important;
      width: 100% !important;
      height: 100% !important;
      max-height: inherit !important;
      animation-duration: 1s !important;
      animation-name: fade-in-up !important;
      transition-timing-function: ease !important;
      box-shadow: none !important;

      .notification-search-wrapper {
        position: var(--position-absolute);
        left: var(--value-0);
        right: var(--value-10);
        top: 55px;
        @include flex-justify-between(flex, center, space-between);

        .notification-filter-wrapper {
          width: 250px;

          .p-treeselect {
            box-shadow: none !important;
            background-color: var(--gray-100);
            height: 26px;
            padding: 0 10px;
          }
        }
      }

      .mat-mdc-tab-group {
        .mat-mdc-tab-body-wrapper {
          margin-top: 55px;
        }
      }

      .p-tabview {
        .p-tabview-panels {
          margin-top: 55px;
        }
      }

      .notification-wrapper {
        height: calc(100vh - 280px);
        padding-bottom: var(--value-50);
        margin-top: 50px;
      }

      .mat-tab-header .mat-tab-label {
        border: var(--display-none);
        opacity: 1;
        padding: var(--value-0);
        background: var(--tab-default);
        border-radius: 5px 5px 0px 0px;
        margin-right: 16px;
        color: var(--black);
        letter-spacing: 1px;
        width: 100px;
        height: 38px;
        font-weight: var(--font-weight-500);
        font-size: var(--font-size-12);
        line-height: 16px;

        @media (min-width: $media-breakpoint-md-up) {
          height: 35px;
          margin-right: 5px;
          font-size: var(--font-size-12);
          width: 200px;
        }

        &.mat-tab-label-active {
          border-bottom: 2px solid var(--primary-purple);
          box-shadow: var(--display-none);
          color: var(--black);
          background: var(--tab-default);
        }
      }

      .mat-tab-body {
        padding-bottom: var(--value-50);
      }
    }
  }
}

.fade-in-up {
  animation: fade-in-up 1s ease;
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

//newscreen
.welcome-dashboard {
  background-color: var(--gray-100);
  padding: 8px 15px;

  h1 {
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-500);
  }

  span {
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-500);
    color: var(--black);
  }
}

.favorites-list {
  margin-top: var(--value-20);
  margin-bottom: var(--value-20);
  background-color: var(--white);

  ul {
    li {
      position: var(--position-relative);

      &:hover {
        .cancel-fav {
          display: var(--display-flex);
        }
      }
    }
  }

  .cancel-fav {
    display: var(--display-none);
    width: 18px;
    height: 18px;
    @include border-radius(50%);
    background-color: var(--rolling-stone);
    position: var(--position-absolute);
    right: 7px;
    @include align-justify(center, center);
    cursor: var(--cursor-pointer);
    top: 8px;

    .mat-icon {
      font-size: var(--font-size-14);
      color: var(--white);
      width: 14px;
      height: 14px;
    }
  }
}

.favorites-list,
.tasks-list,
.home-base-list {
  background-color: var(--white);
}

.favorites-list,
.tasks-list,
.name-list {
  text-align: var(--text-center);
  border: 1px solid var(--gray-100);

  h3 {
    font-size: var(--font-size-12);
    font-style: normal;
    font-weight: var(--font-weight-700);
    color: var(--black);
    background-color: var(--gray-100);
    padding: 5px 10px;
    height: 30px;
    @include flex-center(flex, center, center);
    margin: var(--value-0);
  }

  .fav-list-wrapper {
    height: 330px;
    min-height: 330px;
    position: var(--position-relative);

    .fav-list-text {
      margin: var(--value-0);
      @include flex-center(flex, center, center);
      height: var(--height-100);
      color: var(--black);
      font-size: var(--font-size-12);
    }
  }

  ul {
    margin: var(--value-0);
    list-style-type: var(--display-none);
    padding: var(--value-10);

    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: var(--value-0);
      }

      &.active {
        span {
          background-color: var(--primary-purple);
          color: var(--white);
          border: var(--display-none);
        }
      }

      span {
        @include border-radius(5px);
        border: 1px solid rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.87);
        padding: 8px 24px;
        @include flex-center(flex, center, center);
        text-align: var(--text-center);
        font-size: var(--font-size-12);
        font-style: normal;
        font-weight: var(--font-weight-500);
        background: var(--Alabaster);
        height: 30px;
        cursor: var(--cursor-pointer);
      }
    }
  }
}

.kpi-list-wrapper {
  .mat-standard-chip {
    &:after {
      background: transparent;
    }

    .mat-chip-ripple {
      display: var(--display-none);
    }
  }
}

.check-icons {
  .check_circle_outline {
    color: green;
  }

  .cancel {
    color: var(--red);
  }
}

.mat-vertical-content {
  padding: 20px 20px 20px 20px !important;
}

//create kpi update screen
.creat-kpi-wrapper {
  .mat-vertical-stepper-header {
    padding: 0 0 24px 0;
  }

  .mat-vertical-content-container {
    margin-left: var(--value-10);
  }

  .mat-vertical-content {
    padding: 0 20px 20px 20px !important;
  }

  .embedded-report-filters {
    height: 350px !important;

    iframe {
      height: 350px !important;
    }
  }

  .form-ellipsis .form-group input {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: var(--overflow-hidden);
  }
}

.creat-kpi-wrapper {
  .mat-checkbox-layout .mat-checkbox-label {
    font-size: var(--font-size-14);

    @media (max-width: 1800px) {
      font-size: var(--font-size-12);
    }
  }

  .btn-back,
  .btn-next {
    @include border-radius(50%);
    padding: var(--value-0);
    width: 30px;
    min-width: 30px;
    height: 30px;
    @include flex-align(flex, center);

    .mat-icon {
      font-size: var(--font-size-18);
      height: 18px;
      width: 18px;
      font-weight: var(--font-weight-600);
    }
  }
}

.kpi-list-wrapper {
  cursor: var(--cursor-pointer);

  .kpi-list-cards {
    box-shadow: rgba(197, 171, 198, 0.3) 2px 0px 8px 2px;
    margin: 0 8px 10px;
    width: 180px;

    p {
      color: var(--black);
      font-size: var(--font-size-12);
      font-style: normal;
      font-weight: var(--font-weight-600);
      padding: 15px 10px;
      text-align: var(--text-center);
    }
  }

  .mat-mdc-chip {
    color: var(--white);
    text-align: var(--text-center);
    padding: 5px 10px;
    height: 32px;
    @include border-radius(0);
    margin: 0 !important;
    width: var(--width-100);

    &:hover {
      .mat-mdc-chip-action-label {
        white-space: normal;
        height: 32px;
        line-height: 12px;
        @include flex-center(flex, center, center);
      }
    }

    .mdc-evolution-chip__graphic {
      width: 20px !important;
      padding: 0 !important;
      min-width: 20px !important;
      max-width: 20px !important;
    }

    .mdc-evolution-chip__action {
      padding: 0 !important;
      width: var(--width-100);
    }

    &.mat-mdc-chip-selected.mat-primary {
      background-color: var(--primary-purple) !important;
    }

    .mat-mdc-chip-action-label {
      color: var(--white);
      font-size: var(--font-size-12);
      width: var(--width-100);
      padding-right: var(--value-20);
      line-height: 12px;
      max-width: 140px;
      overflow: var(--overflow-hidden);
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: var(--text-center);

      .mat-icon {
        width: 16px;
        height: 16px;
        font-size: var(--font-size-16);
        top: 50%;
        transform: translateY(-50%);
        position: var(--position-absolute);
        right: var(--value-10);
      }
    }
  }

  .mat-chip-list-wrapper {
    margin: var(--value-0);

    .mat-chip {
      margin: var(--value-0);
      @include border-radius(5px);
      background-color: var(--gray-100);
      padding: 8px 16px;
      margin-right: 15px;
      margin-bottom: 15px;
      font-size: var(--font-size-12);
      color: var(--black);
      font-weight: var(--font-weight-500);
      cursor: var(--cursor-pointer);
    }

    .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
      background-color: var(--primary-purple) !important;
      color: var(--white);
    }
  }

  .home-base-graph-select {
    width: 100% !important;
    margin-bottom: var(--value-10) !important;
  }

  h6 {
    text-align: var(--text-center);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-500);
    margin: var(--value-0);
    padding-left: var(--value-10);
  }
}

app-journey-right-panel {
  lib-create-kpi {
    .creat-kpi-wrapper {
      padding-top: 0 !important;

      .row-threshold {
        flex-direction: column-reverse;

        .col-md-8 {
          flex: 0 0 100%;
          width: var(--width-100);
        }

        .col-md-4 {
          flex: 0 0 100%;
          width: var(--width-100);
          margin-bottom: var(--value-20);
        }
      }

      .mat-vertical-content {
        padding: 0 0 20px 20px !important;
      }

      .create-kpi-header {
        .col-md-4 {
          flex: 0 0 auto;
          width: 50%;

          .form-group label {
            min-width: 50px;
            width: 50px;
          }
        }

        .col-md-8 {
          flex: 0 0 auto;
          width: var(--width-100);
          margin-bottom: var(--value-10);
        }
      }
    }
  }
}

.splitter-over {
  position: var(--position-absolute);
  right: 0px;
  background-color: var(--white);
  top: 0px;
  bottom: var(--value-10);
  min-width: 500px;
  z-index: var(--z-index-2);
  animation: fadeInRight 500ms ease-in-out;
  box-shadow: -10px 0px 7px rgba(63, 63, 63, 0.1);
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }

  to {
    opacity: 1;
  }
}

.home-base-planner {
  padding-left: var(--value-0);
  padding-right: var(--value-0);

  .mat-expansion-panel {
    box-shadow: var(--display-none);
  }

  .mat-expansion-panel-body {
    padding: var(--value-0);
  }
}

.kpi-alert-icon {
  max-width: 24px;
  padding-right: 8px;
  position: var(--position-relative);
  top: -2px;
}

.embedded-report-graph {
  iframe {
    height: 37vh !important;
  }
}

:not(:root):fullscreen {
  .embedded-report-graph {
    iframe {
      height: calc(100vh - 90px) !important;
    }
  }
}

.planner-note {
  font-size: var(--font-size-12);
  color: var(--black);
  font-style: italic;
  font-weight: var(--font-weight-400);

  @media (max-width: 400px) {
    font-size: var(--font-size-10);
  }
}

//animations starts
.bounce2 {
  animation: bounce2 2s ease infinite;
}

@keyframes bounce2 {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-5px);
  }

  60% {
    transform: translateY(-2px);
  }
}

//animations ends
.embedded-report-graph-select {
  iframe {
    height: 340px !important;
    width: 98% !important;
  }
}

.home-base-right-panel {
  width: 90%;

  @media (max-width: $media-breakpoint-lg-down) {
    position: var(--position-fixed) !important;
    top: 40px !important;
    bottom: 53px !important;
  }

  .mat-drawer-inner-container {
    overflow: var(--overflow-hidden);

    @media (max-width: $media-breakpoint-lg-down) {
      overflow: auto;
      overflow-x: hidden;
    }
  }
}

app-home-base {
  .mat-drawer-content {
    overflow: var(--overflow-hidden);
  }
}

//multiview planner
.miv-planner-wrapper {
  .ag-theme-material {
    .ag-pinned-right-cols-container {
      .ag-cell {
        justify-content: flex-start;
      }
    }

    .tree-multi-select {
      .label {
        font-size: var(--font-size-14);
        color: var(--white);
        font-weight: normal;
        max-width: 85px;
        overflow: var(--overflow-hidden);
        white-space: nowrap;
        text-overflow: ellipsis;

        @media (max-width: 1800px) {
          font-size: var(--font-size-12);
        }
      }
    }
  }

  app-grid-custom-header {
    .p-dropdown {
      height: 50px !important;
      background-color: var(--primary-purple) !important;
      border-right: 1px solid var(--white) !important;

      &.isCurrentWeek {
        background-color: var(--Razzmatazz) !important;
      }

      .p-inputtext {
        font-size: var(--font-size-14);
        color: var(--white) !important;
      }

      .p-dropdown-trigger {
        color: var(--white) !important;
      }
    }

    .miv-planner-tree {
      padding-left: 3rem;
    }
  }

  .ag-theme-material.custom-ag-grid .ag-pinned-left-header .ag-header-row .ag-header-cell:nth-child(1) {
    padding: var(--value-0);
    height: 40px;
    @include flex-align(flex, center);

    @media (max-width: 1800px) {
      height: 35px;
    }
  }

  .p-treeselect:not(p.disabled) {
    border-color: white;
  }

  .cell-treeview {
    border-right: 2px solid white;

    .p-treeselect {
      background-color: var(--primary-purple);
    }
  }

  .label-update {
    .label {
      color: white;
    }

    .material-icons-round {
      color: white;
    }
  }

  .ag-group-value {
    font-weight: var(--font-weight-bold);
  }

  app-base-demand-planner-grid {
    .p-treeselect .p-treeselect-trigger .p-treeselect-trigger-icon {
      color: white;
    }
  }

  .ag-theme-material.custom-ag-grid .ag-header-cell app-grid-custom-header .mat-select {
    background: var(--primary-purple);

    .mat-select-placeholder {
      color: white !important;
    }
  }

  .ag-theme-material.custom-ag-grid .ag-header-cell app-grid-custom-header .mat-select .mat-select-arrow {
    color: white !important;
    border-right: 2px solid white !important;
  }

  .ag-theme-material .ag-header .ag-header-cell-label .ag-header-cell-text {
    color: var(--black) !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-400);
  }

  .ag-theme-material.custom-ag-grid .ag-header-cell app-grid-custom-header .mat-select {
    border-right: 1px solid white !important;
  }

  .ag-theme-material.custom-ag-grid .ag-row {
    border-bottom: 2px solid var(--white) !important;

    &.is-expanded {
      .ag-cell {
        padding-left: 50px !important;
      }
    }
  }

  .ag-theme-material .ag-header .ag-header-cell:last-child {
    border-right: 0px !important;
  }

  .ag-theme-material.custom-ag-grid .ag-pinned-left-cols-container .ag-row .ag-cell:nth-child(1) {
    border-right: 1px solid var(--white) !important;
    border-color: var(--white);
    border-bottom: 1px solid var(--white) !important;
    padding: 6px 15px;
    text-align: var(--text-right) !important;
    @include flex-align(flex !important, center);
  }

  .p-paginator {
    color: rgba(0, 0, 0, 0.54);
    padding: var(--value-0);
    background-color: var(--wild-sand);
    @include border-radius(5px);
    margin-top: var(--value-20);
    margin-bottom: var(--value-20);
    height: 50px;
    justify-content: flex-end;
    padding: 0 30px;

    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
      min-width: 30px;
      height: 30px;
    }

    .p-paginator-pages {
      .p-paginator-page {
        min-width: 30px;
        height: 30px;

        &.p-highlight {
          background: var(--primary-purple);
          border-color: rgba(103, 58, 183, 0.12);
          color: var(--white);
          @include border-radius(50%);
        }
      }
    }
  }
}

.multi-item-view-planner {
  .mat-select-panel {

    .mat-mdc-option.mat-mdc-option-active,
    .mat-mdc-option:hover {
      background-color: var(--primary-purple) !important;
    }
  }

  .p-tree .p-tree-container .p-treenode.ag-tree-node .p-treenode-content.p-highlight {
    background: var(--primary-purple) !important;
  }
}

#fb-forecast-new-planner {
  .grid-columns-expand {
    height: var(--height-100);
    width: 50px;
    @include flex-center(flex, center, center);
    background-color: var(--primary-purple);
    color: var(--white);
    position: var(--position-absolute);
    z-index: var(--z-index-1);
    border-right: 1px solid var(--white);
    top: 3px;

    .box {
      border: 1px solid var(--white);
      @include flex-center(flex, center, center);
      width: 20px;
      height: 20px;
      position: var(--position-relative);
      background-color: var(--primary-purple);
      color: var(--white);

      @media (max-width: 1800px) {
        width: 15px;
        height: 15px;
      }

      &:before {
        content: '';
        position: var(--position-absolute);
        top: -7px;
        right: -6px;
        width: 20px;
        height: 20px;
        border: 1px solid var(--white);
        z-index: var(--z-index-negative1);

        @media (max-width: 1800px) {
          width: 15px;
          height: 15px;
        }
      }

      span {
        font-size: 14px !important;

        @media (max-width: 1800px) {
          font-size: 12px !important;
        }
      }
    }
  }
}

.task-progress-dialog {
  .p-mdc-dialog-content {
    padding: var(--value-0);
  }

  .chart-container {
    margin-bottom: var(--value-0);
    height: 330px !important;
  }
}

app-notifications {
  .notification-alert .mat-tab-header {
    margin-top: var(--value-10);
  }
}

.grain-level-grid {
  padding: 0;

  .ag-theme-material .ag-body-viewport {
    max-height: 600px;
  }
}

.kpi-filter-checkbox {
  .mat-checkbox-layout {
    width: 0 !important;
    min-width: 25px !important;
    text-align: var(--text-center);
    margin: var(--value-0);
  }
}

.action-portal-warper {
  app-file-download-rendere {
    .btn {
      color: var(--bs-btn-hover-color);
      background-color: var(--bs-btn-hover-bg);
      border-color: var(--bs-btn-hover-border-color);
    }
  }
}

.minimize-right-panel {
  box-shadow: -10px 0px 7px rgba(63, 63, 63, 0.1);
  position: var(--position-absolute);
  right: 0px;
  background-color: var(--white);
  top: 0px;
  bottom: var(--value-10);
  width: 30px;
  z-index: 1;

  ul {
    margin: var(--value-0);
    padding: var(--value-0);
    list-style-type: var(--display-none);
    height: 30px;
    transform: rotate(90deg);
    @include flex-align(flex, center);

    li {
      margin-top: var(--value-10);

      p {
        padding: 0 20px;
        white-space: nowrap;
        font-size: var(--font-size-12);
        position: var(--position-relative);
        cursor: var(--cursor-pointer);
        transition: all ease 0.5s;
        text-decoration: var(--display-none);
        margin: var(--value-0);

        &:hover {
          color: var(--Carnation);
          transition: all ease 0.5s;

          &:before {
            background-color: var(--Carnation);
            transition: all ease 0.5s;
          }
        }

        &:before {
          content: '';
          position: var(--position-absolute);
          height: 5px;
          background-color: var(--Alto);
          top: -10px;
          bottom: var(--value-0);
          right: 20px;
          left: 20px;
          transition: all ease 0.5s;
        }
      }
    }
  }
}

.minimize-panel {
  position: var(--position-relative);
  top: -7px;
}

.genai-chat-wrapper {
  height: 398px;

  .inner-genai-message {
    overflow-y: auto;
    padding: 5px;
  }
}

app-notifications {
  .notification-alert .mat-tab-header {
    margin-top: var(--value-10);
  }
}

.menu-form-btn {
  @media (max-width: 400px) {
    height: 36px;
    min-width: 100px;
  }
}

.genai-btn {
  @media (max-width: 425px) {
    min-width: 94px;
    height: 40px;
  }
}

.kpi-drawer-wrapper {
  .add-circle {
    width: 35px;
    height: 35px;
    @include border-radius(50%);
    @include flex-center(flex, center, center);
    background: var(--primary-purple);
    cursor: var(--cursor-pointer);

    .mat-icon {
      color: var(--white);
      font-size: var(--font-size-20);
      width: 20px;
      height: 20px;
    }
  }

  .creat-kpi-wrapper {
    overflow-y: auto;
    height: calc(100vh - 275px);

    .mat-vertical-content {
      padding: 0 0 20px 20px !important;
    }
  }

  .kpi-drawer-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: var(--value-50);
  }
}

app-tracking {
  .p-calendar {
    width: var(--width-100);

    input {
      font-size: var(--font-size-12);
    }
  }
}

.on-radio {
  .p-calendar {
    input {
      padding: 0 5px;
      font-size: var(--font-size-12);
      height: 26px;
      background-color: var(--gray-100);
      border: var(--display-none);
      @include border-radius(0);
    }
  }
}

.after-radio {
  input {
    padding: 0 5px;
    font-size: var(--font-size-12);
    height: 26px;
    background-color: var(--gray-100);
    border: var(--display-none);
    @include border-radius(0);
  }

  span {
    line-height: 1.25rem;
    font-size: 0.875rem;
    letter-spacing: 0.0142857143em;
    font-weight: var(--font-weight-400);
    color: #5f6368;
    padding-left: var(--value-10);
  }
}

.custom-wrapper {
  .title {
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: 0.00625em;
    font-weight: var(--font-weight-500);
    padding: 0 0 13px;
    margin: var(--value-0);
    color: #3c4043;
    border: var(--display-none);
  }

  .repeat {
    line-height: 1.25rem;
    font-size: 0.875rem;
    letter-spacing: 0.0142857143em;
    font-weight: var(--font-weight-400);
    color: #5f6368;
  }

  .custom-input {
    border: var(--display-none);
    background-color: var(--gray-100);
    height: 26px;
  }

  .dates {
    p {
      margin: var(--value-0);
      width: 24px;
      height: 24px;
      font-size: var(--font-size-10);
      font-weight: var(--font-weight-500);
      @include border-radius(50%);
      background-color: rgb(241, 243, 244);
      color: rgb(128, 134, 139);
      margin-right: 8px;
      cursor: var(--cursor-pointer);
      @include flex-center(flex, center, center);

      &.active {
        background-color: var(--secondary-carnation);
        color: var(--white);
      }
    }
  }

  .lable-1 {
    margin: var(--value-0);
    width: 100px;

    .mat-radio-label {
      line-height: 1.25rem;
      font-size: 0.875rem;
      letter-spacing: 0.0142857143em;
      font-weight: var(--font-weight-400);
      color: #5f6368;
      margin: var(--value-0);
    }
  }
}

.kpi-dialog-wrapper {
  .p-mdc-dialog-content {
    padding-bottom: var(--value-0);
  }

  .p-mdc-dialog-content {
    max-height: 60vh;
    height: 60vh;
  }
}

.p-datepicker table td.p-datepicker-today>span.p-highlight {
  color: var(--white);
  background: var(--secondary-carnation);
  border-color: transparent;
  border: var(--display-none);
  box-shadow: var(--display-none);
}

.alerts-calender {
  &.p-datepicker {
    width: auto !important;

    .p-datepicker-header .p-datepicker-prev,
    .p-datepicker-header .p-datepicker-next {
      width: 10px;
      height: 10px;
    }

    .p-datepicker-header .p-datepicker-title .p-datepicker-year,
    .p-datepicker-header .p-datepicker-title .p-datepicker-month {
      font-size: var(--font-size-12);
    }

    .p-datepicker-header .p-datepicker-title {
      line-height: 0;
    }

    table {
      margin: var(--value-0);

      th {
        font-size: var(--font-size-12);
        text-align: var(--text-center);
      }

      td {
        padding: var(--value-0);
        font-size: var(--font-size-12);
      }
    }
  }
}

.total-pinned {
  color: var(--white);
  height: 50px !important;
  background-color: var(--primary-purple) !important;
  padding: 0 10px;
  width: 135px !important;

  img {
    margin-right: 16px;
    max-width: 20px;
  }

  span {
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-13);
  }
}

app-miv-planner {
  .custom-scroll-hide {

    // .ag-header-viewport{
    //   .ag-header-row{
    //     .ag-header-cell{
    //       &:last-child{
    //         &[col-id="Total"]{
    //           margin-right: var(--value-10);
    //         }
    //       }
    //     }
    //   }
    // }
    .ag-pinned-right-header {
      .ag-header-cell {
        &:hover {
          background-color: var(--primary-purple) !important;
        }
      }
    }
  }
}

.tracking-wrapper {
  .title {
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-500);
    margin-bottom: var(--value-10);
    padding: var(--value-0);
    border: var(--display-none);
  }
}

.tracking-schedule {
  .col {
    flex: 0;
  }

  .col-size {
    @media (min-width: 1500px) {
    width: 20%;
    }
    @media (min-width: 1900px) {
      width: 16.66%;
    }
  }
}

.tracking-dates {
  .form-group {
    margin: var(--value-0);
    width: 227px;

    input {
      &::placeholder {
        font-size: var(--font-size-12);
        color: var(--black);
        font-weight: var(--font-weight-400);
      }
    }
  }
}

.creat-alert-dialog {
  h1 {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-500);
    margin-bottom: 15px;
    margin-left: 15px;
  }

  .creat-kpi-wrapper {
    padding: var(--value-0);

    .mat-mdc-checkbox {
      .mdc-label {
        font-size: var(--font-size-12);
      }
    }
  }

  .header {
    .form-group {
      label {
        width: 70px;
        min-width: 70px;
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-500);
        margin-bottom: var(--value-10);
        padding: var(--value-0);
        border: var(--display-none);
      }
    }
  }

  .creat-kpi-wrapper {
    padding: var(--value-0);

    .mat-checkbox-layout .mat-checkbox-label {
      font-size: var(--font-size-12);
    }
  }

  .mat-checkbox-inner-container {
    height: 14px;
    width: 14px;
  }
}

app-threshold,
app-manual-threshold-setting,
.select-grain-wrapper {
  display: var(--display-block);
  position: var(--position-relative);

  .add-filter {
    position: var(--position-absolute);
    top: var(--value-10);
    right: var(--value-10);
    width: 35px;
    height: 35px;
    @include border-radius(50%);
    @include flex-center(flex, center, center);
    background: var(--primary-purple);
    cursor: var(--cursor-pointer);

    .mat-icon {
      color: var(--white);
      font-size: var(--font-size-18);
      width: 18px;
      height: 18px;
    }
  }
}

.ag-theme-material.custom-ag-grid .ag-header-cell app-grid-custom-header .p-dropdown .p-inputtext {
  color: white !important;
  justify-content: var(--justify-center);
  font-size: var(--font-size-13);
  font-weight: var(--font-weight-600);
}

.planners-grid-col {
  .ag-theme-material {
    .ag-grid-total-col-align {
      .ag-pinned-right-cols-container {
        width: 134px !important;
        max-width: 134px !important;
        min-width: 134px !important;

        .ag-cell {
          justify-content: flex-start;
        }
      }
    }

    .ag-pinned-right-cols-container {
      width: 134px !important;
      max-width: 134px !important;
      min-width: 134px !important;
      border-left: 1px solid var(--white);

      .ag-cell-range-right {
        font-weight: var(--font-weight-600);
      }
    }
  }
}

.ag-pinned-right-cols-container {
  .ag-cell {
    justify-content: flex-start;
  }
}

.dynamic-hierarchy-wrapper {
  .mat-mdc-form-field {
    width: 350px !important;
  }

  .menu-configuration-wrapper {
    padding: var(--value-0);

    .action-panel {
      position: var(--position-absolute);
      top: -10px;
      right: var(--value-10);
      z-index: var(--z-index-1);

      .sub-menu-icon {
        width: 30px;
        height: 30px;

        img {
          max-width: 12px;
          min-width: 12px;
        }
      }
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content {
      padding: 12px 45px 12px 15px;
      margin-bottom: var(--value-0);
    }

    // .p-tree {
    //   .p-treenode-children {
    //     .p-element {
    //       [ng-reflect-level="3"] {
    //         .p-treenode[aria-expanded=true] .p-treenode-children .p-treenode[aria-expanded=true] .p-tree-toggler-icon {
    //           transform: rotate(90deg) !important;
    //         }
    //       }
    //     }
    //   }
    // }

    .zoom-in-out {
      position: var(--position-absolute);
      right: var(--value-0);
    }

    .p-tree .p-tree-filter-container {
      width: 350px;
      margin-bottom: var(--value-10);

      .p-tree-filter {
        border: var(--display-none);
        height: 30px;
        padding: 0 10px;
        text-align: var(--text-left);
        background-color: var(--gray-100);
      }

      .p-tree-filter-icon {
        color: var(--black);
        font-weight: var(--font-weight-bold);
      }
    }
  }

  .card {
    // height: 600px;
    height: calc(100vh - 300px);
    overflow: auto;
    padding: var(--value-20);

    &::-webkit-scrollbar {
      height: 10px;
    }
  }

  .p-organizationchart {
    .p-organizationchart-node-content {
      font-size: var(--font-size-12);
      @include border-radius(5px);
      min-width: 200px;

      &:before {
        content: '';
        position: var(--position-absolute);
        top: var(--value-0);
        left: var(--value-0);
        right: var(--value-0);
        height: 8px;
        background-color: var(--primary-purple);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      .p-node-toggler {
        background: #80808040;
        @include border-radius(5px);
        width: 16px;
        height: 16px;
        bottom: -6px;
        margin: var(--value-0);
        transform: translateX(-50%);
        @include flex-center(flex, center, center);
        text-decoration: var(--display-none);

        .p-node-toggler-icon {
          top: var(--value-0);
          font-size: var(--font-size-10);
          font-weight: var(--font-weight-bold);
        }
      }
    }
  }

  .view-tree {
    padding: var(--value-10);

    .label-padding {
      color: var(--rolling-stone);
    }

    button {
      position: var(--position-absolute);
      top: -5px;
      right: -3px;
      box-shadow: var(--display-none);
      width: 24px;
      height: auto;

      .mat-icon {
        font-size: var(--font-size-24);
      }
    }
  }

  .hierarchy-menu-custom {
    @include flex-align(flex, center);
    justify-content: space-evenly;
    margin: 0 -16px;
    font-size: var(--font-size-12);
    padding-bottom: 5px;

    .hierarchy-menu-icon {
      position: var(--position-relative);
      background: var(--white);
      box-shadow: 0px 2px 4px rgba(63, 63, 63, 0.3);
      @include border-radius(50%);
      width: 30px;
      height: 30px;
      @include flex-center(flex, center, center);

      img {
        max-width: 15px;
        min-width: 15px;
        cursor: var(--cursor-pointer);
      }
    }
  }
}

.hierarchy-sub-tabs {
  .mat-tab-header {
    .mat-tab-labels {
      justify-content: flex-end;
    }

    .mat-tab-label {
      color: #686868;
      width: auto !important;
      background-color: transparent;

      &.mat-tab-label-active {
        background: transparent;
        color: #44195b;
      }
    }
  }
}

.kpi-icon {
  .mat-icon {
    margin-left: 5px;
    font-size: var(--font-size-22);
    width: 22px;
    height: 22px;
    font-weight: var(--font-weight-500);
    color: var(--red);
  }
}

.ag-pinned-right-header {
  .total-pinned {
    padding: var(--value-0);
  }
}

.export-import-btn {
  position: var(--position-relative);
  width: 130px;
  margin-left: var(--value-10) !important;
  text-align: var(--text-left);
  justify-content: flex-start;
  padding: 0 10px 0 15px !important;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid rgba(0, 0, 0, 0);
    border-right: 4px solid rgba(0, 0, 0, 0);
    border-top: 4px solid;
    margin: 0 4px;
    color: var(--white);
    position: var(--position-absolute);
    right: 5px;
  }
}

.drag-button {
  @include flex-justify-between(flex !important, center, space-between);
  border: var(--display-none);
  background-color: transparent;
  padding: 10px 6px;

  .inner-data {
    .mat-icon {
      min-width: 24px;
    }

    span {
      white-space: nowrap;
      width: 220px;
      overflow: var(--overflow-hidden);
      text-overflow: ellipsis;
      padding: 0 10px;
      font-size: var(--font-size-14);
    }
  }
}

.drag-button-wrapper {
  margin: var(--value-10);

  .btn {
    height: 40px;
    min-width: 90px;

    &.btn-secondary {
      margin-left: var(--value-10);
    }
  }
}

app-action-renderer {
  width: var(--width-100);
}

.p-inputwrapper {
  width: var(--width-100);

  .p-inputnumber {
    width: var(--width-100);
  }
}

app-grain-level-threshold {

  // .p-radiobutton {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
  .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-header {
    padding: 10px 0 10px 5px;
  }
}

app-dynamic-threshold-setting {
  .field-radiobutton {
    label {
      margin: 0;
      font-size: 12px;
    }
  }
}

.field-radiobutton {
  display: flex;
  align-items: center;

  label {
    padding-left: 5px;
    margin: 0 !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    font-size: 14px !important;
  }

  p-radiobutton {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .p-radiobutton {
    .p-radiobutton-box {
      &.p-highlight {
        border-color: #ff4081;
      }

      .p-radiobutton-icon {
        background-color: #ff4081;
      }
    }
  }

  .p-radiobutton.p-radiobutton-checked:not(.p-radiobutton-disabled).p-radiobutton-focused {
    box-shadow: 0 0 1px 10px rgba(#ff4081, 0.12);
  }
}


.track-progress-view {
  max-height: 450px;
  overflow: auto;
}

.search-filter {
  .form-group {
    width: 200px;
    margin: 0 10px 0 10px;

    input {
      color: var(--black) !important;
      font-size: var(--font-size-12);
      font-weight: normal;

      &::placeholder {
        color: var(--black) !important;
        font-size: var(--font-size-12);
        font-weight: normal;
      }
    }

    .mat-mdc-form-field {
      input {
        color: var(--black) !important;
        font-size: var(--font-size-12);
        font-weight: normal;

        &::placeholder {
          color: var(--black) !important;
          font-size: var(--font-size-12);
          font-weight: normal;
        }
      }
    }
  }

  .filter-select {
    margin: 0 10px;
    width: 200px;

    .mat-select {
      background-color: var(--gray-100) !important;
      height: 27px;
      padding: 0 10px;
      font-size: var(--font-size-13);

      .mat-select-trigger {
        height: var(--height-100);
      }
    }
  }
}

.ngx-bar {
  background: linear-gradient(90deg,
      rgb(248, 72, 110) 33%,
      rgb(252, 107, 107) 66%,
      rgb(254, 172, 71) 80%) !important;
}

app-kpi,
app-graph {
  .algo-planner-accordion {
    padding: 0 !important;
  }
}

.visibility-off-icon {
  color: #00000085 !important;
  cursor: default;
}

.commentCount {
  position: var(--position-relative);
  @include flex-align(flex, center);

  .count {
    margin: var(--value-0);
    position: var(--position-absolute);
    background-color: var(--primary-purple);
    color: var(--white);
    @include border-radius(50%);
    height: 14px;
    width: 13px;
    right: -5px;
    top: -3px;
    font-size: 8px;
    @include flex-center(flex, center, center);
    line-height: inherit;
    padding-top: var(--value-0);
    font-family: var(--font-family) !important;
  }
}

.notification-filter-wrapper {
  // // .p-checkbox .p-checkbox-box {
  // //   margin-top: 3px;
  // //   width: 16px !important;
  // //   height: 16px !important;
  // }

  .p-tree {
    max-height: 350px;
    overflow-y: auto;

    .p-tree-empty-message {
      width: 240px;
      height: 28px;
      @include flex-center(flex, center, center);
      border: var(--display-none);
      background-color: var(--gray-100);
      font-size: var(--font-size-12);
    }

    .p-tree-container {
      .p-treenode {
        .p-treenode-content {
          padding: 8px 44px 8px 8px !important;
          width: 200px !important;
          height: 30px !important;

          &:before {
            content: '';
            border-bottom: none !important;
          }

          .p-tree-toggler {
            right: -9px !important;
          }

          .p-treenode-label {
            padding-top: 3px;
          }
        }

        .p-treenode-children {
          width: 215px;

          .p-treenode {
            .p-treenode-content {
              &:before {
                content: '';
                border-bottom: 1px solid black !important;
              }
            }
          }
        }
      }
    }
  }
}

.tenants-users {
  background-color: var(--primary-purple);
  height: calc(100% - 40px);
  z-index: var(--z-index-9);
  position: var(--position-relative);
  @include flex-column-between(flex, column, space-between);
  padding-bottom: var(--value-10);

  &.navigator-icon {
    .navigator {
      opacity: 1;

      &:before {
        display: var(--display-none);
      }
    }
  }

  .sidenav-icon {
    width: 50px;
    @include flex-center(flex, center, center);
    border-bottom: var(--value-0);
    height: 55px;
    position: var(--position-relative);
    opacity: 0.4;
    padding-left: 5px;

    &:before {
      content: '';
      position: var(--position-absolute);
      top: var(--value-10);
      left: var(--value-0);
      bottom: var(--value-10);
      width: 4px;
      background: var(--rolling-stone);
      @include border-radius(5px);
    }

    &.active {
      opacity: 1;

      &:before {
        background: linear-gradient(0deg, #f8486e, #f8486e), #d81ad1;
      }
    }

    .sidenav-icons {
      position: var(--position-relative);
      transition: all ease-out 0.5s;
      width: 35px;
      height: 35px;
      @include border-radius(50%);
      @include flex-center(flex, center, center);
      cursor: var(--cursor-pointer);
      box-shadow: 0px 4px 20px rgba(63, 63, 63, 0.1) !important;
      background: var(--white) !important;

      &:hover {
        transition: all ease-in 0.5s;
        box-shadow: 0px 0px 10px 0px rgba(248, 72, 110, 1) !important;
        background: #bab7b7;

        img {
          color: var(--black);
          transform: scale(1.2);
          transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        }
      }

      img {
        max-width: 20px;
      }

      .count {
        margin: var(--value-0);
        position: var(--position-absolute);
        background-color: var(--primary-purple);
        color: var(--white);
        @include border-radius(50%);
        height: 16px;
        width: 16px;
        right: -3px;
        top: -5px;
        font-size: var(--font-size-10);
        @include flex-center(flex, center, center);
        line-height: inherit;
        padding-top: 2px;
        font-family: var(--font-family) !important;

        @media (max-width: 1800px) {
          height: 12px;
          width: 12px;
        }
      }

      .material-icons {
        font-size: var(--font-size-24);
        width: auto;
      }
    }
  }
}

.roll-up-filters {
  .filter-tree {
    .p-treeselect {
      background: var(--gray-100);
      height: 26px;
      padding: 0 10px;
      font-size: var(--font-size-12);
      @include border-radius(5px);
      width: 100% !important;
      text-align: var(--text-left);
      box-shadow: none !important;
    }

    .p-treeselect-panel .expand-collapse-icon {
      top: 35px;
    }
  }

  .p-treeselect-panel {
    margin-top: 26px;
  }

  .p-component {
    width: var(--width-100);
  }

  .p-dropdown-filter-icon {
    top: 30%;
  }

  .p-dropdown-panel {
    margin-top: 26px;
  }
}

.tracking-upcoming {
  .p-paginator {
    padding: var(--value-0);
    width: 50%;
    justify-content: flex-end;

    .p-paginator-pages {
      @include flex-align(flex, center);
    }

    .p-element {
      font-size: var(--font-size-14);
      width: 30px;
      min-width: 30px;
      height: 30px;
      @include flex-center(flex, center, center);
    }
  }
}

#fb-forecast-graph {
  .highcharts-root {
    .highcharts-legend {
      .highcharts-legend-item {
        path.highcharts-point {
          display: block !important;
        }

        &.highcharts-legend-item-hidden {
          text {
            text-decoration: none !important;
            color: #ccc !important;
            fill: #ccc !important;
          }
        }

        text {
          color: #7c7c7c !important;
          fill: #7c7c7c !important;
          font-size: 12px !important;
        }

        .highcharts-graph {
          display: none !important;
        }
      }
    }

    .highcharts-halo {
      fill-opacity: 1;
    }

    .highcharts-credits,
    path.highcharts-point {
      display: none !important;
    }

    .highcharts-xaxis-grid {
      path.highcharts-grid-line {
        stroke-width: 1px !important;
      }
    }
  }
}

#fb-forecast-graph-signle-point {
  .highcharts-root {
    .highcharts-legend {
      .highcharts-legend-item {
        path.highcharts-point {
          display: block !important;
        }

        &.highcharts-legend-item-hidden {
          text {
            text-decoration: none !important;
            color: #ccc !important;
            fill: #ccc !important;
          }
        }

        text {
          color: #7c7c7c !important;
          fill: #7c7c7c !important;
          font-size: 12px !important;
        }

        .highcharts-graph {
          display: block !important;
        }
      }
    }

    .highcharts-halo {
      fill-opacity: 1;
    }

    .highcharts-credits,
    path.highcharts-point {
      display: block !important;
    }

    .highcharts-xaxis-grid {
      path.highcharts-grid-line {
        stroke-width: 1px !important;
      }
    }
  }
}

app-image-renderer {
  width: var(--width-100);
}

.mat-mdc-select {
  font-size: 12px !important;
}

.mat-mdc-select-panel {
  .mat-mdc-option {
    .mat-pseudo-checkbox {
      display: var(--display-none);
    }

    &:hover,
    &.mat-mdc-option-active {
      background: rgba(240, 67, 113, 0.12) !important;

      .mdc-list-item__primary-text {
        color: var(--secondary-carnation) !important;
      }
    }
  }
}

.multiline-tooltip {
  .mdc-tooltip__surface {
    max-width: max-content;
    text-align: var(--text-left);
  }
}

.p-calendar {
  width: var(--width-100);
}

.mat-mdc-checkbox {
  .mdc-checkbox {
    padding: var(--value-0);
    width: 16px;
    height: 16px;
    flex: 0 0 16px;

    .mdc-checkbox__native-control {
      height: 16px !important;
    }

    .mat-mdc-checkbox-touch-target,
    .mdc-checkbox__ripple,
    .mat-mdc-checkbox-ripple {
      display: var(--display-none);
    }

    .mdc-checkbox__background {
      top: var(--value-0);
      left: var(--value-0);
      width: 16px;
      height: 16px;
    }
  }
}

app-homebase-graph-filter {
  .p-mdc-dialog-content {
    height: calc(100% - 60px);

    iframe {
      height: 350px !important;
    }
  }
}

.buildprice {
  .mat-mdc-dialog-title {
    margin: 0 !important;
  }
}

app-tenant-navigator {
  .ag-theme-material {
    .ag-center-cols-viewport {
      cursor: var(--cursor-pointer);
    }
  }
}

.dynamic-titles {
  kpi-card {
    .kpi-card {
      .cardkpi-header {
        .kpiName {
          max-width: fit-content !important;
          padding: 0 !important;
        }
      }
    }
  }
}

.grain-level-stepper {
  margin-bottom: var(--value-50);

  .mat-step-label {

    .mat-mdc-radio-group,
    .mat-mdc-radio-button {
      height: 40px;
      display: var(--display-inline-block);
      cursor: var(--cursor-pointer);
    }

    .mdc-form-field>label {
      height: 40px;
      @include flex-align(flex, center);
    }
  }

  .mat-step-header {
    padding: 15px 14px;
    width: max-content;

    .mat-step-header-ripple {
      display: var(--display-none);
    }

    .mat-step-icon {
      display: var(--display-none);
    }
  }

  .mat-vertical-content-container {
    margin-left: 34px;
  }
}

.dynamic-threshold-wrapper {
  .threshold-disabled {

    .mat-mdc-radio-button {
      .mdc-radio__outer-circle {
        border-color: #ddd !important;
      }

      .mdc-form-field .mdc-label {
        color: #ddd;
        pointer-events: var(--display-none);
      }
    }

    .threshhold-group {
      pointer-events: var(--display-none);
    }

    app-row-dynamic-threshold {
      .threshhold-group {
        .form-group {
          label {
            opacity: 0.5;
          }

          .mat-icon {
            color: #ddd;
          }

          input {
            opacity: 0.5;
          }
        }
      }
    }
  }

  // app-radio-button {
  //   .p-radiobutton {
  //     width: auto !important;
  //     height: auto !important;
  //   }
  // }

  .mat-mdc-radio-button,
  app-radio-button {
    min-width: 180px;

    .mdc-form-field {
      height: 30px;

      .mdc-radio {
        padding-left: var(--value-0);

        .mdc-radio__background {
          width: 18px;
          height: 18px;

          .mdc-radio__inner-circle {
            top: -1px;
            left: -1px;
          }
        }
      }

      .mdc-label {
        font-size: var(--font-size-12);
      }
    }
  }

  app-row-dynamic-threshold {
    .threshhold-group {
      .form-group {
        margin-bottom: var(--value-0);

        .mat-icon {
          width: 16px;
        }

        label {
          width: 160px;
          color: var(--red);
          margin: 0;
        }

        input {
          text-align: var(--text-center) !important;
        }
      }
    }
  }
}

.bulk-wrapper {
  .items-wrapper-grid {
    padding: var(--value-0);

    .ag-theme-material {
      .ag-center-cols-viewport {
        .ag-row {
          .ag-cell {
            padding: 0 14px;

            app-status-renderer {
              .mat-mdc-progress-bar {
                @include border-radius(20px !important);

                width: 125px;
                text-transform: capitalize;
                color: var(--white);
                height: 25px;

                .mdc-linear-progress__buffer {
                  height: 25px !important;
                }

                &.completed {
                  .mdc-linear-progress__buffer {
                    background-color: var(--Salem);
                  }

                  .mdc-linear-progress__bar {
                    .mdc-linear-progress__bar-inner {
                      width: auto !important;
                    }
                  }

                }

                &.cancelled {
                  .mdc-linear-progress__buffer {
                    background-color: var(--Ebony);
                  }

                  .mdc-linear-progress__bar {
                    .mdc-linear-progress__bar-inner {
                      width: auto !important;
                    }
                  }
                }

                &.failed {
                  .mdc-linear-progress__buffer {
                    background-color: var(--red);
                  }

                  .mdc-linear-progress__bar {
                    .mdc-linear-progress__bar-inner {
                      width: auto !important;
                    }
                  }
                }

                &.in-progress {
                  .mdc-linear-progress__buffer {
                    background-color: var(--Amber);
                  }

                  .mdc-linear-progress__buffer {
                    background-color: var(--mine-shaft);
                  }

                  .mdc-linear-progress__bar {
                    .mdc-linear-progress__bar-inner {
                      width: auto !important;
                    }
                  }
                }

                &.in.progress {
                  .mdc-linear-progress__buffer {
                    background-color: var(--Amber);
                  }

                  .mdc-linear-progress__bar {
                    .mdc-linear-progress__bar-inner {
                      width: auto !important;
                    }
                  }
                }

                &.started {
                  .mdc-linear-progress__buffer {
                    background-color: var(--Fiord);
                  }

                  .mdc-linear-progress__bar {
                    .mdc-linear-progress__bar-inner {
                      width: auto !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #fb-forecast-new-planner {
    .dropdown {
      .treeview-container {
        max-height: 250px !important;
      }
    }
  }
}

.progress-wrapper {
  position: var(--position-relative);

  span {
    position: var(--position-absolute);
    z-index: var(--z-index-9);
    color: var(--white);
    top: var(--value-0);
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    line-height: 25px;
  }
}

.dynamic-tile-wrapper {
  @include flex-center(flex, center, center);

  .dynamic-tile-content-details {
    width: 16% !important;

    .dynamic-titles {
      kpi-card {
        .kpi-card {
          .cardkpi-header {
            height: 38px !important;

            .kpiName {
              padding: 1px !important;
              white-space: normal !important;
            }
          }

          .cardkpi-footer {
            height: 60px !important;

            p {
              &.kpiName {
                font-weight: 800 !important;
                font-size: 20px !important;
              }
            }
          }
        }
      }

      label {
        text-align: var(--text-center);
        font-weight: var(--font-weight-800);
      }
    }
  }
}

.dynamic-tile-clear-icon {
  .p-dropdown-clearable {
    .p-dropdown-clear-icon {
      width: 10px;
      height: 10px;
      top: 16px !important;
    }
  }
}

.istreecustomefilter {
  @include flex-align(flex, center);
  padding: 5px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  .expand-collapse-icon {
    padding: 5px;
    font-size: 14px !important;
    color: gray;
    cursor: var(--cursor-pointer);
    position: static;
  }

  .p-treeselect-filter-container {
    @include flex-align(flex, center);
    position: var(--position-relative);

    input {
      border: var(--display-none);
      height: 30px;
      padding: 0 20px 0 10px;
      text-align: var(--text-left);
      background-color: var(--gray-100);
      @include border-radius(5px);
      width: var(--width-100);
      margin: 0 10px 0 35px;

      &:focus,
      &:focus-visible {
        outline: var(--display-none);
      }
    }

    .material-icons {
      position: var(--position-absolute);
      color: rgba(0, 0, 0, 0.6);
      font-size: 22px !important;
      right: 12px;
    }
  }

  .cross {
    color: rgba(0, 0, 0, 0.6);
    font-size: 22px !important;
    padding-right: 8px;
  }
}

app-confirmation-dialog {
  .action-panel {
    .btn {
      padding: 8px 16px;
      width: 60px;
      min-width: 60px;
      height: 40px;
    }
  }
}

.text-menu-highlight {
  background-color: yellow;
  word-break: break-word;
  display: block !important;
  -webkit-line-clamp: inherit !important;
  -webkit-box-orient: inherit !important;
  margin: 0 10px 0 0;
  width: auto !important;
  text-align: var(--text-left);
  padding: 1px 5px !important;
}

lib-dynamic-tile {
  .p-treeselect .p-treeselect-label-container .p-treeselect-label {
    width: 100%;
  }

  .p-treeselect .p-treeselect-trigger .p-treeselect-trigger-icon {
    color: inherit;
  }
}

legend {
  float: inherit !important;
  width: inherit !important;
  padding: 12px !important;
  margin-bottom: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
}

fieldset {
  min-width: inherit !important;
  padding: inherit !important;
  margin: inherit !important;
  border: 1px solid #e0e0e0 !important;
}

.sidenav-search-pane {
  border-bottom: 1px solid #b9b9b9;

  .p-autocomplete-clearable {
    width: var(--width-100);
    display: var(--display-block);
  }

  .p-autocomplete {
    height: 30px;
    width: calc(100% - 10px);
    margin: 5px;
    background-color: var(--gray-100);

    .p-icon-wrapper {
      display: var(--display-none);
    }

    .p-autocomplete-input {
      padding: 0 25px 0 10px;
      width: var(--width-100);
      background-color: var(--gray-100);
      border: var(--display-none);
      @include border-radius(5px);
      box-shadow: var(--display-none);

      &::placeholder {
        font-size: var(--font-size-12);
        color: var(--black);
        font-weight: normal;
      }
    }

    .p-autocomplete-dropdown {
      position: var(--position-absolute);
      right: 5px;
      background-color: transparent;
      padding: var(--value-0);
      width: 20px;
      height: 20px;
      top: 50%;
      transform: translateY(-50%);

      .pi-search {
        color: var(--black);
      }
    }

    .p-autocomplete-clear-icon {
      position: var(--position-absolute);
      right: 7px;
      background-color: var(--gray-100);
      z-index: var(--z-index-1);
      color: var(--black);
    }

    .p-autocomplete-panel {
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
      @include border-radius(3px);

      .p-autocomplete-items {
        padding: var(--value-0);
        margin: var(--value-0);
        background-color: var(--white);
        border: 1px solid #b9b9b9;
        border-top: var(--display-none);
        overflow-y: auto;
        height: auto;
        max-height: 200px;
        width: var(--width-100);

        .p-autocomplete-item {
          padding: var(--value-10);
          font-size: var(--font-size-12);
          background: transparent !important;

          span {
            white-space: normal;
          }
        }

        .p-autocomplete-empty-message {
          @include flex-center(flex, center, center);
          position: var(--position-relative);
          top: 40%;
          font-size: var(--font-size-14);
        }
      }
    }
  }
}

.custom-multiselect-panel {
  .p-multiselect-items {
    .p-multiselect-item-group {
      padding: 0 1rem;
      margin: 6px 0;
      border-bottom: 1px solid #d9d9d9;

      &:nth-child(1) {
        border-bottom: var(--display-none);
      }
    }

    .multiselect-group {
      color: var(--red);
      font-size: var(--font-size-12);
    }
  }
}

.tenant-confirm-modal {
  width: 550px;

  app-confirmation-dialog {
    .p-mdc-dialog-content {
      text-align: var(--text-center);

      img {
        max-width: 50px;
        margin-bottom: var(--value-10);
      }

      p {
        max-width: 430px;
        margin: 0 auto 20px;
      }
    }

    .action-panel {
      .btn {
        width: auto;

        &.btn-green {
          background-color: var(--caribbean-green);
          margin-left: var(--value-10);
        }
      }
    }
  }
}

app-journey-view,
app-selection-table,
app-journey-shared-with-me,
app-grain-level-alerts-view {

  .ag-row-hover:not(.ag-full-width-row)::before,
  .ag-row-hover.ag-full-width-row.ag-row-group::before {
    display: var(--display-none);
  }
}

.cdk-overlay-container {
  z-index: 1201;
}

.pos-checkbox {
  margin-bottom: 0.5rem !important;

  .mdc-label {
    width: 100% !important;
  }
}

.tree-visibility-off {
  .p-treenode-content {
    opacity: 0.5;
  }

  .p-tree-toggler {
    pointer-events: var(--display-none);
  }
}

.replenishment-form-wrapper {
  .content-label {
    padding-bottom: 5px !important;
  }

  .mdc-label {
    min-width: 127px !important;
    width: 127px !important;
    margin-left: 2px !important;
    margin-top: 2px !important;
  }

  .mat-mdc-checkbox {
    .mdc-checkbox {
      width: 2px !important;
    }
  }

  .mdc-checkbox {
    & .mdc-checkbox__native-control:enabled:not(:checked):not( :indeterminate):not([data-indeterminate='true'])~.mdc-checkbox__background {
      border-color: var(--black) !important;
    }
  }

  .p-dropdown {
    height: 30px !important;
  }

  .warehouse-content {
    input {
      padding: 0 5px !important;
    }
  }
}

.exception-wrapper {
  .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])~.mdc-checkbox__background {
    border-color: var(--black) !important;
  }

  .mdc-label {
    margin-left: 2px !important;
    margin-top: 2px !important;
  }

  .input-wrapper {
    margin-right: var(--value-20);
  }
}

.pos-exception-wrapper {
  .mdc-checkbox {
    & .mdc-checkbox__native-control:enabled:not(:checked):not( :indeterminate):not([data-indeterminate='true'])~.mdc-checkbox__background {
      border-color: var(--black) !important;
    }
  }

  .mat-mdc-input-element {
    max-width: 70% !important;
  }
}

.my-journey-grid .ag-theme-material .ag-floating-bottom .ag-row {
  background-color: var(--Athens-Gray) !important;
}

.parentScenario {
  background-color: var(--red);
}

.mat-tab-scenario {
  .p-tabview-nav {
    li {
      &.p-highlight {
        .p-tabview-nav-link {
          .mat-tab-scenario-new label {
            z-index: var(--z-index-1);
            color: var(--white) !important;
          }
        }
      }
    }

    .p-tabview-nav-link {
      .mat-tab-scenario-new label {
        z-index: var(--z-index-1);
        color: var(--btn-disabled) !important;
      }
    }
  }

  .mat-mdc-tab-header {
    .mat-mdc-tab {
      background: #d4d4d4;
      border-bottom: var(--display-none);

      .mdc-tab__content .mdc-tab__text-label .journey-tab-content label {
        color: #7c7c7c;
      }

      .child-tab {
        &:after {
          content: '';
          position: var(--position-absolute);
          height: 3px;
          left: -5px;
          right: var(--value-0);
          bottom: var(--value-0);
          background-color: var(--French-Rose);
        }
      }

      &.mdc-tab--active {
        .child-tab {
          &:after {
            left: -15px;
            right: -10px;
          }
        }

        .new-tab-new {
          position: var(--position-relative);
          height: 35px;

          &:before {
            content: '';
            position: var(--position-absolute);
            top: var(--value-0);
            right: -10px;
            bottom: var(--value-0);
            left: -10px;
            background-color: var(--alto);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }

          label {
            z-index: var(--z-index-1);
            color: var(--black) !important;
          }
        }

        .mat-tab-scenario-new {
          &:before {
            background-color: var(--primary-purple);
          }

          label {
            z-index: var(--z-index-1);
            color: var(--white) !important;
          }

          .journey-tab-icon .material-icons {
            color: var(--white);
          }

          &.parent-tab {
            &:after {
              content: '';
              position: var(--position-absolute);
              height: 3px;
              left: -10px;
              right: -10px;
              bottom: var(--value-0);
              background-color: var(--French-Rose);
            }
          }
        }

        .mat-tab-comparison-new {
          label {
            z-index: var(--z-index-1);
            color: var(--white) !important;
          }
        }
      }

      .mat-tab-scenario-new {
        &:before {
          z-index: var(--z-index-negative1);
        }

        label {
          z-index: var(--z-index-1);
          color: var(--btn-disabled) !important;
        }

        .journey-tab-icon .material-icons {
          color: #fffc;
        }
      }
    }
  }

  .mat-tab-scenario-new {
    position: var(--position-relative);
    height: 35px;

    &:before {
      content: '';
      position: var(--position-absolute);
      top: var(--value-0);
      right: -10px;
      bottom: var(--value-0);
      left: -10px;
      background-color: var(--primary-purple);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .journey-tab-icon .material-icons {
      color: var(--white);
    }
  }

  .mat-tab-comparison-new {
    position: var(--position-relative);
    height: 35px;
    color: var(--white);

    &.parent-tab {
      &:after {
        content: '';
        position: var(--position-absolute);
        height: 3px;
        left: -10px;
        right: -10px;
        bottom: var(--value-0);
        background-color: var(--Carnation);
      }
    }

    &:before {
      content: '';
      position: var(--position-absolute);
      top: var(--value-0);
      right: -10px;
      bottom: var(--value-0);
      left: -10px;
      background-color: var(--Carnation);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    label {
      color: #2e2e30 !important;
    }

    .journey-tab-icon .material-icons {
      color: var(--white);
    }
  }
}

.journey-group-wrapper {
  position: var(--position-relative);
  padding-right: 40px;
}

.tab-hidden {
  display: none !important;
}

.journeyCount {
  color: #3961c8 !important;
}

.w-134 {
  width: 134px !important;
  min-width: 134px !important;
}

.data-view-toggle {
  margin-left: var(--value-10);

  .p-selectbutton {
    @include flex-align(flex, center);

    .p-button {
      height: 35px;
      width: 45px;
      min-width: 45px;
      padding: var(--value-0);
      position: var(--position-relative);
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $media-breakpoint-xxl-screen-down) {
        height: 35px;
      }

      &:before {
        content: "";
      }

      img {
        max-width: 27px;
      }

      &.p-highlight {
        background: var(--secondary-carnation);
        color: var(--white);

        img {
          filter: invert(1);
        }
      }

      .p-button-label {
        font-size: var(--font-size-13);
        display: var(--display-none);

        @media (max-width: $media-breakpoint-xxl-screen-down) {
          font-size: var(--font-size-12);
        }
      }
    }
  }
}

.grid-color-icon {
  @include flex-center(flex, center, center);
  width: 35px;
  height: 32px;

  .material-icons {
    color: var(--white);
    background-color: var(--secondary-carnation);
    border-radius: 50%;
  }
}

.comparison-wrapper {
  .form-group {
    margin-bottom: var(--value-30);

    label {
      font-size: var(--font-size-12);
      font-family: var(--font-family);
      font-weight: var(--font-weight-500);
      margin-bottom: var(--value-10);
    }
  }

  app-tree {
    width: var(--width-100);

    .p-treeselect .p-treeselect-label-container .p-treeselect-label {
      width: var(--width-100);
    }
  }

  .save-row {
    height: var(--height-100);
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 12px;
    overflow: var(--overflow-hidden);

    .material-symbols-outlined {
      color: var(--Carnation);
      font-size: var(--font-size-26);
      cursor: var(--cursor-pointer);
    }
  }

  .ag-theme-material {
    .ag-center-cols-container {
      border-right: 1px solid #f2f1f3;
    }

    .ag-header {
      margin-left: 1px;
    }

    .ag-row {
      border-bottom: 1px solid var(--white);
    }

    .measure-name {
      background-color: var(--Wild-Sand);
    }

    .measure-value {
      font-weight: var(--font-weight-800);
      background-color: var(--Alto);
      justify-content: flex-end;
    }
  }
}

.form-group {
  &.disabled {
    label {
      opacity: 0.38;
    }
  }
}

.comparison-dialog {
  app-comparison-create {
    .p-dropdown-panel {
      .p-dropdown-header {
        height: 45px;

        .p-dropdown-filter-container {
          height: 30px;
        }
      }
    }

    .p-mdc-dialog-content {
      height: 280px;
    }
  }
}

.pb-130 {
  padding-bottom: var(--value-30);
}

app-measures,
app-constraint {
  .ag-theme-material .ag-cell-inline-editing {
    height: 34px;
    box-shadow: var(--display-none);
    border: none !important;

    .ag-input-field-input {
      height: 34px;
      border-bottom: none !important;
      padding: var(--value-0);
    }
  }
}

.currency-drawer {
  right: var(--value-0);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  transform: translate3d(100%, 0, 0) !important;

  &.mat-drawer-opened {
    transform: none !important;
  }
}

.isCurrentWeek {
  background-color: var(--Razzmatazz) !important;
}

.quadrant-chart {
  @include flex-center(flex, center, center);

  .highcharts-credits {
    display: var(--display-none);
  }
}

.p-tree-wrapper.p-scroller {
  >ul {
    padding-left: var(--value-30);
  }
}

.p-overlay {
  .p-treeselect-panel {
    &.custom-grid-panel-treeselect {
      width: 365px;
    }

    &.custom-grid-panel-multiselect {
      width: 400px;

      .p-treeselect-items-wrapper {
        overflow: visible !important;
      }
    }

    .p-tree {
      .p-tree-container {
        .p-treenode {
          .p-treenode-content {
            padding: 3px 25px;
            min-height: 40px;
            font-size: var(--font-size-11);
          }
        }
      }
    }
  }
}

app-journey-view {

  app-all-journeys,
  app-journey-shared-with-me,
  app-my-journey {
    .journey-grid {
      .ag-ltr {
        .ag-row-group-leaf-indent {
          margin-left: 23px;
        }

        .ag-group-expanded {
          margin-right: 5px;
        }

        .ag-row-group-indent-1 {
          padding-left: 16px;
        }
      }
    }
  }
}

.stock-planner-wrapper {
  padding: var(--value-20);

  .stock-planner-dropdowns {
    app-tree {
      .p-treeselect {
        box-shadow: none !important;
        background-color: var(--gray-100);
        padding: 0 10px !important;
      }
    }
  }

  .btn-back,
  .btn-next {
    @include border-radius(50%);
    padding: var(--value-0);
    width: 30px;
    min-width: 30px;
    height: 30px;
    @include flex-align(flex, center);

    .mat-icon {
      font-size: var(--font-size-18);
      height: 18px;
      width: 18px;
      font-weight: var(--font-weight-600);
    }
  }

  .mat-stepper-vertical {
    .mat-step-header {
      padding: 15px 0;
    }

    .mat-vertical-content-container {
      margin-left: 13px;
    }
  }
}

.item-account-combo-wrapper {
  justify-content: flex-end;

  app-tree {
    .p-treeselect {
      box-shadow: none !important;
      background-color: var(--gray-100);
      padding: 0 10px;
      @include border-radius(5px);
      height: 26px;
    }
  }
}

.active-btn {
  @include flex-justify-end(flex, center, flex-end);
  margin-bottom: 15px;
  margin-top: 15px;

  .btn {
    background: linear-gradient(0deg, #f8486e, #f8486e), #d81ad1;
    color: var(--white) !important;
    box-shadow: 0px 4px 20px rgba(63, 63, 63, 0.1) !important;
    width: 32px !important;
    height: 32px !important;
    margin-left: 5px;
    @include flex-center(flex, center, center);
    cursor: var(--cursor-pointer);
    @include border-radius(50%);
    min-width: 32px;
    padding: var(--value-0);

    .material-symbols-outlined {
      font-size: var(--font-size-22);
    }
  }
}

.replenishment-label {
  .mdc-label {
    width: 100% !important;
  }

  .mdc-form-field {
    width: 100% !important;
  }
}

.pos-checkbox-label {
  .mdc-label {
    width: 100% !important;
  }
}

.warehouse-wrapper {
  .mdc-label {
    width: 100% !important;
  }
}

.warehouse-wrapper {
  .mdc-form-field {
    width: 100% !important;
  }
}

.pricing-wrapper {
  .mat-mdc-checkbox {
    margin-bottom: var(--value-10);
  }
}

.excpetion-tabs-wrapper {
  .mat-mdc-tab-group {
    flex-direction: row;

    .mat-mdc-tab-header {
      border-bottom: var(--display-none);

      .mat-mdc-tab-header-pagination {
        display: none !important;
      }

      .mat-mdc-tab-labels {
        flex-direction: column;

        .mdc-tab {
          flex-grow: 1;
          margin-bottom: 5px !important;
        }
      }
    }

    .mat-mdc-tab-body-wrapper {
      flex: 1 1 auto;
    }
  }

  input[disabled] {
    background-color: var(--silver) !important;
  }
}

.replanishment-dropdowns {
  .p-dropdown {
    @include border-radius(0);
    color: var(--color-30);
    font-weight: normal;
    height: 40px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
    border: var(--display-none);
    margin: var(--value-0);
    padding: 0 20px 0 35px;
    background-color: var(--white);

    @media (max-width: 1800px) {
      height: 35px;
    }
  }
}

app-dc-to-store {
  .ag-theme-material {
    .ag-cell {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }

    .ag-row {
      border-bottom: 2px solid #ffff;
      background-color: rgb(238, 238, 238) !important;

      .ag-cell-wrapper.ag-row-group {
        height: var(--height-100);
        align-items: var(--align-center);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-13);
      }
    }

    .cell-treeview .p-treeselect .p-treeselect-label.p-placeholder {
      text-align: var(--text-center);
      color: white;
      font-size: 12px !important;
      font-weight: 500 !important;
    }

    .ag-pinned-left-header {
      .ag-header-cell {
        app-repl-review-grid-header {
          width: var(--width-100);

          .p-treeselect .p-treeselect-trigger .p-treeselect-trigger-icon {
            color: var(--white);
            font-weight: 500;
          }
        }
      }
    }
  }
}

app-review-tab {
  .ag-theme-material {
    .ag-cell {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }

    .ag-row {
      border-bottom: 2px solid #ffff;
      background-color: rgb(238, 238, 238) !important;

      .ag-cell-wrapper.ag-row-group {
        height: var(--height-100);
        align-items: var(--align-center);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-13);
      }
    }

    .cell-treeview .p-treeselect .p-treeselect-label.p-placeholder {
      text-align: var(--text-center);
      color: white;
      font-size: 12px !important;
      font-weight: 500 !important;
    }

    .ag-pinned-left-header {
      .ag-header-cell {
        padding: var(--value-0);

        app-repl-review-grid-header {
          width: var(--width-100);

          .p-treeselect .p-treeselect-trigger .p-treeselect-trigger-icon {
            color: var(--white);
            font-weight: 500;
          }
        }
      }
    }
  }
}

.replCustomDropdowns {
  &.p-dropdown-panel {
    .p-dropdown-items {
      .p-dropdown-item {
        font-size: var(--font-size-11);
        border-bottom: 1px solid;
        padding-top: 12px;
        padding-bottom: var(--value-10);
        padding-left: var(--value-30);
      }
    }
  }
}

.repl-col {
  flex: 0 0 auto;
  width: 20%;
}

.replenishmentLegend {
  .p-fieldset {
    .p-fieldset-legend {
      display: var(--display-none);
    }
  }
}

.forecast-wrapper {
  .p-fieldset {
    .p-fieldset-legend {
      display: var(--display-none);
    }
  }

  .mdc-label {
    font-size: var(--font-size-12);
    white-space: nowrap;
  }

  .p-disabled {
    background-color: var(--silver);
    opacity: 1;
  }

  .mat-fields {
    margin-right: var(--value-10) !important;
  }

  .mat-mdc-checkbox {
    margin-bottom: 8px;
  }

  input::placeholder {
    font-size: var(--font-size-12);
    font-weight: initial;
  }

  .label-forecast {
    margin-right: 0px !important;
  }
}

.product-wrapper {
  .mdc-label {
    width: 300px !important;
  }
}

.pricing-wrapper {
  .mdc-label {
    width: 300px !important;
  }
}

.pos-wrapper {
  .p-fieldset {
    .p-fieldset-legend {
      display: var(--display-none);
    }
  }

  .mdc-label {
    font-size: var(--font-size-12);
  }

  .mat-fields {
    margin-right: var(--value-10) !important;
  }

  .mat-mdc-checkbox {
    margin-bottom: 15px;
  }

  input::placeholder {
    font-size: var(--font-size-12);
    font-weight: initial;
  }
}

.replCustomDropdownsReadonly {
  &.p-dropdown-panel {
    display: var(--display-none);
  }
}

.replanishment-dropdownsReadonly {
  .p-dropdown {
    @include border-radius(0);
    color: var(--color-30);
    font-weight: normal;
    height: 40px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
    border: var(--display-none);
    margin: var(--value-0);
    padding: 0 20px 0 35px;
    background-color: var(--white);

    @media (max-width: 1800px) {
      height: 35px;
    }

    .p-dropdown-trigger {
      .p-dropdown-trigger-icon {
        display: var(--display-none);
      }
    }
  }
}

app-job-detail-dailog {
  .form-group {
    .p-overlay {
      right: var(--value-0);
    }
  }
}

.hierarchy-setting-item-modal {
  app-account-form {
    .algo-planner-accordion {
      .mat-expansion-panel {
        .mat-expansion-panel-content {
          height: 100% !important;
        }
      }
    }
  }

  app-item-form,
  app-account-form {
    .algo-planner-accordion {
      padding: var(--value-0);

      .mat-expansion-panel {
        box-shadow: var(--display-none);

        .mat-expansion-panel-header {
          display: var(--display-none);
        }

        .mat-expansion-panel-content {
          margin: var(--value-0);
          height: 505px;
          overflow-y: auto;
          padding-right: var(--value-10);
          overflow-x: hidden;
        }

        .mat-expansion-panel-body {
          padding: var(--value-0);
        }
      }
    }
  }
}

.group-icon {
  color: #f44336;
}

.fb-forecast-accordion {
  .group-icon {
    font-size: 15px !important;
  }
}

//paginator
.p-paginator {
  background-color: var(--wild-sand);
  @include border-radius(5px);
  margin-top: var(--value-20);
  margin-bottom: var(--value-20);
  height: 30px;
  padding: var(--value-0);
  @include flex-justify-end(flex, center, flex-end);
  padding-right: var(--value-30);

  .p-paginator-current {
    height: 15px;
    margin: 0 30px;
    padding: var(--value-0);
    color: #0000008a;
    font-size: var(--font-size-13);
  }

  .p-paginator-element {
    height: 30px;
  }

  .p-paginator-pages {
    .p-paginator-page {
      height: 30px;
      font-size: var(--font-size-13);
      color: #0000008a;
      border: var(--display-none);

      &.p-highlight {
        background: #ec41731a !important;
        color: #ec4173 !important;
      }
    }
  }

  .p-dropdown {
    .p-inputtext {
      font-size: var(--font-size-13);
      color: #0000008a !important;
    }
  }
}

.repl-planner-wrapper {
  .ag-theme-material.custom-ag-grid {
    .ag-pinned-left-header {
      .ag-header-row {
        .ag-header-cell:nth-child(1) {
          padding: var(--value-0);
        }
      }
    }
  }
}

.fb-forecast-accordion {
  .p-dropdown {
    .p-dropdown-trigger {
      color: var(--black);
    }
  }
}

.rejectedColor {
  color: red !important;
}

.repl-summary-wrapper {
  .p-treeselect {
    .p-treeselect-trigger {
      .p-treeselect-trigger-icon {
        color: var(--black) !important;
      }
    }
  }
}

.data-view-export {
  min-height: 35px !important;
  color: rgba(0, 0, 0, .87);
  padding: 0 16px;
  width: 100%;
  display: flex;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;

  @media (max-width: 1899px) {
    font-size: 12px;
  }

  .p-button-label {
    text-align: left;
    font-size: 13px;
  }

  .mat-mdc-menu-item-text {
    font-size: 13px !important;

    app-notifications,
    app-main {
      .material-symbols-outlined {
        height: 24px;
        width: 24px;
      }
    }

    app-share-kpi {
      @include flex-column-between(flex, column, space-between);
      height: var(--height-100);

      .form-group {
        @include flex-align(flex, center);

        label {
          width: max-content;
          margin-right: var(--value-20);
          margin-bottom: var(--value-0);
        }
      }
    }
  }
}

app-notifications,
app-main {
  .material-symbols-outlined {
    height: 24px;
    width: 24px;
  }
}

app-share-kpi {
  @include flex-column-between(flex, column, space-between);
  height: var(--height-100);

  .form-group {
    @include flex-align(flex, center);

    label {
      width: max-content;
      margin-right: var(--value-20);
      margin-bottom: var(--value-0);
    }
  }
}

app-notifications,
app-main {
  .material-symbols-outlined {
    height: 24px;
    width: 24px;
  }
}

app-share-kpi {
  @include flex-column-between(flex, column, space-between);
  height: var(--height-100);

  .form-group {
    @include flex-align(flex, center);

    label {
      width: max-content;
      margin-right: var(--value-20);
      margin-bottom: var(--value-0);
    }
  }
}

//line chart css
.line-chart {
  .line-series {
    .ng-tns-c3170173079-72 {
      .line {
        stroke: #156082 !important;
      }
    }
  }

  .circle.ng-tns-c729575222-80 {

    .circle-data-0circle,
    .circle-data-1circle,
    .circle-data-2circle {
      fill: #156082 !important;
    }
  }
}

app-selection-table {
  ag-grid-angular {
    app-action-renderer {
      .action-renderer-icons {
        .material-symbols-outlined {
          box-shadow: none !important;
          background: transparent;
          font-size: 35px;
          height: auto !important;
          width: 100% !important;
          margin: 0 auto;
        }
      }
    }
  }
}

.invalid-feedback {
  color: var(--Punch);
  /* Red text */
  font-size: 0.75em;
  display: block;
}

.selection-dropdowns {
  .build-dropdown-wrapper {
    width: 355px;
  }

  .measure-icon-wrapper {
    margin-right: 5px;
  }

  .measure-icon-wrapper {
    margin-right: 5px;
  }

  .btn-radius-wrapper {
    width: 110px;
    display: var(--display-block);
  }
}

.kpi-drawer-wrapper {
  .show-search-wrapper {
    background-color: var(--gray-100) !important;
    border-radius: 5px;
    padding-left: var(--value-10);
  }
}

.input-field-wrapper {
  .form-group {
    display: var(--display-block) !important;
  }

  .profile-form-wrapper {
    width: 100% !important;
    border: none !important;
    height: 26px !important;
    text-align: var(--text-left) !important;
    background-color: var(--gray-100) !important;
    border-radius: 5px;
  }
}

.ranging-search {
  .mat-mdc-form-field-icon-suffix>.mat-icon {
    padding-right: 2px;
  }
}

app-button {
  .p-disabled {
    opacity: 1;
  }
}

action-bar {
  .search-bar {
    width: 200px;
    display: flex;
    justify-content: center;
  }

  .input-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    app-input {
      width: 100%;

      .search-input {
        padding-right: 28px !important;
      }
    }
  }

  .search-icon {
    position: absolute;
    right: 0px;
    cursor: pointer;
    font-size: 20px;
    top: 4px;
    width: 32px;
    padding: 0 5px;
    // .mat-icon {
    //   width: 32px;
    //   padding: 0 5px;
    // }
  }

  input {
    padding-right: 40px;
  }
}

.p-tooltip {
  .p-tooltip-text {
    padding: 4px 8px;
    min-width: 40px;
    max-width: 200px;
    min-height: 24px;
    max-height: 40vh;
    text-align: center;
    background: #616161;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    margin: 3px;
  }
}

.ranging-search {
  .mat-mdc-form-field-icon-suffix>.mat-icon {
    padding-right: 2px;

    app-input {
      width: 100% !important;

      .ranging-search {
        .mat-mdc-form-field-icon-suffix>.mat-icon {
          padding-right: 2px;
        }
      }
    }

  }
}

app-input,
app-calender {
  width: 100% !important;
}

app-button {
  .p-disabled {
    opacity: 1;
  }
}

app-textarea {
  width: 100%;
}

// .p-checkbox {
//   .p-checkbox-box {
//     border: 1px solid var(--btn-disabled);
//     width: 16px;
//     height: 16px;

//     &.p-highlight {
//       border-color: var(--btn-disabled) !important;
//       background: transparent !important;
//       width: 16px;
//       height: 16px;

//       checkicon {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         position: relative;

//         &:after {
//           content: "";
//           background-image: url("../assets/images/fa-solid_check.png");
//           background-size: contain;
//           background-repeat: no-repeat;
//           background-position: center;
//           width: 10px;
//           height: 10px;
//         }

//         .p-checkbox-icon {
//           display: none;

//         }
//       }
//     }
//   }
// }

.p-checkbox {
  box-shadow: none !important;

  .p-checkbox-box {
    width: 16px;
    height: 16px;

    &.p-highlight {
      border-color: #ff4081;
      background: #ff4081;
    }
  }
}


.input-switch-wrapper {
  .input-switch-label {
    display: flex;
    align-items: center;
  }
}

p-inputswitch {
  height: 24px;

  .p-inputswitch {
    width: 38px;
    height: 24px;

    &.p-inputswitch-checked {

      .p-inputswitch-slider:before {
        background: var(--secondary-carnation);
        transform: translateX(1.1rem);
        left: -1px;
      }
    }

    .p-inputswitch-slider {
      background: #F4F4F4;
      border-radius: 20px;

      &:before {
        background: var(--primary-purple);
        width: 18px;
        height: 18px;
        margin-top: -9px;
        box-shadow: none;
        left: 2px;
      }
    }
  }
}



//p-tabview
.p-tabview {
  .p-tabview-nav-btn.p-link {
    color: #000;
    width: 35px;
    height: 35px;
    box-shadow: none;
  }

  .p-tabview-nav {
    border: solid #dddddd;
    margin-bottom: var(--value-10);
    padding: 0;
    border-width: 0 0 1px 0;
    height: 35px;

    .p-tabview-nav-link {
      height: 35px !important;
      margin-right: 5px;
      font-size: var(--font-size-12);
      width: 200px;
      min-width: 200px;
      max-width: 200px;
      padding: var(--value-0);
      background: var(--tab-default);
      border-radius: 5px 5px 0 0;
      color: var(--black) !important;
      letter-spacing: 1px;
      font-weight: var(--font-weight-500);
      border: none;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &[aria-selected="true"] {
        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: var(--primary-purple);
        }
      }
    }
  }

  .p-tabview-ink-bar {
    display: none;
  }

  .p-tabview-panels {
    padding: 0;
    border-radius: 0;
  }
}

//calender
.p-datepicker {
  .p-datepicker-buttonbar{
    padding: 8px 0;
    .p-button.p-button-text{
      font-size: var(--font-size-12);
      width: 60px;
      height: 35px;
      padding: 0;
      &:first-child{
        color: var(--white);
        background-color: var(--secondary-carnation);
        @include border-radius(5px);
        @include flex-align(flex, center);
        border-color: transparent;
      }
      &:last-child{
        background-color: transparent;
        color: var(--secondary-carnation);
        @include border-radius(5px);
        border: 3px solid var(--secondary-carnation);
      }
    }
  }
  .p-datepicker-header {
    font-size: 14px;

    .p-datepicker-prev,
    .p-datepicker-next {
      height: 20px;
    }

    .p-datepicker-title {
      line-height: 0;
    }
  }

  table {
    td>span {
      width: 30px;
      height: 30px;
      font-size: 14px;
    }
  }
}

.p-overlaypanel {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);

  .p-overlaypanel-content {
    padding: 8px 0;
  }
}

.p-scrolltop {
  background: #fff;
  border-radius: 50px;
  width: 130px;
  height: 40px;
  right: 0px;
  bottom: 30px;
  animation: bounce2 2s ease infinite;

  &:before {
    content: "";
    background-image: url("../assets/images/arrow-back-to-top.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 32px;
    height: 32px;
    position: absolute;
    left: 4px;
  }

  &:after {
    content: "Back to Top";
    position: absolute;
    left: 42px;
    color: #000000;
    font-size: 14px;
    top: 8px;
  }

  .p-icon-wrapper {
    display: none;
  }
}

.p-stepper.p-stepper-vertical {
  .p-stepper-panel {
    .p-stepper-header {
      padding: 0 0 10px 5px;
      font-size: 12px;
    }

    .p-stepper-separator {
      margin-inline-start: 10px;
      background-color: rgba(0, 0, 0, .12);
      width: 1px;
    }
  }
}

// .p-radiobutton {
//   width: 16px;
//   height: 16px;

//   .p-radiobutton-box {
//     border: 1px solid #D9D1E0;
//     width: 16px;
//     height: 16px;

//     &.p-highlight {
//       border-color: #D9D1E0 !important;
//     }

//     .p-radiobutton-icon {
//       width: 6px;
//       height: 6px;
//     }
//   }
// }


.custom-overlay-panel {
  min-width: 112px;
  max-width: 280px;
  overflow: auto;
}

.p-sidebar {
  .p-sidebar-header {
    position: absolute;
    right: 20px;
    top: 11px;
    padding: 0;
    z-index: 1;

    .p-sidebar-icon {
      width: 24px;
      height: 24px;
    }
  }

  .p-sidebar-content {
    padding: 0;
  }
}


app-editor {
  height: 200px;
  display: block;

  .p-editor-container {
    border: 1px solid #eee;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 0;
    height: 200px;

    .ql-toolbar.ql-snow {
      border: none;
      border-bottom: 1px solid #eee;
    }

    .p-editor-content.ql-snow {
      border: none;
      height: 150px !important;
      border-radius: 0;

      .ql-editor {
        border-radius: 0 !important;
      }
    }
  }
}

.ql-mention-list-container {
  max-height: 437px;
  width: 400px;
  padding: 0 4px;

  .ql-mention-list-item {
    padding: 4px 8px;
    display: flex;
    align-items: center;
    font-size: 13px;
    max-height: 32px;
    line-height: 32px;
    border-radius: 4px;

    &.selected {
      background-color: #cce2fa;
    }
  }
}

.chat-profile-wrapper {
  .mat-mdc-icon-button {
    padding: 0;
    height: 24px;
    width: 24px;
  }
}

.p-paginator {
  .p-inputwrapper {
    width: auto;
  }
}

.permission-table {
  .table {
    app-input-switch {
      padding: 0;
      display: flex;
    }
  }
}

.kpi-card {
  &.kpi-card1 {
    kpi-card {
      .kpi-card {
        border: 2px solid var(--primary-purple);

        .cardkpi-header {
          background-color: var(--primary-purple);
        }
      }
    }
  }

  &.kpi-card2 {
    kpi-card {
      .kpi-card {
        border: 2px solid #1B9FAC;

        .cardkpi-header {
          background-color: #1B9FAC;
        }
      }
    }
  }

  &.kpi-card3 {
    kpi-card {
      .kpi-card {
        border: 2px solid #D51A52;

        .cardkpi-header {
          background-color: #D51A52;
        }
      }
    }
  }

  &.kpi-card4 {
    kpi-card {
      .kpi-card {
        border: 2px solid #FF9500;

        .cardkpi-header {
          background-color: #FF9500;
        }
      }
    }
  }
}


.p-fieldset-custom {
  .p-fieldset {
    border: none !important;
    margin: 30px 20px 0 !important;

    .p-fieldset-legend {
      padding: 0 !important;
      width: 100% !important;
      display: flex;

      a {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        border-top: 2px solid rgb(216, 26, 209);
        border-radius: 0;
        padding-top: 10px;
        padding-right: 10px;
        padding-left: 0;
        padding-bottom: 0;
        color: var(--black);
        font-weight: var(--font-weight-500);
        font-size: 16px;
        height: 50px;

        &:focus {
          background: none;
        }

        .p-icon-wrapper {
          display: none;
        }

        .custom-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
      }
    }
  }
}

.sidenav-panel-custom {
  .p-panel {
    border-radius: 0;
    box-shadow: none;

    .p-panel-content {
      padding: 0;

      .favorite-item {
        height: 35px;
        padding-left: 22px;
        background-color: var(--gray-100);
        padding-right: 18px;
        font-size: 11px;

        .pi {
          font-size: 10px;
          font-weight: bold;
        }
      }
    }

    .p-panel-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px 0 15px;
      border-bottom: 1px solid #b9b9b9;
      height: 30px;
      border-radius: 0;
      font-size: 10px;
      color: #000;
      cursor: pointer;

      @media (min-width: 1900px) {
        height: 40px;
        font-size: 12px;
      }

      &:hover {
        background-color: var(--mercury) !important;

        .pi {
          background-color: var(--gray-100);
        }
      }

      .pi {
        font-size: 9px;
        font-weight: bold;
        color: #000 !important;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      .p-panel-icons {
        display: none;
      }
    }
  }

}

.pi {
  cursor: pointer;
}

.p-datatable-table {

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: #f2f1f3;
    border-width: 1px;
  }

  .p-datatable-thead>tr>th {
    padding: 8px 12px;
    border: 1px solid #f2f1f3;
    color: #fff;
    background: var(--primary-purple);
    font-size: 12px;
  }

  .p-datatable-tbody>tr>td {
    font-size: 12px;
    padding: 8px 12px;
    border: 1px solid #f2f1f3;
  }
}

.item-search-wrapper {
  .form-group {
    input {
      padding-right: 30px !important;
    }
  }

}

.save-selection-input {
  input {
    height: 35px;
    width: 100%;
    margin-bottom: 20px;
    border: none;
    background-color: #f4f4f4;
    padding: 0 12px;
  }
}

.p-overlaypanel {

  &:after,
  &:before {
    display: none;
  }
}

.p-fileupload {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 0;

  .p-fileupload-buttonbar {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-top: 1px solid #e0e0e0;

    .p-button {
      background: var(--secondary-carnation)
    }
  }

  .p-fileupload-content {
    border: none;
    overflow-y: auto;
    height: 270px;
    padding: 12px;

    .p-fileupload-row {
      margin-bottom: 20px;

      >div {
        padding: 0;
        font-size: 14px;
      }

      .p-button {
        background: var(--secondary-carnation);
        padding: 0;
        width: 30px;
        height: 30px;
        border-radius: 4px;
      }
    }
  }
}

.p-fieldset-legend-text{
  @media (max-width: 1800px){
    font-size: 12px;
  }
}

.logs-dialog{
  @media (max-width: 1200px){
    width: 100% !important;
  }
}

.measure-ag-grid{
  .ag-theme-material{
    .ag-header{
      margin-left: 1px;
      margin-bottom: 2px;
    }
    .ag-pinned-left-header{
      .ag-header-cell{
        padding: 0;
        .ag-header-cell-comp-wrapper{
          display: none;
        }
      }
    }
    .ag-pinned-left-cols-container{
      .ag-row{
        border: none;
        .ag-cell{
          padding: 0;
        }
      }
    }
  }
}