@import 'mixins';
//light theme css
.algo-light-theme {
  .page-login {
    input.mat-input-element {
      color: var(--black);
    }
  }

  .container-fluid:fullscreen {
    background-color: var(--white);
    overflow: auto;
  }

  input.mat-input-element {
    color: var(--black);
  }

  .sideBar {
    ul {
      li {

        &:hover,
        &.active {
          background-color: var(--white);
        }

        .material-icons {
          color: var(--black);
        }
      }
    }
  }

  .mat-select-placeholder {
    color: var(--white);
  }

  .ag-theme-material {
    .ag-header {
      background-color: var(--primary-purple);

      .ag-header-cell {
        background-color: var(--primary-purple);
        border-right: var(--display-none);
      }

      .ag-header-cell-label .ag-header-cell-text {
        color: var(--black);
      }
    }

    .ag-header-group-text {
      color: var(--black);
    }

    .ag-icon {
      color: var(--black);
    }

    .ag-header-viewport {
      border-left: var(--display-none);
      border-bottom: 2px solid var(--French-Rose);
    }
  }

  .user-page {
    .mat-mdc-form-field {
      .mat-icon {
        color: var(--black);
      }
    }
  }

  .e-treeview .e-list-item div.e-icons::before {
    color: var(--black);
  }

  .e-list-parent {
    background-color: var(--white);

    .e-list-item {
      background-color: var(--Alabaster);
    }
  }

  .right-chat,
  .response-inner {
    span {
      color: #00000073;
    }
  }

  .right-chat .right-icon mat-icon,
  .right-chat .right-icon p {
    color: var(--black);
  }

  .darker {
    background-color: var(--color-indigo);

    p {
      color: var(--black);
    }
  }

  .darker:before {
    border-color: #fcdbe2 transparent transparent transparent;
  }

  .response-back {
    background-color: var(--Concrete);
  }

  .response-back:before {
    border-color: transparent #f3f3f3 transparent transparent;
  }

  .mat-drawer-container {
    background-color: transparent !important;
  }

  .algo-sidenav {
    background-color: var(--white);

    .mat-expansion-panel {
      .mat-expansion-panel-header {
        .mat-expansion-indicator:after {
          color: var(--black);
        }
      }
    }

    .mat-expansion-panel-header {
      &:hover {
        .mat-expansion-panel-header-title {
          display: var(--display-flex);
          white-space: normal;
        }
      }
    }

    .mat-expansion-panel-header-title {
      color: var(--black) !important;
      max-width: 185px;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: var(--display-block);
      overflow: var(--overflow-hidden);

      &:hover {
        display: var(--display-flex);
        white-space: normal;
      }
    }

    .single-link {
      .mat-mdc-list-item {
        color: var(--black);
      }
    }

    .arrow {
      color: var(--black);
    }
  }

  .mat-accordion {
    .mat-expansion-panel-header {
      font-family: var(--font-family);
      background-color: var(--gray-100);
      @include border-radius(0);
      box-shadow: 0px 4px 7px rgb(63 63 63 / 10%);

      .mat-expansion-panel-header-title {
        color: var(--black);
        font-weight: var(--font-weight-500);
        font-size: var(--font-size-18);
        font-family: var(--font-family);
        height: 60px;

        @media (max-width: $media-breakpoint-xxl-screen-down) {
          font-size: var(--font-size-12);
        }
      }

      .mat-expansion-indicator {
        &:after {
          color: var(--Ebony);
        }
      }
    }
  }

  &.mat-expanded {
    transition: all ease-in-out 0.3s;
    background-color: var(--gray-100);

    &:focus,
    &:hover {
      transition: all ease-in-out 0.3s;
      background-color: var(--gray-100);
    }

    .mat-expansion-panel-header-title {
      transition: all ease-in-out 0.3s;
      color: var(--black);
    }
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    box-shadow: 0px 4px 7px rgba(63, 63, 63, 0.1);
    transition: all ease-in-out 0.3s;

    .mat-expansion-panel-header-title {
      transition: all ease-in-out 0.3s;
      color: var(--black);
    }
  }

  .response-inner-div {
    color: var(--black);
  }
}

//dark theme css
.algo-dark-theme {
  background-color: var(--black);

  .tenants-users .sidenav-icon .sidenav-icons img {
    filter: invert(1);
  }

  .creat-kpi-wrapper .add-filter {
    color: var(--white);
  }

  .alert-notif-text {
    color: var(--white);
  }

  app-notifications .notification-alert .notifications-alerts .mat-tab-header .mat-tab-label {
    background: #655f5f;
  }

  .notification-alert .notification-wrapper {
    background-color: var(--black);
  }

  .notification-alert .notification-desc ul {
    color: var(--white);
  }

  .notification-alert .notification-desc .notification-desc-info .time {
    color: var(--white);
  }

  .welcome-dashboard {
    background-color: var(--black);
    border-bottom: 1px solid var(--white);

    .material-icons {
      color: var(--white);
    }
  }

  .home-base-list {
    ul {
      li {
        p {
          color: var(--white) !important;
        }
      }
    }
  }

  .home-base-graph {
    background: var(--black) !important;

    a {
      color: var(--white) !important;
    }
  }

  .home-base-right-panel {
    background-color: var(--Woodsmoke) !important;
  }

  .favorites-list h3,
  .tasks-list h3,
  .name-list h3 {
    background-color: var(--Woodsmoke);
    border-bottom: 1px solid var(--white);
  }

  .layout-icon .layout-wrapper {
    background-color: var(--Woodsmoke);
  }

  .mat-checkbox-frame {
    border-color: var(--white);
  }

  .fb-dialog-wrapper .mat-mdc-dialog-container .p-mdc-dialog-content .radio-wrapper .mat-checkbox:nth-child(odd),
  .fb-dialog-wrapper .mat-mdc-dialog-container .p-mdc-dialog-content .inner-data .main-label:nth-child(odd) {
    background-color: var(--Woodsmoke);
  }

  .form-group>label {
    color: white !important;
  }

  .item-wrapper {
    .mat-tab-header {
      .mat-tab-label {
        color: rgba(white, 0.4);
        background-color: var(--mine-shaft);

        &.mat-tab-label-active {
          color: white;
        }
      }
    }

    .form-group {
      input {
        background-color: var(--black) !important;
        border: 2px solid var(--white) !important;
        color: white;

        &::placeholder {
          color: white;
        }
      }
    }
  }

  .input-search-icon {
    color: white !important;
  }

  .item-search-wrapper {
    border-bottom: 2px solid #d71ad1;

    .item-search-btn {
      i {
        color: white;
      }
    }
  }

  app-name-renderer {
    a {
      color: var(--secondary-carnation) !important;
    }
  }

  .item-search-wrapper {
    .form-fields {
      .mat-mdc-form-field-appearance-legacy {
        .mat-mdc-form-field-flex {
          background-color: transparent;
          // border: 1px solid var(--white);
          padding-right: 5px;
          padding-left: 5px;
          @include border-radius(5px);
        }

        .mat-icon {
          color: white;
        }
      }
    }
  }

  .dashboardImage {
    display: var(--display-none);
  }

  .dashboardImageDark {
    display: var(--display-block);
  }

  .fb-forecast-accordion {
    .mat-expansion-panel {
      .mat-expansion-panel-header {
        background-color: transparent !important;
        border-top: var(--display-none);
      }
    }
  }

  .summary-form-view {

    b,
    label {
      color: var(--white);
    }
  }

  .left-dropdown {
    .scrolling-wrapper {
      background-color: var(--black);
      height: 50px;
      border: 1px solid var(--white);

      .text {
        color: var(--white);
      }

      .scrolling-dropdown {
        .dropdown .btn {
          color: var(--black) !important;
          border: none !important;
        }
      }
    }
  }

  #fb-forecast-new-planner {
    padding: var(--value-0);
    background-color: var(--black);

    .item-wrapper.fb-forecast-planner {
      h3 {
        color: var(--white);
      }
    }

    .item-wrapper {
      .form-group {
        label {
          color: var(--white);
        }
      }
    }

    .dropdown .btn,
    .btn-wrapper .mat-select.disti-dropdown {
      background-color: var(--black) !important;
      color: var(--white);
      border: 1px solid var(--white);
    }

    .btn-wrapper .mat-select.disti-dropdown .mat-select-trigger .mat-select-placeholder,
    .btn-wrapper .mat-select.disti-dropdown .mat-select-trigger .mat-select-value-text,
    .btn-wrapper .mat-select.disti-dropdown .mat-select-trigger .mat-select-arrow {
      color: var(--white) !important;
    }
  }

  .form-control {
    background-color: var(--black) !important;
    color: var(--white) !important;
  }

  .mat-slide-toggle {
    .mat-checked {
      .mat-slide-toggle-thumb {
        background-color: var(--secondary-carnation) !important;
      }
    }
  }

  .mat-slide-toggle-content,
  input.mat-input-element,
  .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
    color: var(--white);
  }

  input.mat-input-element {
    &::placeholder {
      color: white;
    }
  }

  .mat-drawer .company-user-name .mdc-button {
    background-color: var(--black);
  }

  .mat-drawer .company-user-name .mdc-button .mdc-button-wrapper .icon-user {
    color: var(--white);
  }

  .mat-drawer .company-user-name .mdc-button .mdc-button-wrapper .material-icons {
    color: var(--white);
  }

  .page-head,
  .page-head-cred {
    background-color: var(--black);
    border-top: 1px solid #666666;

    .mat-mdc-form-field {
      background-color: var(--black);
    }

    .material-icons {
      color: var(--white);
    }
  }

  .sideBar {
    ul {
      li {

        &:hover,
        &.active {
          background-color: var(--black);
        }

        .material-icons {
          color: var(--white);
        }
      }
    }
  }

  .all-show-grid {
    .ag-theme-material.custom-ag-grid {
      .ag-pinned-left-header {
        .ag-header-row {
          .ag-header-cell {
            &:nth-child(1) {
              background-color: var(--black) !important;
            }
          }
        }
      }

      .ag-center-cols-clipper {
        .ag-cell {
          margin-left: 1px;

          &::after {
            border: 5px solid #4a4848;
          }
        }

        .ag-cell.disabled-input {
          background-color: var(--black) !important;
        }
      }

      .ag-center-cols-viewport {
        .ag-row {
          .ag-cell {
            color: var(--black);
            font-weight: var(--font-weight-500);
          }
        }
      }

      .ag-header-cell {
        app-grid-custom-header {
          .mat-select {
            background-color: var(--black);
          }

          .mat-select-placeholder {
            color: #f3f3f3 !important;
          }
        }
      }
    }
  }

  .ag-theme-material {
    .ag-header {
      background-color: var(--primary-purple) !important;
      background-image: linear-gradient(0deg, #100408 0%, #7d157f 100%);

      .ag-header-cell {
        background-color: var(--primary-purple) !important;
        background-image: linear-gradient(0deg, #100408 0%, #7d157f 100%);
        border-right: 1px solid #969497 !important;
      }

      .ag-header-cell-label .ag-header-cell-text {
        color: var(--white);
      }
    }

    .ag-cell {
      margin-left: -1px;
      border-top: 1 !important;
      border-color: #969497;
    }

    .ag-pinned-left-cols-container {
      margin-left: -1px;
    }

    .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
      margin-left: var(--value-0);
    }

    .ag-header-group-text {
      color: var(--white);
    }

    .ag-paging-panel {
      background-color: var(--mine-shaft);

      .ag-paging-button {
        .ag-icon {
          color: #e7dddd;
        }
      }

      .ag-paging-row-summary-panel {
        background-color: var(--mine-shaft);
        color: var(--white);
      }

      .ag-paging-page-summary-panel {
        background-color: var(--mine-shaft);
        color: var(--white);
      }
    }

    .ag-icon {
      color: var(--French-Rose);
    }

    .ag-header-viewport {
      border-left: var(--display-none);
      border-bottom: 2px solid var(--French-Rose);
    }

    .ag-root-wrapper {
      background-color: var(--Cod-Gray);
    }

    .ag-body-viewport .ag-center-cols-clipper {
      background-color: var(--mine-shaft);
    }

    .ag-center-cols-viewport {
      background-color: var(--black);

      .ag-row {
        border-color: #969497 !important;

        .ag-cell {
          color: var(--white) !important;
          background-color: var(--Cod-Gray);
          // border: var(--display-none);
        }
      }
    }
  }

  .user-page {
    .mat-mdc-form-field {
      .mat-icon {
        color: var(--white);
      }
    }
  }

  .e-treeview .e-list-item div.e-icons::before {
    color: var(--white);
  }

  .menu .menu-toggle {
    background-color: var(--white);
  }

  .menu {
    .menu-toggle {
      &.menu-show {
        .icon-more-vertical:before {
          color: var(--black);
        }
      }

      .icon-more-vertical {
        color: var(--black) !important;
      }
    }
  }

  .e-list-parent {
    // background-color: var(--Tuatara:);

    .e-list-item {
      background-color: var(--black);
    }
  }

  .bot-accordian .e-list-parent .e-text-content .e-list-text .action-panel p {
    color: var(--white);
  }

  .bot-accordian .action-panel.top .form-heading {
    color: var(--white);
  }

  .mat-expansion-panel {
    background: var(--black);
  }

  .right-chat,
  .response-inner {
    span {
      color: var(--white);
    }
  }

  .right-chat .right-icon mat-icon,
  .right-chat .right-icon p {
    color: var(--white);
  }

  .darker {
    background-color: var(--black);
    border: 1px solid var(--French-Rose);

    p {
      color: var(--white);
    }
  }

  .darker:before {
    border-color: #000 transparent transparent transparent;
  }

  .response-back {
    background-color: var(--black);
    border: 1px solid #9b0b97;
  }

  .response-back:before {
    border-color: transparent #000 transparent transparent;
  }

  .response-back:before {
    border-color: transparent #000 transparent transparent;
  }

  .response-inner-div {
    color: var(--white);
  }

  .mat-drawer-container {
    background-color: transparent !important;
  }

  .algo-sidenav {
    background-color: var(--black);

    .mat-expansion-panel {
      .mat-expansion-panel-header {
        background-color: var(--black);

        .mat-expansion-indicator:after {
          color: var(--white);
        }
      }
    }

    .mat-expansion-panel-header-title {
      color: var(--white) !important;
      display: var(--display-block);
      overflow: var(--overflow-hidden);
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .single-link {
      .mat-mdc-list-item {
        color: var(--white);
      }
    }

    .arrow {
      color: var(--white);
    }
  }

  .mat-accordion {
    .mat-expansion-panel-header {
      background-color: var(--Mardi-Gras) !important;
      box-shadow: 0px 4px 7px rgba(63, 63, 63, 0.1);
      z-index: var(--z-index-1);
      border-bottom: 1px solid rgb(125 9 120);
      margin-top: 3px;
    }
  }

  &.mat-expanded {
    transition: all ease-in-out 0.3s;
    background-color: var(--black);

    &:focus,
    &:hover {
      transition: all ease-in-out 0.3s;
      background-color: var(--black);
    }

    .mat-expansion-panel-header-title {
      transition: all ease-in-out 0.3s;
      color: var(--white);
    }
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    box-shadow: 0px 4px 7px rgba(63, 63, 63, 0.1);
    transition: all ease-in-out 0.3s;

    .mat-expansion-panel-header-title {
      transition: all ease-in-out 0.3s;
      color: var(--black);
    }
  }

  .mat-expansion-panel {
    .mat-expansion-panel-header {
      .mat-expansion-panel-header-title {
        color: var(--white) !important;

        &:hover {
          background: none !important;
        }
      }

      .mat-expansion-indicator:after {
        color: var(--white);
      }
    }
  }

  .right-sidenav #rightSidenav .mat-drawer-content {
    background-color: var(--black);
  }

  .setting-icon,
  .favorites .fav-icon .mat-icon,
  .product-wrapper h3.title,
  .mat-select-value,
  .form-group .mat-select .mat-select-arrow,
  .success-text p,
  .ag-overlay-no-rows-center,
  .new-item-planner h2,
  .mat-select-placeholder,
  .mat-select-arrow,
  .item-wrapper.fb-forecast-planner app-treeview-dropdown .dropdown .btn:hover,
  #fb-forecast-new-planner .dropdown .dropdown-menu .row-all .col-12 i,
  #fb-forecast-new-planner .dropdown .treeview-container .row-item i:before,
  .item-wrapper.fb-forecast-planner app-treeview-dropdown .dropdown .dropdown-toggle[aria-expanded="true"],
  .mat-mdc-option,
  .mat-pseudo-checkbox,
  #fb-forecast-new-planner .form-check-input+label,
  #fb-forecast-new-planner .dropdown .treeview-container .row-item,
  .item-wrapper.insight-wrapper .fb-forecast-accordion .mat-expansion-panel-header-description a,
  .item-wrapper .form-group i,
  .permission-wrapper h3,
  .item-wrapper ul li a {
    color: var(--white) !important;
  }

  .mat-icon,
  i,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  label {
    color: var(--white) !important;
  }

  .product-wrapper .single-link {
    background-color: var(--black);
  }

  .item-wrapper {
    .info {
      background-color: var(--black);
      border: 1px solid var(--white);
    }

    .labels h4 {
      color: var(--white);
    }
  }

  .item-wrapper .form-group .user-data {
    background-color: var(--black);
    color: var(--white);
  }

  .permission-table {
    .table td {
      color: var(--white) !important;
    }
  }

  .item-wrapper.insight-wrapper .fb-forecast-accordion .mat-expansion-panel-header-description a:first-child {
    border-right: 1px solid var(--white);
  }

  #fb-forecast-new-planner .form-check-input+label:before {
    background-color: var(--black);
    border: 1px solid white;
  }

  .item-wrapper .form-group .mat-select {
    background-color: var(--black);
    border: 1px solid white;

    .mat-select-arrow {
      color: var(--white) !important;
    }

    .form-group.time-dropdown app-treeview-dropdown .dropdown .btn {
      background-color: var(--black) !important;
    }
  }

  #fb-forecast-new-planner .dropdown .dropdown-toggle:before {
    color: var(--white) !important;
  }

  .dropdown button:after {
    color: var(--white) !important;
  }

  .item-wrapper .form-group.time-dropdown app-treeview-dropdown .dropdown .btn,
  #fb-forecast-new-planner .dropdown .dropdown-menu,
  .mat-select-panel {
    background-color: var(--black) !important;
  }

  .item-wrapper.fb-forecast-planner .form-group input {
    background-color: var(--black) !important;
    color: var(--white);
    border: 1px solid var(--white) !important;

    &::placeholder {
      color: white;
    }
  }

  .data-wrapper .mat-select {
    background-color: var(--secondary-carnation) !important;
  }

  .mat-mdc-dialog-container {
    background: var(--black) !important;
    color: var(--white);
    border: 1px solid #1a1717;
  }

  #fb-forecast-new-planner .dropdown .dropdown-menu .dropdown-container .treeview-header .form-control {
    background-color: var(--black);
    color: var(--white);

    &::placeholder {
      color: var(--white);
    }
  }

  .mat-tab-label-content {
    color: var(--white) !important;
  }

  .ag-cell {
    background-color: var(--black);
    color: var(--white);
  }

  .treeview-text {
    color: var(--white);
  }

  .btn.btn-light {
    background-color: var(--black);
    border: 1px solid var(--white);
    color: var(--white);
  }

  #fb-forecast-items-attribute .form-group.tree-view app-dropdown-treeview-select .dropdown .btn {
    background-color: var(--black) !important;
    color: var(--white) !important;
    border: 1px solid var(--white);
  }

  #fb-forecast-items-attribute .form-group.tree-view .dropdown .dropdown-menu {
    background-color: var(--black);
    color: var(--white);
    border: 1px solid var(--white);
  }

  #fb-forecast {
    .mat-icon-button {
      color: black;
      background-color: white;
    }
  }

  // .ag-theme-alpine-dark {
  //   &.custom-ag-grid {
  //     .ag-header-cell {
  //       app-grid-custom-header {
  //         .mat-select {
  //           .mat-select-arrow {
  //             border-right: 2px solid var(--white) !important;
  //             color: white !important;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  ngx-dropzone {
    background: var(--black) !important;
    color: var(--white) !important;
  }

  .loader-inner {
    display: var(--display-none);
  }

  .loader-inner-dark {
    display: var(--display-flex);

    p.icon {
      background-color: white;
    }

    p.icon:before {
      background-color: black;
    }

    p.icon:after {
      background-color: white;
    }
  }

  .right-sidenav {
    mat-drawer.mat-drawer {
      background-color: var(--black) !important;

      .notes-head {
        background-color: var(--black) !important;
      }
    }

    #rightSidenav {
      // .mat-drawer-content {
      //   background-color: var(--black) !important;
      //   border-left: 1px solid #666666 !important;
      // }
    }
  }

  .notification-alert {
    .notification-desc {
      border: 1px solid var(--white) !important;
    }

    .mat-mdc-tab-header {
      .mat-mdc-tab {
        .mdc-tab__content {
          .mdc-tab__text-label {
            color: var(--white) !important;
          }
        }

        &.mdc-tab--active {
          border-bottom: 2px solid #e1d9da;
        }
      }
    }

    .notifications-alerts {
      background-color: var(--black) !important;
      border: 1px solid var(--white) !important;

      .notification-desc {
        background-color: var(--black);

        &.unread {
          background-color: var(--color-2d);
        }

        .notification-desc-info {
          .desc {
            color: #efff;
          }
        }
      }

      .all-notify {
        background: linear-gradient(0deg, #2c161c 0%, #661967 100%);
        height: 45px;
        color: var(--white) !important;
      }

      .notification-head {
        background-color: var(--black) !important;
        background-image: linear-gradient(90deg, #000 0%, #000 100%) !important;

        .notification-cancel {
          .material-icons {
            color: var(--white) !important;
          }
        }
      }

      .item-wrapper {
        .form-group {
          .mat-mdc-text-field-wrapper {
            .mat-mdc-form-field-icon-suffix {
              right: -12px !important;
            }
          }
        }
      }
    }
  }

  .notes-inner-body {
    .notes-action-btn {
      .btn {
        background-color: var(--black);
        color: var(--white);

        &:hover {
          .icon-add {
            background-color: var(--black) !important;
          }
        }
      }
    }

    .notes-final-view .mat-card,
    .notes-list .mat-card {
      background: var(--black) !important;
    }

    .notes-final-view .mat-card .mdc-button,
    .notes-list .mat-card .mdc-button {
      background-color: var(--white) !important;
    }
  }

  .p-dropdown {
    border: 1px solid var(--white) !important;
    background-color: var(--black);
    background: var(--black);

    &:hover {
      border-color: white !important;
    }

    .p-dropdown-panel {
      background: black;
      color: white;
      border: 1px solid white !important;
    }

    .p-inputtext {
      color: white !important;
    }

    .p-dropdown-items .p-dropdown-item {
      color: white;
    }

    .p-dropdown-header {
      background: black;
      border-bottom: 1px solid white;

      .p-dropdown-filter-icon {
        color: white !important;
      }
    }

    .p-dropdown-trigger .p-dropdown-trigger-icon {
      color: white;
    }
  }

  .p-multiselect {
    border: 1px solid var(--white);
    background-color: var(--black);
    background: var(--black);

    &:hover {
      border-color: white !important;
    }

    .p-multiselect-trigger {
      .p-multiselect-trigger-icon {
        color: white !important;
      }
    }

    .p-multiselect-label-container .p-multiselect-label {
      color: white !important;
    }
  }

  .p-multiselect-panel {
    background: black;
    color: white;
    box-shadow: 0 3px 1px -2px rgba(255, 255, 255, 0.2),
      0 2px 2px 0 rgba(255, 255, 255, 0.14),
      0 1px 5px 0 rgba(255, 255, 255, 0.12) !important;

    .p-multiselect-header {
      background: black;
      border-bottom: 1px solid white;

      .p-multiselect-filter-container {
        .p-multiselect-filter-icon {
          color: white !important;
        }

        .p-inputtext {
          background-color: black !important;
          border: 1px solid white !important;
          color: var(--white);
        }
      }
    }

    .p-multiselect-header .p-multiselect-close,
    .p-multiselect-items .p-multiselect-item {
      color: white !important;
    }

    .p-multiselect-items .p-multiselect-item {
      &:hover {
        color: white !important;
      }
    }

    .p-multiselect-items .p-multiselect-item.p-highlight {
      background-color: var(--Woodsmoke) !important;
    }
  }

  .p-treeselect {
    color: var(--white);
    box-shadow: 0 3px 1px -2px rgba(255, 255, 255, 0.2),
      0 2px 2px 0 rgba(255, 255, 255, 0.14),
      0 1px 5px 0 rgba(255, 255, 255, 0.12) !important;
    background-color: var(--black);

    .p-treeselect-label-container {
      .p-treeselect-label {
        color: var(--white);
      }
    }

    .p-treeselect-label.p-placeholder {
      color: var(--white);
    }

    .p-treeselect-trigger {
      .p-treeselect-trigger-icon {
        color: var(--white);
      }
    }
  }

  //tree grid dropdown
  .p-treeselect-panel {
    box-shadow: 0 5px 5px -3px rgba(255, 255, 255, 0.2),
      0 8px 10px 1px rgba(255, 255, 255, 0.14),
      0 3px 14px 2px rgba(255, 255, 255, 0.12);

    .p-treeselect-header {
      background-color: var(--black);
      color: var(--white);

      .p-treeselect-filter-container {
        .p-inputtext {
          background-color: black;
          border: 1px solid var(--white);
          color: var(--white);
        }
      }

      .p-treeselect-close {
        color: var(--white) !important;
      }

      .p-treeselect-filter-container .pi-search {
        color: var(--white);
      }
    }
  }

  .p-tree {
    background: black;
    color: white;

    .p-tree-container {
      .p-treenode {
        padding: var(--value-0);

        .p-treenode-content {
          .p-tree-toggler {
            color: var(--white) !important;
          }

          &.p-treenode-selectable:not(.p-highlight):hover {
            color: var(--white);
          }

          &.p-highlight {
            color: var(--white);

            .p-tree-toggler {
              color: var(--white);
            }
          }
        }
      }
    }
  }

  .item-wrapper {
    .p-treeselect {
      background-color: black;
      border: 1px solid var(--white) !important;
    }
  }

  //tree dropdown{
  .p-dropdown-panel {
    box-shadow: 0 5px 5px -3px rgba(255, 255, 255, 0.2),
      0 8px 10px 1px rgba(255, 255, 255, 0.14),
      0 3px 14px 2px rgba(255, 255, 255, 0.12);

    .p-dropdown-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      color: var(--white);
      background: var(--black);

      .p-dropdown-filter-icon {
        color: var(--white);
      }

      .p-dropdown-filter-container {
        .p-inputtext {
          color: var(--white);
          border: 1px solid var(--white);
          background-color: var(--black);

          &:focus {
            box-shadow: var(--display-none);
          }
        }
      }
    }

    .p-dropdown-items {
      .p-dropdown-item {
        color: var(--white) !important;
        background: var(--black);

        &:hover {
          background: rgba(0, 0, 0, 0.87) !important;
        }
      }

      .p-dropdown-empty-message {
        color: var(--white);
        background: var(--black);
      }
    }
  }

  //retail dropdown
  .disti-dropdown {
    .p-dropdown {
      color: var(--white);
      box-shadow: 0 3px 1px -2px rgba(255, 255, 255, 0.2),
        0 2px 2px 0 rgba(255, 255, 255, 0.14),
        0 1px 5px 0 rgba(255, 255, 255, 0.12) !important;
      background-color: var(--black);

      &:focus {
        box-shadow: 0 3px 1px -2px rgba(255, 255, 255, 0.2),
          0 2px 2px 0 rgba(255, 255, 255, 0.14),
          0 1px 5px 0 rgba(255, 255, 255, 0.12) !important;
      }

      .p-inputtext {
        color: var(--white);
      }
    }

    .p-multiselect {
      color: var(--white);
      box-shadow: 0 3px 1px -2px rgba(255, 255, 255, 0.2),
        0 2px 2px 0 rgba(255, 255, 255, 0.14),
        0 1px 5px 0 rgba(255, 255, 255, 0.12) !important;
      background-color: var(--black);

      &:focus {
        box-shadow: 0 3px 1px -2px rgba(255, 255, 255, 0.2),
          0 2px 2px 0 rgba(255, 255, 255, 0.14),
          0 1px 5px 0 rgba(255, 255, 255, 0.12) !important;
      }

      .p-inputtext {
        color: var(--white);
      }
    }
  }

  .cell-treeview {
    border-right: 1px solid var(--white);

    &.simple {
      background-color: var(--black);
      color: var(--white);

      span {
        color: var(--white);
      }
    }
  }

  .label-update {
    .label {
      color: white;
    }

    .material-icons-round {
      color: white;

      &.disabled {
        opacity: 0.6;
      }
    }
  }

  .algo-accordion {
    .mat-expansion-panel {
      .mat-expansion-panel-header {
        .mat-expansion-indicator {
          &::after {
            color: white;
          }
        }
      }
    }
  }

  .fb-dialog-wrapper {
    .mat-mdc-dialog-container {
      .p-mdc-dialog-content {
        .radio-wrapper {
          .mat-checkbox {
            .mat-checkbox-label {
              color: var(--Woodsmoke);
            }
          }
        }
      }
    }
  }

  .bot-wrapper {
    .mat-ex pansion-panel:not(.mat-expanded) {
      .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
        .mat-expansion-panel-header-title {
          background-color: var(--display-none);
        }
      }
    }
  }

  .mat-slide-toggle-bar {
    background-color: var(--Mine-Shaft) !important;
  }

  .journey-task-board {
    .column-title {
      color: var(--white) !important;
    }

    .tasks-container {
      background-color: var(--mine-shaft) !important;
      color: var(--white);
    }
  }

  .journey-taskboard-card {
    background-color: var(--Mine-Shaft);
    box-shadow: var(--display-none);

    .task-detail {
      color: var(--white);
    }

    .task-timeline {
      .task-date {
        color: var(--white);
      }
    }
  }

  .ag-checkbox-input-wrapper {
    &::after {
      color: var(--white);
    }
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    background: var(--white);
    color: #bf1059 !important;
    @include border-radius(5px);
  }

  .sidenav-icon {
    .sidenav-icons {
      .material-icons {
        color: var(--white);
      }
    }
  }

  .material-icons {
    color: var(--white) !important;
  }

  .algo-planner-accordion {
    .graph-legends {
      .legend-label-text {
        color: var(--gray-100);
      }
    }
  }

  .bot-accordian {
    .e-list-parent {
      .e-text-content {
        background: #211d24 !important;
        @include border-radius(10px);
      }
    }
  }

  .e-treeview .e-list-item.e-active>.e-fullrow,
  .e-treeview .e-list-item.e-hover>.e-fullrow {
    background-color: var(--black) !important;
  }

  .notes-head {
    .mat-card-header {
      .notes-actions {
        .material-icons {
          &:hover {
            background-color: var(--Shuttle-Gray);
          }
        }
      }

      .mat-card-title {
        color: var(--white);
      }

      .mat-card-subtitle {
        color: var(--white);
      }
    }
  }

  .mat-toolbar {
    background-color: var(--primary-purple) !important;
    background-image: linear-gradient(0deg, #2c161c 0%, #971099 100%);
  }

  .loadingBar {
    background-color: var(--Ebony) !important;
  }

  .sideBar {
    background-color: var(--Crimson);
    background-image: linear-gradient(0deg, #2c161c 0%, #971099 100%);
  }

  .algo-sidenav {
    .second-count {
      .material-icons {
        color: var(--white);
      }
    }
  }

  .item-wrapper {
    .form-group {
      &.textarea {
        textarea {
          background-color: var(--Mine-Shaft) !important;
          color: var(--white);
        }
      }
    }
  }

  .mat-tab-header {
    .mat-tab-label {
      background-color: var(--mine-shaft);

      &.mat-tab-label-active {
        background: #655f5f;
      }
    }
  }

  .mat-menu-content:not(:empty) {
    background-color: var(--mine-shaft);
    color: var(--white);
  }

  .mat-menu-item {
    color: var(--white);
  }

  .journey-modal-header {
    &.header {
      background: linear-gradient(0deg, #2c161c 0%, #89126a 100%) !important;
    }
  }

  .item-wrapper {
    background-color: var(--black);

    .mat-mdc-table {
      tbody {
        background-color: var(--black);

        .mat-mdc-cell {
          color: var(--white);
        }
      }
    }
  }

  .mat-menu-panel {
    background-color: var(--mine-shaft) !important;
  }

  .comment-box {
    .add-comment {
      textarea {
        background: #383535;
        color: var(--white);
      }
    }
  }

  .mat-radio-outer-circle {
    border-color: var(--white);
  }

  app-genai {
    .mat-stepper-horizontal {
      background-color: var(--Mine-Shaft);
    }
  }

  .mat-step-text-label {
    color: var(--white);
  }

  .mat-stepper-horizontal-line {
    border-color: #575151;
  }

  app-genai-user-metadata {
    .mat-mdc-table {
      background-color: var(--Tundora);
    }

    th.mat-mdc-header-cell,
    td.mat-mdc-cell,
    td.mat-mdc-footer-cell {
      color: #dccdcd;
    }

    .mat-mdc-cell {
      input {
        background-color: var(--Tundora);
        border-bottom: 1px solid #3b3738;
        color: var(--white);

        &::placeholder {
          color: #dccdcd;
        }
      }
    }
  }

  app-manage-subscription {
    .feed-wrapper {
      .feed-header {
        background-color: var(--mine-shaft) !important;
        color: var(--white);
      }

      .feed-manage-body {
        .p-button {
          background: #383535 !important;
          color: var(--white);
        }

        .p-multiselect {
          background: #383535;
        }

        .p-panel {
          .p-panel-header {
            background-color: var(--mine-shaft);
            color: var(--white);
          }

          .p-panel-content {
            background-color: var(--Ebony);
          }
        }

        .p-togglebutton.p-button.p-highlight {
          background: linear-gradient(0deg,
              #2c161c 0%,
              rgba(139, 0, 103, 0.9215686275) 100%) !important;
          border-color: #16061c !important;
          color: var(--white);
        }

        .p-paginator {
          background: #383535 !important;
          color: var(--white);
        }
      }

      .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
        border-color: #d1b3b9;
      }

      .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
        color: rgb(243 243 243 / 87%);
      }
    }
  }

  .p-paginator {
    background: #383535 !important;
    color: var(--white) !important;

    .p-paginator-first {
      color: var(--white);
    }

    .p-paginator-last {
      color: var(--white);
    }

    .p-paginator-pages {
      .p-paginator-page {
        color: var(--white);
      }

      .p-paginator-page:not(.p-highlight):hover {
        background-color: var(--Abbey);
        color: var(--white);
      }
    }

    .p-paginator-next {
      color: #f1f1f1;
    }

    .p-paginator-next:not(.p-disabled):not(.p-highlight):hover {
      background-color: var(--Abbey);
      color: var(--white);
    }

    .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
      background-color: var(--Abbey);
      color: var(--white);
    }

    .p-paginator-prev {
      color: #f1f1f1;
    }
  }

  .favorites-list ul li span,
  .tasks-list ul li span,
  .name-list ul li span {
    background: #211e1e;
    color: white;
    border: 1px solid white;
  }

  .favorites-list .fav-list-wrapper,
  .tasks-list .fav-list-wrapper,
  .name-list .fav-list-wrapper {
    background-color: var(--black);
  }

  .home-base-list {
    .no-data-display {
      background-color: var(--black);
      color: var(--white) !important;
    }
  }

  .favorites-list .fav-list-wrapper .fav-list-text,
  .tasks-list .fav-list-wrapper .fav-list-text,
  .name-list .fav-list-wrapper .fav-list-text {
    color: var(--white);
  }

  app-algo-pushed-journeys {
    .action-panel {
      margin-bottom: var(--value-10);
    }
  }

  .name-wrapper {
    p {
      color: var(--white) !important;
    }
  }

  .mat-checkbox-disabled {
    .mat-checkbox-label {
      color: var(--white);
    }
  }

  .mat-stepper-horizontal,
  .mat-stepper-vertical {
    background-color: var(--black);
  }

  .mat-stepper-vertical-line::before {
    border-left-color: var(--white);
  }

  .mat-step-header {
    .mat-step-icon {
      background-color: rgb(96 81 81 / 54%);
    }
  }

  .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
    color: var(--white);
  }

  mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
    border-color: rgb(148 140 140 / 38%);
  }

  .notes-cards {
    .card-details .mat-mdc-card-header {
      padding: var(--value-0);
    }

    .mat-card {
      background: var(--black);

      .notes-field {
        input {
          background-color: var(--Cod-Gray);
          color: var(--white);

          &::placeholder {
            color: white !important;
          }
        }

        textarea {
          background-color: var(--Cod-Gray);
          color: white;

          &::placeholder {
            color: white !important;
          }
        }
      }
    }
  }

  .notes-search {
    input {
      background-color: var(--Cod-Gray);
      color: var(--white);

      &::placeholder {
        color: white !important;
      }
    }
  }

  .btn.btn-secondary {
    color: var(--white);
  }

  .sub-journey-label {
    color: #9a989b;
  }

  .task-view-wrapper {
    background-color: var(--black);

    .task-header {
      background-color: var(--Emperor);
      margin-bottom: var(--value-0);
    }

    .task-view-tree .p-tree .p-tree-container .p-treenode .p-treenode-content {
      background-color: rgba(0, 0, 0, 0.87);
    }
  }

  .keyboard_tab {
    .material-icons {
      color: var(--white) !important;
    }
  }

  .ag-theme-material.custom-ag-grid .ag-header-cell app-grid-custom-header .p-dropdown {
    background-color: var(--primary-purple) !important;
    background-image: linear-gradient(0deg, #100408 0%, #7d157f 100%);
  }

  .miv-planner-wrapper {
    app-grid-custom-header {
      .p-dropdown {
        background-color: var(--black) !important;
      }
    }

    .cell-treeview {
      border-right: var(--value-0);
      height: 50px;

      .p-treeselect {
        background-color: var(--black);
      }
    }
  }

  .menu-configuration-wrapper .p-tree .p-tree-container .p-treenode .p-treenode-content {
    background-color: var(--color-1a);
  }

  .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    background-color: rgb(28 26 26);
    color: var(--white);
  }

  .sub-menu-icon {
    .material-icons {
      color: var(--black) !important;
    }
  }

  .home-base-list {
    ul {
      background: var(--black);
    }
  }

  .notes-cards {
    .mat-card {
      .mat-card-content {
        color: var(--white);
      }
    }
  }

  .action-renderer-icons {
    .disabled {
      color: var(--white) !important;
      opacity: 0.8 !important;
      background: #857e7e !important;
    }
  }

  .favorites-list,
  .tasks-list,
  .home-base-list {
    background-color: var(--black);
  }

  #fb-forecast-new-planner {
    .grid-columns-expand {
      background: linear-gradient(0deg, #100408 0%, #7d157f 100%) !important;

      .box {
        background-color: rgb(33 32 32);
      }
    }
  }

  .p-dropdown-panel {
    .p-dropdown-items {
      .p-dropdown-item {
        &.p-highlight {
          color: var(--secondary-carnation) !important;
          background: rgba(26, 24, 24, 0.823);
        }
      }
    }
  }

  .feed-public-journey {
    .mat-select {
      background-color: var(--secondary-carnation) !important;
    }
  }

  .algo-sidenav {
    .single-link {
      .mdc-list-item__content {
        .menu-name {
          color: var(--white);
        }
      }
    }
  }

  .fb-dialog-wrapper2 {
    .mat-mdc-dialog-container {
      .mdc-dialog__surface {
        background-color: var(--black);
      }

      .p-mdc-dialog-content {
        p {
          color: var(--white);
        }
      }
    }
  }

  .fb-dialog-wrapper {
    .mat-mdc-dialog-container {
      .mdc-dialog__surface {
        background-color: var(--black);
      }

      .p-mdc-dialog-content {
        color: var(--white) !important;

        .measure-wrapper {
          .input-wrapper {
            background-color: var(--color-2e) !important;
            color: var(--white);

            input {
              color: var(--white);

              &::placeholder {
                color: var(--white);
              }
            }
          }
        }
      }
    }
  }

  .company-user-name {
    p {
      color: var(--white);
    }

    .mdc-button {
      .icon-user {
        color: var(--white);
      }
    }
  }

  .ag-theme-material.custom-ag-grid .ag-center-cols-viewport .ag-cell.disabled-input,
  .ag-theme-material.custom-ag-grid .ag-floating-bottom-viewport .ag-cell.disabled-input,
  .ag-theme-material.custom-ag-grid .ag-pinned-right-cols-container .ag-cell.disabled-input {
    background-color: var(--black) !important;
  }

  .ag-theme-material.custom-ag-grid .ag-center-cols-viewport .ag-cell:after,
  .ag-theme-material.custom-ag-grid .ag-floating-bottom-viewport .ag-cell:after,
  .ag-theme-material.custom-ag-grid .ag-pinned-right-cols-container .ag-cell:after {
    border: 5px solid #4a4848 !important;
  }

  .total-pinned {
    background-color: var(--primary-purple) !important;
    background-image: linear-gradient(0deg, #100408 0%, #7d157f 100%);

    img {
      filter: none !important;
    }
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])~.mdc-checkbox__background {
    border-color: var(--white);

    &:hover {
      border-color: var(--white) !important;
    }
  }

  .profiles {
    background: var(--Woodsmoke);
    margin-bottom: 6px;

    .action-icons {
      .action-icon {
        color: var(--white) !important;
      }
    }

    .default-icon {
      color: var(--white) !important;
    }
  }

  mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    &::placeholder {
      color: white !important;
    }
  }

  .ag-theme-material .ag-header .ag-header-cell:hover,
  .ag-theme-material .ag-header .ag-header-group-cell:hover {
    background-color: var(--black) !important;
  }

  .mat-mdc-dialog-container {
    .mdc-dialog__surface {
      background-color: var(--black);
    }

    .p-mdc-dialog-content {
      color: var(--white) !important;
    }
  }

  .ag-theme-material {
    .ag-paging-panel {
      .ag-paging-page-size {
        .ag-picker-field {
          color: var(--white) !important;
        }
      }

      .ag-picker-field-wrapper {
        background-color: var(--Mine-Shaft) !important;
        color: var(--white) !important;

        .ag-icon {
          color: white;
        }
      }
    }
  }

  .item-search-wrapper {
    .form-fields {
      .mat-mdc-form-field-has-icon-suffix {
        .mat-mdc-text-field-wrapper {
          background-color: var(--Thunder);
        }
      }

      .mat-mdc-form-field-flex {
        background-color: var(--Thunder);

        input {
          color: var(--white);
        }
      }
    }
  }

  .mat-mdc-tab-header {
    .mat-mdc-tab {
      background-color: var(--mine-shaft);
      color: var(--white) !important;

      .mdc-tab__text-label {
        color: var(--white) !important;
      }

      &.mdc-tab--active {
        background: linear-gradient(0deg, #2c161c 0%, #830985 100%) !important;
        color: var(--white) !important;

        .mdc-tab__text-label {
          color: var(--white) !important;
        }
      }
    }
  }

  .ag-theme-material.custom-ag-grid .ag-row.ag-row-space {
    background-color: var(--mine-shaft) !important;

    &:hover {
      background-color: var(--mine-shaft) !important;
    }
  }

  .ag-theme-material.custom-ag-grid .ag-pinned-left-cols-container .ag-row.ag-row-space .ag-cell:nth-child(2):before,
  .ag-theme-material.custom-ag-grid .ag-pinned-left-floating-bottom .ag-row.ag-row-space .ag-cell:nth-child(2):before {
    background-color: var(--mine-shaft) !important;
  }

  .mdc-switch:disabled .mdc-switch__track::before {
    background-color: red !important;
  }

  .ag-popup {
    .ag-select-list {
      .ag-list-item {
        background: #3c3939 !important;
        color: white !important;

        &:active {
          background: var(--black) !important;
          color: white !important;
        }
      }
    }
  }

  .right-sidenav #rightSidenav.icon-bg-color .mat-drawer-content .sidenav-icon .sidenav-icons {
    background: #3b3939 !important;
  }

  .create-update {
    .mat-mdc-slide-toggle {
      background-color: var(--black) !important;
    }
  }

  .search-filter {
    .form-group {
      mat-form-field {
        input {
          border: none !important;

          &::placeholder {
            color: rgb(223, 223, 223) !important;
          }
        }

        .mat-mdc-form-field-flex {
          background-color: var(--black);
        }

        .mat-mdc-form-field-icon-suffix {
          background-color: var(--black);
        }
      }
    }
  }

  .notes-cards {
    .mat-mdc-card {
      background: #0e0e0e;
      color: #c7c6c6;
    }
  }

  .user-page {
    .form-group {
      .mat-mdc-text-field-wrapper {
        border: 1px solid var(--white);

        input {
          border: none !important;
        }

        .mat-mdc-form-field-icon-suffix {
          background-color: var(--black);
        }
      }
    }
  }

  .zoom-in-out {
    filter: invert(100%);
  }

  .journey-comment-wrapper {
    background: #403d3d;
  }

  .dynamic-report-wrapper {
    app-journey-comment {
      .journey-comment-parent {
        background: #1c1919;
      }
    }
  }

  .chat-wrapper {
    .chat-profile-wrapper {
      background-color: var(--black) !important;

      .name-text {
        color: var(--white) !important;
      }

      .time-text {
        color: #baa !important;
      }
    }
  }

  .mat-mdc-menu-panel {
    .mat-mdc-menu-content {
      background: var(--black) !important;

      .mat-mdc-menu-item {
        .mat-mdc-menu-item-text {
          color: var(--white) !important;
        }
      }
    }
  }

  .mce-content-body {
    color: var(--white) !important;
  }

  app-journey-right-panel {
    background: #1c1919;
  }

  .item-wrapper {
    .form-group {
      .mat-mdc-form-field-flex {
        background-color: var(--black);
      }

      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-icon-suffix {
          background: var(--display-none);
          right: 15px;

          .mat-mdc-icon-button[disabled] {
            color: var(--white) !important;
          }
        }
      }
    }
  }

  .user-logout {
    .mat-mdc-menu-content {
      background-color: var(--Crimson) !important;
      background-image: linear-gradient(0deg,
          #2c161c 0%,
          #971099 100%) !important;
    }
  }

  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    height: auto !important;
  }

  app-notifications .notification-alert .notifications-alerts .notification-search-wrapper .notification-filter-wrapper .p-treeselect {
    background-color: var(--black);
  }

  .cell-treeview {
    .p-treeselect {
      background-color: var(--primary-purple) !important;
      background-image: linear-gradient(0deg, #100408 0%, #7d157f 100%);
    }
  }

  .classification-btn {
    p-dropdown {
      .p-dropdown {
        border: none !important;
      }
    }
  }

  .data-wrapper {
    .p-dropdown {
      border: none !important;
    }
  }

  app-dashboard {
    .bot-wrapper[_ngcontent-ng-c1817556264] {
      margin-bottom: 5px !important;
    }
  }

  .ag-theme-material .ag-layout-auto-height .ag-center-cols-viewport,
  .ag-theme-material .ag-layout-auto-height .ag-center-cols-container,
  .ag-theme-material .ag-layout-print .ag-center-cols-viewport,
  .ag-theme-material .ag-layout-print .ag-center-cols-container {
    background: black;
  }

  .miv-planner-wrapper {
    .ag-theme-material {
      .custom-ag-grid {
        .ag-row {
          border-bottom: 1px solid var(--white) !important;
        }
      }
    }
  }

  .right-sidenav #rightSidenav.icon-bg-color .sidenav-icon .sidenav-icons {
    background: #3b3939 !important;
  }

  app-measure-dependency {
    .p-editor-container {
      .p-editor-toolbar {
        background: #b3aab2 !important;
      }

      .p-editor-content {
        .ql-editor {
          background: var(--Cod-Gray);
          color: rgb(235 228 228 / 87%);
        }
      }
    }
  }

  .ag-overlay-loading-wrapper {
    background-color: var(--black) !important;
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: rgb(253 253 253 / 94%);
  }

  .notes-cards .notes-field input,
  .notes-cards .notes-field textarea {
    background-color: var(--color-39);
    color: var(--white);

    &::placeholder {
      color: white !important;
    }
  }

  .mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not( :indeterminate):not([data-indeterminate="true"])~.mdc-checkbox__background {
    border-color: var(--white) !important;
  }

  app-save-journey .item-wrapper .form-group.textarea textarea {
    margin-left: 2px;
  }

  .drag-button .inner-data span {
    color: var(--white);
  }

  .ag-theme-material {

    .ag-tabs-header,
    .ag-theme-material .ag-column-drop-horizontal {
      background-color: var(--color-0e);
    }

    .ag-tabs-body {
      background: var(--Cod-Gray);
      color: var(--white);

      .ag-menu-option-active {
        background-color: var(--Cod-Gray);
      }
    }

    .ag-menu {
      background-color: var(--Cod-Gray);
      color: var(--white);

      .ag-menu-option-active {
        background-color: var(--Cod-Gray);
      }
    }
  }

  .ag-theme-material input[class^="ag-"] {
    color: var(--white);
  }

  .ag-theme-material input[class^="ag-"]::placeholder {
    color: var(--white);
  }

  app-base-planner-measure-profile .mat-mdc-input-element::placeholder {
    color: var(--white);
  }

  app-journey-share .mat-mdc-dialog-title {
    background-color: var(--Loulou);
  }

  .mat-mdc-dialog-title {
    background: linear-gradient(0deg, #2c161c 0%, #89126a 100%) !important;
  }

  .btn.btn-primary {
    background: linear-gradient(0deg, #2c161c 0%, #8b0067eb 100%) !important;
    border-color: #16061c;
  }

  .btn.btn-secondary {
    // background: linear-gradient(0deg, #2c161c 0%, #8b0067eb 100%) !important;
    border-color: #5f0e3b !important;
  }

  .btn.btn-primary:disabled,
  .btn.btn-primary.disabled {
    background: var(--btn-disabled) !important;
  }

  .dashboard-clear-btn {
    background: linear-gradient(0deg, #870e28, #f8486e), #d81ad1;
  }

  .mat-toolbar {
    .toggleBtn {
      background: linear-gradient(0deg, #690d43, #f8486e), #d81ad1 !important;
    }
  }

  .algo-accordion .accordion-icon {
    background: linear-gradient(0deg, #690d43, #cb359d), #d81ad1 !important;
    z-index: var(--z-index-2);

    .red {
      color: #ffc107 !important;
    }
  }

  .data-wrapper .p-dropdown {
    background: linear-gradient(0deg, #2c161c 0%, #8b0067eb 100%);
  }

  .classification-btn {
    p-multiselect {
      .p-multiselect {
        background: linear-gradient(0deg, #2c161c 0%, #8b0067eb 100%);
      }
    }

    p-dropdown {
      .p-dropdown {
        background: linear-gradient(0deg, #2c161c 0%, #8b0067eb 100%);
      }
    }
  }

  .mdc-dialog__container {
    .journey-modal-header.header {
      background-color: var(--Loulou) !important;
    }
  }

  .ag-overlay-loading-center {
    background: #252020;
    color: var(--white);
  }

  .mat-stepper-vertical .mat-step-header .mat-step-icon-selected,
  .mat-stepper-vertical .mat-step-header .mat-step-icon-state-done,
  .mat-stepper-vertical .mat-step-header .mat-step-icon-state-edit {
    background-color: var(--Disco);
  }

  .miv-planner-wrapper .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: linear-gradient(0deg, #2c161c 0%, #8b0067eb 100%);
  }

  // .task-board-header .assigned-checkbox.icon .material-icons{
  //   color: #8b209d !important;
  // }
  #fb-forecast-graph {
    .highcharts-root {
      .highcharts-background {
        fill: var(--black) !important;
      }

      .highcharts-axis-labels {
        text {
          color: var(--white) !important;
          fill: var(--white) !important;
        }
      }

      .highcharts-legend {
        .highcharts-legend-item {
          text {
            color: var(--gray-100) !important;
            fill: var(--gray-100) !important;
          }
        }
      }

      .highcharts-button {
        .highcharts-button-box {
          fill: var(--black) !important;
        }

        .highcharts-button-symbol {
          stroke: var(--white) !important;
        }
      }
    }

    .highcharts-contextmenu {
      .highcharts-menu {
        background-color: var(--black) !important;

        .highcharts-menu-item {
          color: var(--white) !important;

          &:hover {
            color: var(--black) !important;
          }
        }
      }
    }
  }

  .p-multiselect-panel .p-checkbox .p-checkbox-box.p-highlight {
    background: linear-gradient(0deg, #690d43, #f8486e), #d81ad1 !important;
    border-color: #a5245a !important;
  }

  .p-tree .p-tree-container .p-treenode .p-checkbox .p-checkbox-box.p-highlight {
    background: linear-gradient(0deg, #690d43, #f8486e), #d81ad1 !important;
    border-color: #a5245a !important;
  }

  .ag-theme-material {
    &.custom-ag-grid {
      .white-background {
        border-bottom: 0.1px solid white !important;
      }
    }
  }

  .ag-theme-material.custom-ag-grid .ag-cell-range-selected-1:not(.ag-cell-focus),
  .ag-theme-material.custom-ag-grid .ag-root:not(.ag-context-menu-open) .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing).disabled-input {
    background: var(--black) !important;
  }

  .ag-theme-material.custom-ag-grid .ag-cell-range-selected-1:not(.ag-cell-focus),
  .ag-theme-material.custom-ag-grid .ag-root:not(.ag-context-menu-open) .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
    background-color: rgb(0 0 0) !important;
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate="true"]:enabled~.mdc-checkbox__background {
    background: linear-gradient(0deg, #690d43, #f8486e), #d81ad1 !important;
    border-color: #a5245a !important;
  }

  .tenants-users {
    .sidenav-icon {
      .sidenav-icons {
        background: linear-gradient(0deg, #2c161c 0%, #de11e1 100%) !important;

        .count {
          background: linear-gradient(0deg, #870e28, #ff0f43), #ff00f6;
        }
      }
    }
  }

  .sidenav-icon {
    .sidenav-icons {
      .count {
        background: linear-gradient(0deg, #870e28, #ff0f43), #ff00f6;
      }
    }
  }

  app-base-ag-grid {
    .ag-theme-material {
      .ag-cell {
        margin-left: 2px !important;
      }
    }
  }

  app-image-renderer {
    img {
      filter: invert(100%);
    }
  }

  app-measure-profile-form {
    .form-group {
      .mat-mdc-text-field-wrapper {
        border: 1px solid var(--white) !important;

        .mat-mdc-form-field-infix {
          input {
            color: var(--white) !important;
          }
        }
      }
    }
  }

  .ag-theme-material {
    .ag-body-horizontal-scroll {
      background: var(--black) !important;
    }

    .ag-floating-bottom {
      border: 1px solid var(--white) !important;

      .ag-floating-bottom-viewport {
        .ag-cell {
          border-right: 1px solid #252222 !important;
          border-color: var(--black) !important;
        }
      }
    }
  }

  .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible) .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
    background: var(--black) !important;
  }

  app-save-journey {
    .item-wrapper {
      .form-group {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-icon-suffix {
            right: var(--value-0);

            .mat-datepicker-toggle {
              margin-left: -45px;
              color: white;
            }
          }
        }
      }
    }
  }

  app-journey-share .p-dialog-header {
    background-color: var(--Loulou);
  }

  .p-dialog {
    background-color: var(--black);

    .p-dialog-header {
      background: linear-gradient(0deg, #2c161c 0%, #89126a 100%) !important;
    }

    .p-mdc-dialog-content {
      background-color: var(--black);

      input {
        color: var(--white);

        &::placeholder {
          color: var(--white);
        }
      }

      .measure-wrapper {
        .input-wrapper {
          background-color: var(--color-2e) !important;
          color: var(--white);

          input {
            color: var(--white);

            &::placeholder {
              color: var(--white);
            }
          }
        }
      }
    }
  }

  .fb-dialog-wrapper {
    .p-mdc-dialog-content {
      .p-mdc-dialog-content {
        .inner-data {
          .main-label {
            &:nth-child(odd) {
              background-color: var(--Woodsmoke);
            }
          }
        }
      }
    }
  }

  .notification-search-wrapper {
    .search-filter {
      .form-group {
        .mat-mdc-form-field {
          input {
            color: var(--white) !important;
          }
        }
      }
    }
  }

  .notification-alert {
    .mat-mdc-tab-header {
      .mat-mdc-tab {
        .count {
          background: linear-gradient(0deg, #690d43, #f8486e), #d81ad1 !important;
        }
      }
    }
  }

  app-public-journeys {
    .feed-public-journey {
      .p-dropdown {
        background: linear-gradient(0deg,
            #2c161c 0%,
            rgba(139, 0, 103, 0.9215686275) 100%) !important;
        border-color: #16061c !important;
      }

      .feed-wrapper {
        .toggle-btn {
          .btn {
            background: none !important;
          }
        }
      }
    }
  }

  app-task-form {
    .item-wrapper {
      .form-group {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-icon-suffix {
            right: var(--value-0);

            .mat-datepicker-toggle {
              margin-left: -45px;
              color: white;
            }
          }
        }
      }
    }

    .journey-modal-header {
      &.header {
        background: none !important;
      }
    }
  }

  .fb-dialog-wrapper2 {
    .p-mdc-dialog-content {
      .p-mdc-dialog-content {
        p {
          color: var(--white);
        }
      }
    }
  }

  app-item-form {
    .mdc-switch {
      background: #3e3c3ced;
      @include border-radius(10px);
    }
  }

  app-genai-option {
    .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:disabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
      border-color: #f9f2f2 !important;
    }
  }

  .algo-sidenav {
    .mat-expansion-panel {
      .mat-expansion-panel-header {
        &:hover {
          background-color: var(--color-4d) !important;

          .mat-expansion-indicator {
            background-color: var(--black) !important;
          }
        }
      }
    }

    .mat-expansion-panel.mat-expanded .mat-expansion-panel-body .mat-mdc-nav-list {
      background-color: var(--Cod-Gray);
    }

    .second-count {
      &:hover {
        background-color: var(--Mine-Shaft) !important;
      }

      .count {
        background: linear-gradient(0deg, #870e28, #ff0f43), #ff00f6;
      }
    }
  }

  .favorites-list ul li.active span,
  .tasks-list ul li.active span,
  .name-list ul li.active span {
    background: linear-gradient(0deg,
        #2c161c 0%,
        rgba(139, 0, 103, 0.9215686275) 100%) !important;
  }

  app-tracking .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background {
    .mdc-radio__outer-circle {
      border-color: #a5245a !important;
    }

    .mdc-radio__inner-circle {
      border-color: #b50d53 !important;
    }
  }

  app-genai-option .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background {
    .mdc-radio__outer-circle {
      border-color: #a5245a !important;
    }

    .mdc-radio__inner-circle {
      border-color: #b50d53 !important;
    }
  }

  .graph-img {
    img {
      filter: invert(1);
    }
  }

  .ag-theme-material {
    &.custom-ag-grid {
      .ag-row {
        &.ag-row-space {

          &.ag-row-hover:not(.ag-full-width-row)::before,
          &.ag-row-hover.ag-full-width-row.ag-row-group::before {
            background-color: var(--mine-shaft) !important;
          }
        }
      }
    }
  }

  app-confirmation-dialog {
    .p-mdc-dialog-content {
      color: var(--white) !important;
    }
  }

  .bot-wrapper {

    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]),
    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) .mat-expansion-panel-header-title {
      background: none !important;
    }
  }

  app-measures-detail {
    .p-editor-container {
      .p-editor-toolbar {
        background: #b3aab2 !important;
      }

      .p-editor-content {
        .ql-editor {
          background: var(--Cod-Gray);
          color: rgb(235 228 228 / 87%);
        }
      }
    }
  }

  .sidenav-menus {

    .mat-expansion-panel .mat-expansion-panel-content .mat-mdc-list-base .mat-mdc-list-item,
    .mat-expansion-panel .mat-expansion-panel-content .mat-mdc-list-base .mat-mdc-list-option {
      padding: var(--value-0);

      &:hover {
        background-color: var(--mine-shaft) !important;
      }
    }
  }

  .algo-sidenav {
    .mat-expansion-panel.mat-expanded .mat-expansion-panel-header.level1 {
      background-color: var(--color-2a) !important;

      &:hover {
        background-color: var(--Cocoa-Brown) !important;
      }
    }

    .mat-expansion-panel.mat-expanded .level0:hover {
      background-color: var(--col-p) !important;
    }

    .single-link:hover:before {
      background-color: rgba(77, 73, 73, 0.8) !important;
    }

    .single-link .mat-mdc-list-item:hover {
      background-color: rgba(77, 73, 73, 0.8) !important;
    }

    .mat-expansion-panel .mat-expansion-panel-body .mat-mdc-nav-list:hover:before {
      background-color: var(--mine-shaft) !important;
    }

    .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
      &:hover {
        background-color: var(--black) !important;
      }
    }

    .company-user-expansion.mat-expansion-panel .mat-expansion-panel-header:hover {
      background-color: rgba(77, 73, 73, 0.8) !important;
    }

    .single-link:hover {
      background-color: var(--color-4d) !important;

      .second-count {
        background-color: var(--Mine-Shaft) !important;
      }
    }

    .mat-expansion-panel.mat-expanded .mat-expansion-panel-body .mat-mdc-nav-list:hover .second-count {
      background-color: var(--Mine-Shaft) !important;
    }
  }

  .no-favorites-items {
    background-color: var(--Mine-Shaft) !important;
    color: var(--white) !important;

    &:hover {
      background-color: var(--mine-shaft) !important;
    }
  }

  .mat-expansion-panel.mat-expanded .level1 .mat-mdc-list-item {
    background-color: var(--Mine-Shaft) !important;
  }

}