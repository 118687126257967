@import 'mixins';
.e-list-parent {
  padding: 0 !important;
  overflow: var(--overflow-hidden);
  .e-list-item {
    margin: 5px;
    padding: var(--value-0);
    @include border-radius(8px);
    &.e-node-collapsed,
    &.e-node-focus{
      .action-panel{
       padding: 3px 22px 3px 35px;
      }
    }
    .e-text-content {
      .e-icons {
        margin: 0 !important;
      }
    }
    &.e-level-2{
      margin-top: 15px;
      margin-left: var(--value-30);
      .action-panel{
        padding: 3px 22px 3px 35px;
        @media (max-width: 767px){
          padding: 3px 15px 3px 15px;
          overflow-x: scroll;
        }
      }
    }
    &[aria-expanded="true"],
    &.e-node-collapsed{
      .action-panel{
        padding: 3px 47px 3px 12px;
      }
      .e-level-2{
        .action-panel{
          padding: 3px 22px 3px 12px;
        }
      }
    }
  }
}

.e-treeview .e-list-item.e-active > .e-fullrow,
.e-treeview .e-list-item.e-hover > .e-fullrow {
  background-color: var(--Athens-Gray);
  left: inherit;
  right: inherit;
  width: inherit;
  border: var(--display-none);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.e-treeview .e-list-item.e-active > .e-text-content .e-list-text {
  color: #666ee8;
}
.e-treeview .e-list-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: var(--font-size-15);
  padding: var(--value-0);
}
::ng-deep.e-treeview {
  .e-fullrow {
    height: 46px !important;
    border: var(--display-none);
  }
}

.e-list-text {
  width: var(--width-100);
  .action-panel {
    @include flex-justify-between(flex, center, space-between);
    padding: 3px 24px 3px 33px;
    height: var(--height-100);
    overflow-x: auto;
  }
}
.sub {
  .mat-icon {
    height: 18px;
  }
}

::ng-deep.internal-info {
  @include flex-align(flex, center);
  .mdc-button-wrapper {
    justify-content: var(--justify-center);
    @media (min-width: $media-breakpoint-md-up) {
      justify-content: var(--justify-between);
    }
    span {
      display: var(--display-none);
      @media (min-width: $media-breakpoint-md-up) {
        display: var(--display-block);
      }
    }
  }
  .icon-add {
    padding-left: var(--value-0);
    @media (min-width: $media-breakpoint-md-up) {
      padding-left: var(--value-30);
    }
  }
}
