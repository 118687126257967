@import 'mixins';

//algo zoom out
.algo-zoom-out {

  //right sidenav
  .right-sidenav {
    #rightSidenav {
      .mat-drawer-content {
        padding: 40px 0 70px;
      }
    }
  }

  //left side nav
  .sideBar {
    width: 50px;

    .logo-img {
      max-width: 30px;
      margin: 8px 10px;

      @media (max-width: $media-breakpoint-xxl-screen-down) {
        max-width: 30px;
        margin: 8px 10px;
      }
    }

    ul {
      li {
        height: 35px;

        @media (min-width: $media-breakpoint-xxl-screen-up) {
          height: 40px;
        }
      }

      img {
        max-width: 30px;

        @media (max-width: $media-breakpoint-xxl-screen-down) {
          max-width: 30px;
        }
      }
    }
  }

  .company-holder-col-footer {
    width: 50px;

    .cv-tos-holder {
      @include flex-column(flex, column, center, center);
      color: var(--white);
    }
  }

  .mat-toolbar {
    min-height: 32px;

    .mat-toolbar-row {
      height: 32px;

      @media (max-width: 1800px) {
        height: 32px;
      }
    }
  }

  .algo-sidenav {
    .mat-expansion-panel {
      .mat-expansion-panel-header {
        height: 35px !important;

        @media (max-width: 1800px) {
          height: 30px !important;
        }

        @media (min-width: $media-breakpoint-xxl-screen-up) {
          height: 40px !important;
        }
      }

      .mat-expansion-panel-header-title {
        @media (min-width: $media-breakpoint-xxl-screen-up) {
          font-size: var(--font-size-12);
        }
      }
    }

    .single-link {
      max-height: 40px;
      height: 40px;

      @media (max-width: 1800px) {
        max-height: 30px;
        height: 30px;
      }

      @media (min-width: $media-breakpoint-xxl-screen-up) {
        max-height: 40px;
        height: 40px;
      }

      .mat-mdc-list-item {
        @media (min-width: $media-breakpoint-md-up) {
          font-size: var(--font-size-12);
        }

        @media (max-width: 1800px) {
          font-size: var(--font-size-10);
        }
      }
    }

    .mat-expansion-indicator::after {
      padding: 2px;
    }
  }

  .page-container {
    padding-left: var(--value-0);
    padding-top: 32px;

    @media (min-width: 1025px) {
      padding-left: var(--value-50);
      padding-top: 0;
    }
  }

  //algo bottom search bar
  .page-head {
    img {
      max-width: 40px;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: var(--display-none);
    }

    .mat-mdc-form-field-appearance-legacy {
      .mat-mdc-form-field-label {
        span {
          font-size: var(--font-size-16);
        }
      }
    }
  }

  .page-head,
  .page-head-cred {
    padding: 0 50px 0 20px;
  }

  .custom-width {
    .page-head {
      left: var(--value-0);

      @media (min-width: $media-breakpoint-lg-up) {
        left: 247px;
      }

      @media (min-width: $media-breakpoint-xxl-screen-up) {
        left: 300px;
      }
    }
  }

  .full-width {
    .page-head {
      left: var(--value-0);

      @media (min-width: $media-breakpoint-lg-up) {
        left: 50px;
      }
    }
  }

  //left sidenav


  .company-user-name {
    height: 40px;

    @media (max-width: $media-breakpoint-xxl-screen-down) {
      height: 40px;
    }



    p {
      font-size: var(--font-size-12);
      width: var(--width-100);
      overflow: var(--overflow-hidden);
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 10px;
      margin-right: var(--value-30);

      @media (max-width: 1800px) {
        font-size: var(--font-size-10);
      }
    }

    .mdc-button {
      .material-icons {
        font-size: 32px;
        position: var(--position-absolute);
        right: 7px;
        transform: rotate(90deg);
      }

      .icon-user {
        font-size: var(--font-size-24);
      }

      .mdc-button__label {
        width: var(--width-100);
        @include flex-align(flex, center);
      }
    }
  }

  .mdc-button {
    .icon-user {
      font-size: var(--font-size-22);
    }

    .material-icons {
      font-size: var(--font-size-30);

      @media (max-width: 1800px) {
        font-size: var(--font-size-25);
      }
    }
  }

  .mat-expansion-panel {
    .mat-expansion-panel-header {
      height: 50px !important;

      @media (min-width: $media-breakpoint-xxl-screen-up) {
        height: 50px !important;
      }

      @media (max-width: 1800px) {
        height: 45px !important;
      }
    }

    .mat-expansion-panel-header-title {
      @media (min-width: $media-breakpoint-xxl-screen-up) {
        font-size: var(--font-size-13);
      }
    }
  }

  .algo-accordion {
    .mat-expansion-panel {
      .mat-expansion-panel-header {
        .mat-expansion-panel-header-title {
          font-size: var(--font-size-16);
          height: 50px;

          @media (max-width: 1800px) {
            font-size: var(--font-size-12);
          }

          @media (max-width:425px) {
            font-size: var(--font-size-12);
          }
        }
      }
    }

    .accordion-icon {
      height: 24px;
      @media (max-width: 1800px){
        height: 22px;
      }
      .material-icons {
        font-size: var(--font-size-16);
        @media (max-width: 1800px){
          font-size: var(--font-size-13);
        }
      }

      .material-symbols-outlined {
        font-size: var(--font-size-16);
        font-size: var(--font-size-16);
        @include flex-center(flex, center, center);


        @media (max-width: 768px) {
          font-size: var(--font-size-14);
        }
      }
    }
  }

  .bot-img {

    img {
      max-width: 36px;

      @media (max-width: 1800px) {
        max-width: 35px;
      }
    }
  }

  .p-treeselect {
    height: 40px;

    @media (max-width: 1800px) {
      height: 35px;
    }

    .p-treeselect-label-container {
      .p-treeselect-label {
        font-size: var(--font-size-12);

        @media (max-width: 1800px) {
          font-size: var(--font-size-10);
        }
      }
    }
  }

  #fb-forecast-new-planner {
    .btn-wrapper {
      .adjust-btn {
        height: 40px;
        font-size: var(--font-size-14);

        @media (max-width: 1800px) {
          height: 35px;
          font-size: var(--font-size-12);
        }
      }
    }

    .material-icons {
      font-size: 24px !important;

      &.setting-icon {
        font-size: 31px !important;

        @media (max-width: 1800px) {
          font-size: 30px !important;
        }
      }
    }

  }

  .item-wrapper {
    &.fb-forecast-planner {

      .form-group {

        input {
          height: 26px !important;
        }
      }
    }

    .p-treeselect {
      height: 26px;
    }
  }

  .p-multiselect {
    height: 26px;
  }

  .p-dropdown {
    height: 26px;
  }

  .mat-tab-header {
    .mat-tab-label {
      height: 35px;
      margin-right: 5px;
      font-size: var(--font-size-12);

      @media (max-width: 1800px) {
        height: 30px;
        font-size: var(--font-size-10);
      }

      @media (max-width:425px) {
        font-size: var(--font-size-10);
      }
    }
  }

  .ag-theme-material {
    .ag-pinned-right-header {
      .ag-header-cell-label {
        justify-content: var(--justify-center);
      }
    }

    &.custom-ag-grid {
      .ag-header {
        height: 40px !important;
        min-height: 40px !important;

      }

      .ag-header-row {
        height: 40px !important;

        .ag-header-cell {
          height: 40px !important;
        }
      }

      .ag-row {
        height: 32px !important;
      }
    }

    .ag-header {
      height: 35px !important;
      min-height: 35px !important;
    }

    .ag-header-row {
      height: 35px !important;

      .ag-header-cell {
        height: 35px !important;
      }
    }
  }

  .dynamic-report-wrapper {
    padding: 10px 0 15px 15px;
  }

  .algo-planner-accordion {
    padding: 40px 25px 0;

    .graph-legends {
      .legend-label-text {
        font-size: var(--font-size-12);
      }
    }
  }

  .disti-dropdown {
    .p-multiselect {
      height: 40px;

      @media (max-width: 1800px) {
        height: 35px;
      }
    }

    .p-dropdown {
      height: 40px;

      @media (max-width: 1800px) {
        height: 35px;
      }

      .p-inputtext {
        font-size: var(--font-size-12);
      }
    }
  }

  .item-search-wrapper {

    .item-search-btn {
      height: 45px;

      i {
        font-size: var(--font-size-26);
        max-width: 26px;
      }

      .btn {
        height: 45px;
        @include border-radius(0);
      }
    }
  }

  app-admin-settings {
    .mat-tab-header {
      margin: var(--value-0);
      margin-top: var(--value-10);
      margin-left: 25px;
    }

    .item-search-wrapper {
      padding-top: var(--value-0);
    }
  }

  .items-wrapper-grid {
    padding: 0 25px;
  }

  // .mat-select {

  //   .mat-select-value-text,
  //   .mat-select-placeholder,
  //   .mat-select-arrow {
  //     color: var(--white) !important;
  //   }
  // }
}

// ---------------------------------------------------------
// @Page Container
// ---------------------------------------------------------

.page-container {
  padding: var(--value-0);
  min-height: 100vh;
  transition: all 0.2s ease;
}

//dashboard clear btbn
.dashboard-clear-btn {
  background: var(--secondary-carnation);
  color: var(--white) !important;
  box-shadow: 0px 4px 20px rgba(63, 63, 63, 0.1) !important;
  width: 25px !important;
  height: 25px !important;
  margin-left: 5px;
  @include flex-center(flex, center, center);
  cursor: var(--cursor-pointer);
  @include border-radius(50%);

  .material-icons {
    font-size: 20px !important;
  }
  img{
    max-width: 14px;
    position: relative;
    left: -1px;
  }
}

.bot-img {
  position: var(--position-absolute);
  left: 14px;
  top: 2px;
  z-index: var(--z-index-2);

  img {
    @media (max-width: $media-breakpoint-xxl-screen-down) {
      max-width: 36px;
    }
  }
}

.mat-drawer-backdrop {
  top: 40px !important;
  position: var(--position-fixed) !important;

  @media (min-width: $media-breakpoint-lg-up) {
    display: none !important;
  }
}

//left side nav
.sideBar {
  position: var(--position-fixed);
  top: var(--value-0);
  bottom: var(--value-0);
  left: var(--value-0);
  width: 50px;
  z-index: var(--z-index-9);
  background-color: var(--primary-purple);
  display: var(--display-none);

  @media (min-width: 1025px){
    display: var(--display-block);
  }

  .logo-img {
    max-width: 50px;
    margin: 16px 10px 20px;

    @media (max-width: $media-breakpoint-xxl-screen-down) {
      max-width: 35px;
      margin: 11px 7px 11px;
    }
  }

  ul {
    padding: var(--value-0);
    list-style-type: var(--display-none);
    margin-bottom: var(--value-0);

    li {
      cursor: var(--cursor-pointer);
      padding: 12px 10px;
      height: 60px;
      @include flex-center(flex, center, center);

      .material-icons {
        font-size: 40px;
      }

      img {
        max-width: 35px;

        @media (max-width: $media-breakpoint-xxl-screen-down) {
          max-width: 25px;
        }
      }
    }
  }
}

.company-holder-col-footer {
  text-align: var(--text-center);
  position: var(--position-absolute);
  bottom: var(--value-0);
  padding-bottom: 6px;
  width: 69px;
  z-index: var(--z-index-9);

  @media (max-width: $media-breakpoint-xxl-screen-down) {
    width: 50px;
  }

  .cv-tos-holder {
    font-size: var(--font-size-10);
    margin-top: 8px;

    @media (max-width: $media-breakpoint-xxl-screen-down) {
      display: flex;
      flex-direction: column;
    }
  }

  .white-text {
    color: var(--white);
  }
}

//left sidenav
.company-user-expansion {
  &.mat-expanded {
    .mat-expansion-panel-header {
      background-color: var(--Purple) !important;

      &:hover {
        background-color: transparent !important;
      }

      .mat-expansion-panel-header-title {
        p {
          color: var(--white);
        }
      }

      .mat-expansion-indicator {
        &:after {
          color: var(--white) !important;
        }
      }
    }
  }

  .mat-expansion-panel-header {
    &:hover {
      background-color: transparent !important;
    }
  }

  .mat-expansion-panel-header-title {
    @include flex-align(flex !important, center);

    p {
      font-size: var(--font-size-12);
      width: var(--width-100);
      overflow: var(--overflow-hidden);
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 10px;
      text-align: var(--text-center);
      margin: var(--value-0);
      color: #b732af;
      font-weight: var(--font-weight-500);
    }
  }
}

.company-user-name {
  height: 75px;
  @include flex-align(flex, center);
  padding: var(--value-0);
  border-bottom: 1px solid #b9b9b9;

  @media (max-width: $media-breakpoint-xxl-screen-down) {
    height: 50px;
  }

  p {
    font-weight: var(--font-weight-500);
    margin: var(--value-0);
    font-size: var(--font-size-16);
    color: #b732af;
    overflow: var(--overflow-hidden);
    text-overflow: ellipsis;

    @media (max-width: $media-breakpoint-xxl-screen-down) {
      font-size: var(--font-size-14);
    }
  }

  .mdc-button {
    width: var(--width-100);
    height: var(--height-100);
    background-color: var(--white);
    @include border-radius(0);
    padding: 0 6px;

    @media (min-width: $media-breakpoint-xxl-screen-up) {
      padding: 0 15px;
    }

    &[aria-expanded="true"] {
      background-color: var(--Purple);

      p {
        color: var(--white);
      }

      .icon-user {
        color: var(--white) !important;
      }

      .material-icons {
        color: var(--white) !important;
        transform: rotate(270deg);
      }
    }

    img {
      @media (max-width: 1800px) {
        width: 25px;
        height: 25px;
      }
    }

    .icon-user {
      font-size: var(--font-size-30);
      color: var(--black);

      @media (max-width: $media-breakpoint-xxl-screen-down) {
        font-size: var(--font-size-20);
      }
    }

    .material-icons {
      color: #7d7d7d;
      font-size: 40px;

      @media (max-width: $media-breakpoint-xxl-screen-down) {
        font-size: var(--font-size-20);
      }
    }
  }

  .mat-select {

    .mat-select-value-text,
    .mat-select-placeholder,
    .mat-select-arrow {
      color: var(--white) !important;
      font-weight: normal !important;
    }
  }
}

.user-logout {
  margin: var(--value-0);
  @include border-radius(0);
  width: 239px;
  border-top: 1px solid var(--white);

  .p-dropdown {
    padding: 10px 15px;
    font-size: var(--font-size-12);
    height: 38px;
    background-color: transparent;

    .p-inputtext {
      color: var(--white) !important;
    }

    .p-dropdown-trigger {
      color: var(--white);
    }
  }

  .mat-mdc-menu-content {
    background-color: var(--secondary-carnation);
    background-image: linear-gradient(0deg, #f04371 0%, #970999 100%);
    color: var(--white);
    padding-top: 0 !important;
    padding-bottom: 0 !important;



    .mat-select {
      padding: 10px 15px;
      font-size: var(--font-size-12);
    }

    .mat-select-value {
      color: var(--white);
    }

    .mat-select-arrow {
      color: var(--white) !important;
    }

    .mat-mdc-menu-item,
    .mat-mdc-list-item {
      line-height: 35px;
      height: 35px;
      min-height: 35px;
      padding: 0 15px;
      display: block;

      .mat-mdc-menu-item-text {

        color: var(--white);
        font-size: var(--font-size-12);
      }

    }

    .mat-mdc-list-item {
      .mdc-label {
        margin: var(--value-0);
        color: var(--white);
        font-size: var(--font-size-12);
        padding-left: var(--value-10);
      }
    }

    .logout {
      border-top: 1px solid var(--white);
      padding: 10px 15px;

      a {
        color: var(--white);
        font-size: var(--font-size-12);
      }
    }
  }
}

//algo sidenav
.algo-sidenav {
  width: 240px;
  color: var(--white);
  z-index: var(--z-index-9999);
  box-shadow: var(--display-none);
  border-right: var(--display-none);
  border-right: 1px solid #666666 !important;

  @media (max-width: $media-breakpoint-xxl-screen-down) {
    width: 185px;
  }

  &.main-sidenav {
    position: var(--position-fixed) !important;
    width: 240px !important;

    @media (max-width: $media-breakpoint-xxl-screen-down) {
      width: 184px !important;
    }

    // .sidenav-algoplus{
    //   .p-accordion-tab .p-accordion-header-link .p-accordion-toggle-icon{
    //     right: 9px;
    //   }
    // }

    .sidenav-algoplus {
      overflow: auto;
      height: calc(100vh - 162px);
      width: var(--width-100);

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--Silver-Chalice);
        ;
        @include border-radius(5px);
      }

      &::-webkit-scrollbar-track {
        background-color: var(--Bon-Jour);
      }

      .single-link .mat-mdc-list-item {
        padding: 0 15px 0 16px;
      }

    }

    .company-user-name-info {
      .p-panel-header {
        height: 32px;
      }
      .p-panel-expanded {
        .p-panel-header {
          background-color: var(--Purple) !important;
          color: #fff;
          &:hover{
            .pi{
              background-color: transparent;
            }
          }
          .user-name,
          .pi {
            color: #fff !important;
          }
        }

        .p-panel-content {
          background-color: var(--secondary-carnation);
          background-image: linear-gradient(0deg, #f04371, #970999);
          color: var(--white);
          border-top: 1px solid var(--white);
          border-radius: 0;
          .company-user-expansion {
            .user-menu {
              a {
                line-height: 35px;
                height: 35px;
                min-height: 35px;
                display: block;
                padding: 0 16px;
                font-size: 12px;
                color: #fff;
              }
            }
          }
        }

      }

      .user-name {
        font-size: var(--font-size-12);
        width: var(--width-100);
        overflow: var(--overflow-hidden);
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 10px;
        text-align: var(--text-center);
        margin: var(--value-0);
        color: #b732af;
        font-weight: var(--font-weight-500);
      }

      .icon-user {
        z-index: 2;
        font-size: 14px;
      }

      .user-img {
        position: absolute;
        z-index: 2;
        left: 15px;
        top: 6px;
      }

      .p-accordion-tab {
        box-shadow: none;
        margin: 0;

        &.p-accordion-tab-active {
          .user-img {
            filter: invert(1);
          }

          .p-accordion-header-link {
            background-color: var(--Purple) !important;
            color: var(--white);
            border-bottom: 1px solid var(--white);

            &:hover {
              background-color: var(--Purple) !important;
            }

            .p-accordion-header-text {
              color: var(--white);
            }

            .p-accordion-toggle-icon {
              background-color: transparent !important;

              &:after {
                transform: rotate(45deg);
                color: var(--white);
              }
            }
          }

          .p-accordion-content {
            background-color: var(--secondary-carnation);
            background-image: linear-gradient(0deg, #f04371, #970999);
            color: var(--white);
            border-top: 1px solid var(--white);
          }
        }

        .p-accordion-content {
          padding: 0;
          border-radius: 0;

          .mat-mdc-menu-item {
            line-height: 35px;
            height: 35px;
            min-height: 35px;
            padding: 0 15px;
            display: block;

            .mat-mdc-menu-item-text {
              color: var(--white);
              font-size: var(--font-size-12);
            }
          }

          .logout {
            border-top: 1px solid var(--white);
            padding: 10px 15px;

            a {
              color: var(--white);
              font-size: var(--font-size-12);
            }
          }
        }

        .p-accordion-header-link {
          height: 32px;
          padding: 0 15px 0 15px;
          border-bottom: 1px solid #b9b9b9;
          width: 100%;
          text-decoration: none;
          color: var(--black);
          border-radius: 0;
          font-size: var(--font-size-12);
          width: var(--width-100);
          overflow: var(--overflow-hidden);
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 0 10px;
          text-align: var(--text-center);
          margin: var(--value-0);
          color: #b732af;
          font-weight: var(--font-weight-500);
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: var(--mercury) !important;

            .p-accordion-toggle-icon {
              background-color: var(--gray-100);
            }
          }

          .p-accordion-toggle-icon {
            margin: 0;
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: var(--position-absolute);
            right: 14px;
            line-height: 0;

            &:after {
              border-style: solid;
              border-width: 0px 2px 2px 0;
              content: "";
              transform: rotate(315deg);
              padding: 2px;
              color: var(--black);
            }

            .p-icon {
              display: none;
            }
          }

          .p-accordion-header-text {
            display: none;
          }
        }
      }
    }

    .p-panelmenu {
      .p-panelmenu-panel {
        box-shadow: none;
        border-radius: 0;

        .p-panelmenu-header-content {
          border-radius: 0;
        }

        .p-panelmenu-content {
          padding: 0;

          .p-submenu-list {
            padding: 0;
            margin: 0;
          }

          .p-menuitem {
            &[aria-expanded="true"] {
              .pi-angle-down:before {
                transform: rotate(180deg);
              }
            }
          }
        }

        .p-panelmenu-header {
          &[aria-expanded="true"] {
            .pi-angle-down:before {
              transform: rotate(180deg);
            }
          }
        }

      }

      .single-item {
        height: 30px !important;
        display: flex;
        align-items: center;
        padding: 0 5px 0 16px !important;
        border-bottom: 1px solid #b9b9b9;
        text-decoration: none;
        justify-content: space-between;

        &:hover {
          background-color: var(--mercury) !important;
        }

        @media (min-width: 1900px) {
          height: 40px !important;
        }

        &.level1 {
          border: none;
          padding-left: var(--value-20) !important;
          padding-right: 5px !important;
          background-color: var(--gray-100);
        }

        &.level2 {
          padding-left: 40px !important;
          background-color: var(--Alto);
        }

        .label {
          display: block;
          color: #000;
          font-size: var(--font-size-10);

          @media (min-width: 1900px) {
            font-size: var(--font-size-12);
          }
        }
      }

      .sidenav-menu-header {
        height: 30px !important;
        display: flex;
        align-items: center;
        padding: 0 14px 0 16px !important;
        border-bottom: 1px solid #b9b9b9;
        cursor: pointer;
        justify-content: space-between;

        &:hover {
          background-color: var(--mercury) !important;

          .pi {
            background-color: var(--gray-100);
          }
        }

        @media (min-width: 1900px) {
          height: 40px !important;
        }

        &.level1 {
          padding-left: 26px !important;
          background-color: var(--gray-100) !important;

          &:hover {
            background-color: var(--mercury) !important;
          }
        }

        .label {
          display: block;
          color: #000;
          font-size: var(--font-size-10);

          @media (min-width: 1900px) {
            font-size: var(--font-size-12);
          }
        }

        .pi {
          font-size: 11px;
          font-weight: bold;
          color: #000 !important;
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

        }
      }
    }

    .p-accordion-tab {
      border-radius: 0;
      margin: 0;
      box-shadow: none;
      overflow: hidden;

      &.p-accordion-tab-active {
        .p-accordion-toggle-icon {
          &:after {
            transform: rotate(45deg) !important;
          }
        }
      }

      .p-accordion-header-link {
        padding: 0 6px 0 15px;
        border-bottom: 1px solid #b9b9b9;
        width: 100%;
        height: 30px;
        text-decoration: none;
        color: var(--black);
        border-radius: 0;
        font-size: var(--font-size-10);

        @media (min-width: 1900px) {
          height: 40px;
          font-size: var(--font-size-12);
        }

        &:hover {
          background-color: var(--mercury) !important;

          .p-accordion-toggle-icon {
            background-color: var(--gray-100);
          }
        }

        .p-accordion-toggle-icon {
          margin: 0;
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          position: var(--position-absolute);
          right: 14px;
          line-height: 0;

          &:after {
            border-style: solid;
            border-width: 0px 2px 2px 0;
            content: "";
            transform: rotate(315deg);
            padding: 2px;
            color: var(--black);
          }

          .p-icon {
            display: none;
          }
        }

        .p-accordion-header-text {
          font-size: var(--font-size-10);

          @media (min-width: 1900px) {
            font-size: var(--font-size-12);
          }
        }
      }

      .p-accordion-content {
        border-radius: 0;
        padding: 0;

        .mat-mdc-nav-list {
          height: 40px;
          background-color: var(--gray-100) !important;
          padding: 0;

          &.list-height-auto {
            height: auto;
          }

          .mat-mdc-list-item {
            padding-left: var(--value-10) !important;
            background-color: var(--gray-100);
            height: 40px;
            padding-right: var(--value-0) !important;

            &.favorites {
              height: 35px;
              padding-left: var(--value-20);
              background-color: var(--gray-100);

              &:hover {
                background-color: var(--mercury);
              }

              .mdc-list-item__content {
                padding-left: 8px;

                .mdc-list-item__primary-text {
                  @include flex-justify-between(flex, center, space-between);
                }
              }
            }

            .mdc-list-item__content {
              padding-left: 15px;
            }
          }
        }


        .level2 {
          height: 40px;

          @media (max-width: 1800px) {
            height: 30px;
          }
        }
      }
    }
  }

  .sidenav-menus {
    .level1 {
      .p-accordion-tab {
        &.p-accordion-tab-active {
          .p-accordion-header-link {
            .p-accordion-toggle-icon {
              transform: rotate(-180deg);
            }
          }
        }

        .p-accordion-header-link {
          padding-left: 26px;
          background-color: var(--gray-100) !important;

          &:hover {
            background-color: var(--mercury) !important;
          }

          .p-accordion-toggle-icon {
            transform: rotate(360deg);
          }
        }
      }

      .mat-mdc-nav-list.level2 {
        .mat-mdc-list-item {
          border-radius: 0;
          padding-left: 20px !important;
          border-bottom: 1px solid #c8c6c6;
          background-color: var(--Alto) !important;
        }
      }

      .level2 {
        .p-accordion-tab {
          &.p-accordion-tab-active {
            .p-accordion-header-link {
              .p-accordion-toggle-icon {
                transform: rotate(-180deg);
              }
            }
          }

          .p-accordion-header-link {
            padding-left: 26px;
            background-color: var(--white) !important;

            &:hover {
              background-color: var(--mercury) !important;
            }

            .p-accordion-toggle-icon {
              transform: rotate(360deg);
            }
          }
        }
      }
    }

    .mat-expansion-panel .mat-expansion-panel-content .mat-mdc-list-base .mat-mdc-list-item,
    .mat-expansion-panel .mat-expansion-panel-content .mat-mdc-list-base .mat-mdc-list-option {
      padding: var(--value-0);
      margin: 0 !important;
      padding-left: 5px;
    }
  }

  .company-user-expansion.mat-expansion-panel {
    &.mat-expanded {
      .mat-expansion-panel-header {
        background-color: var(--Purple) !important;

        &:hover {
          background-color: var(--Purple) !important;

          .mat-expansion-indicator {
            background-color: transparent;
          }
        }

        .mat-expansion-panel-header-title {
          .icon-user {
            color: var(--white);
          }

          p {
            color: var(--white);
          }
        }

        .mat-expansion-indicator {
          &:after {
            color: var(--white) !important;
          }
        }
      }
    }

    .mat-expansion-panel-header {

      @media (max-width: 1800px) {
        height: 40px !important;
      }

      .mat-expansion-indicator {
        width: 16px;
        height: 16px;
        @include flex-center(flex, center, center);
        @include border-radius(50%);
      }

      &:hover {
        background-color: var(--mercury) !important;

        .mat-expansion-indicator {
          background-color: var(--gray-100);
        }
      }
    }

    .mat-expansion-panel-header-title {
      @include flex-align(flex !important, center);

      p {
        font-size: var(--font-size-12);
        width: var(--width-100);
        overflow: var(--overflow-hidden);
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 10px;
        text-align: var(--text-center);
        margin: var(--value-0);
        color: #b732af;
        font-weight: var(--font-weight-500);
      }
    }

    .mat-expansion-panel-body {
      background-color: var(--secondary-carnation);
      background-image: linear-gradient(0deg, #f04371 0%, #970999 100%);
      color: var(--white);
      border-top: 1px solid var(--white);

      .p-dropdown {
        padding: 10px 15px;
        font-size: var(--font-size-12);
        height: 38px;
        background-color: transparent;
        border: none !important;

        .p-inputtext {
          color: var(--white) !important;
        }

        .p-dropdown-trigger {
          color: var(--white);
        }
      }

      .mat-mdc-menu-item,
      .mat-mdc-list-item {
        line-height: 35px;
        height: 35px;
        min-height: 35px;
        padding: 0 15px;
        display: block;

        .mat-mdc-menu-item-text {
          color: var(--white);
          font-size: var(--font-size-12);
        }
      }

      .mat-mdc-list-item.mat-mdc-list-item-single-line.mdc-list-item--with-one-line {
        display: none;
      }

      .mat-mdc-list-item .mdc-label {
        margin: var(--value-0);
        color: var(--white);
        font-size: var(--font-size-12);
        padding-left: var(--value-10);
      }

      .logout {
        border-top: 1px solid var(--white);
        padding: 10px 15px;

        a {
          color: var(--white);
          font-size: var(--font-size-12);
        }
      }
    }
  }


  .mat-expansion-panel {
    margin: var(--value-0);
    background-color: transparent;
    box-shadow: none !important;
    @include border-radius(0 !important);
    border-bottom: var(--display-none);

    .mat-expansion-panel-header.level0 {
      padding: 0 30px 0 16px !important;
    }

    &.mat-expanded {
      .level0 {
        &:hover {
          background-color: var(--mercury) !important;
        }
      }

      .mat-expansion-panel-header {
        &.level1 {
          padding-left: 26px;
          background-color: var(--gray-100);

          &:hover {
            background-color: var(--mercury) !important;
          }
        }

        &.mat-expanded {
          &.isNested-menu {
            background-color: var(--gray-100);
          }
        }
      }

      .mat-expansion-panel-body {

        .level2 {
          padding-left: 20px !important;
          border-bottom: 1px solid #c8c6c6;

          &:last-child {
            border-bottom: var(--display-none);
          }
        }

        .mat-mdc-nav-list {
          background-color: var(--Alto);

          &:hover {
            .second-count {
              background-color: var(--Alto);
            }
          }
        }
      }

      .level1 {
        height: 40px;
        background-color: var(--gray-100) !important;

        @media (max-width: 1800px) {
          height: 30px;
        }

        &:before {
          background-color: var(--mercury) !important;
        }

        .mat-mdc-list-item {
          height: 40px !important;

          @media (max-width: 1800px) {
            height: 30px !important;
          }
        }

        .mat-mdc-list-item {
          padding-left: var(--value-10) !important;
          background-color: var(--gray-100);

          &:hover {
            background-color: var(--mercury) !important;
          }
        }

      }

      .level2 {
        height: 40px;

        @media (max-width: 1800px) {
          height: 30px;
        }
      }
    }

    .mat-expansion-panel-body {
      .mat-mdc-nav-list {
        position: var(--position-relative);

        &:hover {
          &:before {
            background-color: var(--Silver);
            opacity: 1;
          }
        }

        &::before {
          top: var(--value-0);
          left: var(--value-0);
          right: var(--value-0);
          bottom: var(--value-0);
          position: var(--position-absolute);
          content: "";
          opacity: 0;
        }

        .mat-mdc-list-item {
          &::before {
            display: var(--display-none);
          }
        }
      }
    }

    .mat-expansion-panel-content {
      background-color: transparent;

      .mat-mdc-list-base .mat-mdc-list-option,
      .mat-mdc-list-base .mat-mdc-list-item {
        height: 35px;
        padding-left: var(--value-20);

        .mdc-list-item__content {
          padding-left: 15px;
        }
      }

      .mat-mdc-list-base .mat-mdc-list-item.favorites {
        height: 35px;
        padding-left: var(--value-20);
        background-color: var(--gray-100);

        &:hover {
          background-color: var(--mercury);
        }

        .mdc-list-item__content {
          padding-left: 8px;

          .mdc-list-item__primary-text {
            @include flex-justify-between(flex, center, space-between);
          }

          &:hover {
            .icon-more-data {
              left: -12px;
              transition: all ease 0.4s;
            }
          }
        }
      }
    }

    .mat-expansion-panel-header {
      padding: 0 30px 0 15px;
      border-bottom: 1px solid #b9b9b9;
      width: 239px;

      @media (max-width: $media-breakpoint-xxl-screen-down) {
        width: 184px;
      }

      &:hover {
        background-color: var(--mercury) !important;

        .mat-expansion-indicator {
          background-color: var(--gray-100);
        }
      }

      @media (min-width: $media-breakpoint-xxl-screen-up) {
        height: 60px !important;
      }

      .mat-expansion-indicator {
        position: var(--position-absolute);
        right: 14px;
        line-height: 0;
        width: 16px;
        height: 16px;
        @include flex-center(flex, center, center);
        @include border-radius(50%);
      }
    }

    .mat-expansion-panel-body {
      padding: 0 !important;

      .mat-mdc-list-base {
        padding: var(--value-0);
        @include flex-align(flex, center);
      }
    }

    .mat-expansion-panel-header-title {
      font-size: var(--font-size-12);
      margin-right: 5px;
      font-weight: normal;

      @media (max-width: 1800px) {
        font-size: var(--font-size-10);
      }

      @media (min-width: $media-breakpoint-xxl-screen-up) {
        font-size: var(--font-size-14);
      }

      .material-icons {
        font-size: var(--font-size-20);
        padding-right: var(--value-10);
      }
    }
  }

  .single-link {
    padding: var(--value-0);
    max-height: 75px;
    height: 75px;
    @include flex-align(flex, center);
    padding: var(--value-0);
    border-bottom: 1px solid #b9b9b9;
    position: var(--position-relative);

    &:hover {
      .second-count {
        background-color: var(--gray-100);
      }

      &:before {
        background-color: var(--mercury);
        opacity: 1;
      }
    }

    &::before {
      top: var(--value-0);
      left: var(--value-0);
      right: var(--value-0);
      bottom: var(--value-0);
      position: var(--position-absolute);
      content: "";
      opacity: 0;
    }

    .mat-mdc-list-item {
      font-weight: var(--font-weight-400);
      font-size: var(--font-size-12);

      &:before {
        display: var(--display-none);
      }

      @media (min-width: $media-breakpoint-md-up) {
        font-size: var(--font-size-14);
      }

      &:hover {
        background-color: transparent;
      }
    }

    .mdc-list-item__content {
      .menu-name {
        display: -webkit-box;
        max-width: 190px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: var(--overflow-hidden);
        word-break: break-word;
        font-size: var(--font-size-12);
        color: var(--black);
        white-space: normal;
        line-height: 14px;

        @media (max-width: 1800px) {
          font-size: var(--font-size-10);
        }
      }
    }

    .mat-mdc-list-item {
      max-height: 30px;
      height: 30px;
      padding: 0 15px;

      @media (min-width: $media-breakpoint-xxl-screen-up) {
        max-height: 40px;
        height: 40px;
      }
    }
  }

  .second-count {
    position: var(--position-relative);
    transition: all ease 0.5s;
    width: 35px;
    height: 35px;
    min-width: 35px;
    @include border-radius(50%);
    margin-right: 5px;
    @include flex-center(flex, center, center);
    cursor: var(--cursor-pointer);
    margin-top: var(--value-0);

    @media (max-width: 1800px) {
      width: 30px;
      min-width: 30px;
      height: 30px;
    }

    &:hover {
      transition: all ease 0.5s;
      background-color: var(--secondary-carnation) !important;
      box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
      // @media (max-width: 1800px) {
      //   width: 37px;
      //   min-width: 37px;
      //   height: 37px;
      // }

      .material-icons {
        color: var(--white);
        transform: scale(1.2);
        transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }

    .material-icons {
      font-size: var(--font-size-20);
      color: var(--rolling-stone);

      @media (max-width: 1800px) {
        font-size: var(--font-size-18);
      }
    }

    .count {
      margin: var(--value-0);
      position: var(--position-absolute);
      background-color: var(--primary-purple);
      color: var(--white);
      @include border-radius(50%);
      height: 14px;
      width: 14px;
      right: var(--value-0);
      top: 2px;
      font-size: var(--font-size-10);
      @include flex-center(flex, center, center);
      line-height: inherit;
      font-family: var(--font-family) !important;

      @media (max-width: 1800px) {
        height: 12px;
        width: 12px;
        font-size: 9px;
      }
    }
  }

  .arrow {
    @include flex-align(flex, center);
    padding-left: var(--value-0);
    position: var(--position-relative);
    font-size: var(--font-size-12);
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: var(--overflow-hidden);
    padding-right: 5px;
    line-height: 14px;

    &:hover {
      display: var(--display-flex);
      line-height: 14px;
    }

    @media (max-width: 1800px) {
      font-size: var(--font-size-10);
    }

    i {
      font-size: var(--font-size-10);
      position: var(--position-absolute);
      left: -15px;
      transition: all ease 0.5s;
    }
  }
}

//top bar
.mat-toolbar {
  position: var(--position-fixed);
  top: var(--value-0);
  z-index: var(--z-index-9999);
  min-height: 56px;
  margin-bottom: 35px !important;
  background-color: var(--primary-purple) !important;
  box-shadow: 2px 6px 6px -8px rgba(0, 0, 0, 0.2);

  @media (max-width: $media-breakpoint-xxl-screen-down) {
    min-height: 50px;
    margin-bottom: var(--value-20);
  }

  .mat-toolbar-row {
    height: 56px;
    justify-content: var(--justify-between);
    padding-right: 60px;

    @media (max-width: 768px) {
      padding-right: var(--value-0);
    }

    @media (max-width: $media-breakpoint-xxl-screen-down) {
      height: 50px;
    }
  }

  .right-user {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 5px;

    @media (min-width: $media-breakpoint-lg-up) {
      position: var(--position-fixed);
      right: var(--value-0);
    }

    @media (max-width: 768px) {
      padding-right: 45px;
    }

    .btn-wrapper {
      margin-left: var(--value-20);

      .mdc-button {
        color: var(--white);
        background-color: var(--French-Rose);

        &:focus {
          outline: var(--display-none);
        }

        .icon-down {
          margin-left: var(--value-10);
        }
      }

      .user-logo {
        width: 40px;
        height: 40px;
        margin-right: var(--value-10);
      }
    }

    .microsoft {
      max-width: 50px !important;
      width: 50px !important;
    }
  }

  .toggleBtn {
    width: 20px !important;
    height: 20px !important;
    background: var(--secondary-carnation);
    box-shadow: 0px 4px 20px rgba(63, 63, 63, 0.1) !important;
    position: var(--position-relative);
    border: var(--display-none);
    @include flex-center(flex, center, center);
    @include border-radius(50%);
    min-width: 20px;
    padding: 0;

    &:focus {
      outline: none !important;
    }

    &:after {
      border-style: solid;
      border-width: 0 2px 2px 0;
      content: "";
      display: var(--display-inline-block);
      font-size: 15px !important;
      color: var(--white) !important;
      padding: 3px;
      vertical-align: middle;
      transform: rotate(-45deg);
      position: var(--position-absolute);
      left: 4px;
      transition: all .5s ease;
    }
  }
}

//right sidenav
.right-sidenav {
  position: var(--position-fixed);
  right: var(--value-0);
  display: var(--display-flex);
  top: var(--value-0);
  bottom: var(--value-0);
  z-index: var(--z-index-9);
  padding: 40px 0 70px;
  background-color: var(--gray-100);
  border-left: 1px solid #dfdddd;

  #rightSidenav {
    display: var(--display-flex);
    flex-direction: unset;

    &.icon-bg-color {
      .sidenav-icon .sidenav-icons {
        background: #d5d1d191 !important;

        &:hover {
          transition: all ease 0.3s;
          background: var(--secondary-carnation) !important;

          .material-icons,
          .material-symbols-outlined {
            color: var(--white);
          }
        }
      }
    }

    .mat-drawer-content {
      background-color: var(--gray-100);
      border-left: 1px solid #dfdddd;
      width: 50px;
      margin-right: 0 !important;
      color: var(--white);
      padding: 56px 0 70px;
      @include flex-align(flex, center);
      flex-direction: column;
      overflow: var(--overflow-hidden);

      .mat-icon-button {
        margin-bottom: var(--value-20);
        box-shadow: var(--display-none);
        transition: all ease 0.3s;
        position: var(--position-relative);

        .material-icons {
          color: var(--rolling-stone);
        }

        &:before {
          content: "";
          position: var(--position-absolute);
          bottom: -6px;
          height: 1px;
          background-color: var(--Silver-Chalice);
          left: -5px;
          right: -5px;
        }

        &:focus {
          outline: var(--display-none);
        }

        &:hover {
          transition: all ease 0.3s;
          background-color: var(--secondary-carnation);
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
            0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

          @media (max-width:425px) {
            width: 36px;
            height: 36px;
          }

          .material-icons {
            color: var(--white);
          }
        }
      }
    }

    &.hide-sidenav {
      .mat-drawer-content {
        // margin-right: -300px !important;
      }
    }

    &.show-sidenav {
      .button-notes {
        background-color: var(--secondary-carnation);
        position: var(--position-relative);
        transition: all ease 0.3s;

        &:after {
          content: " ";
          position: var(--position-absolute);
          width: 3px;
          height: 40px;
          background-color: var(--secondary-carnation);
          right: -20px;
          border-radius: 3px 0 0 3px;
        }

        &:hover {
          transform: all ease 0.3s;
          background-color: var(--secondary-carnation);
        }
      }
    }
  }

  .mat-drawer {
    width: 300px !important;
    background-color: var(--white);

    @media (min-width: 769px) {
      padding-top: var(--value-40);
    }
  }
}

.notes-head {
  box-shadow: none !important;
  border-bottom: 1px solid var(--Concrete);
  @include border-radius(0 !important);
  padding: 15px 18px 15px 10px !important;
  @include flex-justify-between(flex, center, space-between);

  h2 {
    font-size: var(--font-size-14);
    margin: var(--value-0);
    font-weight: var(--font-weight-500);
  }

  .notes-actions {
    height: 24px;

    .material-icons {
      font-size: var(--font-size-22);
    }
  }

  // .mat-card-header {
  //   display: flex;
  //   align-items: center;
  //   justify-content: var(--justify-between);

  //   .mat-card-header-text {
  //     display: var(--display-none);
  //   }

  //   .mat-card-title {
  //     color: #80868b;
  //     font-size: var(--font-size-12);
  //     font-weight: var(--font-weight-500);
  //     letter-spacing: 1.5px;
  //     text-transform: uppercase;
  //     outline: var(--display-none);
  //     margin-bottom: var(--value-0);
  //   }

  //   .mat-card-subtitle {
  //     margin: var(--value-0);
  //     font-size: var(--font-size-16);
  //     font-weight: var(--font-weight-500);
  //     line-height: 20px;
  //     color: #5f6368;
  //     margin-top: 0 !important;
  //   }

  //   .notes-actions {
  //     display: flex;
  //     align-items: center;

  //     .material-icons {
  //       width: 40px;
  //       height: 40px;
  //       @include border-radius(50%);
  //       display: flex;
  //       align-items: center;
  //       justify-content: var(--justify-center);
  //       font-size: var(--font-size-22);
  //       transition: all ease 0.3s;
  //       cursor: var(--cursor-pointer);

  //       &:hover {
  //         transition: all ease 0.3s;
  //         background-color: var(--Solitude);
  //         box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  //           0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  //       }
  //     }
  //   }
  // }
}

.notes-search {
  padding: var(--value-10);
  position: var(--position-relative);

  .mat-icon {
    position: var(--position-absolute);
    right: var(--value-10);
    font-size: var(--font-size-22);
    top: 15px;
  }

  input {
    background-color: var(--gray-100);
    border: var(--display-none);
    height: 30px;
    width: var(--width-100);
    color: var(--color-30);
    font-size: var(--font-size-12);
    padding: 0 25px 0 10px;

    &::placeholder {
      font-size: var(--font-size-12);
      color: var(--color-30);
    }
  }
}

.notes-inner-body {
  padding: 0 10px 10px 10px;

  .notes-action-btn {
    .btn {
      width: var(--width-100);
      padding: 0 0 0 10px;
      transition: all ease 0.3s;
      box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
      color: black;
      @include flex-justify-between(flex, center, space-between);
      font-size: var(--font-size-12);

      &:hover {
        background-color: var(--Concrete)45;
        transition: all ease 0.3s;
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
          0 1px 3px 1px rgba(60, 64, 67, 0.15);

        .icon-add {
          color: var(--white);
          background-color: var(--secondary-carnation);
          transition: all ease 0.3s;
        }
      }

      .mdc-button-wrapper {
        @include flex-align(flex, center);
      }

      .icon-add {
        width: 30px;
        height: 30px;
        @include flex-center(flex, center, center);
        @include border-radius(50%);
        margin: 0 10px;
      }
    }
  }

  .notes-final-view,
  .notes-list {
    .mat-card {
      border: 1px solid #f1f1f1;
      transition: all ease 0.3s;
      box-shadow: var(--display-none);

      &:hover {
        transition: all ease 0.3s;
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
          0 1px 3px 1px rgba(60, 64, 67, 0.15);
      }

      .mdc-button {
        padding: var(--value-0);
        min-width: inherit;
        width: 40px;
        height: 40px;
        @include border-radius(50%);
        background-color: var(--color-9e);
        transition: all ease 0.3s;

        &:hover {
          transition: all ease 0.3s;
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
            0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        }
      }
    }

    .action-panel {
      padding: var(--value-0);

      .mat-raised-button {
        &:hover {
          background-color: var(--color-9e);
          transition: all ease 0.3s;
        }
      }
    }
  }

  .notes-list {
    .material-icons {
      font-size: var(--font-size-22);
      position: var(--position-absolute);
      top: 5px;
      right: 5px;
    }

    .mat-card {
      @include flex-justify-between(flex, center, space-between);
      padding: 10px 15x 10px 10px;

      .mat-card-header {
        color: var(--black);
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-500);
        word-break: break-all;

        h5 {
          font-size: var(--font-size-14);
          word-break: break-all;
        }
      }

      .mat-card-content {
        margin: var(--value-0);
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-500);
        line-height: 20px;
        color: var(--black);
        word-break: break-all;

        h6 {
          font-size: var(--font-size-12);
          word-break: break-all;
          white-space: break-spaces;
        }
      }
    }
  }
}

.notes-cards {
  padding-left: var(--value-10);
  padding-top: var(--value-10);
  padding-right: var(--value-10);
  padding-bottom: var(--value-50);

  .note-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 !important;
    padding: var(--value-0);

    .btn {
      min-width: 80px;
      height: 40px;
    }
  }

  .notes-field {
    margin-bottom: 10px;

    input,
    textarea {
      background-color: var(--gray-100);
      border: var(--display-none);
      height: 30px;
      width: var(--width-100);
      color: var(--color-30);
      font-size: var(--font-size-12);
      padding: 0 10px 0 10px;
      margin-bottom: var(--value-10);

      &::placeholder {
        font-size: var(--font-size-12);
        color: var(--color-30);
        font-weight: var(--font-weight-500);
      }
    }

    textarea {
      resize: var(--display-none);
      height: 100px;
      padding: 5px 10px;
    }
  }

  .mat-mdc-card {
    padding: var(--value-10);
    margin-bottom: var(--value-20);

    .mat-mdc-card-content {
      padding: var(--value-0);
      margin: var(--value-0);
      font-size: var(--font-size-14);
      font-weight: var(--font-weight-500);
      word-break: normal;
      text-align: justify;

    }

    .material-icons {
      font-size: var(--font-size-22);
    }



    .mat-mdc-card-actions {
      @include flex-justify-between(flex, center, space-between);
      margin: 0 !important;
      padding: var(--value-0);

      .mat-checkbox {
        .mat-checkbox-label {
          font-size: var(--font-size-12);
        }
      }

      .btn {
        min-width: 80px;
        height: 40px;

        @media (max-width: 1800px) {
          min-width: 70px;
          height: 32px;
        }
      }
    }
  }
}

.card-details {
  padding: var(--value-10);
  margin-bottom: var(--value-20);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  border-style: solid;
  border-width: 0;
  background-color: var(--mdc-elevated-card-container-color, var(--mat-app-surface));
  border-color: var(--mdc-elevated-card-container-color, var(--mat-app-surface));
  border-radius: var(--mdc-elevated-card-container-shape, var(--mat-app-corner-medium));
  box-shadow: var(--mdc-elevated-card-container-elevation, var(--mat-app-level1));

  .card-header {
    margin-bottom: var(--value-10);
    @include flex-justify-between(flex, center, space-between);
    padding: var(--value-0);

    .zoom-in-out {
      max-width: 16px;
      cursor: var(--cursor-pointer);
      padding-top: 3px;
    }

    .mat-mdc-card-header-text {
      display: var(--display-none);
    }

    h3 {
      padding: var(--value-0);
      margin: var(--value-0);
      font-size: var(--font-size-14);
      font-weight: var(--font-weight-500);
      word-break: normal;
      text-align: justify;
    }
  }

  h5 {
    font-size: var(--font-size-14);
    margin: var(--value-0);
    max-width: 250px;
    overflow: var(--overflow-hidden);
    text-overflow: ellipsis;
  }

  h6 {
    font-size: var(--font-size-12);
  }
}

.notification-alert {
  .notifications-alerts {
    position: var(--position-fixed);
    top: 32px;
    right: 51px;
    z-index: var(--z-index-9);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: var(--white);
    @include border-radius(5px);
    width: 460px;
    height: 504px;
    max-height: 504px;
    animation-duration: 800ms;
    animation-name: bounce_notify;
    transition-timing-function: ease-in;

    @media (max-width: $media-breakpoint-lg-down) {
      overflow-y: auto;
      width: 420px;
      height: 520px;
      max-height: 520px;
    }

    @media (max-width: 768px) {
      width: 300px;
      height: 500px;
      max-height: 500px;
      right: var(--value-0);
    }

    @media (max-width: 1800px) {
      width: 430px;
      height: 493px;
      max-height: 493px;
    }

    .p-tabview .p-tabview-nav .p-tabview-nav-link {
      color: var(--secondary-carnation) !important;
      padding: var(--value-10);
      width: auto;
      max-width: 160px;
      min-width: auto;
    }

    .count,
    .p-badge {
      width: 24px;
      height: 24px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary-purple);
      border-radius: 50%;
      margin-left: var(--value-10);
      color: #fff;
      font-size: var(--font-size-11);
      font-weight: var(--font-weight-500);
      letter-spacing: 0;
      line-height: 0;
      min-width: 24px;
      min-height: 24px;
      @media (max-width: 1800px){
        height: 12px;
        width: 12px;
        min-width: 12px;
        padding: 0;
        font-size: 9px;
      }
    }

    .material-icons {
      font-size: var(--font-size-18);
      margin-left: 5px !important;
    }
  }

  .notification-head {
    @include flex-justify-between(flex, center, space-between);
    padding: 15px;
    border-top-left-radius: 5px;
    position: var(--position-relative);
    border-top-right-radius: 5px;

    @media (max-width: 1800px) {
      padding: 12px;
    }
  }

  .notification-title {
    @include flex-align(flex, center);

    @media (max-width: 767px) {
      width: var(--width-100);
      justify-content: flex-start;
      margin-bottom: var(--value-0);
    }

    h2 {
      color: var(--black);
      font-size: var(--font-size-16);
      font-weight: var(--font-weight-500);
      @include flex-align(flex, center);

      @media (max-width: 1800px) {
        font-size: var(--font-size-14);
      }

      .mat-icon {
        margin-left: var(--value-10);
        font-size: var(--font-size-19);
        @include flex-center(flex, center, center);

        @media (max-width: 1800px) {
          margin-left: 8px;
          font-size: var(--font-size-16);
        }
      }

      @media (max-width: 767px) {
        font-size: var(--font-size-14);
      }
    }
  }

  .btn-primary {
    background-color: var(--black);
    color: var(--white);
    line-height: 30px;
    padding: var(--value-0);
  }

  .notification-cancel {
    .material-icons {
      @media (max-width: 1800px) {
        font-size: var(--font-size-18);
      }
    }
  }

  .btn-light {
    @include border-radius(20px);
    padding: 0 10px;
    line-height: 30px;
    color: var(--French-Rose);
    display: var(--display-flex);
    background-color: var(--white);

    .material-icons {
      font-size: var(--font-size-22);
      padding-right: var(--value-10);
    }
  }

  .all-notify {
    @include flex-center(flex, center, center);
    color: var(--French-Rose);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-500);
    background-color: var(--Concrete);
    margin-left: 8px;
    margin-right: 15px;
    border-radius: 4px;
    height: 50px;
    text-decoration: var(--display-none);

    @media (max-width: 1800px) {
      font-size: var(--font-size-12);
      height: 40px;
    }
  }

  .p-virtualscroller-content {
    padding: var(--value-0);
  }

  .mat-mdc-tab-header {
    border-bottom: 1px solid var(--Concrete);
    margin-bottom: var(--value-0);
    padding: 2px;

    .mat-mdc-tab {
      border: var(--display-none);
      width: auto;
      max-width: 160px;
      height: 40px !important;
      background: transparent;
      min-width: unset;
      padding: var(--value-10);
      margin-right: 15px;
      color: #7f7f7f;

      .mdc-tab__ripple,
      .mat-mdc-tab-ripple {
        display: var(--display-none);
      }

      .mdc-tab__content {
        height: auto;

        .mdc-tab__text-label {
          color: var(--secondary-carnation) !important;
        }
      }

      &.mdc-tab--active {
        box-shadow: var(--display-none);
        border-top-color: transparent;
        border-bottom: 2px solid var(--secondary-carnation);
        color: var(--secondary-carnation);

        .material-icons {
          font-size: var(--font-size-18);
          margin-left: 5px !important;
        }
      }

      .count {
        width: 24px;
        height: 24px;
        padding: 5px;
        @include flex-center(flex, center, center);
        background-color: var(--primary-purple);
        @include border-radius(50%);
        margin-left: var(--value-10);
        color: #ffff;
        font-size: var(--font-size-11);
        font-weight: var(--font-weight-500);
      }
    }
  }

  .alerts-icon {
    width: 50px;
    min-width: 50px;
    height: 50px;
    @include border-radius(50%);
    @include flex-center(flex, center, center);
    color: var(--white);

    &.info-icon {
      .mat-icon {
        transform: rotate(-180deg);
      }
    }

    &.success-alert {
      background-color: rgba($color: #00cc99, $alpha: 0.1);
      color: #00cc99;
    }

    &.error-alert {
      background-color: rgba($color: #eb5757, $alpha: 0.1);
      color: #eb5757;
    }

    &.warning-alert {
      background-color: rgba($color: #f2c94c, $alpha: 0.1);
      color: #f2c94c;
    }

    &.info-alert {
      background-color: rgba($color: #5458f7, $alpha: 0.1);
      color: #5458f7;
    }
  }

  .notification-wrapper {
    overflow-y: auto;
    height: 410px;
  }

  .notifications-info-count {
    padding: 15px;

    h6 {
      color: var(--black);
      font-size: var(--font-size-14);
      font-weight: var(--font-weight-500);
      @include flex-align(flex, center);
      margin: var(--value-0);

      .count {
        background: linear-gradient(0deg, #f8486e, #f8486e), #d81ad1;
        width: 20px;
        height: 20px;
        @include border-radius(50%);
        @include flex-center(flex, center, center);
        color: var(--white);
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-400);
        margin-left: var(--value-10);
      }
    }
  }

  .notification-desc {
    padding: 8px;
    border: 1px solid #ECECEC;
    cursor: var(--cursor-pointer);
    border-radius: 4px;
    margin: 8px;
    position: relative;

    .action-items {
      position: absolute;
      right: 8px;
      top: 8px;

      .material-icons {
        width: 18px;
        height: 18px;
      }

      .notif-read,
      .notif-unread {
        max-width: 14px;
        height: auto;
        margin-left: 5px;
      }

      .notif-unread {
        display: none;
      }
    }

    &.alerts {
      min-height: 76px;
      justify-content: var(--justify-between);
    }

    &.notif {
      min-height: 76px;
      justify-content: var(--justify-between);
    }

    ul {
      padding-left: 18px;
      margin: var(--value-0);
      font-size: var(--font-size-12);
    }

    &.unread {
      background-color: var(--gray-100);

      .action-items {
        .notif-unread {
          display: block;
        }

        .notif-read {
          display: none
        }
      }
    }

    .notification-icon {
      @include border-radius(50%);
      min-width: 50px;
      height: 50px;
      @include flex-center(flex, center, center);
      background-color: var(--primary-purple);
      background-image: linear-gradient(270deg, #8d009d 0%, #ec4173 100%);

      @media (max-width: 1800px) {
        min-width: 45px;
        height: 45px;
      }

      img {
        max-width: 30px;
        height: auto;

        @media (max-width: 1800px) {
          max-width: 20px;
        }
      }

      .material-icons {
        color: var(--white);
        font-size: 36px;
      }
    }

    img {
      width: 50px;
      height: 50px;
    }

    .notification-desc-info {
      padding-left: 8px;
      padding-right: 70px;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .username {
        margin: var(--value-0);
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-500);
      }

      .desc {
        margin: var(--value-0);
        font-size: var(--font-size-12);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .time {
        font-size: var(--font-size-12);
        color: #7f7f7f;
      }
    }
  }
}

.notification-new {
  padding: var(--value-10);

  .notification-info {
    @include flex-between(flex, space-between);
    padding: var(--value-10);
    border-bottom: 1px solid #9e9e9e;

    .actions {
      @include flex-justify-between(flex, center, space-between);
      flex-direction: column;

      span {
        font-size: var(--font-size-21);
      }
    }

    &.notify-read {
      background-color: var(--Athens-Gray);

      .notify {
        background-color: rgba(#232323, 0.2);
      }

      .notify,
      .check {
        color: var(--black);
      }
    }
  }

  .notification-details {
    display: var(--display-flex);

    .notify {
      font-size: var(--font-size-24);
      color: var(--French-Rose);
      background-color: rgba(#ec4173, 0.2);
      margin-right: var(--value-10);
      height: 30px;
      width: 30px;
      @include border-radius(50%);
      padding: var(--value-0);
      @include flex-center(flex, center, center);
      position: var(--position-relative);
    }

    p {
      white-space: normal;
      font-size: var(--font-size-14);
      max-width: 400px;
      color: var(--French-Rose);
      line-height: 20px;
    }

    .notification-time {
      @include flex-align(flex, center);
      color: #9e9e9e;

      .material-icons {
        font-size: var(--font-size-16);
        padding-right: 8px;
      }

      p {
        line-height: 0;
        margin-bottom: var(--value-0);
        font-size: var(--font-size-12);
        color: #9e9e9e;
      }
    }
  }
}

.notify-area {
  max-height: 460px;
  overflow: auto;
}

.sidenav-icon {
  width: 50px;
  @include flex-center(flex, center, center);
  border-bottom: var(--value-0);

  @media (min-width: 769px) {
    border-bottom: 1px solid #dfdddd;
  }

  @media (max-width: 1800px) {
    height: 40px;
  }

  height: 50px;

  .sidenav-icons {
    position: var(--position-relative);
    transition: all ease-out 0.5s;
    width: 35px;
    height: 35px;
    @include border-radius(50%);
    @include flex-center(flex, center, center);
    cursor: var(--cursor-pointer);
    box-shadow: 0px 4px 20px rgba(63, 63, 63, 0.1) !important;
    background: rgba(213, 209, 209, 0.568627451) !important;

    @media (max-width: 1800px) {
      width: 30px;
      height: 30px;
    }

    &:hover {
     transition: all ease 0.3s;
      background: var(--secondary-carnation) !important;

      .material-icons {
        color: var(--black);
        // transform: scale(1.2);
        transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
      }

      .pi {
        color: var(--white);
      }
    }

    .material-icons,
    .material-symbols-outlined {
      font-size: var(--font-size-20);
      color: var(--black);
      width: 18px;

      @media (max-width: 1800px) {
        font-size: var(--font-size-18);
      }

      // @media (min-width: 769px) {
      //   color: var(--rolling-stone);
      // }
    }

    .count {
      margin: var(--value-0);
      position: var(--position-absolute);
      background-color: var(--primary-purple);
      color: var(--white);
      @include border-radius(50%);
      height: 16px;
      width: 16px;
      right: 0px;
      top: 0px;
      font-size: var(--font-size-10);
      @include flex-center(flex, center, center);
      line-height: inherit;
      padding-top: 2px;
      font-family: var(--font-family) !important;

      @media (max-width: 1800px) {
        height: 12px;
        width: 12px;
      }
    }

    .pi {
      font-size: 16px;
      color: var(--black);

    }

    .p-badge {
      animation: bounce2 2s ease infinite;
      margin: var(--value-0);
      position: var(--position-absolute);
      background-color: var(--primary-purple);
      color: var(--white);
      border-radius: 50%;
      height: 16px;
      width: 16px;
      right: -10px;
      top: -10px;
      font-size: var(--font-size-10);
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: inherit;
      padding-top: 2px;
      font-family: var(--font-family) !important;
      min-width: 16px;
      transform: none;
      transform-origin: inherit;
    }
  }
}

//algo bottom search bar
.page-head {
  img {
    @media (max-width: $media-breakpoint-xxl-screen-down) {
      max-width: 45px;
    }
  }
}

.mat-mdc-tab-header {
  border-bottom: 1px solid #dddddd;
  margin-bottom: var(--value-10);

  .mat-mdc-tab {
    height: 35px !important;
    margin-right: 5px;
    font-size: var(--font-size-12);
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    padding: var(--value-0);
    background: var(--tab-default);
    border-radius: 5px 5px 0 0;
    color: var(--black) !important;
    letter-spacing: 1px;
    font-weight: var(--font-weight-500);

    .mdc-tab__ripple {
      display: var(--display-none);
    }

    .mat-mdc-tab-ripple {
      display: var(--display-none);
    }

    .mdc-tab-indicator {
      display: var(--display-none);
    }

    .mdc-tab__text-label {
      color: var(--black) !important;
      letter-spacing: 1px;
      font-weight: var(--font-weight-500);
    }

    &.mdc-tab--active {
      border-bottom: 2px solid var(--primary-purple) !important;
      box-shadow: var(--display-none);
      color: var(--black) !important;
      background: var(--tab-default) !important;

      .mdc-tab__text-label {
        color: var(--black) !important;
        letter-spacing: 1px;
        font-weight: var(--font-weight-500);
      }
    }
  }
}

.page-head,
.page-head-cred {
  position: var(--position-fixed);
  z-index: var(--z-index-9999);
  bottom: var(--value-0);
  right: var(--value-0);
  background-color: var(--white);
  padding: 10px 100px 0 80px;
  box-shadow: 0px -1px 11px -2px rgba(0, 0, 0, 0.35);

  @media (max-width: $media-breakpoint-xxl-screen-down) {
    padding: var(--value-0);
  }

  .mat-mdc-form-field {
    box-shadow: var(--display-none);
    background: var(--white);
    margin-bottom: var(--value-0);
    padding: 0 15px;
    @include border-radius(0);

    @media (max-width: $media-breakpoint-xxl-screen-down) {
      margin-bottom: 5px;
    }

    .mat-mdc-text-field-wrapper {
      background-color: transparent;
    }

    .mdc-line-ripple,
    .mat-mdc-form-field-subscript-wrapper,
    .mat-mdc-form-field-focus-overlay {
      display: var(--display-none);
    }


    .mat-mdc-form-field-underline {
      height: inherit;
    }

    &.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-ripple {
      height: inherit;
    }

    .mat-mdc-form-field-wrapper {
      padding: var(--value-0);

      input:placeholder {
        color: var(--black);
        font-weight: var(--font-weight-500);
      }
    }
  }

  form {
    width: var(--width-100);
  }

  .mdc-button {
    background-color: var(--French-Rose);
    height: 50px;
    margin-top: -10px;
    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.58);
    color: var(--white);
    @include border-radius(0);
  }

  .col-12 {
    padding-right: 15px;
    @include flex-align(flex, center);
  }

  .material-icons {
    font-size: 40px !important;
    transform: rotate(180deg);
  }
}

.custom-width {
  .page-head {
    left: 320px;
    right: var(--value-10);

    @media (max-width: $media-breakpoint-xxl-screen-down) {
      left: 250px;
    }
  }

  .page-head-cred {
    left: 250px;
    right: var(--value-10);
  }
}

.full-width {
  .page-head {
    left: 80px;
    right: var(--value-10);

    @media (max-width: $media-breakpoint-xxl-screen-down) {
      left: 65px;
    }
  }

  .page-head-cred {
    left: 0px;
    right: var(--value-10);
  }
}

.accordion-wrapper {

  .accordion-icon {
    transition: all ease 0.2s;
    background-color: var(--secondary-carnation);
    height: 24px;
    width: auto;
    @include border-radius(4px);
    z-index: var(--z-index-2);
    padding-right: 8px;
    padding-left: 8px;
    position: var(--position-absolute);
    color: var(--white);
    @include flex-align(flex, center);
    cursor: var(--cursor-pointer);
    right: 50px;
    top: 7px;
    left: inherit;

    .material-icons {
      font-size: var(--font-size-16);
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 8px;

      @media (max-width: 768px) {
        font-size: var(--font-size-14);
      }

      transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);

      &:hover {
        transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        transform: scale(1.5);
      }
    }

    .material-symbols-outlined {
      font-size: var(--font-size-16);
      font-size: var(--font-size-16);
      @include flex-center(flex, center, center);


      @media (max-width: 768px) {
        font-size: var(--font-size-14);
      }
    }

    .separator {
      border-right: 1px solid var(--white);
      margin: 0 12px;
      height: 20px;
      opacity: 0.4;

      @media (max-width: 768px) {
        margin: 0 6px;
      }
    }
  }
}

//algo accordion
.algo-accordion {
  width: var(--width-100);

  // &:hover {
  //   .accordion-icon {
  //     opacity: 1;
  //     transition: all ease 0.2s;
  //   }
  // }

  .accordion-icon {
    transition: all ease 0.2s;
    background-color: var(--secondary-carnation);
    height: 24px;
    width: auto;
    @include border-radius(4px);
    z-index: var(--z-index-2);
    padding-right: 8px;
    padding-left: 8px;
    position: var(--position-absolute);
    color: var(--white);
    @include flex-align(flex, center);
    cursor: var(--cursor-pointer);
    right: 50px;
    top: 7px;
    left: inherit;

    .material-icons {
      font-size: var(--font-size-16);
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 8px;
      @media (max-width: 1800px){
        margin: 0 4px;
        width: 15px;
        height: 15px;
      }

      transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);

      &:hover {
        transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        transform: scale(1.5);
      }
    }

    .material-symbols-outlined {
      font-size: var(--font-size-16);
      font-size: var(--font-size-16);
      @include flex-center(flex, center, center);


      @media (max-width: 768px) {
        font-size: var(--font-size-14);
      }
    }

    .separator {
      border-right: 1px solid var(--white);
      margin: 0 12px;
      height: 20px;
      opacity: 0.4;

      @media (max-width: 768px) {
        margin: 0 6px;
      }
    }
  }

  .p-accordion {
    margin-top: 0 !important;
    box-shadow: none !important;
    @include border-radius(0 !important);

    .p-accordion-tab {
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      margin-bottom: 15px;
    }

    .p-accordion-header-link {
      font-family: var(--font-family);
      background-color: var(--gray-100);
      @include border-radius(0);
      box-shadow: 0px 2px 3px rgb(63 63 63 / 10%);
      padding: 0 20px 0 60px;
      height: 38px !important;
      text-decoration: none;
      color: var(--black);
      color: var(--black);
      font-weight: var(--font-weight-500);
      font-family: var(--font-family);
      font-size: var(--font-size-16);

      @media (max-width: 1800px) {
        font-size: var(--font-size-12);
      }

      @media (max-width:425px) {
        font-size: var(--font-size-12);
      }

      .p-accordion-toggle-icon {
        margin-right: 0;
      }

      .p-icon {
        width: 12px;
        height: 12px;
      }

      .p-accordion-header-text {
        color: var(--black);
        font-weight: var(--font-weight-500);
        font-family: var(--font-family);
        font-size: var(--font-size-16);

        @media (max-width: 1800px) {
          font-size: var(--font-size-12);
        }

        @media (max-width:425px) {
          font-size: var(--font-size-12);
        }
      }
    }

    .p-accordion-content {
      padding: 0;
      border-radius: 0;
    }
  }

  .mat-expansion-panel {
    margin-top: 0 !important;
    box-shadow: none !important;
    @include border-radius(0 !important);

    .mat-expansion-panel-header {
      font-family: var(--font-family);
      background-color: var(--gray-100);
      @include border-radius(0);
      box-shadow: 0px 4px 7px rgb(63 63 63 / 10%);

      .mat-expansion-panel-header-title {
        color: var(--black);
        font-weight: var(--font-weight-500);
        font-size: var(--font-size-18);
        font-family: var(--font-family);
        height: 60px;

        @media (max-width: $media-breakpoint-xxl-screen-down) {
          font-size: var(--font-size-12);
        }
      }

      .mat-expansion-indicator {
        &:after {
          color: var(--Ebony);
        }
      }
    }
  }

  .mat-expansion-panel-header {
    padding: 0 50px 0 80px;

    @media (min-width: $media-breakpoint-xxl-screen-up) {
      height: 60px !important;
    }

    @media (max-width: 767px) {
      padding: 0 10px 0 10px;
    }
  }
}

//ag grid
.ag-center-cols-viewport::-webkit-scrollbar {
  display: var(--display-none);
}

.ag-grid-auto-height {
  .ag-theme-material .ag-root-wrapper.ag-layout-normal {
    height: calc(100vh - 435px);
  }
}

.ag-theme-material .ag-ltr .ag-header-select-all {
  margin-right: 24px;
  margin-left: 24px;
}

.combos-grid .ag-ltr .ag-header-select-all {
  margin: 0 !important;
  color: var(--white);
}

.ag-theme-material .ag-ltr .ag-header-select-all {
  margin-right: 24px;
  margin-left: 24px;
}

.ag-theme-material {
  width: var(--width-100);
  border: var(--display-none);
  box-shadow: var(--display-none);
  margin-bottom: var(--value-20);
  font-family: var(--font-family);

  &.journey-grid {
    .ag-body-viewport {
      max-height: 700px;
      overflow-y: auto;
      min-height: 400px;
    }
  }

  &.ag-dnd-ghost {
    width: auto !important;
  }

  &.ag-theme-height {
    .ag-root-wrapper.ag-layout-normal {
      min-height: 500px;
    }
  }

  .ag-body-viewport {
    max-height: 700px;
    overflow-y: auto;
  }

  .ag-header {
    border-bottom: none !important;
    background-color: var(--primary-purple) !important;
    border-radius: 15px 15px 0 0;

    .ag-header-cell,
    .ag-header-group-cell {
      background-color: var(--primary-purple) !important;
      padding-left: 12px;
      padding-right: 12px;
      border-color: var(--Athens-Gray);
      border-top: var(--display-none);
      border-right: 1px solid var(--white) !important;

      &:hover {
        background-color: var(--primary-purple) !important;
      }

      .ag-header-icon {
        opacity: 1 !important;
      }

      .ag-icon {
        color: var(--white);
      }
    }

    .ag-header-cell-label .ag-header-cell-text {
      color: var(--white) !important;
      font-weight: var(--font-weight-500);
      font-size: var(--font-size-12);
    }
  }

  .ag-row {
    border-bottom: 1px solid #f2f1f3;
  }

  .ag-header-viewport {
    border-bottom: none !important;
  }

  .ag-pinned-right-cols-container {
    border-right: 1px solid #f2f1f3;
  }

  .ag-cell {
    line-height: 31px;
    font-size: var(--font-size-12);
    @include flex-align(flex, center);
    padding-left: 12px;
    padding-right: 12px;
    border-color: #f2f1f3;
    border-top: var(--display-none);
    border-bottom: none !important;
    border-right: none !important;

    @media (max-width: 1800px) {
      padding-left: 5px;
    }

    .ag-group-contracted {
      margin-right: 5px;
    }
  }

  .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border-color: #f2f1f3;
    border-top: var(--display-none);
  }

  .ag-body-viewport .ag-center-cols-clipper .ag-body-viewport.ag-layout-normal {
    background-color: transparent;
  }

  .ag-floating-bottom {
    border-top: var(--display-none);
    border-bottom: 1px solid var(--Concrete);
    background-color: var(--Athens-Gray);

    .ag-row {
      background-color: var(--Athens-Gray);
    }
  }

  &.ag-details-grid {
    margin: var(--value-0);
    border: 1px solid #f1f1f1;

    .ag-layout-auto-height {
      height: 188px;
    }

    .ag-header {
      @include border-radius(0);
      background-color: var(--Athens-Gray) !important;

      .ag-header-cell-label .ag-header-cell-text {
        color: #323030 !important;
        font-weight: var(--font-weight-500);
        font-size: var(--font-size-13);
      }

      .ag-header-cell .ag-icon {
        color: #c6c8ca;
      }

      .ag-header-cell .ag-cell-label-container {
        border-right: 1px solid #c6c8ca;
      }
    }
  }

  .ag-paging-panel {
    border-top: var(--display-none);
    color: rgba(0, 0, 0, 0.54);
    flex-direction: column;
    padding: var(--value-0);
    background-color: var(--wild-sand);
    @include border-radius(5px);
    margin-top: var(--value-20);
    margin-bottom: var(--value-20);

    @media (min-width: $media-breakpoint-md-up) {
      height: 30px;
      flex-direction: row;
    }

    .ag-picker-field-wrapper {
      border: var(--display-none);
      @include border-radius(0);
      background-color: var(--wild-sand);
      height: 30px;
      min-height: 30px;
    }

    .ag-paging-row-summary-panel,
    .ag-paging-page-summary-panel {
      background-color: var(--wild-sand);
      display: var(--display-flex);
      margin-bottom: var(--value-10);

      @media (min-width: $media-breakpoint-md-up) {
        margin-bottom: var(--value-0);
      }

      span {
        padding-right: 5px;

        @media (max-width: 425px) {
          padding-right: 1px;
        }
      }
    }

    .ag-paging-row-summary-panel {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .ag-paging-page-summary-panel {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .ag-paging-button {
      .ag-icon {
        color: var(--black);
        opacity: 1;
      }
    }
  }

  // .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
  //   border-left: 1px solid #f2f1f3 !important;
  //   background-color: var(--silver) !important;
  //   border-bottom: 1px solid white !important;
  // }

  .ag-pinned-left-header {
    border-right: var(--display-none);
    margin-left: -1px;
  }

  .ag-root-wrapper.ag-layout-normal {
    height: var(--height-100);
    width: var(--width-100);
    min-height: 600px
  }

  .ag-horizontal-left-spacer,
  .ag-horizontal-right-spacer {
    border-right: var(--display-none);
    overflow: var(--overflow-hidden);
    border-left: var(--display-none);
  }

  .ag-body-horizontal-scroll {
    background-color: var(--gray-100);
  }

  .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track,
  .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb,
  .ag-body-viewport.ag-layout-normal::-webkit-scrollbar-track,
  .ag-body-viewport.ag-layout-normal::-webkit-scrollbar-thumb,
  .ag-primary-cols-list-panel::-webkit-scrollbar-track,
  .ag-primary-cols-list-panel::-webkit-scrollbar-thumb,
  .ag-tool-panel-wrapper::-webkit-scrollbar-track,
  .ag-tool-panel-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--gray-100);
    // @include border-radius(8px);
  }

  .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb,
  .ag-body-viewport.ag-layout-normal::-webkit-scrollbar-thumb,
  .ag-primary-cols-list-panel::-webkit-scrollbar-thumb,
  .ag-virtual-list-viewport::-webkit-scrollbar-thumb,
  .ag-tool-panel-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--Silver-Chalice);
    // @include border-radius(8px);
  }

  .ag-body-horizontal-scroll-viewport::-webkit-scrollbar,
  .ag-primary-cols-list-panel::-webkit-scrollbar {
    height: 10px;
  }

  .ag-body-viewport.ag-layout-normal::-webkit-scrollbar,
  .ag-primary-cols-list-panel::-webkit-scrollbar,
  .ag-virtual-list-viewport::-webkit-scrollbar,
  .ag-tool-panel-wrapper::-webkit-scrollbar {
    width: 10px;
    // @include border-radius(8px);
    background-color: var(--gray-100);
  }
}

//algo tabs
.mat-tab-group {
  &.mat-primary {
    .mat-ink-bar {
      background-color: transparent;
    }
  }
}

.mat-tab-header {
  border-bottom: 1px solid #dddddd;
  margin-bottom: var(--value-10);

  // margin-top: var(--value-10);
  .mat-tab-label {
    border: var(--display-none);
    opacity: 1;
    padding: var(--value-0);
    background: var(--tab-default);
    border-radius: 5px 5px 0px 0px;
    margin-right: 16px;
    color: var(--black);
    letter-spacing: 1px;
    width: 150px;
    height: 38px;
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-12);
    line-height: 16px;

    @media (min-width: $media-breakpoint-md-up) {
      width: 200px;
      height: 45px;
    }

    @media (max-width: 1800px) {
      width: 150px;
    }

    &.mat-tab-label-active {
      border-bottom: 2px solid var(--primary-purple);
      box-shadow: var(--display-none);
      color: var(--black);
      background: var(--tab-default);
    }

    .mat-tab-label-content {
      span {
        margin-left: 0 !important;
      }
    }
  }

  .mat-tab-list {
    @media (max-width:425px) {
      transform: translateX(-114px);
    }
  }

  .mat-tab-header-pagination {
    @media (min-width: 375px) {
      min-width: 26px;
    }
  }
}

.show-menu {
  display: var(--display-block);
  margin-bottom: 8px;

  @media (min-width: 1025px) {
    display: var(--display-none);
  }
  .pi-ellipsis-v{
    color: var(--white);
  }
}

.show-sideicons {
  display: var(--display-none);

  @media (min-width: 1025px) {
    display: var(--display-block);
  }
}

.right-side-mat-menu-notification {
  background-color: var(--mercury);
  display: var(--display-block);

  @media (min-width: 1025px) {
    display: var(--display-none);
  }

  &.mat-menu-panel {
    min-width: 50px;
  }

  .mat-menu-content:not(:empty) {
    @include flex-column(flex, column, center, center);
  }

  .sidenav-icon {
    border-bottom: 1px solid #b9b9b9;

    .sidenav-icons {
      .material-icons {
        color: var(--rolling-stone);
        font-size: var(--font-size-30);

        @media (max-width: $media-breakpoint-lg-down) {
          font-size: var(--font-size-22);
        }
      }
    }
  }
}

.notfound {
  @include flex-column(flex, column, center, center);
  height: calc(100vh - 130px);

  &.in-progress {
    .notfound-404 {
      h1 {
        font-size: 100px;
        text-transform: uppercase;
      }
    }

    img {
      max-width: 200px;
    }
  }

  .notfound-404 {
    h1 {
      color: var(--tab-default);
      font-weight: var(--font-weight-900);
      font-size: 270px;
      margin: var(--value-0);
    }
  }

  h2 {
    font-size: 46px;
    color: var(--black);
    font-weight: var(--font-weight-900);
    text-transform: uppercase;
  }

  p {
    font-size: var(--font-size-16);
    color: var(--black);
    font-weight: var(--font-weight-400);
    margin-top: 15px;
  }
}

app-base-dialog {
  .mat-dialog-actions {
    .btn {
      margin-left: var(--value-10);
    }
  }
}

.grain-level-grid .ag-theme-material .ag-root-wrapper.ag-layout-normal {
  height: var(--height-100);
  width: var(--width-100);
  min-height: 500px !important;
}

.no-favorites-items {
  background-color: var(--gray-100) !important;
  height: 40px;
  @include flex-align(flex, center);
  padding: 0 10px 0 24px;

  @media (max-width: 1800px) {
    height: 30px;
  }

  span {
    font-size: var(--font-size-12);
    line-height: 12px;

    @media (max-width: 1800px) {
      font-size: var(--font-size-10);
    }
  }
}